import React, { Component } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  InputAdornment,
  IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Joyride from 'react-joyride';
import Map from './Map';  
import ResultsPanel from './ResultsPanel';  
import RatioDisplay from './RatioDisplay'; 
import ratioData from './results_level_5_year_ratio.json'; 
import PublicationTimeline from './PublicationTimeline'; 
import publicationData from './publication_history_level_5.json'; 
import AuthorsPanel from './AuthorsPanel';

class App extends Component {
  state = {
    showAll: true,
    showLegend: true,
    preSearch: '',
    searchQuery: '',
    preAuthorSearch: '',
    authorSearchQuery: '',
    filterEnabled: true,  
    generateConnections: true,
    isPopupOpen: true, 
    chips: ["Algebra", "Geometry", "Number Theory", "Topology", "68", "68T", "68T30"], 
    Authorchips: ["Hoffmann Heiko", "Hosseini Maliheh", "Honma Dai", "Novikova Natalia M"], 

    steps: [
      {
        target: '.show-overlay',
        content: 'Clicking on this checkbox, you can enable the interactive mode.',
        disableBeacon: true,
        placement: "right"
      },
      {
        target: '.show-legend', 
        content: 'This checkbox ensures that the legend in bottom right corner of the map is on or off. Colors in the legens indicate the number of publications in a ceratin class.',
        disableBeacon: true,
        placement: "right"
      },
      {
        target: '.search-field', 
        content: 'Use this search bar to find classes of publications by cc code or field name in ZbMath API. If Filter checkbox is enabled, then search will filter out all classes which do not match the search query',
        disableBeacon: true,
        placement: "right"
      },
      {
        target: '.show-filter', 
        content: 'If you want to prevent search from filtering out unmatched classes in the map, disable this checkbox.',
        disableBeacon: true,
        placement: "right"
      },
      {
        target: '.author-search-field', 
        content: 'Use this search bar to find authors by their name and surname in ZbMath API. Results of the search have colorcoding using linear interpolation, where red indicates the classes with highest number of papers published, while blue indicates the classes with the lowest number of publications.',
        disableBeacon: true,
        placement: "right"
      },
      {
        target: '.chips-box', 
        content: 'You can use example queries for classes to get to know better how to use the search tool',
        disableBeacon: true,
        placement: "right"
      },
      {
        target: '.authors-chips-box', 
        content: 'You can use example queries for authors to get to know better how to use the search tool',
        disableBeacon: true,
        placement: "right"
      },
      {
        target: '.map-container', 
        content: 'Here you can see two maps, on the left side: CycleOSM Layout and on the right side classical Carto OSM Layout. You can regulate the size of every layout by the toggle in the middle of the map. In the left top corner of the map, there is a screenshot button for exporting the map as an image.',
        disableBeacon: true
      }
    ],
    run: false  
  };

  toggleAll = () => {
    this.setState(prevState => ({ showAll: !prevState.showAll }));
  };

  toggleLegend = () => {
    this.setState(prevState => ({ showLegend: !prevState.showLegend }));
  };

  toggleFilter = () => {
    this.setState(prevState => ({ filterEnabled: !prevState.filterEnabled }));
  };

  toggleGenerateConnections = () => {
    this.setState((prevState) => ({
      generateConnections: !prevState.generateConnections,
    }));
  };

  togglePopup = () => {
    this.setState(prevState => ({ isPopupOpen: !prevState.isPopupOpen }));
  };

  handleSearchChange = event => {
    this.setState({ preSearch: event.target.value });
  };

  handleAuthorSearchChange = event => {
    this.setState({ preAuthorSearch: event.target.value });
  };

  handleFeatureClick = featureName => {
    this.setState({ searchQuery: featureName });
    this.setState({ preSearch: featureName });
  };

  handleChipClick = chipValue => {
    this.setState({ searchQuery: chipValue });
    this.setState({ preSearch: chipValue });
  };

  handleAuthorChipClick = chipValue => {
    this.setState({ authorSearchQuery: chipValue });
    this.setState({ preAuthorSearch: chipValue });
  };

  handleChipDelete = chipToDelete => {
    this.setState(prevState => ({
      chips: prevState.chips.filter(chip => chip !== chipToDelete)
    }));
  };

  handleAuthorChipDelete = chipToDelete => {
    this.setState(prevState => ({
      Authorchips: prevState.Authorchips.filter(chip => chip !== chipToDelete)
    }));
  };

  handleClearSearch = () => {
    this.setState({ searchQuery: '' });
    this.setState({ preSearch: '' });
  };

  handleAuthorClearSearch = () => {
    this.setState({ authorSearchQuery: '' });
    this.setState({ preAuthorSearch: '' });
  };

  startTour = () => {
    this.setState({ run: true, isPopupOpen: false });
  };

  handleSearch = () => {
    this.setState({ searchQuery: this.state.preSearch });
  };

  handleAuthorSearch = () => {
    this.setState({ authorSearchQuery: this.state.preAuthorSearch });
  };
  

  render() {
    const { steps, run, isPopupOpen, chips, Authorchips } = this.state;

    return (
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <Box
          sx={{
            width: 350,
            backgroundColor: '#f0f0f0',
            padding: 2,
            borderRight: '1px solid #ccc',
            overflowY: 'auto',
          }}className="box"
        >
          <Typography variant="h3" gutterBottom>
            OpenMathMap v2.0
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={this.state.showAll} onChange={this.toggleAll} />}
              label="Interactive mode"
              className='show-overlay'
            />
            <FormControlLabel
              control={<Checkbox checked={this.state.showLegend} onChange={this.toggleLegend} />}
              label="Show Legend"
              className='show-legend'
            />
            <FormControlLabel
              control={<Checkbox checked={this.state.filterEnabled} onChange={this.toggleFilter} />}
              label="Enable Filter"
              className='show-filter'
            />
            <FormControlLabel
            control={
              <Checkbox
                checked={this.state.generateConnections}
                onChange={this.toggleGenerateConnections}
              />
            }
            label="Generate Connections"
            />
            <TextField
              label="Search by cc or by name of the field"
              variant="outlined"
              value={this.state.preSearch}
              onChange={this.handleSearchChange}
              fullWidth
              margin="normal"
              className="search-field"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      onClick={this.handleClearSearch}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button endIcon={<SearchIcon />}
            variant="contained"
            color="primary"
            onClick={this.handleSearch}
            style={{ marginTop: '10px' }}
          >
            Search
          </Button>
            <Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: '10px' }} className="chips-box">
                {chips.map(chip => (
                  <Chip
                    key={chip}
                    label={chip}
                    variant="outlined"
                    onClick={() => this.handleChipClick(chip)}
                    onDelete={() => this.handleChipDelete(chip)}
                    color={/\d/.test(chip) ? 'secondary' : 'primary'} 
                  />
                ))}
              </Box>
            </Box>
            <ResultsPanel searchQuery={this.state.searchQuery} className="results-panel" />
            <TextField
              label="Search by authors"
              variant="outlined"
              value={this.state.preAuthorSearch}
              onChange={this.handleAuthorSearchChange}
              fullWidth
              margin="normal"
              className="author-search-field"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      onClick={this.handleAuthorClearSearch}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button endIcon={<SearchIcon />}
            variant="contained"
            color="primary"
            onClick={this.handleAuthorSearch}
            style={{ marginTop: '10px' }}
          >
            Search Authors
          </Button>
            <Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: '10px'  }} className="authors-chips-box">
                {Authorchips.map(chip => (
                  <Chip
                    key={chip}
                    label={chip}
                    variant="outlined"
                    onClick={() => this.handleAuthorChipClick(chip)}
                    onDelete={() => this.handleAuthorChipDelete(chip)}
                    color="primary"
                  />
                ))}
              </Box>
            </Box>
            <AuthorsPanel searchQuery={this.state.authorSearchQuery} className="authors-panel" />
            <RatioDisplay searchQuery={this.state.searchQuery} ratioData={ratioData} />
            <PublicationTimeline publicationData={publicationData} searchQuery={this.state.searchQuery} />

          </FormGroup>
        </Box>

        <Box sx={{ flex: 1, position: 'relative' }} className="map-container">
          <Map
            showAll={this.state.showAll}
            showLegend={this.state.showLegend}
            searchQuery={this.state.searchQuery}
            authorSearchQuery={this.state.authorSearchQuery}
            filterEnabled={this.state.filterEnabled} 
            onFeatureClick={this.handleFeatureClick} 
            generateConnections={this.state.generateConnections} 
          />
        </Box>

        <Joyride
          steps={steps}
          styles={{
            options: {
              arrowColor: '#ffffff',
              backgroundColor: '#ffffff',
              overlayColor: 'rgba(83, 83, 83, 0.4)',
              primaryColor: '#42a5f5',
              textColor: '#000',
              width: 900,
              zIndex: 1000,
            },
          }}
          run={run}
          continuous={true}
          showSkipButton={true}
          locale={{
            last: 'End tour',
            skip: 'Skip tour'
          }}
        />

        <Dialog open={isPopupOpen} onClose={this.togglePopup}>
          <DialogTitle>Welcome to OpenMathMap v2.0</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to start a tour to learn how to use the map?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.startTour} color="primary">
              Yes
            </Button>
            <Button onClick={this.togglePopup} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default App;
