import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography } from '@mui/material';

const GetClassNames = ({ searchQuery = '' }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!searchQuery || !/\d/.test(searchQuery) || searchQuery.trim() === '' || ![2, 3, 5].includes(searchQuery.length)) {
        setResults(null);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`https://backend.mathmap.kwarc.info/api/search?q=${encodeURIComponent(searchQuery)}`);
        setResults(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!results) {
    return <Typography>No results found.</Typography>;
  }

  return (
    <Typography>
      {results.result?.short_title || "No title available"}
    </Typography>
  );
};

export default GetClassNames;
