import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,LinearProgress } from '@mui/material';

const AuthorsPanel = ({ searchQuery }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (searchQuery.trim() === '') {
        setResults(null);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`https://backend.mathmap.kwarc.info/api/author-publications?author=${encodeURIComponent(searchQuery)}`);
        setResults(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]);

  if (loading) {
    return (<Box><Typography>Loading...</Typography>
      <LinearProgress /></Box>);  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!results) {
    return <Typography>No results found.</Typography>;
  }

  const resultsArray = Object.entries(results);

  if (resultsArray.length === 0) {
    return <Typography>No results found for '{searchQuery}'.</Typography>;
  }

  return (
    <Box sx={{ margin: 2, padding: 2, border: '1px solid #ccc', borderRadius: 4, backgroundColor: '#fff' }}>
      <Typography variant="h5" gutterBottom>
        Results for '{searchQuery}':
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f0f0f0' }}> {/* Add background color */}
              <TableCell><strong>Class Name</strong></TableCell>
              <TableCell align="right"><strong>Number of papers</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultsArray.map(([className, count], index) => (
              <TableRow key={className} sx={index === 0 ? { fontWeight: 'bold' } : {}}>
                <TableCell>{index === 0 ? <strong>Author's Home {className}</strong> : className}</TableCell>
                <TableCell align="right">{count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AuthorsPanel;
