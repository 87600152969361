import React from 'react';
import { Typography } from '@mui/material';
import { Timeline, Grid } from 'rsuite';

const PublicationTimeline = ({ publicationData, searchQuery }) => {
  if (!searchQuery || !publicationData[searchQuery]) {
    return null; 
  }

  const years = Object.keys(publicationData[searchQuery]);

  return (
    <Grid fluid>
          <Timeline align="alternate">
            {years.map(year => (
              <Timeline.Item key={year}>
                <Typography variant="subtitle1">{year}</Typography>
                <Typography>{publicationData[searchQuery][year]} papers published</Typography>
              </Timeline.Item>
            ))}
          </Timeline>
    </Grid>
  );
};

export default PublicationTimeline;
