import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, List, ListItem, Link,LinearProgress } from '@mui/material';

function extractZbMathIdFromUrl(url) {
  
  const parts = url.split('cc:');
  
  if (parts.length < 2) {
    return null; 
  }
  
  const idPart = parts[1].trim().replace(/\/$/, ''); 
  
  return idPart;
}

const ResultsPanel = ({ searchQuery }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let modifiedSearchQuery = searchQuery.trim();

      if (/\d/.test(modifiedSearchQuery)) {
        if (modifiedSearchQuery.length === 2) {
          modifiedSearchQuery += "-XX";
        } else if (modifiedSearchQuery.length === 3) {
          modifiedSearchQuery += "XX";
        }
      }
  
      if ((/\d/.test(modifiedSearchQuery)) && (![2, 3, 5].includes(modifiedSearchQuery.length))) {
        setResults(null);
        return;
      }


      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`https://backend.mathmap.kwarc.info/api/search?q=${encodeURIComponent(modifiedSearchQuery)}`);
        setResults(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]);

  if (loading) {
    return (<Box><Typography>Loading...</Typography>
      <LinearProgress /></Box>);
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!results || typeof results.result === 'undefined') {
    return <Typography>No results found.</Typography>;
  }

  return (
    <Box sx={{ margin: 2, padding: 2, border: '1px solid #ccc', borderRadius: 4, backgroundColor: '#fff' }}>
      <Typography variant="h5" gutterBottom>
        Results:
      </Typography>
      <List>
        {Array.isArray(results.result) ? (
          results.result.map((item, index) => (
            <ListItem key={index}>
              {renderResultItem(item)}
            </ListItem>
          ))
        ) : (
          <ListItem>
            {renderResultItem(results.result)}
          </ListItem>
        )}
      </List>
      <img src="https://zbmath.org/static/zbMATH@2x.gif" alt="ZbMath Logo" style={{ width: '100%', maxWidth: 200, marginTop: 10 }} />
    </Box>
  );
};

const renderResultItem = (item) => {
  if (!item) {
    return null;
  }

  return (
    <>
      <Typography variant="body1">
        <strong>{extractZbMathIdFromUrl(item.zbmath_url)}</strong>
      </Typography>
      {item.zbmath_url && (
        <Link href={item.zbmath_url} target="_blank" rel="noopener noreferrer">
          <Typography variant="body1">
            <strong>{item.long_title}</strong>
          </Typography>
        </Link>
      )}
    </>
  );
};

export default ResultsPanel;
