const citiesData = {
    "type": "FeatureCollection", "features": [
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.55972,12.90908]},"properties":{"population":717395,"capital":4,"place":"city","name":"00-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.59149,16.0877]},"properties":{"population":598645,"capital":4,"place":"city","name":"00-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.62062,-2.09476]},"properties":{"population":639859,"capital":4,"place":"city","name":"00-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.94876,12.04601]},"properties":{"population":813710,"capital":4,"place":"city","name":"00A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.78591,11.79248]},"properties":{"population":822897,"capital":4,"place":"city","name":"00A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.60739,12.72501]},"properties":{"population":758781,"capital":4,"place":"city","name":"00A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.59382,12.46925]},"properties":{"population":729505,"capital":4,"place":"city","name":"00A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.1865,7.9666]},"properties":{"population":708254,"capital":4,"place":"city","name":"00A09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.56047,17.9189]},"properties":{"population":693049,"capital":4,"place":"city","name":"00A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.6816,12.33373]},"properties":{"population":809346,"capital":4,"place":"city","name":"00A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.89204,15.20601]},"properties":{"population":641673,"capital":4,"place":"city","name":"00A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.81375,14.76149]},"properties":{"population":566988,"capital":4,"place":"city","name":"00A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.25766,14.30721]},"properties":{"population":346573,"capital":4,"place":"city","name":"00A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.83497,9.29699]},"properties":{"population":874225,"capital":4,"place":"city","name":"00A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.53288,-4.45948]},"properties":{"population":817272,"capital":4,"place":"city","name":"00A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.08134,14.28236]},"properties":{"population":355534,"capital":4,"place":"city","name":"00A64"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.66385,9.42667]},"properties":{"population":663725,"capital":4,"place":"city","name":"00A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.86518,11.60914]},"properties":{"population":631716,"capital":4,"place":"city","name":"00A66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.87674,12.19137]},"properties":{"population":597126,"capital":4,"place":"city","name":"00A67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.54448,12.38693]},"properties":{"population":762608,"capital":4,"place":"city","name":"00A69"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.54767,9.89831]},"properties":{"population":710085,"capital":4,"place":"city","name":"00A71"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.5669,9.87366]},"properties":{"population":557215,"capital":4,"place":"city","name":"00A72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.89405,14.99753]},"properties":{"population":460517,"capital":4,"place":"city","name":"00A73"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.39186,12.84818]},"properties":{"population":833158,"capital":4,"place":"city","name":"00A79"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.45771,-3.01239]},"properties":{"population":686901,"capital":4,"place":"city","name":"00A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.24292,14.0042]},"properties":{"population":336729,"capital":4,"place":"city","name":"00Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.3112,17.37534]},"properties":{"population":734148,"capital":4,"place":"city","name":"00B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.60023,15.15959]},"properties":{"population":573009,"capital":4,"place":"city","name":"00B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.60953,9.76391]},"properties":{"population":935625,"capital":4,"place":"city","name":"00B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.69089,14.62548]},"properties":{"population":506890,"capital":4,"place":"city","name":"00B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.50359,-2.93427]},"properties":{"population":1029272,"capital":4,"place":"city","name":"00B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.89804,11.30728]},"properties":{"population":786633,"capital":4,"place":"city","name":"00B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.10221,14.6192]},"properties":{"population":414313,"capital":4,"place":"city","name":"00B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.08613,14.18232]},"properties":{"population":426267,"capital":4,"place":"city","name":"00B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.5491,9.71586]},"properties":{"population":610031,"capital":4,"place":"city","name":"00B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.24756,13.93807]},"properties":{"population":219722,"capital":4,"place":"city","name":"00B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.34994,20.72848]},"properties":{"population":875258,"capital":4,"place":"city","name":"00Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.10102,16.74484]},"properties":{"population":494164,"capital":4,"place":"city","name":"01-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.01092,6.9196]},"properties":{"population":651619,"capital":4,"place":"city","name":"01-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.19462,15.80026]},"properties":{"population":759639,"capital":4,"place":"city","name":"01-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.37938,16.24671]},"properties":{"population":686171,"capital":4,"place":"city","name":"01-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.0766,16.78469]},"properties":{"population":219722,"capital":4,"place":"city","name":"01-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.01808,16.4854]},"properties":{"population":530330,"capital":4,"place":"city","name":"01-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.37176,8.08451]},"properties":{"population":764156,"capital":4,"place":"city","name":"01A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.63711,14.78596]},"properties":{"population":504342,"capital":4,"place":"city","name":"01A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.76061,14.61276]},"properties":{"population":358351,"capital":4,"place":"city","name":"01A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.8954,14.32078]},"properties":{"population":138629,"capital":4,"place":"city","name":"01A11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.0496,14.24021]},"properties":{"population":426267,"capital":4,"place":"city","name":"01A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.08729,14.41807]},"properties":{"population":406044,"capital":4,"place":"city","name":"01A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.75245,14.81438]},"properties":{"population":475359,"capital":4,"place":"city","name":"01A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.17578,7.59311]},"properties":{"population":517048,"capital":4,"place":"city","name":"01A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.00223,14.70246]},"properties":{"population":614203,"capital":4,"place":"city","name":"01A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.85228,10.85094]},"properties":{"population":771512,"capital":4,"place":"city","name":"01A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.8046,6.76122]},"properties":{"population":668835,"capital":4,"place":"city","name":"01A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.78298,14.97638]},"properties":{"population":622455,"capital":4,"place":"city","name":"01A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.01027,14.323]},"properties":{"population":283321,"capital":4,"place":"city","name":"01A29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.5865,15.42375]},"properties":{"population":729844,"capital":4,"place":"city","name":"01A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.15143,5.58377]},"properties":{"population":688038,"capital":4,"place":"city","name":"01A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.75074,9.79168]},"properties":{"population":716394,"capital":4,"place":"city","name":"01A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.66199,11.96915]},"properties":{"population":740549,"capital":4,"place":"city","name":"01A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.73537,11.45123]},"properties":{"population":811102,"capital":4,"place":"city","name":"01A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.86111,8.0306]},"properties":{"population":805452,"capital":4,"place":"city","name":"01A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.61902,2.77768]},"properties":{"population":888544,"capital":4,"place":"city","name":"01A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.95158,7.053]},"properties":{"population":961293,"capital":4,"place":"city","name":"01A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.44073,7.6793]},"properties":{"population":662406,"capital":4,"place":"city","name":"01A61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.00639,-4.75005]},"properties":{"population":775061,"capital":4,"place":"city","name":"01A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.45523,15.63553]},"properties":{"population":537527,"capital":4,"place":"city","name":"01A67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.36634,-0.19411]},"properties":{"population":1006032,"capital":4,"place":"city","name":"01A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.24446,7.29516]},"properties":{"population":667456,"capital":4,"place":"city","name":"01A72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.20396,5.77232]},"properties":{"population":657228,"capital":4,"place":"city","name":"01A73"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.75655,15.4968]},"properties":{"population":692951,"capital":4,"place":"city","name":"01A74"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.63629,2.61382]},"properties":{"population":838685,"capital":4,"place":"city","name":"01A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.23026,8.35993]},"properties":{"population":722911,"capital":4,"place":"city","name":"01A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.44749,3.94287]},"properties":{"population":550938,"capital":4,"place":"city","name":"01A85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.01325,7.13207]},"properties":{"population":601615,"capital":4,"place":"city","name":"01A90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.07851,9.04928]},"properties":{"population":692657,"capital":4,"place":"city","name":"01A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.81725,14.7721]},"properties":{"population":358351,"capital":4,"place":"city","name":"01Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.57694,10.86175]},"properties":{"population":458496,"capital":4,"place":"city","name":"03-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.69337,4.60715]},"properties":{"population":750878,"capital":4,"place":"city","name":"03-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.05833,9.26286]},"properties":{"population":788870,"capital":4,"place":"city","name":"03-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.54031,4.10948]},"properties":{"population":836147,"capital":4,"place":"city","name":"03-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.35631,11.70512]},"properties":{"population":516478,"capital":4,"place":"city","name":"03-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.02744,9.38908]},"properties":{"population":782324,"capital":4,"place":"city","name":"03-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.96522,11.89056]},"properties":{"population":69314,"capital":4,"place":"city","name":"03-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.28736,11.32496]},"properties":{"population":734407,"capital":4,"place":"city","name":"03-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.16352,6.55593]},"properties":{"population":940705,"capital":4,"place":"city","name":"03A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.45122,11.75013]},"properties":{"population":682328,"capital":4,"place":"city","name":"03A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.75195,13.64951]},"properties":{"population":299573,"capital":4,"place":"city","name":"03A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.51047,13.92357]},"properties":{"population":239789,"capital":4,"place":"city","name":"03Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.49684,5.91129]},"properties":{"population":753849,"capital":4,"place":"city","name":"03B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.87339,10.96989]},"properties":{"population":753849,"capital":4,"place":"city","name":"03B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.95232,-0.16568]},"properties":{"population":727655,"capital":4,"place":"city","name":"03B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.60699,15.48901]},"properties":{"population":421950,"capital":4,"place":"city","name":"03B16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.24356,5.73243]},"properties":{"population":752185,"capital":4,"place":"city","name":"03B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.60075,16.41701]},"properties":{"population":634563,"capital":4,"place":"city","name":"03B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.85802,9.96571]},"properties":{"population":829054,"capital":4,"place":"city","name":"03B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.67511,9.3215]},"properties":{"population":709090,"capital":4,"place":"city","name":"03B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.7437,8.85707]},"properties":{"population":829204,"capital":4,"place":"city","name":"03B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.41466,15.74332]},"properties":{"population":487519,"capital":4,"place":"city","name":"03B38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.91044,7.31599]},"properties":{"population":811552,"capital":4,"place":"city","name":"03B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.679,12.00537]},"properties":{"population":781116,"capital":4,"place":"city","name":"03B42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.86587,10.50123]},"properties":{"population":774196,"capital":4,"place":"city","name":"03B44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.18697,3.93367]},"properties":{"population":898869,"capital":4,"place":"city","name":"03B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.52257,-1.60479]},"properties":{"population":721670,"capital":4,"place":"city","name":"03B47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.14226,8.21634]},"properties":{"population":723417,"capital":4,"place":"city","name":"03B48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.85327,10.33219]},"properties":{"population":822977,"capital":4,"place":"city","name":"03B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.03713,9.15438]},"properties":{"population":823747,"capital":4,"place":"city","name":"03B52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.61443,16.08678]},"properties":{"population":710085,"capital":4,"place":"city","name":"03B53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.48925,6.08175]},"properties":{"population":685961,"capital":4,"place":"city","name":"03B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.18498,5.88461]},"properties":{"population":792153,"capital":4,"place":"city","name":"03B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.14669,5.96143]},"properties":{"population":510594,"capital":4,"place":"city","name":"03B62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.32021,3.878]},"properties":{"population":768156,"capital":4,"place":"city","name":"03B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.76416,-1.98659]},"properties":{"population":873761,"capital":4,"place":"city","name":"03B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.23426,8.7785]},"properties":{"population":626339,"capital":4,"place":"city","name":"03B80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.0789,18.21021]},"properties":{"population":727239,"capital":4,"place":"city","name":"03B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.69626,16.02318]},"properties":{"population":554126,"capital":4,"place":"city","name":"03Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.89875,-1.45174]},"properties":{"population":691671,"capital":4,"place":"city","name":"03C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.85833,8.85498]},"properties":{"population":638181,"capital":4,"place":"city","name":"03C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.56835,2.44464]},"properties":{"population":682001,"capital":4,"place":"city","name":"03C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.96534,8.65653]},"properties":{"population":684481,"capital":4,"place":"city","name":"03C13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.36735,6.0412]},"properties":{"population":631716,"capital":4,"place":"city","name":"03C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.72459,2.60623]},"properties":{"population":640191,"capital":4,"place":"city","name":"03C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.89786,17.56256]},"properties":{"population":531811,"capital":4,"place":"city","name":"03C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.76776,9.52934]},"properties":{"population":583188,"capital":4,"place":"city","name":"03C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.76104,18.81966]},"properties":{"population":708086,"capital":4,"place":"city","name":"03C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.79097,18.78105]},"properties":{"population":669826,"capital":4,"place":"city","name":"03C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.8526,8.87403]},"properties":{"population":751914,"capital":4,"place":"city","name":"03C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.28597,8.5067]},"properties":{"population":474493,"capital":4,"place":"city","name":"03C48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.46978,19.08894]},"properties":{"population":645676,"capital":4,"place":"city","name":"03C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.01501,-0.93825]},"properties":{"population":602102,"capital":4,"place":"city","name":"03C52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.66888,2.30795]},"properties":{"population":570711,"capital":4,"place":"city","name":"03C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.47732,4.05]},"properties":{"population":689871,"capital":4,"place":"city","name":"03C57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.90906,-0.0002]},"properties":{"population":803980,"capital":4,"place":"city","name":"03C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.97005,0.35049]},"properties":{"population":659714,"capital":4,"place":"city","name":"03C62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.39956,6.28754]},"properties":{"population":673933,"capital":4,"place":"city","name":"03C64"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.54806,19.56448]},"properties":{"population":617170,"capital":4,"place":"city","name":"03C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.96067,16.19094]},"properties":{"population":248490,"capital":4,"place":"city","name":"03C66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.87544,17.56362]},"properties":{"population":646302,"capital":4,"place":"city","name":"03C68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.84566,16.67764]},"properties":{"population":491998,"capital":4,"place":"city","name":"03C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.95238,9.65494]},"properties":{"population":645676,"capital":4,"place":"city","name":"03C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.84192,-2.65132]},"properties":{"population":672982,"capital":4,"place":"city","name":"03C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.00528,8.65468]},"properties":{"population":547646,"capital":4,"place":"city","name":"03C85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.69894,6.41743]},"properties":{"population":611589,"capital":4,"place":"city","name":"03C90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.88917,11.02697]},"properties":{"population":574300,"capital":4,"place":"city","name":"03C95"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.18142,8.08938]},"properties":{"population":583481,"capital":4,"place":"city","name":"03C98"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.47079,18.99492]},"properties":{"population":666568,"capital":4,"place":"city","name":"03C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.84647,16.47196]},"properties":{"population":499043,"capital":4,"place":"city","name":"03Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.82099,17.40639]},"properties":{"population":592157,"capital":4,"place":"city","name":"03D03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.90963,7.80375]},"properties":{"population":767600,"capital":4,"place":"city","name":"03D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.48318,2.23584]},"properties":{"population":728482,"capital":4,"place":"city","name":"03D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.89112,6.97554]},"properties":{"population":796831,"capital":4,"place":"city","name":"03D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.85128,3.63546]},"properties":{"population":748211,"capital":4,"place":"city","name":"03D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.71106,-4.70605]},"properties":{"population":749776,"capital":4,"place":"city","name":"03D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.36492,3.80352]},"properties":{"population":597888,"capital":4,"place":"city","name":"03D28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.2181,5.86726]},"properties":{"population":732052,"capital":4,"place":"city","name":"03D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.88875,8.82189]},"properties":{"population":607304,"capital":4,"place":"city","name":"03D32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.67805,9.12954]},"properties":{"population":675343,"capital":4,"place":"city","name":"03D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.79416,18.54717]},"properties":{"population":641345,"capital":4,"place":"city","name":"03D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.54587,4.21964]},"properties":{"population":733367,"capital":4,"place":"city","name":"03D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.9753,16.8627]},"properties":{"population":542495,"capital":4,"place":"city","name":"03D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.18987,6.51334]},"properties":{"population":649375,"capital":4,"place":"city","name":"03D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.52094,-1.72076]},"properties":{"population":695559,"capital":4,"place":"city","name":"03D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.83237,17.91597]},"properties":{"population":612905,"capital":4,"place":"city","name":"03D65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.54688,17.28627]},"properties":{"population":504985,"capital":4,"place":"city","name":"03D70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.53351,17.4677]},"properties":{"population":589989,"capital":4,"place":"city","name":"03D75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.56798,6.44535]},"properties":{"population":608221,"capital":4,"place":"city","name":"03D78"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.10727,7.35839]},"properties":{"population":671174,"capital":4,"place":"city","name":"03D80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.12835,17.79778]},"properties":{"population":625958,"capital":4,"place":"city","name":"03D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.99143,16.93513]},"properties":{"population":526269,"capital":4,"place":"city","name":"03Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.08929,7.71295]},"properties":{"population":570044,"capital":4,"place":"city","name":"03E02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.18347,6.83663]},"properties":{"population":546806,"capital":4,"place":"city","name":"03E04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.92207,4.73631]},"properties":{"population":789803,"capital":4,"place":"city","name":"03E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.20458,-2.86174]},"properties":{"population":690274,"capital":4,"place":"city","name":"03E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.91121,5.25664]},"properties":{"population":799395,"capital":4,"place":"city","name":"03E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.85728,5.23284]},"properties":{"population":620657,"capital":4,"place":"city","name":"03E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.59494,25.19979]},"properties":{"population":731721,"capital":4,"place":"city","name":"03E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.23163,5.35376]},"properties":{"population":710085,"capital":4,"place":"city","name":"03E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.58545,25.86618]},"properties":{"population":668959,"capital":4,"place":"city","name":"03E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.87312,5.0592]},"properties":{"population":824485,"capital":4,"place":"city","name":"03E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.64546,6.53044]},"properties":{"population":674170,"capital":4,"place":"city","name":"03E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.64286,4.30219]},"properties":{"population":676272,"capital":4,"place":"city","name":"03E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.87959,25.86324]},"properties":{"population":414313,"capital":4,"place":"city","name":"03E47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.90602,5.11568]},"properties":{"population":726122,"capital":4,"place":"city","name":"03E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.07652,7.99029]},"properties":{"population":771735,"capital":4,"place":"city","name":"03E55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.68023,25.7686]},"properties":{"population":468213,"capital":4,"place":"city","name":"03E57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.94517,4.92805]},"properties":{"population":629710,"capital":4,"place":"city","name":"03E60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.21725,24.95268]},"properties":{"population":598393,"capital":4,"place":"city","name":"03E65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.71847,4.80964]},"properties":{"population":714045,"capital":4,"place":"city","name":"03E70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.16335,6.37055]},"properties":{"population":927799,"capital":4,"place":"city","name":"03E72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.77158,4.61921]},"properties":{"population":652062,"capital":4,"place":"city","name":"03E75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.29257,24.85173]},"properties":{"population":650578,"capital":4,"place":"city","name":"03E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.83988,25.92754]},"properties":{"population":622653,"capital":4,"place":"city","name":"03Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.33855,8.41712]},"properties":{"population":685012,"capital":4,"place":"city","name":"03F03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.67242,9.07621]},"properties":{"population":750988,"capital":4,"place":"city","name":"03F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.20298,7.01372]},"properties":{"population":662936,"capital":4,"place":"city","name":"03F07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.80292,7.99792]},"properties":{"population":553338,"capital":4,"place":"city","name":"03F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.76041,17.89804]},"properties":{"population":613772,"capital":4,"place":"city","name":"03F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.37012,7.60952]},"properties":{"population":701481,"capital":4,"place":"city","name":"03F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.99373,9.74499]},"properties":{"population":602344,"capital":4,"place":"city","name":"03F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.86762,4.55449]},"properties":{"population":752563,"capital":4,"place":"city","name":"03F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.95796,9.62251]},"properties":{"population":704403,"capital":4,"place":"city","name":"03F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.14356,17.84324]},"properties":{"population":573979,"capital":4,"place":"city","name":"03F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.68796,10.99351]},"properties":{"population":566642,"capital":4,"place":"city","name":"03F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.36231,-4.73504]},"properties":{"population":661204,"capital":4,"place":"city","name":"03F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.99886,5.52121]},"properties":{"population":678784,"capital":4,"place":"city","name":"03F52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.2342,-0.47486]},"properties":{"population":696885,"capital":4,"place":"city","name":"03F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.74195,6.7053]},"properties":{"population":700306,"capital":4,"place":"city","name":"03F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.49058,19.39209]},"properties":{"population":674405,"capital":4,"place":"city","name":"03F65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.23016,19.017]},"properties":{"population":665929,"capital":4,"place":"city","name":"03F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.85388,17.45811]},"properties":{"population":445434,"capital":4,"place":"city","name":"03Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.04613,7.06085]},"properties":{"population":709090,"capital":4,"place":"city","name":"03G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.44354,7.47131]},"properties":{"population":696790,"capital":4,"place":"city","name":"03G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.21256,7.09155]},"properties":{"population":739203,"capital":4,"place":"city","name":"03G12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.86471,6.42514]},"properties":{"population":685646,"capital":4,"place":"city","name":"03G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.65726,8.13686]},"properties":{"population":592958,"capital":4,"place":"city","name":"03G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.85082,5.47152]},"properties":{"population":821229,"capital":4,"place":"city","name":"03G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.89316,10.07581]},"properties":{"population":502388,"capital":4,"place":"city","name":"03G27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.3729,8.64564]},"properties":{"population":747760,"capital":4,"place":"city","name":"03G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.51447,8.07413]},"properties":{"population":555295,"capital":4,"place":"city","name":"03G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.18925,8.32115]},"properties":{"population":361091,"capital":4,"place":"city","name":"03Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.42808,6.14744]},"properties":{"population":695844,"capital":4,"place":"city","name":"03H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.3535,1.81313]},"properties":{"population":529330,"capital":4,"place":"city","name":"03H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.0146,5.64508]},"properties":{"population":641345,"capital":4,"place":"city","name":"03H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.7395,0.07036]},"properties":{"population":599146,"capital":4,"place":"city","name":"03H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.61969,-1.98239]},"properties":{"population":325809,"capital":4,"place":"city","name":"03Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.76703,16.84048]},"properties":{"population":69314,"capital":4,"place":"city","name":"04A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.88191,16.91693]},"properties":{"population":69314,"capital":4,"place":"city","name":"04A72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.60595,-4.94271]},"properties":{"population":329583,"capital":4,"place":"city","name":"05-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.16336,3.9059]},"properties":{"population":683410,"capital":4,"place":"city","name":"05-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.06457,-0.68468]},"properties":{"population":738336,"capital":4,"place":"city","name":"05-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.27422,-3.17371]},"properties":{"population":608221,"capital":4,"place":"city","name":"05-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.74663,-3.99837]},"properties":{"population":664898,"capital":4,"place":"city","name":"05-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.5446,11.95877]},"properties":{"population":731721,"capital":4,"place":"city","name":"05-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.59813,-5.03514]},"properties":{"population":207944,"capital":4,"place":"city","name":"05-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.99818,-4.92819]},"properties":{"population":629710,"capital":4,"place":"city","name":"05-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.59689,12.18864]},"properties":{"population":875794,"capital":4,"place":"city","name":"05A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.13773,8.18141]},"properties":{"population":807775,"capital":4,"place":"city","name":"05A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.97022,5.24921]},"properties":{"population":923746,"capital":4,"place":"city","name":"05A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.65944,12.21638]},"properties":{"population":718462,"capital":4,"place":"city","name":"05A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.69856,9.26664]},"properties":{"population":828526,"capital":4,"place":"city","name":"05A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.7353,4.60584]},"properties":{"population":723777,"capital":4,"place":"city","name":"05A18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.94773,7.43379]},"properties":{"population":841958,"capital":4,"place":"city","name":"05A19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.17764,-2.58883]},"properties":{"population":666568,"capital":4,"place":"city","name":"05A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.30252,5.85064]},"properties":{"population":739387,"capital":4,"place":"city","name":"05A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.81907,-2.51599]},"properties":{"population":609130,"capital":4,"place":"city","name":"05A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.16288,-2.28765]},"properties":{"population":710085,"capital":4,"place":"city","name":"05A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.00253,-2.3416]},"properties":{"population":519295,"capital":4,"place":"city","name":"05Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.35179,13.04346]},"properties":{"population":874353,"capital":4,"place":"city","name":"05B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.70432,10.38736]},"properties":{"population":738770,"capital":4,"place":"city","name":"05B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.14335,-4.73361]},"properties":{"population":747363,"capital":4,"place":"city","name":"05B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.62243,14.01403]},"properties":{"population":834759,"capital":4,"place":"city","name":"05B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.03964,20.76518]},"properties":{"population":827180,"capital":4,"place":"city","name":"05B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.56676,10.46361]},"properties":{"population":812444,"capital":4,"place":"city","name":"05B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.23109,12.82203]},"properties":{"population":821743,"capital":4,"place":"city","name":"05B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.91689,13.51525]},"properties":{"population":842310,"capital":4,"place":"city","name":"05B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.14344,-0.79373]},"properties":{"population":762851,"capital":4,"place":"city","name":"05B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.80899,-1.94098]},"properties":{"population":746508,"capital":4,"place":"city","name":"05B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.69279,10.91996]},"properties":{"population":645362,"capital":4,"place":"city","name":"05B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.62108,20.24975]},"properties":{"population":643615,"capital":4,"place":"city","name":"05B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.02631,0.1222]},"properties":{"population":478749,"capital":4,"place":"city","name":"05Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.48372,10.1614]},"properties":{"population":938622,"capital":4,"place":"city","name":"05C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.45075,12.79715]},"properties":{"population":800736,"capital":4,"place":"city","name":"05C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.58481,18.49806]},"properties":{"population":615697,"capital":4,"place":"city","name":"05C09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.89395,0.61233]},"properties":{"population":933264,"capital":4,"place":"city","name":"05C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.44281,14.0729]},"properties":{"population":894259,"capital":4,"place":"city","name":"05C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.06345,14.42999]},"properties":{"population":972937,"capital":4,"place":"city","name":"05C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.62303,18.86243]},"properties":{"population":625958,"capital":4,"place":"city","name":"05C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.18785,0.03506]},"properties":{"population":920099,"capital":4,"place":"city","name":"05C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.16397,13.32481]},"properties":{"population":624997,"capital":4,"place":"city","name":"05C21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.09156,14.54522]},"properties":{"population":750604,"capital":4,"place":"city","name":"05C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.7278,-4.84452]},"properties":{"population":913540,"capital":4,"place":"city","name":"05C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.6489,10.5981]},"properties":{"population":840200,"capital":4,"place":"city","name":"05C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.76347,13.7877]},"properties":{"population":714203,"capital":4,"place":"city","name":"05C31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.90016,10.72063]},"properties":{"population":951118,"capital":4,"place":"city","name":"05C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.21431,0.9775]},"properties":{"population":938050,"capital":4,"place":"city","name":"05C38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.83895,13.75515]},"properties":{"population":890707,"capital":4,"place":"city","name":"05C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.6151,19.29345]},"properties":{"population":641181,"capital":4,"place":"city","name":"05C42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.90609,-0.36768]},"properties":{"population":855775,"capital":4,"place":"city","name":"05C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.22002,18.14393]},"properties":{"population":289037,"capital":4,"place":"city","name":"05C48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.93672,2.3236]},"properties":{"population":928952,"capital":4,"place":"city","name":"05C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.47194,14.33455]},"properties":{"population":582894,"capital":4,"place":"city","name":"05C51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.49639,10.94774]},"properties":{"population":780465,"capital":4,"place":"city","name":"05C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.70044,10.99761]},"properties":{"population":692951,"capital":4,"place":"city","name":"05C57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.22134,13.24504]},"properties":{"population":806180,"capital":4,"place":"city","name":"05C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.57227,10.93409]},"properties":{"population":786863,"capital":4,"place":"city","name":"05C62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.96741,20.39225]},"properties":{"population":638012,"capital":4,"place":"city","name":"05C63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.83745,14.52951]},"properties":{"population":855082,"capital":4,"place":"city","name":"05C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.36828,14.16656]},"properties":{"population":903049,"capital":4,"place":"city","name":"05C69"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.03688,13.47071]},"properties":{"population":947393,"capital":4,"place":"city","name":"05C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.45633,19.55487]},"properties":{"population":624804,"capital":4,"place":"city","name":"05C72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.53456,2.29175]},"properties":{"population":855410,"capital":4,"place":"city","name":"05C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.50043,10.65033]},"properties":{"population":792298,"capital":4,"place":"city","name":"05C76"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.54343,11.13498]},"properties":{"population":849105,"capital":4,"place":"city","name":"05C78"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.55949,13.88478]},"properties":{"population":876623,"capital":4,"place":"city","name":"05C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.74561,11.15195]},"properties":{"population":689060,"capital":4,"place":"city","name":"05C81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.30943,6.90582]},"properties":{"population":797246,"capital":4,"place":"city","name":"05C82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.04187,14.27382]},"properties":{"population":686275,"capital":4,"place":"city","name":"05C83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.6941,13.77259]},"properties":{"population":954738,"capital":4,"place":"city","name":"05C85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.474,10.53049]},"properties":{"population":890707,"capital":4,"place":"city","name":"05C90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.78141,18.69996]},"properties":{"population":585220,"capital":4,"place":"city","name":"05C92"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.28696,-4.6994]},"properties":{"population":897954,"capital":4,"place":"city","name":"05C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.32209,8.37554]},"properties":{"population":682654,"capital":4,"place":"city","name":"05Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.35888,6.82083]},"properties":{"population":741336,"capital":4,"place":"city","name":"05D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.37609,6.33814]},"properties":{"population":762413,"capital":4,"place":"city","name":"05D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.15399,8.68077]},"properties":{"population":579301,"capital":4,"place":"city","name":"05D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.02487,8.26443]},"properties":{"population":624997,"capital":4,"place":"city","name":"05D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.5162,8.62452]},"properties":{"population":571373,"capital":4,"place":"city","name":"05D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.77429,8.79548]},"properties":{"population":270805,"capital":4,"place":"city","name":"05Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.65868,-4.70638]},"properties":{"population":800135,"capital":4,"place":"city","name":"05E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.40368,-4.63106]},"properties":{"population":824275,"capital":4,"place":"city","name":"05E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.78005,19.31891]},"properties":{"population":440671,"capital":4,"place":"city","name":"05E14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.97447,14.3404]},"properties":{"population":756268,"capital":4,"place":"city","name":"05E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.95092,21.9691]},"properties":{"population":519295,"capital":4,"place":"city","name":"05E16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.03198,14.24957]},"properties":{"population":643615,"capital":4,"place":"city","name":"05E18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.89844,20.18376]},"properties":{"population":522035,"capital":4,"place":"city","name":"05E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.18336,20.40218]},"properties":{"population":533271,"capital":4,"place":"city","name":"05E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.7388,11.0144]},"properties":{"population":777443,"capital":4,"place":"city","name":"05E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.84373,20.39727]},"properties":{"population":552146,"capital":4,"place":"city","name":"05E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.55129,19.54828]},"properties":{"population":641181,"capital":4,"place":"city","name":"05E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.31963,22.6261]},"properties":{"population":674875,"capital":4,"place":"city","name":"05E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.00053,20.40269]},"properties":{"population":635957,"capital":4,"place":"city","name":"05E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.83939,19.07875]},"properties":{"population":397029,"capital":4,"place":"city","name":"05Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.20852,21.79247]},"properties":{"population":299573,"capital":4,"place":"city","name":"06-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.68865,20.58135]},"properties":{"population":531320,"capital":4,"place":"city","name":"06-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.91582,19.14408]},"properties":{"population":558724,"capital":4,"place":"city","name":"06-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.92514,18.33001]},"properties":{"population":423410,"capital":4,"place":"city","name":"06-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.81061,21.61071]},"properties":{"population":423410,"capital":4,"place":"city","name":"06-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.44932,22.15649]},"properties":{"population":500394,"capital":4,"place":"city","name":"06-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.74983,22.42986]},"properties":{"population":109861,"capital":4,"place":"city","name":"06-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.83595,22.23611]},"properties":{"population":525227,"capital":4,"place":"city","name":"06-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.26756,22.1232]},"properties":{"population":680572,"capital":4,"place":"city","name":"06A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.51347,3.65364]},"properties":{"population":832941,"capital":4,"place":"city","name":"06A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.01809,5.39457]},"properties":{"population":788306,"capital":4,"place":"city","name":"06A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-17.1534,20.94091]},"properties":{"population":109861,"capital":4,"place":"city","name":"06A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.09592,22.83709]},"properties":{"population":652941,"capital":4,"place":"city","name":"06A11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.30176,21.48673]},"properties":{"population":712689,"capital":4,"place":"city","name":"06A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.88114,21.12126]},"properties":{"population":702908,"capital":4,"place":"city","name":"06A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.9015,22.99441]},"properties":{"population":482028,"capital":4,"place":"city","name":"06A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.83096,24.55343]},"properties":{"population":580211,"capital":4,"place":"city","name":"06A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-17.04929,21.20331]},"properties":{"population":313549,"capital":4,"place":"city","name":"06Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.69195,14.24815]},"properties":{"population":750439,"capital":4,"place":"city","name":"06B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.52264,10.61218]},"properties":{"population":729641,"capital":4,"place":"city","name":"06B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.35716,11.27347]},"properties":{"population":683625,"capital":4,"place":"city","name":"06B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.50346,11.07923]},"properties":{"population":653958,"capital":4,"place":"city","name":"06B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.42023,11.53009]},"properties":{"population":726961,"capital":4,"place":"city","name":"06B23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.77369,11.13576]},"properties":{"population":573657,"capital":4,"place":"city","name":"06B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.75373,11.4137]},"properties":{"population":658755,"capital":4,"place":"city","name":"06B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.51847,11.6353]},"properties":{"population":701929,"capital":4,"place":"city","name":"06B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.57954,10.84855]},"properties":{"population":523644,"capital":4,"place":"city","name":"06B75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.97342,11.92588]},"properties":{"population":665027,"capital":4,"place":"city","name":"06B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.54937,10.79518]},"properties":{"population":376120,"capital":4,"place":"city","name":"06Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.03016,8.69969]},"properties":{"population":700940,"capital":4,"place":"city","name":"06C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.49625,10.0511]},"properties":{"population":616120,"capital":4,"place":"city","name":"06C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.08929,8.7162]},"properties":{"population":737838,"capital":4,"place":"city","name":"06C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.07769,10.32725]},"properties":{"population":508759,"capital":4,"place":"city","name":"06C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.87718,10.10042]},"properties":{"population":430406,"capital":4,"place":"city","name":"06C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.89249,10.3762]},"properties":{"population":317805,"capital":4,"place":"city","name":"06Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.53858,19.40119]},"properties":{"population":739079,"capital":4,"place":"city","name":"06D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.0317,23.75367]},"properties":{"population":606378,"capital":4,"place":"city","name":"06D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.32263,5.92307]},"properties":{"population":637842,"capital":4,"place":"city","name":"06D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.70958,22.55697]},"properties":{"population":659987,"capital":4,"place":"city","name":"06D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.67334,23.22034]},"properties":{"population":665801,"capital":4,"place":"city","name":"06D22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-17.09646,21.28239]},"properties":{"population":500394,"capital":4,"place":"city","name":"06D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.31315,22.65901]},"properties":{"population":659578,"capital":4,"place":"city","name":"06D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.04795,22.89002]},"properties":{"population":727031,"capital":4,"place":"city","name":"06D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.60171,21.43066]},"properties":{"population":565599,"capital":4,"place":"city","name":"06D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.66099,22.51077]},"properties":{"population":618208,"capital":4,"place":"city","name":"06D72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.10436,20.89305]},"properties":{"population":535658,"capital":4,"place":"city","name":"06D75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.20238,24.14417]},"properties":{"population":617586,"capital":4,"place":"city","name":"06D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-17.11304,20.78348]},"properties":{"population":333220,"capital":4,"place":"city","name":"06Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.45892,23.25846]},"properties":{"population":716006,"capital":4,"place":"city","name":"06E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.0843,23.80868]},"properties":{"population":553733,"capital":4,"place":"city","name":"06E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.06011,22.6324]},"properties":{"population":616961,"capital":4,"place":"city","name":"06E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.46395,6.17756]},"properties":{"population":566296,"capital":4,"place":"city","name":"06E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.43239,22.23343]},"properties":{"population":576832,"capital":4,"place":"city","name":"06E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.02454,20.92554]},"properties":{"population":762997,"capital":4,"place":"city","name":"06E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.83558,21.04703]},"properties":{"population":436944,"capital":4,"place":"city","name":"06E75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.23543,24.78606]},"properties":{"population":608677,"capital":4,"place":"city","name":"06E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-17.0246,20.63626]},"properties":{"population":385014,"capital":4,"place":"city","name":"06Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.80545,20.14832]},"properties":{"population":783122,"capital":4,"place":"city","name":"06F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.13205,23.31174]},"properties":{"population":597888,"capital":4,"place":"city","name":"06F07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.95467,21.6696]},"properties":{"population":487519,"capital":4,"place":"city","name":"06F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.55801,3.41799]},"properties":{"population":749997,"capital":4,"place":"city","name":"06F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.1804,20.40847]},"properties":{"population":729505,"capital":4,"place":"city","name":"06F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.36314,18.57506]},"properties":{"population":723705,"capital":4,"place":"city","name":"06F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.83619,21.49928]},"properties":{"population":644888,"capital":4,"place":"city","name":"06F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.34596,20.91985]},"properties":{"population":764683,"capital":4,"place":"city","name":"06F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.93728,21.72629]},"properties":{"population":624610,"capital":4,"place":"city","name":"06F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.92918,20.7192]},"properties":{"population":317805,"capital":4,"place":"city","name":"06Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.01604,-12.22202]},"properties":{"population":358351,"capital":4,"place":"city","name":"08-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.78907,-12.44247]},"properties":{"population":477068,"capital":4,"place":"city","name":"08-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.68083,-12.3514]},"properties":{"population":535658,"capital":4,"place":"city","name":"08-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.94503,-12.37721]},"properties":{"population":368887,"capital":4,"place":"city","name":"08-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.81223,-12.21034]},"properties":{"population":325809,"capital":4,"place":"city","name":"08-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.1014,-12.63942]},"properties":{"population":464439,"capital":4,"place":"city","name":"08-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.53343,-12.68134]},"properties":{"population":420469,"capital":4,"place":"city","name":"08-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.37029,-2.53613]},"properties":{"population":666313,"capital":4,"place":"city","name":"08A02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.55282,-1.7275]},"properties":{"population":715773,"capital":4,"place":"city","name":"08A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.57028,-2.78905]},"properties":{"population":787169,"capital":4,"place":"city","name":"08A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.64127,-2.90913]},"properties":{"population":649072,"capital":4,"place":"city","name":"08A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.36098,-2.57037]},"properties":{"population":746164,"capital":4,"place":"city","name":"08A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.84155,-2.94984]},"properties":{"population":417438,"capital":4,"place":"city","name":"08A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.74702,-1.83158]},"properties":{"population":554517,"capital":4,"place":"city","name":"08A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.48159,-2.52483]},"properties":{"population":634563,"capital":4,"place":"city","name":"08A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.6871,-2.93301]},"properties":{"population":623441,"capital":4,"place":"city","name":"08A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.89281,-2.00809]},"properties":{"population":559842,"capital":4,"place":"city","name":"08A62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.69076,-1.72969]},"properties":{"population":443081,"capital":4,"place":"city","name":"08A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.91401,-2.01971]},"properties":{"population":387120,"capital":4,"place":"city","name":"08A68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.90813,-2.08707]},"properties":{"population":648616,"capital":4,"place":"city","name":"08A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.23008,-2.52694]},"properties":{"population":656103,"capital":4,"place":"city","name":"08A72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.78959,-1.92697]},"properties":{"population":614418,"capital":4,"place":"city","name":"08A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.51891,-2.70802]},"properties":{"population":661472,"capital":4,"place":"city","name":"08Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.68093,-13.65234]},"properties":{"population":719443,"capital":4,"place":"city","name":"08B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.32365,-13.11305]},"properties":{"population":645990,"capital":4,"place":"city","name":"08B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.33826,-13.67383]},"properties":{"population":735500,"capital":4,"place":"city","name":"08B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.59189,-13.57994]},"properties":{"population":685118,"capital":4,"place":"city","name":"08B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.50379,-13.317]},"properties":{"population":602102,"capital":4,"place":"city","name":"08B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.35192,-13.41366]},"properties":{"population":557594,"capital":4,"place":"city","name":"08B26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.49815,-13.43549]},"properties":{"population":512989,"capital":4,"place":"city","name":"08B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.43052,-13.72693]},"properties":{"population":696129,"capital":4,"place":"city","name":"08B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.24519,-13.04517]},"properties":{"population":304452,"capital":4,"place":"city","name":"08Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.98071,-12.44088]},"properties":{"population":641836,"capital":4,"place":"city","name":"08C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.72223,-12.01724]},"properties":{"population":578996,"capital":4,"place":"city","name":"08C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.63127,-12.04052]},"properties":{"population":674875,"capital":4,"place":"city","name":"08C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.92484,-12.15849]},"properties":{"population":417438,"capital":4,"place":"city","name":"08C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.99602,-12.51299]},"properties":{"population":461512,"capital":4,"place":"city","name":"08C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.766,-12.11328]},"properties":{"population":230258,"capital":4,"place":"city","name":"08Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.87313,9.20072]},"properties":{"population":402535,"capital":4,"place":"city","name":"11-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.83825,10.11291]},"properties":{"population":746049,"capital":4,"place":"city","name":"11-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.19162,11.34205]},"properties":{"population":774413,"capital":4,"place":"city","name":"11-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.02757,9.83575]},"properties":{"population":754538,"capital":4,"place":"city","name":"11-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.04764,10.13993]},"properties":{"population":692657,"capital":4,"place":"city","name":"11-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.71156,10.2045]},"properties":{"population":723705,"capital":4,"place":"city","name":"11-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.90227,9.50543]},"properties":{"population":591889,"capital":4,"place":"city","name":"11-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.65423,20.68357]},"properties":{"population":714124,"capital":4,"place":"city","name":"11A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.05528,0.80321]},"properties":{"population":809712,"capital":4,"place":"city","name":"11A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.23572,20.95056]},"properties":{"population":710824,"capital":4,"place":"city","name":"11A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.24821,20.79823]},"properties":{"population":840469,"capital":4,"place":"city","name":"11A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.38835,20.93271]},"properties":{"population":773149,"capital":4,"place":"city","name":"11A41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.17497,20.09028]},"properties":{"population":700306,"capital":4,"place":"city","name":"11A51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.31974,20.70999]},"properties":{"population":779317,"capital":4,"place":"city","name":"11A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.62085,20.48566]},"properties":{"population":781075,"capital":4,"place":"city","name":"11A63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.44143,19.98969]},"properties":{"population":624027,"capital":4,"place":"city","name":"11A67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.01297,20.53913]},"properties":{"population":646925,"capital":4,"place":"city","name":"11A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.00807,19.40954]},"properties":{"population":600881,"capital":4,"place":"city","name":"11Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.44086,21.73761]},"properties":{"population":684587,"capital":4,"place":"city","name":"11B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.83552,20.75142]},"properties":{"population":720191,"capital":4,"place":"city","name":"11B13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.95734,3.67298]},"properties":{"population":715305,"capital":4,"place":"city","name":"11B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.96672,-4.404]},"properties":{"population":669703,"capital":4,"place":"city","name":"11B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.26751,19.9729]},"properties":{"population":588610,"capital":4,"place":"city","name":"11B34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.78951,21.18146]},"properties":{"population":814118,"capital":4,"place":"city","name":"11B37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.3211,16.22916]},"properties":{"population":874782,"capital":4,"place":"city","name":"11B39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.76168,20.6679]},"properties":{"population":687109,"capital":4,"place":"city","name":"11B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.21601,20.8183]},"properties":{"population":586078,"capital":4,"place":"city","name":"11B57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.81176,20.95261]},"properties":{"population":787397,"capital":4,"place":"city","name":"11B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.54151,-0.73779]},"properties":{"population":812888,"capital":4,"place":"city","name":"11B68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.94004,20.7628]},"properties":{"population":721303,"capital":4,"place":"city","name":"11B73"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.97099,2.21167]},"properties":{"population":788004,"capital":4,"place":"city","name":"11B75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.38489,21.04018]},"properties":{"population":822871,"capital":4,"place":"city","name":"11B83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.58539,20.68544]},"properties":{"population":671780,"capital":4,"place":"city","name":"11B85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.0206,20.1029]},"properties":{"population":604737,"capital":4,"place":"city","name":"11B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.45402,20.32357]},"properties":{"population":506259,"capital":4,"place":"city","name":"11Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.33279,-2.96576]},"properties":{"population":750494,"capital":4,"place":"city","name":"11C08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.63307,-3.21449]},"properties":{"population":710002,"capital":4,"place":"city","name":"11C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.98741,-7.91301]},"properties":{"population":400733,"capital":4,"place":"city","name":"11C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.77245,-8.1595]},"properties":{"population":366356,"capital":4,"place":"city","name":"11Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.06815,6.96014]},"properties":{"population":701031,"capital":4,"place":"city","name":"11D04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.81743,1.8245]},"properties":{"population":531320,"capital":4,"place":"city","name":"11D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.68878,2.73047]},"properties":{"population":768708,"capital":4,"place":"city","name":"11D09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.05093,3.20181]},"properties":{"population":761677,"capital":4,"place":"city","name":"11D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.89094,3.60697]},"properties":{"population":776641,"capital":4,"place":"city","name":"11D41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.25628,4.12006]},"properties":{"population":679346,"capital":4,"place":"city","name":"11D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.88166,2.49214]},"properties":{"population":611809,"capital":4,"place":"city","name":"11D57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.85163,1.68481]},"properties":{"population":545103,"capital":4,"place":"city","name":"11D59"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.90033,2.77188]},"properties":{"population":739203,"capital":4,"place":"city","name":"11D61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.9276,2.18103]},"properties":{"population":530330,"capital":4,"place":"city","name":"11D68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.20442,2.20931]},"properties":{"population":674758,"capital":4,"place":"city","name":"11D72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.61443,2.56405]},"properties":{"population":645676,"capital":4,"place":"city","name":"11D75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.82022,2.90886]},"properties":{"population":598393,"capital":4,"place":"city","name":"11D79"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.858,2.24842]},"properties":{"population":677536,"capital":4,"place":"city","name":"11D85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.18164,3.02295]},"properties":{"population":555295,"capital":4,"place":"city","name":"11D88"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.68668,3.13106]},"properties":{"population":619031,"capital":4,"place":"city","name":"11D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.12024,2.29175]},"properties":{"population":516478,"capital":4,"place":"city","name":"11Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.85168,21.1939]},"properties":{"population":693731,"capital":4,"place":"city","name":"11E04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.42714,21.83872]},"properties":{"population":585220,"capital":4,"place":"city","name":"11E08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.43343,20.66731]},"properties":{"population":570378,"capital":4,"place":"city","name":"11E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.11542,21.95076]},"properties":{"population":704577,"capital":4,"place":"city","name":"11E12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.62266,20.64307]},"properties":{"population":737963,"capital":4,"place":"city","name":"11E16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.2868,20.41036]},"properties":{"population":658892,"capital":4,"place":"city","name":"11E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.87954,2.70265]},"properties":{"population":726052,"capital":4,"place":"city","name":"11E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.21481,21.30162]},"properties":{"population":610702,"capital":4,"place":"city","name":"11E39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.66825,21.28763]},"properties":{"population":617586,"capital":4,"place":"city","name":"11E41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.60027,20.81943]},"properties":{"population":667834,"capital":4,"place":"city","name":"11E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.16397,22.06874]},"properties":{"population":618208,"capital":4,"place":"city","name":"11E57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.71539,20.75685]},"properties":{"population":429045,"capital":4,"place":"city","name":"11E70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.27349,22.84148]},"properties":{"population":573979,"capital":4,"place":"city","name":"11E72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.95954,20.74382]},"properties":{"population":656103,"capital":4,"place":"city","name":"11E76"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.2607,21.10494]},"properties":{"population":680572,"capital":4,"place":"city","name":"11E81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.67992,21.18012]},"properties":{"population":576205,"capital":4,"place":"city","name":"11E88"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.89753,24.1311]},"properties":{"population":543807,"capital":4,"place":"city","name":"11E95"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.10088,21.52589]},"properties":{"population":458496,"capital":4,"place":"city","name":"11E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.366,20.05809]},"properties":{"population":440671,"capital":4,"place":"city","name":"11Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.95727,19.85401]},"properties":{"population":726332,"capital":4,"place":"city","name":"11F03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.80576,20.83501]},"properties":{"population":690875,"capital":4,"place":"city","name":"11F06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.77536,19.48871]},"properties":{"population":795647,"capital":4,"place":"city","name":"11F11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.48678,20.19889]},"properties":{"population":674993,"capital":4,"place":"city","name":"11F12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.14083,20.65321]},"properties":{"population":689365,"capital":4,"place":"city","name":"11F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.48253,21.2142]},"properties":{"population":607534,"capital":4,"place":"city","name":"11F22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.43401,21.30456]},"properties":{"population":529330,"capital":4,"place":"city","name":"11F23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.23086,24.6415]},"properties":{"population":634913,"capital":4,"place":"city","name":"11F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.58385,19.85052]},"properties":{"population":781075,"capital":4,"place":"city","name":"11F27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.22853,22.97575]},"properties":{"population":735819,"capital":4,"place":"city","name":"11F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.22258,24.18464]},"properties":{"population":538907,"capital":4,"place":"city","name":"11F32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.33785,24.599]},"properties":{"population":703966,"capital":4,"place":"city","name":"11F33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.88873,22.87244]},"properties":{"population":708506,"capital":4,"place":"city","name":"11F37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.58515,-1.86886]},"properties":{"population":685118,"capital":4,"place":"city","name":"11F41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.55563,-0.67365]},"properties":{"population":715851,"capital":4,"place":"city","name":"11F46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.16427,22.5616]},"properties":{"population":630261,"capital":4,"place":"city","name":"11F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.78747,23.11547]},"properties":{"population":505624,"capital":4,"place":"city","name":"11F52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.99589,21.94122]},"properties":{"population":670073,"capital":4,"place":"city","name":"11F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.02214,23.00236]},"properties":{"population":571373,"capital":4,"place":"city","name":"11F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.42988,22.54585]},"properties":{"population":726892,"capital":4,"place":"city","name":"11F66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.5393,-0.70014]},"properties":{"population":760140,"capital":4,"place":"city","name":"11F67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.70817,20.81425]},"properties":{"population":433073,"capital":4,"place":"city","name":"11F68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.96174,-3.81675]},"properties":{"population":800836,"capital":4,"place":"city","name":"11F70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.10629,0.99143]},"properties":{"population":729301,"capital":4,"place":"city","name":"11F72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.53922,22.17756]},"properties":{"population":636130,"capital":4,"place":"city","name":"11F75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.57724,20.33328]},"properties":{"population":207944,"capital":4,"place":"city","name":"11F77"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.03003,21.84734]},"properties":{"population":738398,"capital":4,"place":"city","name":"11F80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.88794,-0.27281]},"properties":{"population":665415,"capital":4,"place":"city","name":"11F85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.02755,23.2923]},"properties":{"population":523110,"capital":4,"place":"city","name":"11F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.57569,21.57239]},"properties":{"population":509986,"capital":4,"place":"city","name":"11Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.22791,2.62568]},"properties":{"population":816223,"capital":4,"place":"city","name":"11G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.01276,23.83769]},"properties":{"population":596870,"capital":4,"place":"city","name":"11G07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.21257,23.33081]},"properties":{"population":667959,"capital":4,"place":"city","name":"11G09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.07475,-0.91342]},"properties":{"population":705789,"capital":4,"place":"city","name":"11G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.35963,22.21013]},"properties":{"population":683303,"capital":4,"place":"city","name":"11G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.73104,23.32225]},"properties":{"population":527811,"capital":4,"place":"city","name":"11G16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.12948,22.41093]},"properties":{"population":734277,"capital":4,"place":"city","name":"11G18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.89347,20.44053]},"properties":{"population":741697,"capital":4,"place":"city","name":"11G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.5686,-2.93184]},"properties":{"population":657646,"capital":4,"place":"city","name":"11G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.48347,-2.0128]},"properties":{"population":665286,"capital":4,"place":"city","name":"11G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.15386,24.02635]},"properties":{"population":460517,"capital":4,"place":"city","name":"11G32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.9476,23.30438]},"properties":{"population":672743,"capital":4,"place":"city","name":"11G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.57131,23.19154]},"properties":{"population":715461,"capital":4,"place":"city","name":"11G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-17.05031,20.90738]},"properties":{"population":317805,"capital":4,"place":"city","name":"11G42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.04469,24.62531]},"properties":{"population":477068,"capital":4,"place":"city","name":"11G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.00909,-2.5859]},"properties":{"population":682654,"capital":4,"place":"city","name":"11G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.87884,22.33411]},"properties":{"population":599893,"capital":4,"place":"city","name":"11G55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.71361,24.30617]},"properties":{"population":497673,"capital":4,"place":"city","name":"11G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.00912,23.63149]},"properties":{"population":470048,"capital":4,"place":"city","name":"11Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.78314,2.04226]},"properties":{"population":744424,"capital":4,"place":"city","name":"11H06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.28739,-0.79636]},"properties":{"population":482028,"capital":4,"place":"city","name":"11H16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.78046,0.13454]},"properties":{"population":657228,"capital":4,"place":"city","name":"11H31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.28594,-0.74631]},"properties":{"population":517614,"capital":4,"place":"city","name":"11H46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.1577,0.7119]},"properties":{"population":604025,"capital":4,"place":"city","name":"11H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.93186,-0.10815]},"properties":{"population":658479,"capital":4,"place":"city","name":"11H55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.20557,0.73474]},"properties":{"population":517614,"capital":4,"place":"city","name":"11H56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.3182,-0.91076]},"properties":{"population":453259,"capital":4,"place":"city","name":"11H60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.31922,0.22215]},"properties":{"population":527811,"capital":4,"place":"city","name":"11H71"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.15319,-0.47875]},"properties":{"population":543372,"capital":4,"place":"city","name":"11H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.22547,-0.89099]},"properties":{"population":421950,"capital":4,"place":"city","name":"11Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.85424,20.54329]},"properties":{"population":618826,"capital":4,"place":"city","name":"11J04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.89863,22.67097]},"properties":{"population":518738,"capital":4,"place":"city","name":"11J06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.64408,20.64992]},"properties":{"population":645519,"capital":4,"place":"city","name":"11J13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.60011,22.19329]},"properties":{"population":531320,"capital":4,"place":"city","name":"11J17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.70339,22.16453]},"properties":{"population":509986,"capital":4,"place":"city","name":"11J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.58211,21.64003]},"properties":{"population":616331,"capital":4,"place":"city","name":"11J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.96917,20.83178]},"properties":{"population":511198,"capital":4,"place":"city","name":"11J54"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.75272,21.21149]},"properties":{"population":621460,"capital":4,"place":"city","name":"11J61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.26882,23.7698]},"properties":{"population":641017,"capital":4,"place":"city","name":"11J68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.54601,21.02637]},"properties":{"population":746508,"capital":4,"place":"city","name":"11J70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.81193,21.38069]},"properties":{"population":682219,"capital":4,"place":"city","name":"11J71"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.04162,20.90408]},"properties":{"population":655961,"capital":4,"place":"city","name":"11J72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.19115,21.12959]},"properties":{"population":743955,"capital":4,"place":"city","name":"11J81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.30442,21.38568]},"properties":{"population":645676,"capital":4,"place":"city","name":"11J82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.95036,21.02407]},"properties":{"population":653813,"capital":4,"place":"city","name":"11J83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.60628,23.69926]},"properties":{"population":653087,"capital":4,"place":"city","name":"11J85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.41951,21.47588]},"properties":{"population":659304,"capital":4,"place":"city","name":"11J86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.62665,21.68356]},"properties":{"population":480402,"capital":4,"place":"city","name":"11J87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.53112,-3.16805]},"properties":{"population":508140,"capital":4,"place":"city","name":"11J89"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.51163,23.01638]},"properties":{"population":640687,"capital":4,"place":"city","name":"11J91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.89081,20.72211]},"properties":{"population":433073,"capital":4,"place":"city","name":"11J93"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.64904,23.27773]},"properties":{"population":439444,"capital":4,"place":"city","name":"11J95"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.26331,22.6534]},"properties":{"population":485981,"capital":4,"place":"city","name":"11J97"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.37345,21.45784]},"properties":{"population":579605,"capital":4,"place":"city","name":"11J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.50917,21.7916]},"properties":{"population":504342,"capital":4,"place":"city","name":"11Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.80364,23.40146]},"properties":{"population":731721,"capital":4,"place":"city","name":"11K06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.71963,22.90256]},"properties":{"population":687626,"capital":4,"place":"city","name":"11K16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.09277,22.54856]},"properties":{"population":621460,"capital":4,"place":"city","name":"11K31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.39543,22.97437]},"properties":{"population":570378,"capital":4,"place":"city","name":"11K36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.33037,22.86234]},"properties":{"population":716317,"capital":4,"place":"city","name":"11K38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.64571,23.05616]},"properties":{"population":485981,"capital":4,"place":"city","name":"11K41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.94775,23.132]},"properties":{"population":707580,"capital":4,"place":"city","name":"11K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.88395,22.78618]},"properties":{"population":639859,"capital":4,"place":"city","name":"11K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.72385,23.03441]},"properties":{"population":698007,"capital":4,"place":"city","name":"11K55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.88836,23.19143]},"properties":{"population":654391,"capital":4,"place":"city","name":"11K60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.93406,22.67104]},"properties":{"population":712205,"capital":4,"place":"city","name":"11K65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.58202,22.74261]},"properties":{"population":494164,"capital":4,"place":"city","name":"11K70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.53814,22.86443]},"properties":{"population":589164,"capital":4,"place":"city","name":"11K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.07755,22.71446]},"properties":{"population":391202,"capital":4,"place":"city","name":"11Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.69412,-2.45894]},"properties":{"population":718992,"capital":4,"place":"city","name":"11L03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.17932,-3.86319]},"properties":{"population":714045,"capital":4,"place":"city","name":"11L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.54499,-5.41017]},"properties":{"population":717778,"capital":4,"place":"city","name":"11L07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.11119,-7.43867]},"properties":{"population":520948,"capital":4,"place":"city","name":"11L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.6578,-7.15614]},"properties":{"population":528320,"capital":4,"place":"city","name":"11L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.61413,-7.83622]},"properties":{"population":556452,"capital":4,"place":"city","name":"11L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.76152,-7.79646]},"properties":{"population":387120,"capital":4,"place":"city","name":"11L26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.51284,-4.54298]},"properties":{"population":696790,"capital":4,"place":"city","name":"11L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.19635,-7.54382]},"properties":{"population":460517,"capital":4,"place":"city","name":"11L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.16295,-7.50768]},"properties":{"population":340119,"capital":4,"place":"city","name":"11Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.74234,3.3428]},"properties":{"population":837470,"capital":4,"place":"city","name":"11M06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.60375,21.90286]},"properties":{"population":575257,"capital":4,"place":"city","name":"11M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.55174,17.79755]},"properties":{"population":734213,"capital":4,"place":"city","name":"11M26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.80868,1.08343]},"properties":{"population":668959,"capital":4,"place":"city","name":"11M32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.76888,21.00539]},"properties":{"population":729233,"capital":4,"place":"city","name":"11M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.94404,20.28296]},"properties":{"population":673578,"capital":4,"place":"city","name":"11M36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.21093,24.68672]},"properties":{"population":592157,"capital":4,"place":"city","name":"11M38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.49702,16.6179]},"properties":{"population":793915,"capital":4,"place":"city","name":"11M41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.46476,20.76666]},"properties":{"population":493447,"capital":4,"place":"city","name":"11M45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.05867,-0.20914]},"properties":{"population":539362,"capital":4,"place":"city","name":"11M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.41109,24.60438]},"properties":{"population":411087,"capital":4,"place":"city","name":"11M55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.88824,21.10516]},"properties":{"population":547646,"capital":4,"place":"city","name":"11M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.34954,22.59405]},"properties":{"population":485203,"capital":4,"place":"city","name":"11Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.89662,-7.78479]},"properties":{"population":776853,"capital":4,"place":"city","name":"11N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.17755,-8.76189]},"properties":{"population":690575,"capital":4,"place":"city","name":"11N13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.90348,-9.04368]},"properties":{"population":720266,"capital":4,"place":"city","name":"11N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.86956,-8.95843]},"properties":{"population":444265,"capital":4,"place":"city","name":"11N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.8903,-9.48598]},"properties":{"population":574939,"capital":4,"place":"city","name":"11N32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.14232,-8.51306]},"properties":{"population":678784,"capital":4,"place":"city","name":"11N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.31578,-8.75783]},"properties":{"population":705185,"capital":4,"place":"city","name":"11N36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.69748,-8.47847]},"properties":{"population":836170,"capital":4,"place":"city","name":"11N37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.23944,-7.40719]},"properties":{"population":635262,"capital":4,"place":"city","name":"11N45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.0933,-9.05448]},"properties":{"population":613988,"capital":4,"place":"city","name":"11N56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.24563,-8.81249]},"properties":{"population":622653,"capital":4,"place":"city","name":"11N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.03457,-9.29062]},"properties":{"population":643615,"capital":4,"place":"city","name":"11N64"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.81213,-9.33244]},"properties":{"population":605678,"capital":4,"place":"city","name":"11N69"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.01095,-9.35552]},"properties":{"population":448863,"capital":4,"place":"city","name":"11N75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.06618,-8.71836]},"properties":{"population":569373,"capital":4,"place":"city","name":"11N80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.77524,-9.00676]},"properties":{"population":500394,"capital":4,"place":"city","name":"11N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.07222,-9.27872]},"properties":{"population":439444,"capital":4,"place":"city","name":"11Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.19914,0.80129]},"properties":{"population":731853,"capital":4,"place":"city","name":"11P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.54158,5.19865]},"properties":{"population":702197,"capital":4,"place":"city","name":"11P21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.35762,0.65536]},"properties":{"population":737775,"capital":4,"place":"city","name":"11P32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.81743,0.77248]},"properties":{"population":712608,"capital":4,"place":"city","name":"11P55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.15369,0.70258]},"properties":{"population":605912,"capital":4,"place":"city","name":"11P70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.18702,2.00125]},"properties":{"population":764396,"capital":4,"place":"city","name":"11P81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.88444,0.58035]},"properties":{"population":642486,"capital":4,"place":"city","name":"11P82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.46795,0.57962]},"properties":{"population":703262,"capital":4,"place":"city","name":"11P83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.70222,0.49513]},"properties":{"population":609130,"capital":4,"place":"city","name":"11P84"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.53709,1.30837]},"properties":{"population":605443,"capital":4,"place":"city","name":"11P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.0634,-0.09832]},"properties":{"population":382864,"capital":4,"place":"city","name":"11Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.22449,3.98541]},"properties":{"population":744716,"capital":4,"place":"city","name":"11R04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.38341,21.24202]},"properties":{"population":671052,"capital":4,"place":"city","name":"11R06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.97422,21.55517]},"properties":{"population":705703,"capital":4,"place":"city","name":"11R09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.54979,21.16158]},"properties":{"population":790912,"capital":4,"place":"city","name":"11R11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.21604,2.64267]},"properties":{"population":697260,"capital":4,"place":"city","name":"11R16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.50655,20.56726]},"properties":{"population":765112,"capital":4,"place":"city","name":"11R18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.78906,3.45116]},"properties":{"population":658892,"capital":4,"place":"city","name":"11R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.96838,24.69137]},"properties":{"population":632256,"capital":4,"place":"city","name":"11R21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.8851,0.92288]},"properties":{"population":779770,"capital":4,"place":"city","name":"11R23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.729,21.71929]},"properties":{"population":750108,"capital":4,"place":"city","name":"11R27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.33066,-0.73463]},"properties":{"population":800670,"capital":4,"place":"city","name":"11R29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.24836,20.15838]},"properties":{"population":751479,"capital":4,"place":"city","name":"11R32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.88426,21.06206]},"properties":{"population":665027,"capital":4,"place":"city","name":"11R33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.39122,21.86614]},"properties":{"population":660529,"capital":4,"place":"city","name":"11R34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.91206,21.13726]},"properties":{"population":741155,"capital":4,"place":"city","name":"11R37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.59233,22.88486]},"properties":{"population":620455,"capital":4,"place":"city","name":"11R39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.68861,20.31254]},"properties":{"population":759639,"capital":4,"place":"city","name":"11R42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.98205,22.17763]},"properties":{"population":515905,"capital":4,"place":"city","name":"11R44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.78765,23.20673]},"properties":{"population":622455,"capital":4,"place":"city","name":"11R45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.53789,22.257]},"properties":{"population":546806,"capital":4,"place":"city","name":"11R47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.86156,14.16238]},"properties":{"population":709007,"capital":4,"place":"city","name":"11R52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.33596,21.52803]},"properties":{"population":508759,"capital":4,"place":"city","name":"11R54"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.39114,21.20567]},"properties":{"population":588887,"capital":4,"place":"city","name":"11R56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.96611,19.88335]},"properties":{"population":760290,"capital":4,"place":"city","name":"11R58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.7048,22.01175]},"properties":{"population":270805,"capital":4,"place":"city","name":"11R59"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.70842,21.83602]},"properties":{"population":511799,"capital":4,"place":"city","name":"11R60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.39222,23.04707]},"properties":{"population":480402,"capital":4,"place":"city","name":"11R65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.70403,22.99881]},"properties":{"population":623636,"capital":4,"place":"city","name":"11R70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.34034,24.78907]},"properties":{"population":594279,"capital":4,"place":"city","name":"11R80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.80931,24.38922]},"properties":{"population":574620,"capital":4,"place":"city","name":"11R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.55669,23.67563]},"properties":{"population":537527,"capital":4,"place":"city","name":"11Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.44006,22.73151]},"properties":{"population":556068,"capital":4,"place":"city","name":"11S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.67975,-0.46914]},"properties":{"population":680793,"capital":4,"place":"city","name":"11S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.37302,3.00133]},"properties":{"population":656948,"capital":4,"place":"city","name":"11S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.82095,24.09009]},"properties":{"population":510594,"capital":4,"place":"city","name":"11S23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.28065,24.65473]},"properties":{"population":582008,"capital":4,"place":"city","name":"11S25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.12354,23.38884]},"properties":{"population":651767,"capital":4,"place":"city","name":"11S31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.34422,3.06512]},"properties":{"population":635610,"capital":4,"place":"city","name":"11S37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.85074,1.52944]},"properties":{"population":704751,"capital":4,"place":"city","name":"11S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.69726,23.43459]},"properties":{"population":554517,"capital":4,"place":"city","name":"11S45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.72405,23.29041]},"properties":{"population":524702,"capital":4,"place":"city","name":"11S70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.09057,17.73166]},"properties":{"population":749108,"capital":4,"place":"city","name":"11S80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.52997,24.51159]},"properties":{"population":495582,"capital":4,"place":"city","name":"11S82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.90763,21.89985]},"properties":{"population":550533,"capital":4,"place":"city","name":"11S85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.58675,21.01016]},"properties":{"population":527811,"capital":4,"place":"city","name":"11S90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.57375,19.13677]},"properties":{"population":600141,"capital":4,"place":"city","name":"11S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.39038,24.00492]},"properties":{"population":464439,"capital":4,"place":"city","name":"11Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.59887,20.53458]},"properties":{"population":791278,"capital":4,"place":"city","name":"11T06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.50735,23.33533]},"properties":{"population":600635,"capital":4,"place":"city","name":"11T22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.74203,-0.76378]},"properties":{"population":687005,"capital":4,"place":"city","name":"11T23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.00133,22.65636]},"properties":{"population":656385,"capital":4,"place":"city","name":"11T24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.38086,22.38428]},"properties":{"population":638181,"capital":4,"place":"city","name":"11T30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.03584,22.42702]},"properties":{"population":663463,"capital":4,"place":"city","name":"11T55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.28938,22.45846]},"properties":{"population":321887,"capital":4,"place":"city","name":"11T60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.40508,20.24199]},"properties":{"population":843511,"capital":4,"place":"city","name":"11T71"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.32208,20.47863]},"properties":{"population":636475,"capital":4,"place":"city","name":"11T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.68433,22.62507]},"properties":{"population":453259,"capital":4,"place":"city","name":"11Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.88306,17.17875]},"properties":{"population":598393,"capital":4,"place":"city","name":"11U05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.15994,20.17691]},"properties":{"population":321887,"capital":4,"place":"city","name":"11U07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.24437,18.57427]},"properties":{"population":512989,"capital":4,"place":"city","name":"11U09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.38065,19.26425]},"properties":{"population":482831,"capital":4,"place":"city","name":"11U10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.69908,19.40516]},"properties":{"population":469134,"capital":4,"place":"city","name":"11U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.79158,20.34451]},"properties":{"population":207944,"capital":4,"place":"city","name":"11Uxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.4042,21.27348]},"properties":{"population":651323,"capital":4,"place":"city","name":"11Y05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.07633,21.30983]},"properties":{"population":645047,"capital":4,"place":"city","name":"11Y11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.25197,20.85773]},"properties":{"population":783597,"capital":4,"place":"city","name":"11Y16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.07145,22.13298]},"properties":{"population":595583,"capital":4,"place":"city","name":"11Y35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.12103,21.87954]},"properties":{"population":704141,"capital":4,"place":"city","name":"11Y40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.24378,21.0785]},"properties":{"population":636990,"capital":4,"place":"city","name":"11Y50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.53524,21.42388]},"properties":{"population":578689,"capital":4,"place":"city","name":"11Y55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.59605,4.53561]},"properties":{"population":650727,"capital":4,"place":"city","name":"11Y60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.11892,22.15199]},"properties":{"population":520400,"capital":4,"place":"city","name":"11Y65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.13254,23.52517]},"properties":{"population":542495,"capital":4,"place":"city","name":"11Y70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.11007,22.13696]},"properties":{"population":536597,"capital":4,"place":"city","name":"11Y99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.29042,21.36335]},"properties":{"population":482028,"capital":4,"place":"city","name":"11Yxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.97648,-3.10615]},"properties":{"population":632076,"capital":4,"place":"city","name":"11Z05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.54588,5.63881]},"properties":{"population":69314,"capital":4,"place":"city","name":"11Zxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.60185,8.0253]},"properties":{"population":230258,"capital":4,"place":"city","name":"12-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.97807,6.93442]},"properties":{"population":665801,"capital":4,"place":"city","name":"12-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.71631,6.10413]},"properties":{"population":573979,"capital":4,"place":"city","name":"12-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.72885,8.09897]},"properties":{"population":590808,"capital":4,"place":"city","name":"12-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.2341,6.76186]},"properties":{"population":595583,"capital":4,"place":"city","name":"12-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.95932,6.98297]},"properties":{"population":544241,"capital":4,"place":"city","name":"12-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.45043,8.15991]},"properties":{"population":398898,"capital":4,"place":"city","name":"12-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.29786,8.01339]},"properties":{"population":352636,"capital":4,"place":"city","name":"12-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.73509,19.88127]},"properties":{"population":648004,"capital":4,"place":"city","name":"12D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.80345,17.47667]},"properties":{"population":722329,"capital":4,"place":"city","name":"12D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.65647,2.00967]},"properties":{"population":670441,"capital":4,"place":"city","name":"12D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.05839,22.35506]},"properties":{"population":593753,"capital":4,"place":"city","name":"12D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.36881,21.42987]},"properties":{"population":219722,"capital":4,"place":"city","name":"12Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.25449,9.51578]},"properties":{"population":702908,"capital":4,"place":"city","name":"12E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.05895,8.3576]},"properties":{"population":630078,"capital":4,"place":"city","name":"12E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.93704,8.03041]},"properties":{"population":636647,"capital":4,"place":"city","name":"12E12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.02116,8.5771]},"properties":{"population":639526,"capital":4,"place":"city","name":"12E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.73238,7.75929]},"properties":{"population":642810,"capital":4,"place":"city","name":"12E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.58408,7.32027]},"properties":{"population":518178,"capital":4,"place":"city","name":"12E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.65978,7.10623]},"properties":{"population":527299,"capital":4,"place":"city","name":"12E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.10423,6.67302]},"properties":{"population":517614,"capital":4,"place":"city","name":"12E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.5346,6.95091]},"properties":{"population":340119,"capital":4,"place":"city","name":"12Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.66248,22.73712]},"properties":{"population":634738,"capital":4,"place":"city","name":"12F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.16853,19.89641]},"properties":{"population":759538,"capital":4,"place":"city","name":"12F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.64804,22.27673]},"properties":{"population":652356,"capital":4,"place":"city","name":"12F12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.78349,1.90297]},"properties":{"population":536597,"capital":4,"place":"city","name":"12F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.51184,19.64555]},"properties":{"population":626339,"capital":4,"place":"city","name":"12F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.99108,23.64671]},"properties":{"population":531320,"capital":4,"place":"city","name":"12F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.80338,23.36279]},"properties":{"population":412713,"capital":4,"place":"city","name":"12Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.65983,6.36559]},"properties":{"population":663463,"capital":4,"place":"city","name":"12G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.14992,21.76866]},"properties":{"population":423410,"capital":4,"place":"city","name":"12G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.459,22.37047]},"properties":{"population":361091,"capital":4,"place":"city","name":"12G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.16688,21.82883]},"properties":{"population":179175,"capital":4,"place":"city","name":"12Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.01127,1.47142]},"properties":{"population":740731,"capital":4,"place":"city","name":"12H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.59758,21.5809]},"properties":{"population":569035,"capital":4,"place":"city","name":"12H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.71434,19.88452]},"properties":{"population":592425,"capital":4,"place":"city","name":"12H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.29881,21.57062]},"properties":{"population":575890,"capital":4,"place":"city","name":"12H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.86277,-2.91544]},"properties":{"population":385014,"capital":4,"place":"city","name":"12H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.5398,22.71013]},"properties":{"population":294443,"capital":4,"place":"city","name":"12Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.46235,23.52442]},"properties":{"population":418965,"capital":4,"place":"city","name":"12J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.54848,3.97279]},"properties":{"population":704838,"capital":4,"place":"city","name":"12J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.13208,24.11857]},"properties":{"population":469134,"capital":4,"place":"city","name":"12J12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.85795,20.72511]},"properties":{"population":663987,"capital":4,"place":"city","name":"12J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.05773,23.55139]},"properties":{"population":313549,"capital":4,"place":"city","name":"12J17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.9881,23.65353]},"properties":{"population":605912,"capital":4,"place":"city","name":"12J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.86613,19.53813]},"properties":{"population":665929,"capital":4,"place":"city","name":"12J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.31101,24.34274]},"properties":{"population":385014,"capital":4,"place":"city","name":"12J27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.49521,24.07244]},"properties":{"population":516478,"capital":4,"place":"city","name":"12J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.64585,22.65638]},"properties":{"population":283321,"capital":4,"place":"city","name":"12Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.6406,15.86458]},"properties":{"population":596357,"capital":4,"place":"city","name":"12K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.79295,13.85068]},"properties":{"population":583773,"capital":4,"place":"city","name":"12K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.93678,16.11629]},"properties":{"population":509375,"capital":4,"place":"city","name":"12K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.01824,17.13891]},"properties":{"population":69314,"capital":4,"place":"city","name":"12Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.69836,20.31764]},"properties":{"population":547227,"capital":4,"place":"city","name":"12L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.55373,20.47618]},"properties":{"population":452178,"capital":4,"place":"city","name":"12L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.18227,18.56145]},"properties":{"population":661069,"capital":4,"place":"city","name":"12L12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.22325,21.17131]},"properties":{"population":436944,"capital":4,"place":"city","name":"12L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.85645,21.44203]},"properties":{"population":458496,"capital":4,"place":"city","name":"12L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.26165,21.70065]},"properties":{"population":160943,"capital":4,"place":"city","name":"12Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.8241,2.24542]},"properties":{"population":748829,"capital":4,"place":"city","name":"12Y05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.56863,20.72509]},"properties":{"population":219722,"capital":4,"place":"city","name":"12Y99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.23071,22.15349]},"properties":{"population":160943,"capital":4,"place":"city","name":"12Yxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.70011,9.83745]},"properties":{"population":256494,"capital":4,"place":"city","name":"13-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.33208,8.35771]},"properties":{"population":646302,"capital":4,"place":"city","name":"13-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.74043,7.85234]},"properties":{"population":600635,"capital":4,"place":"city","name":"13-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.75945,8.62811]},"properties":{"population":506259,"capital":4,"place":"city","name":"13-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.04182,9.35804]},"properties":{"population":563478,"capital":4,"place":"city","name":"13-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.44104,8.49724]},"properties":{"population":604263,"capital":4,"place":"city","name":"13-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.65398,9.86821]},"properties":{"population":109861,"capital":4,"place":"city","name":"13-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.84431,9.72254]},"properties":{"population":503695,"capital":4,"place":"city","name":"13-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.24824,-2.20532]},"properties":{"population":668461,"capital":4,"place":"city","name":"13A02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.34541,-2.7682]},"properties":{"population":658892,"capital":4,"place":"city","name":"13A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.89031,-3.77708]},"properties":{"population":549306,"capital":4,"place":"city","name":"13A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.55258,-2.22299]},"properties":{"population":830052,"capital":4,"place":"city","name":"13A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.45541,-2.40875]},"properties":{"population":681673,"capital":4,"place":"city","name":"13A18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.75249,-3.71799]},"properties":{"population":69314,"capital":4,"place":"city","name":"13A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.08668,-2.37738]},"properties":{"population":697914,"capital":4,"place":"city","name":"13A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.26514,-2.69975]},"properties":{"population":679458,"capital":4,"place":"city","name":"13A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.06938,4.05982]},"properties":{"population":739510,"capital":4,"place":"city","name":"13A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.8088,-3.71289]},"properties":{"population":402535,"capital":4,"place":"city","name":"13A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.37088,-3.40999]},"properties":{"population":653524,"capital":4,"place":"city","name":"13A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.63101,-2.97212]},"properties":{"population":389182,"capital":4,"place":"city","name":"13Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.60254,6.05816]},"properties":{"population":684268,"capital":4,"place":"city","name":"13B02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.49551,6.23616]},"properties":{"population":624804,"capital":4,"place":"city","name":"13B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.36325,6.13109]},"properties":{"population":667834,"capital":4,"place":"city","name":"13B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.13793,6.46956]},"properties":{"population":69314,"capital":4,"place":"city","name":"13B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.13698,6.28613]},"properties":{"population":541164,"capital":4,"place":"city","name":"13B21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.33423,6.16321]},"properties":{"population":677536,"capital":4,"place":"city","name":"13B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.33565,6.62787]},"properties":{"population":471849,"capital":4,"place":"city","name":"13B24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.2428,5.88047]},"properties":{"population":718083,"capital":4,"place":"city","name":"13B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.24523,6.20837]},"properties":{"population":626530,"capital":4,"place":"city","name":"13B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.49761,6.41327]},"properties":{"population":489783,"capital":4,"place":"city","name":"13B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.0683,6.21911]},"properties":{"population":503695,"capital":4,"place":"city","name":"13B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.56897,6.25532]},"properties":{"population":584643,"capital":4,"place":"city","name":"13B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.52719,6.58054]},"properties":{"population":321887,"capital":4,"place":"city","name":"13Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.54324,4.43616]},"properties":{"population":744600,"capital":4,"place":"city","name":"13C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.21002,19.64126]},"properties":{"population":727170,"capital":4,"place":"city","name":"13C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.83381,22.90433]},"properties":{"population":652502,"capital":4,"place":"city","name":"13C11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.61925,21.10769]},"properties":{"population":558724,"capital":4,"place":"city","name":"13C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.12666,21.80738]},"properties":{"population":690575,"capital":4,"place":"city","name":"13C13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.27614,21.67309]},"properties":{"population":688448,"capital":4,"place":"city","name":"13C14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.63316,21.83498]},"properties":{"population":725559,"capital":4,"place":"city","name":"13C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.23787,21.91947]},"properties":{"population":543372,"capital":4,"place":"city","name":"13C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.337,23.30071]},"properties":{"population":666822,"capital":4,"place":"city","name":"13C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.4028,21.24749]},"properties":{"population":469134,"capital":4,"place":"city","name":"13C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-16.00882,21.52688]},"properties":{"population":69314,"capital":4,"place":"city","name":"13C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.98004,20.65435]},"properties":{"population":636302,"capital":4,"place":"city","name":"13C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.49441,22.61022]},"properties":{"population":402535,"capital":4,"place":"city","name":"13Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.60536,-5.2558]},"properties":{"population":765539,"capital":4,"place":"city","name":"13D02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.02069,-6.00824]},"properties":{"population":691473,"capital":4,"place":"city","name":"13D03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.43785,-5.53636]},"properties":{"population":691274,"capital":4,"place":"city","name":"13D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.38025,-5.9251]},"properties":{"population":642648,"capital":4,"place":"city","name":"13D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.6688,-6.21104]},"properties":{"population":520400,"capital":4,"place":"city","name":"13D09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.01019,-5.82101]},"properties":{"population":580513,"capital":4,"place":"city","name":"13D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.55259,-5.96379]},"properties":{"population":644413,"capital":4,"place":"city","name":"13D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.36424,-6.16001]},"properties":{"population":470048,"capital":4,"place":"city","name":"13D22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.86798,-5.79786]},"properties":{"population":636130,"capital":4,"place":"city","name":"13D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.67834,-6.15794]},"properties":{"population":504985,"capital":4,"place":"city","name":"13D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.80466,-5.13198]},"properties":{"population":732778,"capital":4,"place":"city","name":"13D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.40723,-5.59675]},"properties":{"population":722110,"capital":4,"place":"city","name":"13D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.37441,-5.89722]},"properties":{"population":588610,"capital":4,"place":"city","name":"13D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.28059,-5.81658]},"properties":{"population":385014,"capital":4,"place":"city","name":"13Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.14692,12.88433]},"properties":{"population":758375,"capital":4,"place":"city","name":"13E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.72615,13.03288]},"properties":{"population":671174,"capital":4,"place":"city","name":"13E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.22767,13.43416]},"properties":{"population":666185,"capital":4,"place":"city","name":"13E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.08007,13.80991]},"properties":{"population":591079,"capital":4,"place":"city","name":"13E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.97058,13.93316]},"properties":{"population":294443,"capital":4,"place":"city","name":"13Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.44114,20.90368]},"properties":{"population":763433,"capital":4,"place":"city","name":"13F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.06704,21.5092]},"properties":{"population":528320,"capital":4,"place":"city","name":"13F07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.20903,23.22924]},"properties":{"population":584064,"capital":4,"place":"city","name":"13F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.38358,-0.56962]},"properties":{"population":667708,"capital":4,"place":"city","name":"13F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.35268,2.71457]},"properties":{"population":815765,"capital":4,"place":"city","name":"13F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.38913,19.90334]},"properties":{"population":703085,"capital":4,"place":"city","name":"13F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.62253,21.37647]},"properties":{"population":659167,"capital":4,"place":"city","name":"13F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.93903,23.04416]},"properties":{"population":529330,"capital":4,"place":"city","name":"13F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.81662,22.88568]},"properties":{"population":414313,"capital":4,"place":"city","name":"13F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.31883,20.40445]},"properties":{"population":464439,"capital":4,"place":"city","name":"13F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.09036,22.12251]},"properties":{"population":461512,"capital":4,"place":"city","name":"13F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.79676,21.42341]},"properties":{"population":678897,"capital":4,"place":"city","name":"13F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.34415,20.88093]},"properties":{"population":666695,"capital":4,"place":"city","name":"13F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.01774,21.82582]},"properties":{"population":366356,"capital":4,"place":"city","name":"13F65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.36274,21.4877]},"properties":{"population":583188,"capital":4,"place":"city","name":"13F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.31785,21.74668]},"properties":{"population":363758,"capital":4,"place":"city","name":"13Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.64074,21.20653]},"properties":{"population":753796,"capital":4,"place":"city","name":"13G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.84951,20.29015]},"properties":{"population":207944,"capital":4,"place":"city","name":"13G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.89674,20.47723]},"properties":{"population":138629,"capital":4,"place":"city","name":"13Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.77075,13.57206]},"properties":{"population":678671,"capital":4,"place":"city","name":"13H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.01049,12.51305]},"properties":{"population":797074,"capital":4,"place":"city","name":"13H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.89842,13.45732]},"properties":{"population":703526,"capital":4,"place":"city","name":"13H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.95916,12.76156]},"properties":{"population":668336,"capital":4,"place":"city","name":"13H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.33805,13.77925]},"properties":{"population":368887,"capital":4,"place":"city","name":"13Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.07367,20.82184]},"properties":{"population":633859,"capital":4,"place":"city","name":"13J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-14.55061,23.23701]},"properties":{"population":385014,"capital":4,"place":"city","name":"13J07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.48019,24.4598]},"properties":{"population":599893,"capital":4,"place":"city","name":"13J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.98129,24.27155]},"properties":{"population":569035,"capital":4,"place":"city","name":"13J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.98607,24.18564]},"properties":{"population":470048,"capital":4,"place":"city","name":"13J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.58388,24.54337]},"properties":{"population":502388,"capital":4,"place":"city","name":"13J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.03576,0.52353]},"properties":{"population":562040,"capital":4,"place":"city","name":"13J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.84007,24.30075]},"properties":{"population":567675,"capital":4,"place":"city","name":"13J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-15.78448,22.32009]},"properties":{"population":283321,"capital":4,"place":"city","name":"13Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.08509,18.83958]},"properties":{"population":530826,"capital":4,"place":"city","name":"13K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.43485,18.21536]},"properties":{"population":573334,"capital":4,"place":"city","name":"13L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.58562,21.33502]},"properties":{"population":138629,"capital":4,"place":"city","name":"13L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.54965,19.43155]},"properties":{"population":560211,"capital":4,"place":"city","name":"13M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.09056,18.98671]},"properties":{"population":499043,"capital":4,"place":"city","name":"13M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.64552,20.71178]},"properties":{"population":478749,"capital":4,"place":"city","name":"13M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.27868,-3.71185]},"properties":{"population":644888,"capital":4,"place":"city","name":"13N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.02382,-1.64615]},"properties":{"population":619031,"capital":4,"place":"city","name":"13N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.28421,-3.53411]},"properties":{"population":633859,"capital":4,"place":"city","name":"13N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.70133,-4.47405]},"properties":{"population":459511,"capital":4,"place":"city","name":"13N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.94271,-5.01936]},"properties":{"population":207944,"capital":4,"place":"city","name":"13Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.31465,25.43415]},"properties":{"population":604025,"capital":4,"place":"city","name":"13P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.49224,25.13862]},"properties":{"population":815306,"capital":4,"place":"city","name":"13P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.33479,25.58142]},"properties":{"population":594542,"capital":4,"place":"city","name":"13P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.55767,25.75534]},"properties":{"population":433073,"capital":4,"place":"city","name":"13P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.06125,25.12784]},"properties":{"population":592157,"capital":4,"place":"city","name":"13P25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.29798,25.53418]},"properties":{"population":592958,"capital":4,"place":"city","name":"13P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.55632,25.45199]},"properties":{"population":430406,"capital":4,"place":"city","name":"13Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.91261,-2.96114]},"properties":{"population":329583,"capital":4,"place":"city","name":"14-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.92357,3.34071]},"properties":{"population":617378,"capital":4,"place":"city","name":"14-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.18416,6.66761]},"properties":{"population":724208,"capital":4,"place":"city","name":"14-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.85386,3.52868]},"properties":{"population":655393,"capital":4,"place":"city","name":"14-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.66726,-2.23817]},"properties":{"population":555295,"capital":4,"place":"city","name":"14-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.95885,4.76191]},"properties":{"population":725911,"capital":4,"place":"city","name":"14-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.86436,-3.14429]},"properties":{"population":610031,"capital":4,"place":"city","name":"14-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.57555,19.25367]},"properties":{"population":689568,"capital":4,"place":"city","name":"14A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.4185,18.71207]},"properties":{"population":605208,"capital":4,"place":"city","name":"14A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.84765,17.78782]},"properties":{"population":641181,"capital":4,"place":"city","name":"14A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.56638,19.13299]},"properties":{"population":685540,"capital":4,"place":"city","name":"14A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.01735,24.79189]},"properties":{"population":355534,"capital":4,"place":"city","name":"14A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.63643,-0.82526]},"properties":{"population":678332,"capital":4,"place":"city","name":"14A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.7288,23.84269]},"properties":{"population":160943,"capital":4,"place":"city","name":"14A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.46496,23.63003]},"properties":{"population":479579,"capital":4,"place":"city","name":"14A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.34144,24.09656]},"properties":{"population":336729,"capital":4,"place":"city","name":"14A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.66418,24.76044]},"properties":{"population":506890,"capital":4,"place":"city","name":"14A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.18021,23.28311]},"properties":{"population":417438,"capital":4,"place":"city","name":"14Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.38663,-0.95571]},"properties":{"population":814089,"capital":4,"place":"city","name":"14B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.23298,-3.17194]},"properties":{"population":623244,"capital":4,"place":"city","name":"14B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.32697,-4.4501]},"properties":{"population":539816,"capital":4,"place":"city","name":"14B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.29743,-4.70937]},"properties":{"population":570378,"capital":4,"place":"city","name":"14B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.18426,-4.32739]},"properties":{"population":611809,"capital":4,"place":"city","name":"14B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.33057,-4.69553]},"properties":{"population":487519,"capital":4,"place":"city","name":"14B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.135,-4.60533]},"properties":{"population":552942,"capital":4,"place":"city","name":"14B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.26146,-4.36824]},"properties":{"population":397029,"capital":4,"place":"city","name":"14B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.02312,-4.8555]},"properties":{"population":366356,"capital":4,"place":"city","name":"14Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.78743,20.04663]},"properties":{"population":733367,"capital":4,"place":"city","name":"14C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.91345,18.43064]},"properties":{"population":717548,"capital":4,"place":"city","name":"14C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.6094,20.72075]},"properties":{"population":725700,"capital":4,"place":"city","name":"14C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.47816,-0.95097]},"properties":{"population":805388,"capital":4,"place":"city","name":"14C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.48482,20.82121]},"properties":{"population":542053,"capital":4,"place":"city","name":"14C21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.93733,19.48039]},"properties":{"population":661873,"capital":4,"place":"city","name":"14C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.65117,-0.68857]},"properties":{"population":731322,"capital":4,"place":"city","name":"14C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.97445,-0.95916]},"properties":{"population":749108,"capital":4,"place":"city","name":"14C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.84031,22.80677]},"properties":{"population":509375,"capital":4,"place":"city","name":"14C34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.25217,20.06613]},"properties":{"population":700578,"capital":4,"place":"city","name":"14C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.8629,-0.79573]},"properties":{"population":604025,"capital":4,"place":"city","name":"14C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.15956,22.87796]},"properties":{"population":531811,"capital":4,"place":"city","name":"14C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.94656,24.76732]},"properties":{"population":346573,"capital":4,"place":"city","name":"14Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.68035,15.70852]},"properties":{"population":682437,"capital":4,"place":"city","name":"14D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.01835,19.55799]},"properties":{"population":677078,"capital":4,"place":"city","name":"14D06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.03896,-0.58261]},"properties":{"population":649978,"capital":4,"place":"city","name":"14D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.09105,-2.02977]},"properties":{"population":479579,"capital":4,"place":"city","name":"14D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.57032,18.6455]},"properties":{"population":698007,"capital":4,"place":"city","name":"14D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.92323,2.38152]},"properties":{"population":781883,"capital":4,"place":"city","name":"14D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.35951,11.8667]},"properties":{"population":728824,"capital":4,"place":"city","name":"14D21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.38486,19.03822]},"properties":{"population":627287,"capital":4,"place":"city","name":"14D22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.21162,21.90134]},"properties":{"population":597126,"capital":4,"place":"city","name":"14D23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.89316,22.4803]},"properties":{"population":510594,"capital":4,"place":"city","name":"14D24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.63656,24.71772]},"properties":{"population":138629,"capital":4,"place":"city","name":"14D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.74984,22.74089]},"properties":{"population":516478,"capital":4,"place":"city","name":"14D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.97991,24.83272]},"properties":{"population":321887,"capital":4,"place":"city","name":"14Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.39446,7.55172]},"properties":{"population":763771,"capital":4,"place":"city","name":"14E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.10721,-1.34329]},"properties":{"population":673578,"capital":4,"place":"city","name":"14E07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.22359,-1.71972]},"properties":{"population":599396,"capital":4,"place":"city","name":"14E08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.18881,-2.0017]},"properties":{"population":138629,"capital":4,"place":"city","name":"14E09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.05779,-1.93884]},"properties":{"population":239789,"capital":4,"place":"city","name":"14E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.25415,7.53487]},"properties":{"population":738770,"capital":4,"place":"city","name":"14E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.94493,-2.03525]},"properties":{"population":459511,"capital":4,"place":"city","name":"14E16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.91102,5.10989]},"properties":{"population":538449,"capital":4,"place":"city","name":"14E18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.38259,7.45441]},"properties":{"population":676964,"capital":4,"place":"city","name":"14E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.22421,-1.74]},"properties":{"population":587493,"capital":4,"place":"city","name":"14E22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.98414,5.1535]},"properties":{"population":613988,"capital":4,"place":"city","name":"14E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.09841,5.64869]},"properties":{"population":736010,"capital":4,"place":"city","name":"14E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.20197,-1.91072]},"properties":{"population":160943,"capital":4,"place":"city","name":"14E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.2267,-1.71276]},"properties":{"population":548893,"capital":4,"place":"city","name":"14E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.17509,-1.80679]},"properties":{"population":397029,"capital":4,"place":"city","name":"14Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.50945,-1.80326]},"properties":{"population":842222,"capital":4,"place":"city","name":"14F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.60867,24.01703]},"properties":{"population":446590,"capital":4,"place":"city","name":"14F06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.13532,22.41351]},"properties":{"population":566988,"capital":4,"place":"city","name":"14F08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.77801,15.12327]},"properties":{"population":720489,"capital":4,"place":"city","name":"14F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.13259,19.46416]},"properties":{"population":625190,"capital":4,"place":"city","name":"14F17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.63361,24.49653]},"properties":{"population":504985,"capital":4,"place":"city","name":"14F18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.91046,20.09632]},"properties":{"population":718538,"capital":4,"place":"city","name":"14F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.12346,19.41684]},"properties":{"population":692559,"capital":4,"place":"city","name":"14F22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.61489,19.08006]},"properties":{"population":632972,"capital":4,"place":"city","name":"14F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.1353,19.54334]},"properties":{"population":711639,"capital":4,"place":"city","name":"14F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.15216,20.7729]},"properties":{"population":685329,"capital":4,"place":"city","name":"14F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.11883,16.62382]},"properties":{"population":673578,"capital":4,"place":"city","name":"14F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.6547,20.34774]},"properties":{"population":681563,"capital":4,"place":"city","name":"14F42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.36434,19.93657]},"properties":{"population":663200,"capital":4,"place":"city","name":"14F43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.56624,21.13276]},"properties":{"population":663463,"capital":4,"place":"city","name":"14F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.69872,18.95864]},"properties":{"population":611146,"capital":4,"place":"city","name":"14F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.20949,24.45499]},"properties":{"population":391202,"capital":4,"place":"city","name":"14Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.28979,-0.78]},"properties":{"population":782084,"capital":4,"place":"city","name":"14G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.25815,19.02543]},"properties":{"population":735308,"capital":4,"place":"city","name":"14G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.11337,24.40499]},"properties":{"population":317805,"capital":4,"place":"city","name":"14G12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.47479,2.55817]},"properties":{"population":747533,"capital":4,"place":"city","name":"14G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.56988,19.42839]},"properties":{"population":573009,"capital":4,"place":"city","name":"14G17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.14957,-2.42009]},"properties":{"population":717242,"capital":4,"place":"city","name":"14G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.64436,17.10174]},"properties":{"population":637502,"capital":4,"place":"city","name":"14G22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.82686,2.04837]},"properties":{"population":717930,"capital":4,"place":"city","name":"14G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.9502,22.78304]},"properties":{"population":468213,"capital":4,"place":"city","name":"14G27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.45852,16.87255]},"properties":{"population":570378,"capital":4,"place":"city","name":"14G32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.70866,-0.89464]},"properties":{"population":726473,"capital":4,"place":"city","name":"14G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.18738,2.33232]},"properties":{"population":697073,"capital":4,"place":"city","name":"14G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.84476,24.37406]},"properties":{"population":346573,"capital":4,"place":"city","name":"14G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.65788,18.60031]},"properties":{"population":748549,"capital":4,"place":"city","name":"14G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.29576,19.11614]},"properties":{"population":562040,"capital":4,"place":"city","name":"14G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.6045,23.94506]},"properties":{"population":452178,"capital":4,"place":"city","name":"14Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.16284,2.77873]},"properties":{"population":715617,"capital":4,"place":"city","name":"14H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.25756,17.16977]},"properties":{"population":797487,"capital":4,"place":"city","name":"14H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.5468,19.11015]},"properties":{"population":647234,"capital":4,"place":"city","name":"14H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.18349,20.8681]},"properties":{"population":745645,"capital":4,"place":"city","name":"14H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.94558,-0.8062]},"properties":{"population":726192,"capital":4,"place":"city","name":"14H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.90194,-1.11615]},"properties":{"population":744014,"capital":4,"place":"city","name":"14H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.48298,24.64113]},"properties":{"population":138629,"capital":4,"place":"city","name":"14H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.54715,19.57413]},"properties":{"population":647850,"capital":4,"place":"city","name":"14H37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.38528,18.43696]},"properties":{"population":748099,"capital":4,"place":"city","name":"14H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.15043,19.46239]},"properties":{"population":634035,"capital":4,"place":"city","name":"14H42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.73707,15.73724]},"properties":{"population":790285,"capital":4,"place":"city","name":"14H45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.82404,2.44727]},"properties":{"population":772400,"capital":4,"place":"city","name":"14H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.46766,-2.81311]},"properties":{"population":652649,"capital":4,"place":"city","name":"14H51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.79108,2.57457]},"properties":{"population":786748,"capital":4,"place":"city","name":"14H52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.951,18.29515]},"properties":{"population":702375,"capital":4,"place":"city","name":"14H55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.67857,22.13251]},"properties":{"population":494875,"capital":4,"place":"city","name":"14H57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.17597,19.74105]},"properties":{"population":751752,"capital":4,"place":"city","name":"14H60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.61831,15.44068]},"properties":{"population":659714,"capital":4,"place":"city","name":"14H70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.15103,21.16635]},"properties":{"population":554126,"capital":4,"place":"city","name":"14H81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.16545,20.70916]},"properties":{"population":662406,"capital":4,"place":"city","name":"14H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.97941,23.46657]},"properties":{"population":530330,"capital":4,"place":"city","name":"14Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.48632,19.92837]},"properties":{"population":758375,"capital":4,"place":"city","name":"14J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.8423,20.24439]},"properties":{"population":652941,"capital":4,"place":"city","name":"14J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.22836,-1.2519]},"properties":{"population":759940,"capital":4,"place":"city","name":"14J17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.47442,20.09118]},"properties":{"population":594017,"capital":4,"place":"city","name":"14J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.68779,19.96187]},"properties":{"population":753743,"capital":4,"place":"city","name":"14J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.58742,-1.01339]},"properties":{"population":714361,"capital":4,"place":"city","name":"14J26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.44856,19.12353]},"properties":{"population":636130,"capital":4,"place":"city","name":"14J27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.85543,19.72624]},"properties":{"population":752617,"capital":4,"place":"city","name":"14J28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.83307,19.53768]},"properties":{"population":670686,"capital":4,"place":"city","name":"14J29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.09276,19.15069]},"properties":{"population":752131,"capital":4,"place":"city","name":"14J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.10337,18.95238]},"properties":{"population":785748,"capital":4,"place":"city","name":"14J32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.80271,19.6168]},"properties":{"population":651323,"capital":4,"place":"city","name":"14J33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.84899,21.44999]},"properties":{"population":594542,"capital":4,"place":"city","name":"14J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.87862,21.14089]},"properties":{"population":631716,"capital":4,"place":"city","name":"14J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.92309,24.26126]},"properties":{"population":361091,"capital":4,"place":"city","name":"14J42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.17279,19.51558]},"properties":{"population":723561,"capital":4,"place":"city","name":"14J45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.91051,19.4472]},"properties":{"population":650876,"capital":4,"place":"city","name":"14J50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.2509,-0.91131]},"properties":{"population":748829,"capital":4,"place":"city","name":"14J60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.2883,18.3977]},"properties":{"population":680239,"capital":4,"place":"city","name":"14J70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.89235,18.31512]},"properties":{"population":543372,"capital":4,"place":"city","name":"14J80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.28199,17.3814]},"properties":{"population":607764,"capital":4,"place":"city","name":"14J81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.20713,19.17619]},"properties":{"population":607304,"capital":4,"place":"city","name":"14J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.07284,23.72048]},"properties":{"population":481218,"capital":4,"place":"city","name":"14Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.72872,21.95267]},"properties":{"population":548063,"capital":4,"place":"city","name":"14K02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.82593,-2.15059]},"properties":{"population":644571,"capital":4,"place":"city","name":"14K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.36687,19.49219]},"properties":{"population":654678,"capital":4,"place":"city","name":"14K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.99885,23.21173]},"properties":{"population":514749,"capital":4,"place":"city","name":"14K12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.38485,-2.09564]},"properties":{"population":696034,"capital":4,"place":"city","name":"14K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.47145,19.11075]},"properties":{"population":623441,"capital":4,"place":"city","name":"14K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.45742,19.20589]},"properties":{"population":628971,"capital":4,"place":"city","name":"14K22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.56958,14.91557]},"properties":{"population":700940,"capital":4,"place":"city","name":"14K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.41905,20.76134]},"properties":{"population":616120,"capital":4,"place":"city","name":"14K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.18164,19.91875]},"properties":{"population":550125,"capital":4,"place":"city","name":"14K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.39787,23.79354]},"properties":{"population":409434,"capital":4,"place":"city","name":"14Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.90676,18.46697]},"properties":{"population":695272,"capital":4,"place":"city","name":"14L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.44464,18.84921]},"properties":{"population":556068,"capital":4,"place":"city","name":"14L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.74666,19.93413]},"properties":{"population":701301,"capital":4,"place":"city","name":"14L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.99534,18.1798]},"properties":{"population":564544,"capital":4,"place":"city","name":"14L17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.27149,16.17682]},"properties":{"population":733432,"capital":4,"place":"city","name":"14L24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.28721,24.44626]},"properties":{"population":69314,"capital":4,"place":"city","name":"14L27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.1896,2.49772]},"properties":{"population":809070,"capital":4,"place":"city","name":"14L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.02149,20.8158]},"properties":{"population":655961,"capital":4,"place":"city","name":"14L35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.70552,19.8637]},"properties":{"population":486753,"capital":4,"place":"city","name":"14L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.26092,18.25615]},"properties":{"population":520400,"capital":4,"place":"city","name":"14L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.16392,22.81005]},"properties":{"population":387120,"capital":4,"place":"city","name":"14Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.76275,19.93899]},"properties":{"population":715070,"capital":4,"place":"city","name":"14M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.96352,22.98122]},"properties":{"population":543372,"capital":4,"place":"city","name":"14M06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.66798,17.96336]},"properties":{"population":615697,"capital":4,"place":"city","name":"14M07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.4281,21.01635]},"properties":{"population":719443,"capital":4,"place":"city","name":"14M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.3208,19.58155]},"properties":{"population":658755,"capital":4,"place":"city","name":"14M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.8685,2.59892]},"properties":{"population":818395,"capital":4,"place":"city","name":"14M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.15092,16.72567]},"properties":{"population":738398,"capital":4,"place":"city","name":"14M17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.16273,18.89937]},"properties":{"population":674051,"capital":4,"place":"city","name":"14M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.01418,23.53979]},"properties":{"population":489034,"capital":4,"place":"city","name":"14M22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.446,10.9714]},"properties":{"population":797453,"capital":4,"place":"city","name":"14M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.65587,19.82356]},"properties":{"population":559842,"capital":4,"place":"city","name":"14M27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.8955,17.06151]},"properties":{"population":476217,"capital":4,"place":"city","name":"14M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.43097,23.97705]},"properties":{"population":239789,"capital":4,"place":"city","name":"14M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.33493,19.44391]},"properties":{"population":573979,"capital":4,"place":"city","name":"14M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.43817,23.67189]},"properties":{"population":382864,"capital":4,"place":"city","name":"14Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.79009,5.02396]},"properties":{"population":792840,"capital":4,"place":"city","name":"14N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.80232,2.40637]},"properties":{"population":397029,"capital":4,"place":"city","name":"14N07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.15886,3.9188]},"properties":{"population":723633,"capital":4,"place":"city","name":"14N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.04125,3.18632]},"properties":{"population":655535,"capital":4,"place":"city","name":"14N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.17999,3.00453]},"properties":{"population":605912,"capital":4,"place":"city","name":"14N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.07807,2.95152]},"properties":{"population":477068,"capital":4,"place":"city","name":"14N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.99781,2.67568]},"properties":{"population":506890,"capital":4,"place":"city","name":"14N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.35384,4.53584]},"properties":{"population":751914,"capital":4,"place":"city","name":"14N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.01744,2.4951]},"properties":{"population":511198,"capital":4,"place":"city","name":"14N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.90579,2.32238]},"properties":{"population":400733,"capital":4,"place":"city","name":"14Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.24499,17.94501]},"properties":{"population":674405,"capital":4,"place":"city","name":"14P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.19279,-3.34351]},"properties":{"population":704053,"capital":4,"place":"city","name":"14P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.05832,18.57348]},"properties":{"population":590263,"capital":4,"place":"city","name":"14P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.2529,21.65708]},"properties":{"population":531811,"capital":4,"place":"city","name":"14P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.84094,21.06332]},"properties":{"population":680903,"capital":4,"place":"city","name":"14P25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.16553,19.66925]},"properties":{"population":603068,"capital":4,"place":"city","name":"14P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.2164,19.78169]},"properties":{"population":629156,"capital":4,"place":"city","name":"14Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.69095,1.67498]},"properties":{"population":691274,"capital":4,"place":"city","name":"14Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.52881,1.10197]},"properties":{"population":612029,"capital":4,"place":"city","name":"14Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.70735,1.54886]},"properties":{"population":592958,"capital":4,"place":"city","name":"14Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.66859,1.01019]},"properties":{"population":595324,"capital":4,"place":"city","name":"14Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.90415,-0.46713]},"properties":{"population":109861,"capital":4,"place":"city","name":"14Q25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.88367,-0.08647]},"properties":{"population":313549,"capital":4,"place":"city","name":"14Q30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.12783,0.15379]},"properties":{"population":380666,"capital":4,"place":"city","name":"14Q65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.38602,1.30282]},"properties":{"population":603787,"capital":4,"place":"city","name":"14Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.93858,-0.14497]},"properties":{"population":420469,"capital":4,"place":"city","name":"14Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.15592,8.16611]},"properties":{"population":490527,"capital":4,"place":"city","name":"14R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.50583,7.83003]},"properties":{"population":638181,"capital":4,"place":"city","name":"14R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.49804,7.55526]},"properties":{"population":593224,"capital":4,"place":"city","name":"14R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.53652,6.59204]},"properties":{"population":600388,"capital":4,"place":"city","name":"14R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.34853,7.9916]},"properties":{"population":518178,"capital":4,"place":"city","name":"14R25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.42387,8.10217]},"properties":{"population":421950,"capital":4,"place":"city","name":"14R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.10417,8.26736]},"properties":{"population":256494,"capital":4,"place":"city","name":"14Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.12777,9.19732]},"properties":{"population":647389,"capital":4,"place":"city","name":"14T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.6358,12.04381]},"properties":{"population":376120,"capital":4,"place":"city","name":"14T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.70592,12.00456]},"properties":{"population":414313,"capital":4,"place":"city","name":"14T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.5947,11.67137]},"properties":{"population":409434,"capital":4,"place":"city","name":"14T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.71104,11.9194]},"properties":{"population":294443,"capital":4,"place":"city","name":"14T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.55856,12.04671]},"properties":{"population":304452,"capital":4,"place":"city","name":"14T90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.43755,11.87612]},"properties":{"population":343398,"capital":4,"place":"city","name":"14T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.48258,12.23151]},"properties":{"population":313549,"capital":4,"place":"city","name":"14Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.69676,25.54935]},"properties":{"population":426267,"capital":4,"place":"city","name":"15-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.14727,24.8876]},"properties":{"population":766340,"capital":4,"place":"city","name":"15-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.13212,24.90863]},"properties":{"population":606145,"capital":4,"place":"city","name":"15-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.91402,25.41118]},"properties":{"population":559098,"capital":4,"place":"city","name":"15-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.63267,24.86894]},"properties":{"population":636990,"capital":4,"place":"city","name":"15-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.28815,24.88387]},"properties":{"population":562762,"capital":4,"place":"city","name":"15-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.8907,25.35917]},"properties":{"population":557594,"capital":4,"place":"city","name":"15-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.80911,7.10663]},"properties":{"population":812207,"capital":4,"place":"city","name":"15A03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.34572,11.39402]},"properties":{"population":759337,"capital":4,"place":"city","name":"15A04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.22526,5.78216]},"properties":{"population":761431,"capital":4,"place":"city","name":"15A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.08059,-4.11697]},"properties":{"population":864751,"capital":4,"place":"city","name":"15A09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.81103,23.78101]},"properties":{"population":417438,"capital":4,"place":"city","name":"15A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.55021,-2.62456]},"properties":{"population":679794,"capital":4,"place":"city","name":"15A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.13355,-2.36384]},"properties":{"population":857300,"capital":4,"place":"city","name":"15A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.00869,13.42507]},"properties":{"population":609356,"capital":4,"place":"city","name":"15A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.31413,-2.40261]},"properties":{"population":923190,"capital":4,"place":"city","name":"15A18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.11135,21.23343]},"properties":{"population":411087,"capital":4,"place":"city","name":"15A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.66421,4.87662]},"properties":{"population":832385,"capital":4,"place":"city","name":"15A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.36437,11.73619]},"properties":{"population":689162,"capital":4,"place":"city","name":"15A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.20225,-5.05998]},"properties":{"population":811969,"capital":4,"place":"city","name":"15A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.23617,-3.41389]},"properties":{"population":861866,"capital":4,"place":"city","name":"15A24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.15088,20.05151]},"properties":{"population":636475,"capital":4,"place":"city","name":"15A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.02524,15.05209]},"properties":{"population":684161,"capital":4,"place":"city","name":"15A29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.6703,-9.90368]},"properties":{"population":763964,"capital":4,"place":"city","name":"15A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.79872,24.59797]},"properties":{"population":160943,"capital":4,"place":"city","name":"15A33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.93506,24.44049]},"properties":{"population":69314,"capital":4,"place":"city","name":"15A36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.35392,11.10793]},"properties":{"population":740367,"capital":4,"place":"city","name":"15A39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.76562,7.29591]},"properties":{"population":802649,"capital":4,"place":"city","name":"15A42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.63523,-4.00464]},"properties":{"population":786326,"capital":4,"place":"city","name":"15A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.7641,23.9385]},"properties":{"population":160943,"capital":4,"place":"city","name":"15A48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.95722,24.59656]},"properties":{"population":69314,"capital":4,"place":"city","name":"15A51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.49497,24.01529]},"properties":{"population":138629,"capital":4,"place":"city","name":"15A52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.83899,-9.81941]},"properties":{"population":709920,"capital":4,"place":"city","name":"15A54"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.10492,23.47193]},"properties":{"population":194591,"capital":4,"place":"city","name":"15A57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.40049,-2.3579]},"properties":{"population":829154,"capital":4,"place":"city","name":"15A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.96554,5.50842]},"properties":{"population":778779,"capital":4,"place":"city","name":"15A63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.42578,-0.36718]},"properties":{"population":781883,"capital":4,"place":"city","name":"15A66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.18173,22.48566]},"properties":{"population":389182,"capital":4,"place":"city","name":"15A67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.68246,-4.03112]},"properties":{"population":787397,"capital":4,"place":"city","name":"15A69"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.54125,-2.74652]},"properties":{"population":752131,"capital":4,"place":"city","name":"15A72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.52193,13.53108]},"properties":{"population":682546,"capital":4,"place":"city","name":"15A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.43918,20.85095]},"properties":{"population":517614,"capital":4,"place":"city","name":"15A78"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.34668,20.38092]},"properties":{"population":591889,"capital":4,"place":"city","name":"15A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.64049,19.77147]},"properties":{"population":583188,"capital":4,"place":"city","name":"15A83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.66384,20.78358]},"properties":{"population":609807,"capital":4,"place":"city","name":"15A86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.32516,-4.30861]},"properties":{"population":637331,"capital":4,"place":"city","name":"15A90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.48531,9.32214]},"properties":{"population":687419,"capital":4,"place":"city","name":"15A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.57296,19.95139]},"properties":{"population":487519,"capital":4,"place":"city","name":"15Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.99254,8.11161]},"properties":{"population":697634,"capital":4,"place":"city","name":"15B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.5084,19.16188]},"properties":{"population":550533,"capital":4,"place":"city","name":"15B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.24738,20.47364]},"properties":{"population":602827,"capital":4,"place":"city","name":"15B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.64315,19.93056]},"properties":{"population":294443,"capital":4,"place":"city","name":"15B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.69916,8.76804]},"properties":{"population":801400,"capital":4,"place":"city","name":"15B33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.27405,19.70778]},"properties":{"population":601615,"capital":4,"place":"city","name":"15B34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.54689,20.85705]},"properties":{"population":564190,"capital":4,"place":"city","name":"15B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.57221,14.14452]},"properties":{"population":754802,"capital":4,"place":"city","name":"15B36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.27972,-2.72445]},"properties":{"population":842945,"capital":4,"place":"city","name":"15B48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.62483,9.53975]},"properties":{"population":739141,"capital":4,"place":"city","name":"15B51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.51828,2.9152]},"properties":{"population":820549,"capital":4,"place":"city","name":"15B52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.94287,-3.97904]},"properties":{"population":849105,"capital":4,"place":"city","name":"15B57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.05629,15.29125]},"properties":{"population":608449,"capital":4,"place":"city","name":"15B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.36263,20.1802]},"properties":{"population":378418,"capital":4,"place":"city","name":"15Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.33754,12.42952]},"properties":{"population":343398,"capital":4,"place":"city","name":"16-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.64184,11.43917]},"properties":{"population":574620,"capital":4,"place":"city","name":"16-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.21612,10.61491]},"properties":{"population":650279,"capital":4,"place":"city","name":"16-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.15916,12.2688]},"properties":{"population":481218,"capital":4,"place":"city","name":"16-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.38095,12.19937]},"properties":{"population":407753,"capital":4,"place":"city","name":"16-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.46716,11.56936]},"properties":{"population":635957,"capital":4,"place":"city","name":"16-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.43143,12.26221]},"properties":{"population":588610,"capital":4,"place":"city","name":"16-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.61485,14.50617]},"properties":{"population":632972,"capital":4,"place":"city","name":"16B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.18622,14.29609]},"properties":{"population":544241,"capital":4,"place":"city","name":"16B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.02174,15.01961]},"properties":{"population":397029,"capital":4,"place":"city","name":"16B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.93721,15.1636]},"properties":{"population":138629,"capital":4,"place":"city","name":"16Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.83113,18.38226]},"properties":{"population":626909,"capital":4,"place":"city","name":"16D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.36541,23.57987]},"properties":{"population":138629,"capital":4,"place":"city","name":"16D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.29221,0.33356]},"properties":{"population":587211,"capital":4,"place":"city","name":"16D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.77905,-0.60478]},"properties":{"population":793666,"capital":4,"place":"city","name":"16D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.52621,16.8629]},"properties":{"population":533753,"capital":4,"place":"city","name":"16D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.99766,1.40354]},"properties":{"population":795787,"capital":4,"place":"city","name":"16D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.03606,-0.63939]},"properties":{"population":810228,"capital":4,"place":"city","name":"16D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.03318,17.63505]},"properties":{"population":738647,"capital":4,"place":"city","name":"16D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.95185,-0.58842]},"properties":{"population":828324,"capital":4,"place":"city","name":"16D70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.01515,3.66518]},"properties":{"population":749052,"capital":4,"place":"city","name":"16D80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.33584,3.93909]},"properties":{"population":812385,"capital":4,"place":"city","name":"16D90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.39378,18.4532]},"properties":{"population":468213,"capital":4,"place":"city","name":"16D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.89846,18.88258]},"properties":{"population":728687,"capital":4,"place":"city","name":"16Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.86083,20.58808]},"properties":{"population":673340,"capital":4,"place":"city","name":"16E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.58741,21.68781]},"properties":{"population":764396,"capital":4,"place":"city","name":"16E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.14479,17.39225]},"properties":{"population":701660,"capital":4,"place":"city","name":"16E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.45119,21.65432]},"properties":{"population":739633,"capital":4,"place":"city","name":"16E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.86862,18.25784]},"properties":{"population":608221,"capital":4,"place":"city","name":"16E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.86431,1.31958]},"properties":{"population":758629,"capital":4,"place":"city","name":"16E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.84446,19.58765]},"properties":{"population":626530,"capital":4,"place":"city","name":"16E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.87463,0.48018]},"properties":{"population":783122,"capital":4,"place":"city","name":"16E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.26689,17.71524]},"properties":{"population":596614,"capital":4,"place":"city","name":"16E60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.16363,19.89331]},"properties":{"population":654391,"capital":4,"place":"city","name":"16E65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.87092,22.06404]},"properties":{"population":109861,"capital":4,"place":"city","name":"16E70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.11266,18.9161]},"properties":{"population":411087,"capital":4,"place":"city","name":"16E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.61373,19.04474]},"properties":{"population":620859,"capital":4,"place":"city","name":"16Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.01153,-5.27256]},"properties":{"population":752671,"capital":4,"place":"city","name":"16G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.98039,-3.86233]},"properties":{"population":821608,"capital":4,"place":"city","name":"16G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.83904,-5.0288]},"properties":{"population":670930,"capital":4,"place":"city","name":"16G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.60437,-5.38693]},"properties":{"population":509986,"capital":4,"place":"city","name":"16G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.95775,-5.01545]},"properties":{"population":744014,"capital":4,"place":"city","name":"16G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.95883,-5.05367]},"properties":{"population":730182,"capital":4,"place":"city","name":"16G70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.84011,-5.21358]},"properties":{"population":549306,"capital":4,"place":"city","name":"16G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.33878,-4.34237]},"properties":{"population":745356,"capital":4,"place":"city","name":"16Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.22269,11.47275]},"properties":{"population":768570,"capital":4,"place":"city","name":"16H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.05169,11.97226]},"properties":{"population":468213,"capital":4,"place":"city","name":"16H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.8993,13.2909]},"properties":{"population":219722,"capital":4,"place":"city","name":"16H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.72012,13.10327]},"properties":{"population":325809,"capital":4,"place":"city","name":"16H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.10975,13.1153]},"properties":{"population":270805,"capital":4,"place":"city","name":"16H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.74363,13.23852]},"properties":{"population":138629,"capital":4,"place":"city","name":"16Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.14591,21.229]},"properties":{"population":699117,"capital":4,"place":"city","name":"16K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.72158,4.417]},"properties":{"population":610255,"capital":4,"place":"city","name":"16K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.51918,20.4709]},"properties":{"population":582894,"capital":4,"place":"city","name":"16K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.70513,21.47991]},"properties":{"population":397029,"capital":4,"place":"city","name":"16K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.00446,19.71267]},"properties":{"population":700488,"capital":4,"place":"city","name":"16Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.1653,14.31551]},"properties":{"population":707496,"capital":4,"place":"city","name":"16L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.24906,14.23782]},"properties":{"population":695654,"capital":4,"place":"city","name":"16L60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.05486,14.97685]},"properties":{"population":329583,"capital":4,"place":"city","name":"16L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.91333,14.93289]},"properties":{"population":109861,"capital":4,"place":"city","name":"16Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.41481,19.54136]},"properties":{"population":660258,"capital":4,"place":"city","name":"16N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.79451,18.62596]},"properties":{"population":744891,"capital":4,"place":"city","name":"16N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.20445,-1.18628]},"properties":{"population":816451,"capital":4,"place":"city","name":"16N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.15192,-0.49844]},"properties":{"population":685224,"capital":4,"place":"city","name":"16N80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.32191,20.38007]},"properties":{"population":380666,"capital":4,"place":"city","name":"16N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.72519,18.17316]},"properties":{"population":702019,"capital":4,"place":"city","name":"16Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.1541,3.86387]},"properties":{"population":795261,"capital":4,"place":"city","name":"16P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.46153,-0.54707]},"properties":{"population":710987,"capital":4,"place":"city","name":"16P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.10744,-0.71553]},"properties":{"population":744483,"capital":4,"place":"city","name":"16P40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.14383,-0.36165]},"properties":{"population":709506,"capital":4,"place":"city","name":"16P50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.07173,-0.66299]},"properties":{"population":750439,"capital":4,"place":"city","name":"16P60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.81061,18.83487]},"properties":{"population":643454,"capital":4,"place":"city","name":"16P70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.95435,-0.5469]},"properties":{"population":629710,"capital":4,"place":"city","name":"16P90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.92787,17.17389]},"properties":{"population":373766,"capital":4,"place":"city","name":"16P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.48337,19.68643]},"properties":{"population":239789,"capital":4,"place":"city","name":"16Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.97596,19.4186]},"properties":{"population":710496,"capital":4,"place":"city","name":"16R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.8372,17.12713]},"properties":{"population":576205,"capital":4,"place":"city","name":"16R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.20812,18.20922]},"properties":{"population":571373,"capital":4,"place":"city","name":"16R30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.99945,-1.66702]},"properties":{"population":548893,"capital":4,"place":"city","name":"16R40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.85991,18.41282]},"properties":{"population":695939,"capital":4,"place":"city","name":"16R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.12379,17.61181]},"properties":{"population":501063,"capital":4,"place":"city","name":"16R60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.75289,16.89053]},"properties":{"population":451085,"capital":4,"place":"city","name":"16R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.52464,18.3094]},"properties":{"population":702731,"capital":4,"place":"city","name":"16Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.04598,18.39737]},"properties":{"population":681563,"capital":4,"place":"city","name":"16S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.41306,21.15757]},"properties":{"population":652209,"capital":4,"place":"city","name":"16S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.49406,-0.51521]},"properties":{"population":662406,"capital":4,"place":"city","name":"16S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.70429,18.42217]},"properties":{"population":617170,"capital":4,"place":"city","name":"16S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.63264,3.19155]},"properties":{"population":661472,"capital":4,"place":"city","name":"16S32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.62979,0.42951]},"properties":{"population":843706,"capital":4,"place":"city","name":"16S34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.76721,-0.29726]},"properties":{"population":690073,"capital":4,"place":"city","name":"16S35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.9548,19.07682]},"properties":{"population":771601,"capital":4,"place":"city","name":"16S36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.23949,18.85988]},"properties":{"population":637502,"capital":4,"place":"city","name":"16S37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.44124,19.05892]},"properties":{"population":619031,"capital":4,"place":"city","name":"16S38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.17602,21.72405]},"properties":{"population":659714,"capital":4,"place":"city","name":"16S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.63729,15.56102]},"properties":{"population":836613,"capital":4,"place":"city","name":"16S50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.44762,18.15089]},"properties":{"population":559842,"capital":4,"place":"city","name":"16S60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.40213,17.19852]},"properties":{"population":504342,"capital":4,"place":"city","name":"16S70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.66418,17.77702]},"properties":{"population":637672,"capital":4,"place":"city","name":"16S80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.48011,16.77843]},"properties":{"population":488280,"capital":4,"place":"city","name":"16S85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.48916,17.36076]},"properties":{"population":473619,"capital":4,"place":"city","name":"16S88"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.8314,-1.00764]},"properties":{"population":731521,"capital":4,"place":"city","name":"16S90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.46041,20.57807]},"properties":{"population":603787,"capital":4,"place":"city","name":"16S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.01574,17.87012]},"properties":{"population":256494,"capital":4,"place":"city","name":"16Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.2825,6.09919]},"properties":{"population":784776,"capital":4,"place":"city","name":"16T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.26439,3.53797]},"properties":{"population":585220,"capital":4,"place":"city","name":"16T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.18608,3.07604]},"properties":{"population":653233,"capital":4,"place":"city","name":"16T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.43912,3.64773]},"properties":{"population":646146,"capital":4,"place":"city","name":"16T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.8479,7.18532]},"properties":{"population":669208,"capital":4,"place":"city","name":"16T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.81291,4.03348]},"properties":{"population":582894,"capital":4,"place":"city","name":"16T30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.43189,3.39985]},"properties":{"population":423410,"capital":4,"place":"city","name":"16T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.40441,3.00051]},"properties":{"population":313549,"capital":4,"place":"city","name":"16Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.06285,16.83167]},"properties":{"population":541164,"capital":4,"place":"city","name":"16U10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.05582,17.57177]},"properties":{"population":595324,"capital":4,"place":"city","name":"16U20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.14805,17.09889]},"properties":{"population":620050,"capital":4,"place":"city","name":"16U30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.81952,17.92855]},"properties":{"population":343398,"capital":4,"place":"city","name":"16U40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.50553,19.17906]},"properties":{"population":764300,"capital":4,"place":"city","name":"16U60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.25068,-1.34098]},"properties":{"population":737462,"capital":4,"place":"city","name":"16U70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.81555,-1.3812]},"properties":{"population":729573,"capital":4,"place":"city","name":"16U80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.1853,18.19414]},"properties":{"population":355534,"capital":4,"place":"city","name":"16U90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.71137,18.53297]},"properties":{"population":683518,"capital":4,"place":"city","name":"16U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.22291,18.372]},"properties":{"population":160943,"capital":4,"place":"city","name":"16Uxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.27471,15.07165]},"properties":{"population":778904,"capital":4,"place":"city","name":"16W10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.43951,-0.47304]},"properties":{"population":801268,"capital":4,"place":"city","name":"16W20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.66187,21.59089]},"properties":{"population":629526,"capital":4,"place":"city","name":"16W22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.5853,-1.55188]},"properties":{"population":777106,"capital":4,"place":"city","name":"16W25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.66465,14.23874]},"properties":{"population":834355,"capital":4,"place":"city","name":"16W30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.59462,19.12326]},"properties":{"population":604737,"capital":4,"place":"city","name":"16W35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.37436,15.46378]},"properties":{"population":788344,"capital":4,"place":"city","name":"16W50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.29429,19.8551]},"properties":{"population":591620,"capital":4,"place":"city","name":"16W55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.96762,-0.4086]},"properties":{"population":721376,"capital":4,"place":"city","name":"16W60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.693,20.27458]},"properties":{"population":564190,"capital":4,"place":"city","name":"16W70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.98752,20.48936]},"properties":{"population":682110,"capital":4,"place":"city","name":"16W80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.67444,20.80854]},"properties":{"population":570711,"capital":4,"place":"city","name":"16W99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.34757,17.38901]},"properties":{"population":230258,"capital":4,"place":"city","name":"16Wxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.6876,8.48038]},"properties":{"population":294443,"capital":4,"place":"city","name":"16Y20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.31708,7.06831]},"properties":{"population":764921,"capital":4,"place":"city","name":"16Y30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.24931,6.23948]},"properties":{"population":778155,"capital":4,"place":"city","name":"16Y60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.60349,8.45726]},"properties":{"population":418965,"capital":4,"place":"city","name":"16Y80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.24837,6.82508]},"properties":{"population":730854,"capital":4,"place":"city","name":"16Y99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.7027,8.36283]},"properties":{"population":109861,"capital":4,"place":"city","name":"16Yxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.90303,2.28263]},"properties":{"population":605678,"capital":4,"place":"city","name":"16Z05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.15811,20.24877]},"properties":{"population":277258,"capital":4,"place":"city","name":"16Z10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.43212,19.65387]},"properties":{"population":160943,"capital":4,"place":"city","name":"16Z99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.19444,20.78159]},"properties":{"population":109861,"capital":4,"place":"city","name":"16Zxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.73534,6.50974]},"properties":{"population":230258,"capital":4,"place":"city","name":"17-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.56255,6.21898]},"properties":{"population":515329,"capital":4,"place":"city","name":"17-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.18854,5.9529]},"properties":{"population":624222,"capital":4,"place":"city","name":"17-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.74348,6.75147]},"properties":{"population":427666,"capital":4,"place":"city","name":"17-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.00683,6.70671]},"properties":{"population":482831,"capital":4,"place":"city","name":"17-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.39573,6.11902]},"properties":{"population":622851,"capital":4,"place":"city","name":"17-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.06463,6.54154]},"properties":{"population":546806,"capital":4,"place":"city","name":"17-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.5715,6.63182]},"properties":{"population":469134,"capital":4,"place":"city","name":"17-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.04959,19.19251]},"properties":{"population":530330,"capital":4,"place":"city","name":"17A01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.08616,16.85917]},"properties":{"population":554126,"capital":4,"place":"city","name":"17A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.57308,16.99858]},"properties":{"population":517048,"capital":4,"place":"city","name":"17A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.39878,17.03808]},"properties":{"population":505624,"capital":4,"place":"city","name":"17A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.5481,22.22693]},"properties":{"population":720340,"capital":4,"place":"city","name":"17A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.14196,19.44526]},"properties":{"population":643615,"capital":4,"place":"city","name":"17A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.00127,20.44141]},"properties":{"population":628039,"capital":4,"place":"city","name":"17A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.84546,20.55696]},"properties":{"population":617794,"capital":4,"place":"city","name":"17A36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.82149,21.29898]},"properties":{"population":673221,"capital":4,"place":"city","name":"17A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.1007,18.60868]},"properties":{"population":588610,"capital":4,"place":"city","name":"17A42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.87407,18.63974]},"properties":{"population":500394,"capital":4,"place":"city","name":"17A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.84345,16.85427]},"properties":{"population":567332,"capital":4,"place":"city","name":"17A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.67095,20.62248]},"properties":{"population":612029,"capital":4,"place":"city","name":"17A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.66135,17.01259]},"properties":{"population":277258,"capital":4,"place":"city","name":"17A61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.14336,-4.74047]},"properties":{"population":550938,"capital":4,"place":"city","name":"17A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.40333,14.24022]},"properties":{"population":724208,"capital":4,"place":"city","name":"17A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.46592,18.9215]},"properties":{"population":568017,"capital":4,"place":"city","name":"17A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.01118,17.22271]},"properties":{"population":424849,"capital":4,"place":"city","name":"17A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.99649,19.80982]},"properties":{"population":602827,"capital":4,"place":"city","name":"17A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.15981,17.37479]},"properties":{"population":304452,"capital":4,"place":"city","name":"17Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.03817,17.55419]},"properties":{"population":692461,"capital":4,"place":"city","name":"17B01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.4209,14.40503]},"properties":{"population":752510,"capital":4,"place":"city","name":"17B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.67924,20.83118]},"properties":{"population":583481,"capital":4,"place":"city","name":"17B08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.09339,10.88007]},"properties":{"population":846294,"capital":4,"place":"city","name":"17B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.62202,15.24423]},"properties":{"population":578382,"capital":4,"place":"city","name":"17B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.90094,0.04078]},"properties":{"population":805197,"capital":4,"place":"city","name":"17B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.08506,17.02716]},"properties":{"population":629710,"capital":4,"place":"city","name":"17B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.63668,21.78818]},"properties":{"population":609356,"capital":4,"place":"city","name":"17B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.02888,-4.60858]},"properties":{"population":780302,"capital":4,"place":"city","name":"17B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.62247,-4.25271]},"properties":{"population":760936,"capital":4,"place":"city","name":"17B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.27725,-2.94045]},"properties":{"population":903288,"capital":4,"place":"city","name":"17B37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.00269,16.90308]},"properties":{"population":406044,"capital":4,"place":"city","name":"17B38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.66553,-4.13414]},"properties":{"population":743720,"capital":4,"place":"city","name":"17B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.79004,12.68002]},"properties":{"population":682546,"capital":4,"place":"city","name":"17B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.45064,21.01688]},"properties":{"population":726403,"capital":4,"place":"city","name":"17B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.76318,18.22578]},"properties":{"population":683410,"capital":4,"place":"city","name":"17B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.9632,-3.47879]},"properties":{"population":754960,"capital":4,"place":"city","name":"17B56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.79059,15.24627]},"properties":{"population":701660,"capital":4,"place":"city","name":"17B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.13346,16.97194]},"properties":{"population":400733,"capital":4,"place":"city","name":"17B61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.70669,21.44251]},"properties":{"population":608221,"capital":4,"place":"city","name":"17B62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.89952,16.36244]},"properties":{"population":672503,"capital":4,"place":"city","name":"17B63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.14965,12.05694]},"properties":{"population":784971,"capital":4,"place":"city","name":"17B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.70299,13.55686]},"properties":{"population":701660,"capital":4,"place":"city","name":"17B66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.94114,-4.30689]},"properties":{"population":809223,"capital":4,"place":"city","name":"17B67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.50544,13.78044]},"properties":{"population":759085,"capital":4,"place":"city","name":"17B68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.7547,-4.04313]},"properties":{"population":754908,"capital":4,"place":"city","name":"17B69"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.88609,12.5539]},"properties":{"population":760638,"capital":4,"place":"city","name":"17B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.72786,18.80845]},"properties":{"population":497673,"capital":4,"place":"city","name":"17B75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.23323,14.1797]},"properties":{"population":659714,"capital":4,"place":"city","name":"17B80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.45042,8.39617]},"properties":{"population":761480,"capital":4,"place":"city","name":"17B81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.56129,12.27275]},"properties":{"population":702375,"capital":4,"place":"city","name":"17B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.02059,21.26811]},"properties":{"population":536597,"capital":4,"place":"city","name":"17Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.99723,-3.1219]},"properties":{"population":535185,"capital":4,"place":"city","name":"17C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.55098,-2.81374]},"properties":{"population":642162,"capital":4,"place":"city","name":"17C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.75867,-3.30995]},"properties":{"population":368887,"capital":4,"place":"city","name":"17C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.07525,-2.25715]},"properties":{"population":565599,"capital":4,"place":"city","name":"17C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.93437,-3.17167]},"properties":{"population":448863,"capital":4,"place":"city","name":"17C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.83262,-2.24427]},"properties":{"population":494875,"capital":4,"place":"city","name":"17C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.17012,-3.10677]},"properties":{"population":393182,"capital":4,"place":"city","name":"17C36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.73166,-3.12604]},"properties":{"population":393182,"capital":4,"place":"city","name":"17C37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.69821,-3.22417]},"properties":{"population":508140,"capital":4,"place":"city","name":"17C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.06736,-1.07472]},"properties":{"population":629894,"capital":4,"place":"city","name":"17C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.85632,-3.01611]},"properties":{"population":480402,"capital":4,"place":"city","name":"17C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.93185,-2.36389]},"properties":{"population":397029,"capital":4,"place":"city","name":"17C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.56736,-1.54713]},"properties":{"population":651914,"capital":4,"place":"city","name":"17C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.37041,-2.91079]},"properties":{"population":519849,"capital":4,"place":"city","name":"17C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.12877,-3.12264]},"properties":{"population":376120,"capital":4,"place":"city","name":"17C90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.09471,-2.30616]},"properties":{"population":534233,"capital":4,"place":"city","name":"17C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.70683,-3.31186]},"properties":{"population":355534,"capital":4,"place":"city","name":"17Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.93036,6.56549]},"properties":{"population":681124,"capital":4,"place":"city","name":"17D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.44011,6.30015]},"properties":{"population":568697,"capital":4,"place":"city","name":"17D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.35207,6.6774]},"properties":{"population":501727,"capital":4,"place":"city","name":"17D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.55154,6.73276]},"properties":{"population":460517,"capital":4,"place":"city","name":"17D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.91063,6.31137]},"properties":{"population":568017,"capital":4,"place":"city","name":"17D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.42432,6.57328]},"properties":{"population":313549,"capital":4,"place":"city","name":"17D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.91384,5.18787]},"properties":{"population":611146,"capital":4,"place":"city","name":"17D92"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.08477,6.278]},"properties":{"population":637331,"capital":4,"place":"city","name":"17D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.10585,6.71634]},"properties":{"population":239789,"capital":4,"place":"city","name":"17Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.74995,12.73474]},"properties":{"population":294443,"capital":4,"place":"city","name":"18-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.11372,12.18564]},"properties":{"population":549716,"capital":4,"place":"city","name":"18-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.64519,10.83911]},"properties":{"population":596357,"capital":4,"place":"city","name":"18-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.68741,12.86557]},"properties":{"population":445434,"capital":4,"place":"city","name":"18-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.97875,12.80546]},"properties":{"population":361091,"capital":4,"place":"city","name":"18-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.11293,12.39545]},"properties":{"population":566642,"capital":4,"place":"city","name":"18-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.86211,12.65186]},"properties":{"population":449980,"capital":4,"place":"city","name":"18-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.49601,-1.06335]},"properties":{"population":586363,"capital":4,"place":"city","name":"18A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.551,-3.17721]},"properties":{"population":566988,"capital":4,"place":"city","name":"18A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.59585,-2.87125]},"properties":{"population":633150,"capital":4,"place":"city","name":"18A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.77134,-3.02825]},"properties":{"population":666949,"capital":4,"place":"city","name":"18A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.52742,-1.50187]},"properties":{"population":587773,"capital":4,"place":"city","name":"18A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.28921,-3.0]},"properties":{"population":440671,"capital":4,"place":"city","name":"18A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.8385,-2.98915]},"properties":{"population":632793,"capital":4,"place":"city","name":"18A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.07248,-2.66761]},"properties":{"population":701031,"capital":4,"place":"city","name":"18A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.97254,-2.7061]},"properties":{"population":624610,"capital":4,"place":"city","name":"18A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.42031,-1.30846]},"properties":{"population":634035,"capital":4,"place":"city","name":"18A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.28628,-2.59052]},"properties":{"population":749498,"capital":4,"place":"city","name":"18A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.94943,-2.96933]},"properties":{"population":109861,"capital":4,"place":"city","name":"18A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.10931,-2.88503]},"properties":{"population":597888,"capital":4,"place":"city","name":"18A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.11456,-3.04182]},"properties":{"population":382864,"capital":4,"place":"city","name":"18Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.9547,19.86886]},"properties":{"population":578074,"capital":4,"place":"city","name":"18B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.40271,20.90834]},"properties":{"population":586929,"capital":4,"place":"city","name":"18B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.98815,18.54303]},"properties":{"population":565948,"capital":4,"place":"city","name":"18B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.30989,19.32209]},"properties":{"population":651767,"capital":4,"place":"city","name":"18B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.21257,20.3053]},"properties":{"population":716317,"capital":4,"place":"city","name":"18B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.13921,4.07852]},"properties":{"population":727655,"capital":4,"place":"city","name":"18B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.84214,21.90601]},"properties":{"population":611146,"capital":4,"place":"city","name":"18B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.20659,18.01865]},"properties":{"population":647850,"capital":4,"place":"city","name":"18B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.81578,16.70317]},"properties":{"population":69314,"capital":4,"place":"city","name":"18B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.65649,15.31554]},"properties":{"population":697634,"capital":4,"place":"city","name":"18B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.67014,16.63442]},"properties":{"population":219722,"capital":4,"place":"city","name":"18Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.41319,18.24466]},"properties":{"population":557215,"capital":4,"place":"city","name":"18C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.66186,1.97745]},"properties":{"population":683625,"capital":4,"place":"city","name":"18C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.65707,20.99997]},"properties":{"population":691174,"capital":4,"place":"city","name":"18C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.08143,22.34505]},"properties":{"population":629894,"capital":4,"place":"city","name":"18C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.60539,17.05892]},"properties":{"population":363758,"capital":4,"place":"city","name":"18C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.52081,16.99903]},"properties":{"population":525227,"capital":4,"place":"city","name":"18C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.84376,16.62987]},"properties":{"population":340119,"capital":4,"place":"city","name":"18C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.77807,21.25065]},"properties":{"population":597888,"capital":4,"place":"city","name":"18C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.62559,18.44228]},"properties":{"population":523644,"capital":4,"place":"city","name":"18C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.94789,16.39452]},"properties":{"population":207944,"capital":4,"place":"city","name":"18Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.02143,17.99077]},"properties":{"population":717778,"capital":4,"place":"city","name":"18D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.89684,13.75874]},"properties":{"population":780710,"capital":4,"place":"city","name":"18D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.51749,4.29701]},"properties":{"population":701031,"capital":4,"place":"city","name":"18D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.68534,17.40653]},"properties":{"population":662671,"capital":4,"place":"city","name":"18D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.65625,16.85685]},"properties":{"population":395124,"capital":4,"place":"city","name":"18D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.78641,20.30295]},"properties":{"population":578689,"capital":4,"place":"city","name":"18D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.81173,19.27706]},"properties":{"population":635784,"capital":4,"place":"city","name":"18D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.31159,16.51701]},"properties":{"population":179175,"capital":4,"place":"city","name":"18D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.09548,18.02662]},"properties":{"population":672623,"capital":4,"place":"city","name":"18D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.382,16.16107]},"properties":{"population":160943,"capital":4,"place":"city","name":"18D65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.31908,16.20776]},"properties":{"population":109861,"capital":4,"place":"city","name":"18D70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.51822,18.99028]},"properties":{"population":584354,"capital":4,"place":"city","name":"18D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.90011,16.55675]},"properties":{"population":343398,"capital":4,"place":"city","name":"18Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.84154,9.25847]},"properties":{"population":599396,"capital":4,"place":"city","name":"18E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.37046,9.78778]},"properties":{"population":194591,"capital":4,"place":"city","name":"18E08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.83244,8.97791]},"properties":{"population":694215,"capital":4,"place":"city","name":"18E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.38776,9.77779]},"properties":{"population":248490,"capital":4,"place":"city","name":"18E13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.93958,9.5044]},"properties":{"population":597888,"capital":4,"place":"city","name":"18E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.63191,9.58444]},"properties":{"population":427666,"capital":4,"place":"city","name":"18E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.7303,9.16702]},"properties":{"population":509375,"capital":4,"place":"city","name":"18E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.2459,7.46885]},"properties":{"population":775061,"capital":4,"place":"city","name":"18E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.96222,9.30831]},"properties":{"population":603787,"capital":4,"place":"city","name":"18E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.7356,9.34406]},"properties":{"population":642810,"capital":4,"place":"city","name":"18E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.26091,9.82987]},"properties":{"population":109861,"capital":4,"place":"city","name":"18E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.23182,9.63197]},"properties":{"population":109861,"capital":4,"place":"city","name":"18E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.59655,9.48419]},"properties":{"population":489783,"capital":4,"place":"city","name":"18E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.36888,9.84576]},"properties":{"population":325809,"capital":4,"place":"city","name":"18Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.15126,4.95925]},"properties":{"population":366356,"capital":4,"place":"city","name":"18F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.2068,5.04333]},"properties":{"population":559471,"capital":4,"place":"city","name":"18F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.46057,6.42184]},"properties":{"population":564190,"capital":4,"place":"city","name":"18F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.99292,7.56755]},"properties":{"population":707072,"capital":4,"place":"city","name":"18F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.59157,6.26108]},"properties":{"population":750549,"capital":4,"place":"city","name":"18F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.26917,5.26678]},"properties":{"population":559842,"capital":4,"place":"city","name":"18F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.01177,4.54534]},"properties":{"population":109861,"capital":4,"place":"city","name":"18F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.92858,4.60585]},"properties":{"population":179175,"capital":4,"place":"city","name":"18F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.04469,5.11197]},"properties":{"population":382864,"capital":4,"place":"city","name":"18F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.06003,4.53083]},"properties":{"population":277258,"capital":4,"place":"city","name":"18F70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.38454,4.51058]},"properties":{"population":179175,"capital":4,"place":"city","name":"18F75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.14473,5.81746]},"properties":{"population":556452,"capital":4,"place":"city","name":"18F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.08058,4.58677]},"properties":{"population":207944,"capital":4,"place":"city","name":"18Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.7469,22.13523]},"properties":{"population":631354,"capital":4,"place":"city","name":"18G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.94967,0.62496]},"properties":{"population":669703,"capital":4,"place":"city","name":"18G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.98282,20.25914]},"properties":{"population":657507,"capital":4,"place":"city","name":"18G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.34304,21.12589]},"properties":{"population":613122,"capital":4,"place":"city","name":"18G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.20836,18.26649]},"properties":{"population":624610,"capital":4,"place":"city","name":"18G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.84526,20.09605]},"properties":{"population":661740,"capital":4,"place":"city","name":"18G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.07422,16.53662]},"properties":{"population":138629,"capital":4,"place":"city","name":"18G31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.08081,18.87407]},"properties":{"population":648616,"capital":4,"place":"city","name":"18G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.29844,18.11534]},"properties":{"population":614846,"capital":4,"place":"city","name":"18G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.95016,16.80021]},"properties":{"population":263905,"capital":4,"place":"city","name":"18G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.43073,-2.22371]},"properties":{"population":631535,"capital":4,"place":"city","name":"18G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.77701,20.918]},"properties":{"population":698100,"capital":4,"place":"city","name":"18G55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.29235,22.3553]},"properties":{"population":639859,"capital":4,"place":"city","name":"18G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.02392,16.41368]},"properties":{"population":138629,"capital":4,"place":"city","name":"18G65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.30209,16.76515]},"properties":{"population":277258,"capital":4,"place":"city","name":"18G70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.46976,18.55911]},"properties":{"population":568017,"capital":4,"place":"city","name":"18G80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.44158,16.44035]},"properties":{"population":109861,"capital":4,"place":"city","name":"18G85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.2057,16.71324]},"properties":{"population":309104,"capital":4,"place":"city","name":"18G90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.08458,19.35167]},"properties":{"population":591350,"capital":4,"place":"city","name":"18G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.88865,17.90642]},"properties":{"population":451085,"capital":4,"place":"city","name":"18Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.61081,13.43672]},"properties":{"population":515329,"capital":4,"place":"city","name":"18M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.62797,14.03142]},"properties":{"population":69314,"capital":4,"place":"city","name":"18M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.19924,13.80692]},"properties":{"population":414313,"capital":4,"place":"city","name":"18M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.42875,13.76614]},"properties":{"population":412713,"capital":4,"place":"city","name":"18M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.79326,13.87821]},"properties":{"population":179175,"capital":4,"place":"city","name":"18M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.84858,13.8023]},"properties":{"population":230258,"capital":4,"place":"city","name":"18M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.22895,13.74757]},"properties":{"population":160943,"capital":4,"place":"city","name":"18M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.79826,13.54325]},"properties":{"population":194591,"capital":4,"place":"city","name":"18M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.69279,14.17449]},"properties":{"population":69314,"capital":4,"place":"city","name":"18M45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.75182,14.20067]},"properties":{"population":219722,"capital":4,"place":"city","name":"18M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.10072,13.4885]},"properties":{"population":433073,"capital":4,"place":"city","name":"18M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.55763,13.96068]},"properties":{"population":138629,"capital":4,"place":"city","name":"18M65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.50815,14.03527]},"properties":{"population":294443,"capital":4,"place":"city","name":"18M70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.45031,13.94919]},"properties":{"population":160943,"capital":4,"place":"city","name":"18M75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.95168,13.77861]},"properties":{"population":179175,"capital":4,"place":"city","name":"18M80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.90069,13.73441]},"properties":{"population":219722,"capital":4,"place":"city","name":"18M85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.86687,13.63768]},"properties":{"population":230258,"capital":4,"place":"city","name":"18M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.97296,13.89489]},"properties":{"population":194591,"capital":4,"place":"city","name":"18Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.89487,15.84105]},"properties":{"population":470048,"capital":4,"place":"city","name":"18N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.64024,16.99662]},"properties":{"population":179175,"capital":4,"place":"city","name":"18N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.33228,16.5635]},"properties":{"population":219722,"capital":4,"place":"city","name":"18N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.34219,16.50239]},"properties":{"population":219722,"capital":4,"place":"city","name":"18N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.38617,16.86945]},"properties":{"population":179175,"capital":4,"place":"city","name":"18N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.68224,16.37138]},"properties":{"population":468213,"capital":4,"place":"city","name":"18N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.52898,16.74977]},"properties":{"population":248490,"capital":4,"place":"city","name":"18N45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.30715,16.7985]},"properties":{"population":409434,"capital":4,"place":"city","name":"18N50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.36806,16.70094]},"properties":{"population":179175,"capital":4,"place":"city","name":"18N55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.57452,16.628]},"properties":{"population":371357,"capital":4,"place":"city","name":"18N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.51482,16.74151]},"properties":{"population":219722,"capital":4,"place":"city","name":"18N65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.82339,16.70996]},"properties":{"population":219722,"capital":4,"place":"city","name":"18N70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.33618,17.20072]},"properties":{"population":138629,"capital":4,"place":"city","name":"18N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.47506,16.66508]},"properties":{"population":160943,"capital":4,"place":"city","name":"18Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.18871,18.11862]},"properties":{"population":230258,"capital":4,"place":"city","name":"19-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.40912,18.12695]},"properties":{"population":340119,"capital":4,"place":"city","name":"19-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.10109,17.56751]},"properties":{"population":477068,"capital":4,"place":"city","name":"19-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.20017,18.33162]},"properties":{"population":333220,"capital":4,"place":"city","name":"19-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.31583,18.08548]},"properties":{"population":446590,"capital":4,"place":"city","name":"19-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.34793,18.44767]},"properties":{"population":289037,"capital":4,"place":"city","name":"19-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.47016,18.86285]},"properties":{"population":468213,"capital":4,"place":"city","name":"19A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.21487,20.11637]},"properties":{"population":304452,"capital":4,"place":"city","name":"19A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.57466,1.84828]},"properties":{"population":631173,"capital":4,"place":"city","name":"19A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.44993,18.09861]},"properties":{"population":512989,"capital":4,"place":"city","name":"19A31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.75142,18.42429]},"properties":{"population":499043,"capital":4,"place":"city","name":"19A49"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.30594,18.33198]},"properties":{"population":424849,"capital":4,"place":"city","name":"19A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.38813,20.14418]},"properties":{"population":277258,"capital":4,"place":"city","name":"19Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.13773,11.92143]},"properties":{"population":539362,"capital":4,"place":"city","name":"19B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.38016,12.28445]},"properties":{"population":475359,"capital":4,"place":"city","name":"19B14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.21261,12.14641]},"properties":{"population":506890,"capital":4,"place":"city","name":"19B28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.59028,12.43609]},"properties":{"population":349650,"capital":4,"place":"city","name":"19B37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.87171,12.97424]},"properties":{"population":467282,"capital":4,"place":"city","name":"19B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.64277,12.45398]},"properties":{"population":256494,"capital":4,"place":"city","name":"19Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.58494,12.30143]},"properties":{"population":500394,"capital":4,"place":"city","name":"19C09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.76294,11.42534]},"properties":{"population":477912,"capital":4,"place":"city","name":"19C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.50278,12.42771]},"properties":{"population":395124,"capital":4,"place":"city","name":"19C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.74368,12.26172]},"properties":{"population":239789,"capital":4,"place":"city","name":"19C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.09617,12.66249]},"properties":{"population":447733,"capital":4,"place":"city","name":"19C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.70166,12.61985]},"properties":{"population":277258,"capital":4,"place":"city","name":"19Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.99952,18.77974]},"properties":{"population":446590,"capital":4,"place":"city","name":"19D06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.53948,4.49092]},"properties":{"population":536129,"capital":4,"place":"city","name":"19D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.84747,18.42865]},"properties":{"population":500394,"capital":4,"place":"city","name":"19D23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.06606,19.31565]},"properties":{"population":358351,"capital":4,"place":"city","name":"19D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.32279,17.85386]},"properties":{"population":487519,"capital":4,"place":"city","name":"19D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.29001,20.18225]},"properties":{"population":565948,"capital":4,"place":"city","name":"19D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.91563,17.66186]},"properties":{"population":551745,"capital":4,"place":"city","name":"19D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.53252,19.78694]},"properties":{"population":675227,"capital":4,"place":"city","name":"19D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.74032,17.99799]},"properties":{"population":486753,"capital":4,"place":"city","name":"19D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.52815,19.62302]},"properties":{"population":346573,"capital":4,"place":"city","name":"19Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.16683,14.73023]},"properties":{"population":578074,"capital":4,"place":"city","name":"19E08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.73331,14.82409]},"properties":{"population":618620,"capital":4,"place":"city","name":"19E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.02467,14.82197]},"properties":{"population":528320,"capital":4,"place":"city","name":"19E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.10641,14.66775]},"properties":{"population":391202,"capital":4,"place":"city","name":"19E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.27507,15.44505]},"properties":{"population":263905,"capital":4,"place":"city","name":"19Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.82295,20.23423]},"properties":{"population":458496,"capital":4,"place":"city","name":"19F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.82663,19.77371]},"properties":{"population":522574,"capital":4,"place":"city","name":"19F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.25673,18.29584]},"properties":{"population":597635,"capital":4,"place":"city","name":"19F27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.85342,20.16721]},"properties":{"population":427666,"capital":4,"place":"city","name":"19F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.58293,20.34302]},"properties":{"population":283321,"capital":4,"place":"city","name":"19Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.80664,20.10506]},"properties":{"population":248490,"capital":4,"place":"city","name":"19G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.9982,19.96375]},"properties":{"population":510594,"capital":4,"place":"city","name":"19G12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.71948,19.96362]},"properties":{"population":451085,"capital":4,"place":"city","name":"19G24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.03227,19.92839]},"properties":{"population":471849,"capital":4,"place":"city","name":"19G38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.68879,19.9843]},"properties":{"population":363758,"capital":4,"place":"city","name":"19G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.84293,20.04733]},"properties":{"population":138629,"capital":4,"place":"city","name":"19Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.25906,20.03793]},"properties":{"population":289037,"capital":4,"place":"city","name":"19J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.38291,19.09899]},"properties":{"population":382864,"capital":4,"place":"city","name":"19J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.28179,18.82371]},"properties":{"population":457471,"capital":4,"place":"city","name":"19J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.23677,19.75198]},"properties":{"population":294443,"capital":4,"place":"city","name":"19J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.35553,19.68311]},"properties":{"population":270805,"capital":4,"place":"city","name":"19J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.03985,20.00659]},"properties":{"population":160943,"capital":4,"place":"city","name":"19Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.26957,-0.90301]},"properties":{"population":518178,"capital":4,"place":"city","name":"19K14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.55194,-0.02531]},"properties":{"population":501727,"capital":4,"place":"city","name":"19K33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.2145,-0.80583]},"properties":{"population":622851,"capital":4,"place":"city","name":"19K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.6388,-3.89094]},"properties":{"population":646614,"capital":4,"place":"city","name":"19K56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.58746,0.10844]},"properties":{"population":503043,"capital":4,"place":"city","name":"19K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.5098,0.81871]},"properties":{"population":256494,"capital":4,"place":"city","name":"19Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.96264,8.27722]},"properties":{"population":513579,"capital":4,"place":"city","name":"19L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.03147,8.76629]},"properties":{"population":404305,"capital":4,"place":"city","name":"19L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.97822,8.84724]},"properties":{"population":451085,"capital":4,"place":"city","name":"19L41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.77958,8.48029]},"properties":{"population":594542,"capital":4,"place":"city","name":"19L47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.24809,8.70814]},"properties":{"population":485203,"capital":4,"place":"city","name":"19L50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.31329,8.55276]},"properties":{"population":481218,"capital":4,"place":"city","name":"19L64"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.99323,8.88294]},"properties":{"population":441884,"capital":4,"place":"city","name":"19L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.16158,8.99447]},"properties":{"population":317805,"capital":4,"place":"city","name":"19Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.88199,18.64572]},"properties":{"population":460517,"capital":4,"place":"city","name":"19M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.09039,19.46597]},"properties":{"population":69314,"capital":4,"place":"city","name":"19M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.61122,-4.03974]},"properties":{"population":333220,"capital":4,"place":"city","name":"20-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.15439,-2.83854]},"properties":{"population":689972,"capital":4,"place":"city","name":"20-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.67216,-2.79765]},"properties":{"population":706133,"capital":4,"place":"city","name":"20-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.70032,-4.12706]},"properties":{"population":596870,"capital":4,"place":"city","name":"20-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.45549,-3.1559]},"properties":{"population":681892,"capital":4,"place":"city","name":"20-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.83231,-2.82824]},"properties":{"population":695654,"capital":4,"place":"city","name":"20-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.6451,-3.92689]},"properties":{"population":382864,"capital":4,"place":"city","name":"20-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.54965,-3.93232]},"properties":{"population":690073,"capital":4,"place":"city","name":"20-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.90832,13.11949]},"properties":{"population":589989,"capital":4,"place":"city","name":"20A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.22161,12.81874]},"properties":{"population":451085,"capital":4,"place":"city","name":"20A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.10035,12.54187]},"properties":{"population":668210,"capital":4,"place":"city","name":"20A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.03343,12.95379]},"properties":{"population":387120,"capital":4,"place":"city","name":"20A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.56983,13.06392]},"properties":{"population":248490,"capital":4,"place":"city","name":"20Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.64822,13.09847]},"properties":{"population":672383,"capital":4,"place":"city","name":"20B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.66147,13.03466]},"properties":{"population":575890,"capital":4,"place":"city","name":"20B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.29961,12.94283]},"properties":{"population":600388,"capital":4,"place":"city","name":"20B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.56183,13.3285]},"properties":{"population":649072,"capital":4,"place":"city","name":"20B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.48068,13.31498]},"properties":{"population":663463,"capital":4,"place":"city","name":"20B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.55375,13.22441]},"properties":{"population":556452,"capital":4,"place":"city","name":"20B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.21681,13.32561]},"properties":{"population":803106,"capital":4,"place":"city","name":"20B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.46133,13.07401]},"properties":{"population":653958,"capital":4,"place":"city","name":"20B27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.9568,13.38794]},"properties":{"population":708002,"capital":4,"place":"city","name":"20B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.30014,13.17381]},"properties":{"population":682546,"capital":4,"place":"city","name":"20B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.31733,13.04369]},"properties":{"population":579605,"capital":4,"place":"city","name":"20B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.81838,13.42654]},"properties":{"population":588332,"capital":4,"place":"city","name":"20B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.49344,13.13582]},"properties":{"population":397029,"capital":4,"place":"city","name":"20Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.07685,-1.88864]},"properties":{"population":834093,"capital":4,"place":"city","name":"20C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.57953,-3.29364]},"properties":{"population":751752,"capital":4,"place":"city","name":"20C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.82702,15.09861]},"properties":{"population":759488,"capital":4,"place":"city","name":"20C08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.71721,16.59478]},"properties":{"population":637842,"capital":4,"place":"city","name":"20C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.26931,17.95709]},"properties":{"population":542495,"capital":4,"place":"city","name":"20C11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.65939,18.22342]},"properties":{"population":393182,"capital":4,"place":"city","name":"20C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.16622,0.39988]},"properties":{"population":848011,"capital":4,"place":"city","name":"20C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.10119,15.10707]},"properties":{"population":826359,"capital":4,"place":"city","name":"20C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.81054,15.0209]},"properties":{"population":659987,"capital":4,"place":"city","name":"20C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.81261,-3.18169]},"properties":{"population":807899,"capital":4,"place":"city","name":"20C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.69558,15.93908]},"properties":{"population":530330,"capital":4,"place":"city","name":"20C32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.15183,15.9756]},"properties":{"population":716394,"capital":4,"place":"city","name":"20C33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.34913,17.93192]},"properties":{"population":588332,"capital":4,"place":"city","name":"20C34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.74577,10.1298]},"properties":{"population":667203,"capital":4,"place":"city","name":"20C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.80264,16.18139]},"properties":{"population":623048,"capital":4,"place":"city","name":"20C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.46028,14.85011]},"properties":{"population":607764,"capital":4,"place":"city","name":"20C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.96021,18.12937]},"properties":{"population":487519,"capital":4,"place":"city","name":"20Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.64748,-2.03393]},"properties":{"population":792226,"capital":4,"place":"city","name":"20D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.45558,-0.7312]},"properties":{"population":758171,"capital":4,"place":"city","name":"20D06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.34425,17.03647]},"properties":{"population":731121,"capital":4,"place":"city","name":"20D08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.0095,17.07491]},"properties":{"population":861450,"capital":4,"place":"city","name":"20D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.77879,14.89292]},"properties":{"population":841294,"capital":4,"place":"city","name":"20D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.75165,16.56379]},"properties":{"population":839366,"capital":4,"place":"city","name":"20D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.32965,18.57871]},"properties":{"population":773368,"capital":4,"place":"city","name":"20D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.1943,-1.795]},"properties":{"population":766902,"capital":4,"place":"city","name":"20D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.51528,19.97618]},"properties":{"population":685856,"capital":4,"place":"city","name":"20D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.21412,19.14333]},"properties":{"population":781681,"capital":4,"place":"city","name":"20D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.66646,16.86517]},"properties":{"population":743248,"capital":4,"place":"city","name":"20D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.79367,1.1451]},"properties":{"population":858634,"capital":4,"place":"city","name":"20D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.44147,16.74149]},"properties":{"population":647234,"capital":4,"place":"city","name":"20D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.9944,18.778]},"properties":{"population":540267,"capital":4,"place":"city","name":"20Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.37829,13.95303]},"properties":{"population":774673,"capital":4,"place":"city","name":"20E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.43779,14.86321]},"properties":{"population":784188,"capital":4,"place":"city","name":"20E06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.72607,1.111]},"properties":{"population":864505,"capital":4,"place":"city","name":"20E07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.28893,15.09564]},"properties":{"population":710002,"capital":4,"place":"city","name":"20E08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.5994,16.74657]},"properties":{"population":729437,"capital":4,"place":"city","name":"20E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.21323,15.40824]},"properties":{"population":796102,"capital":4,"place":"city","name":"20E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.48312,15.87329]},"properties":{"population":722911,"capital":4,"place":"city","name":"20E18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.88483,14.61171]},"properties":{"population":779934,"capital":4,"place":"city","name":"20E22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.31944,0.22986]},"properties":{"population":733040,"capital":4,"place":"city","name":"20E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.74851,-0.59846]},"properties":{"population":713966,"capital":4,"place":"city","name":"20E26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.91989,17.05704]},"properties":{"population":721817,"capital":4,"place":"city","name":"20E28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.54011,1.03757]},"properties":{"population":646614,"capital":4,"place":"city","name":"20E32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.44067,16.65483]},"properties":{"population":714598,"capital":4,"place":"city","name":"20E34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.01802,15.5261]},"properties":{"population":768156,"capital":4,"place":"city","name":"20E36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.38654,15.78011]},"properties":{"population":662273,"capital":4,"place":"city","name":"20E42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.17123,15.6339]},"properties":{"population":723777,"capital":4,"place":"city","name":"20E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.28475,17.15472]},"properties":{"population":578689,"capital":4,"place":"city","name":"20E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.86803,18.43788]},"properties":{"population":456434,"capital":4,"place":"city","name":"20Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.65166,-3.04041]},"properties":{"population":902425,"capital":4,"place":"city","name":"20F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.46671,16.84274]},"properties":{"population":633859,"capital":4,"place":"city","name":"20F06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.78709,14.69589]},"properties":{"population":784227,"capital":4,"place":"city","name":"20F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.15877,18.32961]},"properties":{"population":385014,"capital":4,"place":"city","name":"20F11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.71505,15.87592]},"properties":{"population":695749,"capital":4,"place":"city","name":"20F12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.91476,16.24664]},"properties":{"population":722548,"capital":4,"place":"city","name":"20F14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.40517,15.54511]},"properties":{"population":745298,"capital":4,"place":"city","name":"20F16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.54618,18.20218]},"properties":{"population":549306,"capital":4,"place":"city","name":"20F17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.77838,14.74779]},"properties":{"population":735500,"capital":4,"place":"city","name":"20F18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.84297,15.9866]},"properties":{"population":722693,"capital":4,"place":"city","name":"20F19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.1307,17.66386]},"properties":{"population":608904,"capital":4,"place":"city","name":"20F22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.08241,17.32999]},"properties":{"population":628599,"capital":4,"place":"city","name":"20F24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.40854,14.8647]},"properties":{"population":725700,"capital":4,"place":"city","name":"20F28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.62343,16.34319]},"properties":{"population":623048,"capital":4,"place":"city","name":"20F29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.57093,14.70624]},"properties":{"population":682979,"capital":4,"place":"city","name":"20F34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.73944,-3.67942]},"properties":{"population":780261,"capital":4,"place":"city","name":"20F36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.44359,14.53895]},"properties":{"population":642971,"capital":4,"place":"city","name":"20F38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.81758,15.21872]},"properties":{"population":630078,"capital":4,"place":"city","name":"20F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.10723,17.74017]},"properties":{"population":613122,"capital":4,"place":"city","name":"20F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.79683,-0.48752]},"properties":{"population":755642,"capital":4,"place":"city","name":"20F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.32968,11.34998]},"properties":{"population":803203,"capital":4,"place":"city","name":"20F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.0421,15.2867]},"properties":{"population":635437,"capital":4,"place":"city","name":"20F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.92007,-2.66899]},"properties":{"population":830943,"capital":4,"place":"city","name":"20F65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.77481,14.64777]},"properties":{"population":715851,"capital":4,"place":"city","name":"20F67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.6764,15.19488]},"properties":{"population":653524,"capital":4,"place":"city","name":"20F69"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.23547,17.7056]},"properties":{"population":545103,"capital":4,"place":"city","name":"20F70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.17771,15.74536]},"properties":{"population":630991,"capital":4,"place":"city","name":"20F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.10593,17.84135]},"properties":{"population":477912,"capital":4,"place":"city","name":"20Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.91807,1.32735]},"properties":{"population":859248,"capital":4,"place":"city","name":"20G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.83992,18.05052]},"properties":{"population":426267,"capital":4,"place":"city","name":"20G07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.14358,15.19284]},"properties":{"population":710906,"capital":4,"place":"city","name":"20G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.35896,0.46426]},"properties":{"population":801301,"capital":4,"place":"city","name":"20G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.39251,13.33622]},"properties":{"population":725417,"capital":4,"place":"city","name":"20G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.44734,15.68868]},"properties":{"population":682762,"capital":4,"place":"city","name":"20G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.33111,15.19167]},"properties":{"population":664639,"capital":4,"place":"city","name":"20G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.4894,15.80572]},"properties":{"population":694985,"capital":4,"place":"city","name":"20G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.86003,-1.83765]},"properties":{"population":815450,"capital":4,"place":"city","name":"20G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.29464,17.38546]},"properties":{"population":499043,"capital":4,"place":"city","name":"20G41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.02718,14.34393]},"properties":{"population":645047,"capital":4,"place":"city","name":"20G42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.38292,17.56501]},"properties":{"population":534233,"capital":4,"place":"city","name":"20G43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.64455,17.13665]},"properties":{"population":485203,"capital":4,"place":"city","name":"20G44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.82897,13.19966]},"properties":{"population":641181,"capital":4,"place":"city","name":"20G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.69168,16.30188]},"properties":{"population":583773,"capital":4,"place":"city","name":"20G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.32823,17.57188]},"properties":{"population":488280,"capital":4,"place":"city","name":"20Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.12583,13.3951]},"properties":{"population":676272,"capital":4,"place":"city","name":"20H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.02593,13.90887]},"properties":{"population":752779,"capital":4,"place":"city","name":"20H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.01058,13.62935]},"properties":{"population":768063,"capital":4,"place":"city","name":"20H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.71718,13.41584]},"properties":{"population":637331,"capital":4,"place":"city","name":"20H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.81113,13.2038]},"properties":{"population":687419,"capital":4,"place":"city","name":"20H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.8698,13.00482]},"properties":{"population":497673,"capital":4,"place":"city","name":"20H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.71096,12.97473]},"properties":{"population":444265,"capital":4,"place":"city","name":"20H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.64063,12.88501]},"properties":{"population":313549,"capital":4,"place":"city","name":"20Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.89103,-1.46007]},"properties":{"population":767461,"capital":4,"place":"city","name":"20J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.73891,14.27161]},"properties":{"population":759438,"capital":4,"place":"city","name":"20J06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.5471,18.74789]},"properties":{"population":561677,"capital":4,"place":"city","name":"20J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.83824,16.86549]},"properties":{"population":531320,"capital":4,"place":"city","name":"20J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.7713,19.14656]},"properties":{"population":304452,"capital":4,"place":"city","name":"20Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.49402,-2.32696]},"properties":{"population":717088,"capital":4,"place":"city","name":"20K01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.92384,19.16887]},"properties":{"population":69314,"capital":4,"place":"city","name":"20K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.20115,16.27177]},"properties":{"population":691174,"capital":4,"place":"city","name":"20K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.66386,-2.11534]},"properties":{"population":686901,"capital":4,"place":"city","name":"20K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.11049,-3.25731]},"properties":{"population":667834,"capital":4,"place":"city","name":"20K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.52382,21.06585]},"properties":{"population":615060,"capital":4,"place":"city","name":"20K21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.04272,2.39266]},"properties":{"population":701121,"capital":4,"place":"city","name":"20K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.00728,19.73277]},"properties":{"population":688653,"capital":4,"place":"city","name":"20K27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.18854,1.54712]},"properties":{"population":719218,"capital":4,"place":"city","name":"20K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.54916,19.90127]},"properties":{"population":589715,"capital":4,"place":"city","name":"20K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.80596,19.76673]},"properties":{"population":645519,"capital":4,"place":"city","name":"20K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.50528,17.36036]},"properties":{"population":562401,"capital":4,"place":"city","name":"20K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.26691,16.88299]},"properties":{"population":682110,"capital":4,"place":"city","name":"20K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.99337,18.9934]},"properties":{"population":456434,"capital":4,"place":"city","name":"20Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.29512,7.48877]},"properties":{"population":678332,"capital":4,"place":"city","name":"20L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.71248,10.11709]},"properties":{"population":270805,"capital":4,"place":"city","name":"20L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.74082,10.08585]},"properties":{"population":138629,"capital":4,"place":"city","name":"20Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.3749,10.92548]},"properties":{"population":825738,"capital":4,"place":"city","name":"20M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.39704,9.87361]},"properties":{"population":745298,"capital":4,"place":"city","name":"20M07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.55066,10.40452]},"properties":{"population":853993,"capital":4,"place":"city","name":"20M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.59704,10.50493]},"properties":{"population":479579,"capital":4,"place":"city","name":"20M11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.48788,10.28456]},"properties":{"population":738956,"capital":4,"place":"city","name":"20M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.57061,10.62501]},"properties":{"population":517048,"capital":4,"place":"city","name":"20M13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.85081,11.23904]},"properties":{"population":739079,"capital":4,"place":"city","name":"20M14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.46087,10.82627]},"properties":{"population":714361,"capital":4,"place":"city","name":"20M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.68803,9.89479]},"properties":{"population":724921,"capital":4,"place":"city","name":"20M17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.62562,10.3714]},"properties":{"population":706817,"capital":4,"place":"city","name":"20M18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.4702,10.44814]},"properties":{"population":531811,"capital":4,"place":"city","name":"20M19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.05587,11.58909]},"properties":{"population":805674,"capital":4,"place":"city","name":"20M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.59744,10.91393]},"properties":{"population":710660,"capital":4,"place":"city","name":"20M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.9935,11.26982]},"properties":{"population":709090,"capital":4,"place":"city","name":"20M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.41505,9.76183]},"properties":{"population":489783,"capital":4,"place":"city","name":"20M32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.18351,11.18978]},"properties":{"population":761825,"capital":4,"place":"city","name":"20M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.50961,10.14727]},"properties":{"population":698007,"capital":4,"place":"city","name":"20M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.78661,10.46165]},"properties":{"population":317805,"capital":4,"place":"city","name":"20M75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.07189,11.80529]},"properties":{"population":704053,"capital":4,"place":"city","name":"20M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.54676,10.59849]},"properties":{"population":569709,"capital":4,"place":"city","name":"20Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.35019,-4.97612]},"properties":{"population":676849,"capital":4,"place":"city","name":"20N02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.94414,-4.13631]},"properties":{"population":806777,"capital":4,"place":"city","name":"20N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.16316,-4.87332]},"properties":{"population":592958,"capital":4,"place":"city","name":"20N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.32431,-5.1016]},"properties":{"population":695559,"capital":4,"place":"city","name":"20N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.84752,-3.90114]},"properties":{"population":719593,"capital":4,"place":"city","name":"20N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.21346,-4.71955]},"properties":{"population":738025,"capital":4,"place":"city","name":"20N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.24695,-5.17382]},"properties":{"population":602344,"capital":4,"place":"city","name":"20N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.12154,-5.28583]},"properties":{"population":239789,"capital":4,"place":"city","name":"20Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.84846,9.57059]},"properties":{"population":661740,"capital":4,"place":"city","name":"20P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.05152,11.92307]},"properties":{"population":138629,"capital":4,"place":"city","name":"20P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.01288,11.95281]},"properties":{"population":109861,"capital":4,"place":"city","name":"20Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.50949,-3.70508]},"properties":{"population":219722,"capital":4,"place":"city","name":"22-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.49731,-2.97487]},"properties":{"population":562401,"capital":4,"place":"city","name":"22-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.38005,-0.62704]},"properties":{"population":626720,"capital":4,"place":"city","name":"22-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.36042,-3.72238]},"properties":{"population":508140,"capital":4,"place":"city","name":"22-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.53463,-3.66589]},"properties":{"population":387120,"capital":4,"place":"city","name":"22-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.9045,-3.49889]},"properties":{"population":614846,"capital":4,"place":"city","name":"22-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.3283,-4.03219]},"properties":{"population":69314,"capital":4,"place":"city","name":"22-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.36247,-3.78818]},"properties":{"population":486753,"capital":4,"place":"city","name":"22-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.25314,10.98614]},"properties":{"population":770616,"capital":4,"place":"city","name":"22A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.53908,10.74538]},"properties":{"population":592692,"capital":4,"place":"city","name":"22A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.70175,11.18833]},"properties":{"population":722838,"capital":4,"place":"city","name":"22A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.78933,10.98952]},"properties":{"population":587493,"capital":4,"place":"city","name":"22A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.68499,11.57404]},"properties":{"population":670686,"capital":4,"place":"city","name":"22A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.93974,10.85336]},"properties":{"population":593753,"capital":4,"place":"city","name":"22A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.80684,10.31356]},"properties":{"population":475359,"capital":4,"place":"city","name":"22A26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.87817,11.02599]},"properties":{"population":648768,"capital":4,"place":"city","name":"22A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.90333,10.7808]},"properties":{"population":608904,"capital":4,"place":"city","name":"22A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.68221,10.16267]},"properties":{"population":333220,"capital":4,"place":"city","name":"22Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.79031,7.93728]},"properties":{"population":621060,"capital":4,"place":"city","name":"22B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.5963,8.71449]},"properties":{"population":439444,"capital":4,"place":"city","name":"22B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.74961,8.02089]},"properties":{"population":501063,"capital":4,"place":"city","name":"22B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.5579,8.86828]},"properties":{"population":239789,"capital":4,"place":"city","name":"22Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.67315,-2.20708]},"properties":{"population":702731,"capital":4,"place":"city","name":"22C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.21484,-8.26164]},"properties":{"population":179175,"capital":4,"place":"city","name":"22C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.23548,-8.32323]},"properties":{"population":207944,"capital":4,"place":"city","name":"22Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.0685,15.56637]},"properties":{"population":702019,"capital":4,"place":"city","name":"22D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.14584,0.13413]},"properties":{"population":711558,"capital":4,"place":"city","name":"22D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.0706,13.70148]},"properties":{"population":606610,"capital":4,"place":"city","name":"22D12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.51428,20.5282]},"properties":{"population":631716,"capital":4,"place":"city","name":"22D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.42541,22.14676]},"properties":{"population":489783,"capital":4,"place":"city","name":"22D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.15132,11.9239]},"properties":{"population":719368,"capital":4,"place":"city","name":"22D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.41388,18.42859]},"properties":{"population":606610,"capital":4,"place":"city","name":"22D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.48638,21.50068]},"properties":{"population":578996,"capital":4,"place":"city","name":"22D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.62391,13.23194]},"properties":{"population":666057,"capital":4,"place":"city","name":"22D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.15049,21.26789]},"properties":{"population":519295,"capital":4,"place":"city","name":"22D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.40067,18.28176]},"properties":{"population":194591,"capital":4,"place":"city","name":"22D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.05866,22.0724]},"properties":{"population":508140,"capital":4,"place":"city","name":"22D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.3469,19.66083]},"properties":{"population":355534,"capital":4,"place":"city","name":"22Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.07429,13.98143]},"properties":{"population":562762,"capital":4,"place":"city","name":"22E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.71076,-4.4273]},"properties":{"population":664378,"capital":4,"place":"city","name":"22E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.8359,10.74874]},"properties":{"population":724351,"capital":4,"place":"city","name":"22E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.31372,15.48834]},"properties":{"population":583188,"capital":4,"place":"city","name":"22E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.96859,3.87521]},"properties":{"population":759739,"capital":4,"place":"city","name":"22E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.50932,0.02016]},"properties":{"population":646146,"capital":4,"place":"city","name":"22E27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.24095,0.96424]},"properties":{"population":796311,"capital":4,"place":"city","name":"22E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.10773,-4.84339]},"properties":{"population":666695,"capital":4,"place":"city","name":"22E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.85044,-0.16482]},"properties":{"population":781075,"capital":4,"place":"city","name":"22E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.88667,20.4999]},"properties":{"population":528826,"capital":4,"place":"city","name":"22E41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.64716,13.24465]},"properties":{"population":628785,"capital":4,"place":"city","name":"22E43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.45056,6.94273]},"properties":{"population":774066,"capital":4,"place":"city","name":"22E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.30024,5.4814]},"properties":{"population":829179,"capital":4,"place":"city","name":"22E46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.26096,12.68768]},"properties":{"population":679234,"capital":4,"place":"city","name":"22E47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.36739,-4.79466]},"properties":{"population":795296,"capital":4,"place":"city","name":"22E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.97428,19.88384]},"properties":{"population":688243,"capital":4,"place":"city","name":"22E55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.24296,20.70263]},"properties":{"population":489034,"capital":4,"place":"city","name":"22E57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.76215,0.12355]},"properties":{"population":725911,"capital":4,"place":"city","name":"22E60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.3682,8.07777]},"properties":{"population":729233,"capital":4,"place":"city","name":"22E65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.58563,20.54856]},"properties":{"population":433073,"capital":4,"place":"city","name":"22E66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.07473,13.52592]},"properties":{"population":619236,"capital":4,"place":"city","name":"22E67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.57126,-4.33897]},"properties":{"population":857734,"capital":4,"place":"city","name":"22E70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.78177,-4.5821]},"properties":{"population":659987,"capital":4,"place":"city","name":"22E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.55422,20.81938]},"properties":{"population":542495,"capital":4,"place":"city","name":"22Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.61461,-8.16358]},"properties":{"population":543372,"capital":4,"place":"city","name":"22F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.09189,-8.73349]},"properties":{"population":512989,"capital":4,"place":"city","name":"22F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.48118,-8.27147]},"properties":{"population":585220,"capital":4,"place":"city","name":"22F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.98975,-8.48605]},"properties":{"population":542934,"capital":4,"place":"city","name":"22F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.37747,-8.74622]},"properties":{"population":294443,"capital":4,"place":"city","name":"22F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.32004,-8.82154]},"properties":{"population":179175,"capital":4,"place":"city","name":"22Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.62174,12.99039]},"properties":{"population":414313,"capital":4,"place":"city","name":"26-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.94091,16.52106]},"properties":{"population":800336,"capital":4,"place":"city","name":"26-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.40118,16.7049]},"properties":{"population":565248,"capital":4,"place":"city","name":"26-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.42163,14.19429]},"properties":{"population":675110,"capital":4,"place":"city","name":"26-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.92011,15.36794]},"properties":{"population":478749,"capital":4,"place":"city","name":"26-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.78674,12.85785]},"properties":{"population":515329,"capital":4,"place":"city","name":"26-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.63253,12.64215]},"properties":{"population":160943,"capital":4,"place":"city","name":"26-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.49963,12.76383]},"properties":{"population":647543,"capital":4,"place":"city","name":"26-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.37688,20.36219]},"properties":{"population":653378,"capital":4,"place":"city","name":"26A03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.58082,16.16411]},"properties":{"population":697728,"capital":4,"place":"city","name":"26A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.36172,15.79477]},"properties":{"population":620859,"capital":4,"place":"city","name":"26A09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.46899,11.16762]},"properties":{"population":656807,"capital":4,"place":"city","name":"26A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.64727,10.89138]},"properties":{"population":780343,"capital":4,"place":"city","name":"26A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.72689,7.12957]},"properties":{"population":672383,"capital":4,"place":"city","name":"26A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.59299,12.49744]},"properties":{"population":707918,"capital":4,"place":"city","name":"26A18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.80832,18.83263]},"properties":{"population":702908,"capital":4,"place":"city","name":"26A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.48381,9.35929]},"properties":{"population":782963,"capital":4,"place":"city","name":"26A24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.09334,12.74811]},"properties":{"population":662671,"capital":4,"place":"city","name":"26A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.0656,13.57286]},"properties":{"population":644888,"capital":4,"place":"city","name":"26A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.98673,-4.54697]},"properties":{"population":922670,"capital":4,"place":"city","name":"26A33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.63158,20.91117]},"properties":{"population":474493,"capital":4,"place":"city","name":"26A36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.92709,12.87868]},"properties":{"population":719967,"capital":4,"place":"city","name":"26A39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.98855,10.40718]},"properties":{"population":756992,"capital":4,"place":"city","name":"26A42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.15977,9.75049]},"properties":{"population":707749,"capital":4,"place":"city","name":"26A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.14519,18.14064]},"properties":{"population":577455,"capital":4,"place":"city","name":"26A46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.91436,12.29214]},"properties":{"population":701840,"capital":4,"place":"city","name":"26A48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.50335,7.84241]},"properties":{"population":804942,"capital":4,"place":"city","name":"26A51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.19824,16.21127]},"properties":{"population":576205,"capital":4,"place":"city","name":"26A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.29821,18.43889]},"properties":{"population":534233,"capital":4,"place":"city","name":"26Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.62647,8.96168]},"properties":{"population":739326,"capital":4,"place":"city","name":"26B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.99267,9.77256]},"properties":{"population":661873,"capital":4,"place":"city","name":"26B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.58364,11.83693]},"properties":{"population":578689,"capital":4,"place":"city","name":"26B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.24609,11.57525]},"properties":{"population":637161,"capital":4,"place":"city","name":"26B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.99093,12.23075]},"properties":{"population":596614,"capital":4,"place":"city","name":"26B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.53092,7.25745]},"properties":{"population":789803,"capital":4,"place":"city","name":"26B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.20998,11.40441]},"properties":{"population":641836,"capital":4,"place":"city","name":"26B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.31088,-4.38819]},"properties":{"population":673578,"capital":4,"place":"city","name":"26B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.99399,-0.18929]},"properties":{"population":586078,"capital":4,"place":"city","name":"26B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.63338,11.74988]},"properties":{"population":599396,"capital":4,"place":"city","name":"26B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.54114,19.01798]},"properties":{"population":501727,"capital":4,"place":"city","name":"26Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.61103,23.49747]},"properties":{"population":647850,"capital":4,"place":"city","name":"26C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.72086,23.77835]},"properties":{"population":751697,"capital":4,"place":"city","name":"26C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.0372,23.40733]},"properties":{"population":566988,"capital":4,"place":"city","name":"26C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.13584,23.16814]},"properties":{"population":477068,"capital":4,"place":"city","name":"26C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.57896,22.95359]},"properties":{"population":160943,"capital":4,"place":"city","name":"26Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.13761,28.25158]},"properties":{"population":685012,"capital":4,"place":"city","name":"26D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.15317,28.38645]},"properties":{"population":698656,"capital":4,"place":"city","name":"26D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.13676,27.69134]},"properties":{"population":850714,"capital":4,"place":"city","name":"26D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.88258,27.46794]},"properties":{"population":931018,"capital":4,"place":"city","name":"26D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.09584,28.09357]},"properties":{"population":697073,"capital":4,"place":"city","name":"26D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.00234,28.41144]},"properties":{"population":545532,"capital":4,"place":"city","name":"26D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.79313,28.5512]},"properties":{"population":465396,"capital":4,"place":"city","name":"26Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.85044,29.53682]},"properties":{"population":570378,"capital":4,"place":"city","name":"26E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.49827,29.8011]},"properties":{"population":621060,"capital":4,"place":"city","name":"26E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.55714,30.02076]},"properties":{"population":548893,"capital":4,"place":"city","name":"26E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.36192,30.28306]},"properties":{"population":470048,"capital":4,"place":"city","name":"26E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.55663,29.4012]},"properties":{"population":678219,"capital":4,"place":"city","name":"26E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.5106,29.90633]},"properties":{"population":580814,"capital":4,"place":"city","name":"26E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.81709,29.12732]},"properties":{"population":660529,"capital":4,"place":"city","name":"26E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.39641,30.2716]},"properties":{"population":504985,"capital":4,"place":"city","name":"26E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.90037,29.16512]},"properties":{"population":724494,"capital":4,"place":"city","name":"26E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.29464,29.62088]},"properties":{"population":727447,"capital":4,"place":"city","name":"26E60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.44209,29.72169]},"properties":{"population":604973,"capital":4,"place":"city","name":"26E70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.58094,29.63075]},"properties":{"population":462497,"capital":4,"place":"city","name":"26E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.19276,30.2]},"properties":{"population":179175,"capital":4,"place":"city","name":"26Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.62909,0.48647]},"properties":{"population":352636,"capital":4,"place":"city","name":"28-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.82207,3.29304]},"properties":{"population":653233,"capital":4,"place":"city","name":"28-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.80202,6.07353]},"properties":{"population":575257,"capital":4,"place":"city","name":"28-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.7628,0.6114]},"properties":{"population":530826,"capital":4,"place":"city","name":"28-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.47526,1.41394]},"properties":{"population":358351,"capital":4,"place":"city","name":"28-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.11623,0.96121]},"properties":{"population":508759,"capital":4,"place":"city","name":"28-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.62613,0.4572]},"properties":{"population":69314,"capital":4,"place":"city","name":"28-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.49121,0.43854]},"properties":{"population":69314,"capital":4,"place":"city","name":"28-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.81804,0.7225]},"properties":{"population":555295,"capital":4,"place":"city","name":"28-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.52107,11.69693]},"properties":{"population":775662,"capital":4,"place":"city","name":"28A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.93868,13.3218]},"properties":{"population":712528,"capital":4,"place":"city","name":"28A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.76106,8.12112]},"properties":{"population":742892,"capital":4,"place":"city","name":"28A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.7152,11.61383]},"properties":{"population":671659,"capital":4,"place":"city","name":"28A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.1081,-3.07296]},"properties":{"population":741577,"capital":4,"place":"city","name":"28A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.97343,8.72378]},"properties":{"population":692853,"capital":4,"place":"city","name":"28A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.37825,5.01782]},"properties":{"population":688038,"capital":4,"place":"city","name":"28A33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.7665,3.14612]},"properties":{"population":662406,"capital":4,"place":"city","name":"28A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.2646,13.76857]},"properties":{"population":560947,"capital":4,"place":"city","name":"28A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.81256,21.41333]},"properties":{"population":506259,"capital":4,"place":"city","name":"28A51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.57726,19.00051]},"properties":{"population":634212,"capital":4,"place":"city","name":"28A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.328,-1.22493]},"properties":{"population":766622,"capital":4,"place":"city","name":"28A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.11032,1.86852]},"properties":{"population":801400,"capital":4,"place":"city","name":"28A78"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.1327,-0.47702]},"properties":{"population":896149,"capital":4,"place":"city","name":"28A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.42294,11.03375]},"properties":{"population":596870,"capital":4,"place":"city","name":"28A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.90079,19.83154]},"properties":{"population":485203,"capital":4,"place":"city","name":"28Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.72826,24.22034]},"properties":{"population":773499,"capital":4,"place":"city","name":"28B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.58001,23.66995]},"properties":{"population":595324,"capital":4,"place":"city","name":"28B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.78676,23.55907]},"properties":{"population":602586,"capital":4,"place":"city","name":"28B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.47892,23.98912]},"properties":{"population":709506,"capital":4,"place":"city","name":"28B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.73291,23.88749]},"properties":{"population":478749,"capital":4,"place":"city","name":"28B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.7799,23.64384]},"properties":{"population":230258,"capital":4,"place":"city","name":"28Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.02959,0.91779]},"properties":{"population":653233,"capital":4,"place":"city","name":"28C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.61898,9.57279]},"properties":{"population":705789,"capital":4,"place":"city","name":"28C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.396,12.09052]},"properties":{"population":706561,"capital":4,"place":"city","name":"28C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.72695,-4.90535]},"properties":{"population":780343,"capital":4,"place":"city","name":"28C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.33264,13.39779]},"properties":{"population":530330,"capital":4,"place":"city","name":"28C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.03958,18.61202]},"properties":{"population":263905,"capital":4,"place":"city","name":"28Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.71497,25.73899]},"properties":{"population":832700,"capital":4,"place":"city","name":"28D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.19423,25.10481]},"properties":{"population":636475,"capital":4,"place":"city","name":"28D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.41829,24.86541]},"properties":{"population":671901,"capital":4,"place":"city","name":"28D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.71773,25.55226]},"properties":{"population":725417,"capital":4,"place":"city","name":"28D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.3464,25.12638]},"properties":{"population":624997,"capital":4,"place":"city","name":"28D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.39153,24.80758]},"properties":{"population":424849,"capital":4,"place":"city","name":"28Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.46119,3.45275]},"properties":{"population":569373,"capital":4,"place":"city","name":"28E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.18124,4.4939]},"properties":{"population":766246,"capital":4,"place":"city","name":"28E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.73561,1.24407]},"properties":{"population":497673,"capital":4,"place":"city","name":"28E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.98535,2.57827]},"properties":{"population":453259,"capital":4,"place":"city","name":"28E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.21303,0.42376]},"properties":{"population":69314,"capital":4,"place":"city","name":"28Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.23319,8.89634]},"properties":{"population":309104,"capital":4,"place":"city","name":"30-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.53787,12.34733]},"properties":{"population":693439,"capital":4,"place":"city","name":"30-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.13706,13.48876]},"properties":{"population":679458,"capital":4,"place":"city","name":"30-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.29342,9.48877]},"properties":{"population":607534,"capital":4,"place":"city","name":"30-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.5078,9.60746]},"properties":{"population":449980,"capital":4,"place":"city","name":"30-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.03676,10.80191]},"properties":{"population":659850,"capital":4,"place":"city","name":"30-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.2444,8.92924]},"properties":{"population":668210,"capital":4,"place":"city","name":"30-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.39125,-4.36692]},"properties":{"population":561677,"capital":4,"place":"city","name":"30A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.82857,-3.779]},"properties":{"population":682871,"capital":4,"place":"city","name":"30A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.97728,-5.77075]},"properties":{"population":560211,"capital":4,"place":"city","name":"30A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.30542,-1.89737]},"properties":{"population":283321,"capital":4,"place":"city","name":"30Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.69537,6.87533]},"properties":{"population":752023,"capital":4,"place":"city","name":"30B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.46942,19.69094]},"properties":{"population":521493,"capital":4,"place":"city","name":"30B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.09186,16.64412]},"properties":{"population":599893,"capital":4,"place":"city","name":"30B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.40936,7.50546]},"properties":{"population":672262,"capital":4,"place":"city","name":"30B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.69473,10.35935]},"properties":{"population":767461,"capital":4,"place":"city","name":"30B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.19276,-0.72354]},"properties":{"population":616751,"capital":4,"place":"city","name":"30B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.00952,3.4421]},"properties":{"population":708590,"capital":4,"place":"city","name":"30B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.96131,17.68985]},"properties":{"population":547646,"capital":4,"place":"city","name":"30B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.07287,19.13564]},"properties":{"population":340119,"capital":4,"place":"city","name":"30Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.1739,10.38861]},"properties":{"population":778322,"capital":4,"place":"city","name":"30C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.92108,1.6834]},"properties":{"population":835420,"capital":4,"place":"city","name":"30C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.3743,0.86342]},"properties":{"population":744891,"capital":4,"place":"city","name":"30C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.15971,19.98538]},"properties":{"population":560580,"capital":4,"place":"city","name":"30C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.0904,9.38114]},"properties":{"population":654965,"capital":4,"place":"city","name":"30C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.80156,9.09539]},"properties":{"population":746450,"capital":4,"place":"city","name":"30C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.65054,10.06841]},"properties":{"population":632793,"capital":4,"place":"city","name":"30C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.36243,-4.99597]},"properties":{"population":945712,"capital":4,"place":"city","name":"30C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.32733,18.57521]},"properties":{"population":805452,"capital":4,"place":"city","name":"30C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.32611,12.29401]},"properties":{"population":766809,"capital":4,"place":"city","name":"30C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.60785,7.85957]},"properties":{"population":812088,"capital":4,"place":"city","name":"30C62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.9879,1.06034]},"properties":{"population":760589,"capital":4,"place":"city","name":"30C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.07698,14.77991]},"properties":{"population":702197,"capital":4,"place":"city","name":"30C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.39789,14.85709]},"properties":{"population":744483,"capital":4,"place":"city","name":"30C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.72596,-0.10751]},"properties":{"population":777527,"capital":4,"place":"city","name":"30C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.61156,1.33736]},"properties":{"population":691671,"capital":4,"place":"city","name":"30C85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.91843,12.51814]},"properties":{"population":626909,"capital":4,"place":"city","name":"30C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.45216,19.92345]},"properties":{"population":444265,"capital":4,"place":"city","name":"30Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.02895,7.19288]},"properties":{"population":805959,"capital":4,"place":"city","name":"30D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.2477,11.60675]},"properties":{"population":700488,"capital":4,"place":"city","name":"30D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.99723,6.89856]},"properties":{"population":801367,"capital":4,"place":"city","name":"30D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.60237,0.01742]},"properties":{"population":757301,"capital":4,"place":"city","name":"30D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.24803,0.87538]},"properties":{"population":787054,"capital":4,"place":"city","name":"30D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.28807,12.15488]},"properties":{"population":872907,"capital":4,"place":"city","name":"30D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.5321,14.80923]},"properties":{"population":687212,"capital":4,"place":"city","name":"30D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.52357,16.12327]},"properties":{"population":728619,"capital":4,"place":"city","name":"30D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.31579,10.92606]},"properties":{"population":728892,"capital":4,"place":"city","name":"30D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.28762,9.33329]},"properties":{"population":763819,"capital":4,"place":"city","name":"30D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.20004,16.43253]},"properties":{"population":555682,"capital":4,"place":"city","name":"30D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.53703,12.00998]},"properties":{"population":606145,"capital":4,"place":"city","name":"30D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.78044,21.27995]},"properties":{"population":415888,"capital":4,"place":"city","name":"30Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.17307,0.08271]},"properties":{"population":766949,"capital":4,"place":"city","name":"30E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.3871,0.84213]},"properties":{"population":823429,"capital":4,"place":"city","name":"30E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.17329,11.42806]},"properties":{"population":636475,"capital":4,"place":"city","name":"30E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.62325,-0.15484]},"properties":{"population":777527,"capital":4,"place":"city","name":"30E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.20182,-4.9066]},"properties":{"population":822147,"capital":4,"place":"city","name":"30E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.6657,3.12813]},"properties":{"population":615697,"capital":4,"place":"city","name":"30E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.81692,20.21334]},"properties":{"population":349650,"capital":4,"place":"city","name":"30Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.83282,8.20936]},"properties":{"population":765869,"capital":4,"place":"city","name":"30F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.46424,13.18222]},"properties":{"population":575574,"capital":4,"place":"city","name":"30F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.77565,13.44358]},"properties":{"population":606145,"capital":4,"place":"city","name":"30F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.00845,18.90322]},"properties":{"population":541164,"capital":4,"place":"city","name":"30F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.46815,10.15878]},"properties":{"population":693634,"capital":4,"place":"city","name":"30F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.21411,11.71776]},"properties":{"population":752779,"capital":4,"place":"city","name":"30F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.86054,13.12066]},"properties":{"population":735691,"capital":4,"place":"city","name":"30F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.42287,11.25441]},"properties":{"population":672862,"capital":4,"place":"city","name":"30F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.62423,19.91038]},"properties":{"population":568357,"capital":4,"place":"city","name":"30F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[26.51492,-0.22925]},"properties":{"population":750714,"capital":4,"place":"city","name":"30F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.23728,0.74521]},"properties":{"population":690975,"capital":4,"place":"city","name":"30F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.7376,21.71006]},"properties":{"population":444265,"capital":4,"place":"city","name":"30Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.68819,25.40864]},"properties":{"population":595064,"capital":4,"place":"city","name":"30G06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.98979,25.12358]},"properties":{"population":484418,"capital":4,"place":"city","name":"30G12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.24566,25.846]},"properties":{"population":706902,"capital":4,"place":"city","name":"30G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.82151,25.00464]},"properties":{"population":503695,"capital":4,"place":"city","name":"30G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.40568,25.57753]},"properties":{"population":656807,"capital":4,"place":"city","name":"30G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.7507,25.95981]},"properties":{"population":800436,"capital":4,"place":"city","name":"30G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.6832,25.09052]},"properties":{"population":483628,"capital":4,"place":"city","name":"30G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.71433,25.21464]},"properties":{"population":219722,"capital":4,"place":"city","name":"30Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.19911,21.9828]},"properties":{"population":767415,"capital":4,"place":"city","name":"30H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.77755,20.29008]},"properties":{"population":686797,"capital":4,"place":"city","name":"30H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.98255,20.71965]},"properties":{"population":463472,"capital":4,"place":"city","name":"30H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.38448,21.7135]},"properties":{"population":665027,"capital":4,"place":"city","name":"30H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.58947,21.71715]},"properties":{"population":543372,"capital":4,"place":"city","name":"30H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.13493,20.46183]},"properties":{"population":573657,"capital":4,"place":"city","name":"30H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.16577,19.61462]},"properties":{"population":475359,"capital":4,"place":"city","name":"30H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.10651,20.41929]},"properties":{"population":109861,"capital":4,"place":"city","name":"30H45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.45535,20.45259]},"properties":{"population":472738,"capital":4,"place":"city","name":"30H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.247,20.73028]},"properties":{"population":385014,"capital":4,"place":"city","name":"30H80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.28056,20.5246]},"properties":{"population":566642,"capital":4,"place":"city","name":"30H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.21015,20.5672]},"properties":{"population":283321,"capital":4,"place":"city","name":"30Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.46953,12.07274]},"properties":{"population":482831,"capital":4,"place":"city","name":"30J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.46184,10.30542]},"properties":{"population":568357,"capital":4,"place":"city","name":"30J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.0404,11.81659]},"properties":{"population":248490,"capital":4,"place":"city","name":"30J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.69267,11.8464]},"properties":{"population":467282,"capital":4,"place":"city","name":"30J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.10448,11.91533]},"properties":{"population":179175,"capital":4,"place":"city","name":"30Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.05309,11.15788]},"properties":{"population":431748,"capital":4,"place":"city","name":"30K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.38206,11.54244]},"properties":{"population":336729,"capital":4,"place":"city","name":"30K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.05293,11.58167]},"properties":{"population":194591,"capital":4,"place":"city","name":"30K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.75029,11.60349]},"properties":{"population":270805,"capital":4,"place":"city","name":"30K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.93326,11.47732]},"properties":{"population":321887,"capital":4,"place":"city","name":"30K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.81668,11.73187]},"properties":{"population":69314,"capital":4,"place":"city","name":"30Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.77157,4.52171]},"properties":{"population":467282,"capital":4,"place":"city","name":"30L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.54548,4.95903]},"properties":{"population":521493,"capital":4,"place":"city","name":"30L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.13058,5.55864]},"properties":{"population":138629,"capital":4,"place":"city","name":"30L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.69333,2.79824]},"properties":{"population":588332,"capital":4,"place":"city","name":"30L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.05081,5.55044]},"properties":{"population":109861,"capital":4,"place":"city","name":"30Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.19247,-6.09889]},"properties":{"population":248490,"capital":4,"place":"city","name":"31-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.67185,-5.6846]},"properties":{"population":429045,"capital":4,"place":"city","name":"31-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.4965,-0.75741]},"properties":{"population":547646,"capital":4,"place":"city","name":"31-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.11728,-6.14665]},"properties":{"population":407753,"capital":4,"place":"city","name":"31-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.01095,-5.3104]},"properties":{"population":256494,"capital":4,"place":"city","name":"31-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.12534,-5.81833]},"properties":{"population":496284,"capital":4,"place":"city","name":"31-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.62534,-5.88174]},"properties":{"population":491998,"capital":4,"place":"city","name":"31-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.28638,3.8026]},"properties":{"population":768937,"capital":4,"place":"city","name":"31A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.91928,0.17474]},"properties":{"population":663068,"capital":4,"place":"city","name":"31A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.0647,-4.90662]},"properties":{"population":720266,"capital":4,"place":"city","name":"31A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.93939,15.1133]},"properties":{"population":604025,"capital":4,"place":"city","name":"31A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.36243,3.81135]},"properties":{"population":684161,"capital":4,"place":"city","name":"31A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.81208,-2.81767]},"properties":{"population":704228,"capital":4,"place":"city","name":"31A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.17144,10.47854]},"properties":{"population":549306,"capital":4,"place":"city","name":"31A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.45833,14.74335]},"properties":{"population":521493,"capital":4,"place":"city","name":"31A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.27036,19.77823]},"properties":{"population":329583,"capital":4,"place":"city","name":"31Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.68644,28.24296]},"properties":{"population":778655,"capital":4,"place":"city","name":"31B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.52133,28.16952]},"properties":{"population":708002,"capital":4,"place":"city","name":"31B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.095,28.24021]},"properties":{"population":734729,"capital":4,"place":"city","name":"31B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.33026,28.17976]},"properties":{"population":691075,"capital":4,"place":"city","name":"31B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.78857,28.72336]},"properties":{"population":679458,"capital":4,"place":"city","name":"31B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.28129,28.3438]},"properties":{"population":690475,"capital":4,"place":"city","name":"31B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.86763,28.58253]},"properties":{"population":618620,"capital":4,"place":"city","name":"31B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.85956,28.51542]},"properties":{"population":530330,"capital":4,"place":"city","name":"31B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.38131,28.54573]},"properties":{"population":304452,"capital":4,"place":"city","name":"31Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.75549,28.41836]},"properties":{"population":695939,"capital":4,"place":"city","name":"31C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.47036,29.13661]},"properties":{"population":656948,"capital":4,"place":"city","name":"31C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.79676,29.07543]},"properties":{"population":620657,"capital":4,"place":"city","name":"31C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.8091,28.45232]},"properties":{"population":688243,"capital":4,"place":"city","name":"31C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.34579,29.10728]},"properties":{"population":614203,"capital":4,"place":"city","name":"31C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.95984,28.96153]},"properties":{"population":710660,"capital":4,"place":"city","name":"31C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.14239,29.57719]},"properties":{"population":579301,"capital":4,"place":"city","name":"31C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.01063,29.64545]},"properties":{"population":491265,"capital":4,"place":"city","name":"31C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.46852,28.09598]},"properties":{"population":639692,"capital":4,"place":"city","name":"31C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.2943,28.31982]},"properties":{"population":565248,"capital":4,"place":"city","name":"31C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.08951,29.69684]},"properties":{"population":179175,"capital":4,"place":"city","name":"31Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.8073,1.33868]},"properties":{"population":691671,"capital":4,"place":"city","name":"31D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.26329,9.5957]},"properties":{"population":514749,"capital":4,"place":"city","name":"31E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.02991,11.36308]},"properties":{"population":109861,"capital":4,"place":"city","name":"31E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.28036,11.37164]},"properties":{"population":69314,"capital":4,"place":"city","name":"31Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.27184,-1.15174]},"properties":{"population":256494,"capital":4,"place":"city","name":"32-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.93195,-0.85136]},"properties":{"population":517048,"capital":4,"place":"city","name":"32-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.23491,2.03299]},"properties":{"population":672982,"capital":4,"place":"city","name":"32-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.62402,-0.97253]},"properties":{"population":506259,"capital":4,"place":"city","name":"32-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.47169,-1.1433]},"properties":{"population":256494,"capital":4,"place":"city","name":"32-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58111,-0.42934]},"properties":{"population":651619,"capital":4,"place":"city","name":"32-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.38093,-0.92717]},"properties":{"population":548893,"capital":4,"place":"city","name":"32-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.35909,11.85374]},"properties":{"population":665544,"capital":4,"place":"city","name":"32A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.65957,11.71729]},"properties":{"population":721229,"capital":4,"place":"city","name":"32A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.99604,22.14368]},"properties":{"population":239789,"capital":4,"place":"city","name":"32A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.20104,5.50715]},"properties":{"population":738274,"capital":4,"place":"city","name":"32A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.42063,22.36006]},"properties":{"population":321887,"capital":4,"place":"city","name":"32A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.60801,11.25817]},"properties":{"population":691174,"capital":4,"place":"city","name":"32A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.30635,16.52314]},"properties":{"population":532787,"capital":4,"place":"city","name":"32A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.52374,21.38738]},"properties":{"population":516478,"capital":4,"place":"city","name":"32A18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.24099,19.91062]},"properties":{"population":445434,"capital":4,"place":"city","name":"32A19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.56869,11.54334]},"properties":{"population":609582,"capital":4,"place":"city","name":"32A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.65567,12.67494]},"properties":{"population":649978,"capital":4,"place":"city","name":"32A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.72843,7.47884]},"properties":{"population":761283,"capital":4,"place":"city","name":"32A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.25222,13.41447]},"properties":{"population":583188,"capital":4,"place":"city","name":"32A26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.48908,12.38108]},"properties":{"population":591889,"capital":4,"place":"city","name":"32A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.41196,10.19285]},"properties":{"population":723777,"capital":4,"place":"city","name":"32A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.24475,11.47153]},"properties":{"population":701301,"capital":4,"place":"city","name":"32A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.0137,12.77005]},"properties":{"population":679009,"capital":4,"place":"city","name":"32A36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.92606,12.12316]},"properties":{"population":682546,"capital":4,"place":"city","name":"32A37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.03226,13.17216]},"properties":{"population":662539,"capital":4,"place":"city","name":"32A38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.89069,12.86878]},"properties":{"population":672383,"capital":4,"place":"city","name":"32A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.99266,-4.53955]},"properties":{"population":559842,"capital":4,"place":"city","name":"32A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.88768,17.27757]},"properties":{"population":458496,"capital":4,"place":"city","name":"32A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.33299,15.51116]},"properties":{"population":453259,"capital":4,"place":"city","name":"32A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.25512,16.08133]},"properties":{"population":502388,"capital":4,"place":"city","name":"32A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.16114,18.99095]},"properties":{"population":406044,"capital":4,"place":"city","name":"32A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.58203,12.89578]},"properties":{"population":489783,"capital":4,"place":"city","name":"32A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.27176,12.2438]},"properties":{"population":593224,"capital":4,"place":"city","name":"32A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.11136,21.79218]},"properties":{"population":376120,"capital":4,"place":"city","name":"32Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.12728,13.26358]},"properties":{"population":604973,"capital":4,"place":"city","name":"32B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.13246,14.22294]},"properties":{"population":621460,"capital":4,"place":"city","name":"32B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.87591,16.6108]},"properties":{"population":556452,"capital":4,"place":"city","name":"32B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.40048,-0.4717]},"properties":{"population":639191,"capital":4,"place":"city","name":"32B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.48111,17.8904]},"properties":{"population":445434,"capital":4,"place":"city","name":"32B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.31468,15.46589]},"properties":{"population":491998,"capital":4,"place":"city","name":"32B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.82868,21.78299]},"properties":{"population":299573,"capital":4,"place":"city","name":"32Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.48735,13.22874]},"properties":{"population":651471,"capital":4,"place":"city","name":"32C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.00533,17.04255]},"properties":{"population":468213,"capital":4,"place":"city","name":"32C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.48699,20.32681]},"properties":{"population":283321,"capital":4,"place":"city","name":"32C09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.70947,21.64074]},"properties":{"population":109861,"capital":4,"place":"city","name":"32C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.6214,15.95862]},"properties":{"population":518738,"capital":4,"place":"city","name":"32C11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.93485,13.71358]},"properties":{"population":626720,"capital":4,"place":"city","name":"32C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.95969,22.15045]},"properties":{"population":69314,"capital":4,"place":"city","name":"32C16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.68921,22.41506]},"properties":{"population":69314,"capital":4,"place":"city","name":"32C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.50478,15.95621]},"properties":{"population":477068,"capital":4,"place":"city","name":"32C18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.2932,15.51102]},"properties":{"population":493447,"capital":4,"place":"city","name":"32C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.95241,18.69733]},"properties":{"population":387120,"capital":4,"place":"city","name":"32C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.21632,13.60637]},"properties":{"population":628413,"capital":4,"place":"city","name":"32C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.14391,12.31927]},"properties":{"population":651914,"capital":4,"place":"city","name":"32C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.60401,12.68076]},"properties":{"population":689162,"capital":4,"place":"city","name":"32C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.80226,16.96301]},"properties":{"population":477912,"capital":4,"place":"city","name":"32C36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.5942,14.86752]},"properties":{"population":523110,"capital":4,"place":"city","name":"32C37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.10388,-2.54229]},"properties":{"population":676734,"capital":4,"place":"city","name":"32C38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.89369,20.94197]},"properties":{"population":294443,"capital":4,"place":"city","name":"32C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.50749,19.107]},"properties":{"population":336729,"capital":4,"place":"city","name":"32C81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.11133,13.63421]},"properties":{"population":503695,"capital":4,"place":"city","name":"32C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.97579,20.52926]},"properties":{"population":325809,"capital":4,"place":"city","name":"32Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.30326,11.55514]},"properties":{"population":597126,"capital":4,"place":"city","name":"32D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.4644,12.91598]},"properties":{"population":576832,"capital":4,"place":"city","name":"32D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.06505,-1.60043]},"properties":{"population":713807,"capital":4,"place":"city","name":"32D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.80738,14.19057]},"properties":{"population":532787,"capital":4,"place":"city","name":"32D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.74814,22.48971]},"properties":{"population":138629,"capital":4,"place":"city","name":"32D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.68757,19.5962]},"properties":{"population":248490,"capital":4,"place":"city","name":"32D26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.64247,15.46778]},"properties":{"population":487519,"capital":4,"place":"city","name":"32D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.28322,21.22222]},"properties":{"population":248490,"capital":4,"place":"city","name":"32Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.68348,15.71813]},"properties":{"population":550533,"capital":4,"place":"city","name":"32E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.67402,0.58761]},"properties":{"population":690173,"capital":4,"place":"city","name":"32E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.14976,13.65215]},"properties":{"population":595064,"capital":4,"place":"city","name":"32E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.57971,13.26387]},"properties":{"population":662273,"capital":4,"place":"city","name":"32E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.01249,15.58702]},"properties":{"population":532300,"capital":4,"place":"city","name":"32E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.53814,18.73567]},"properties":{"population":414313,"capital":4,"place":"city","name":"32E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.43383,16.69131]},"properties":{"population":466343,"capital":4,"place":"city","name":"32E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.23727,21.2014]},"properties":{"population":239789,"capital":4,"place":"city","name":"32Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.3917,-0.52188]},"properties":{"population":639859,"capital":4,"place":"city","name":"32F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.37533,-1.49881]},"properties":{"population":469134,"capital":4,"place":"city","name":"32F17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.09895,-2.10176]},"properties":{"population":363758,"capital":4,"place":"city","name":"32F18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.08654,-2.08925]},"properties":{"population":69314,"capital":4,"place":"city","name":"32F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.12105,-2.09078]},"properties":{"population":239789,"capital":4,"place":"city","name":"32F27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.46886,-1.63614]},"properties":{"population":431748,"capital":4,"place":"city","name":"32F32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.10398,1.0553]},"properties":{"population":695177,"capital":4,"place":"city","name":"32F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.98851,0.15501]},"properties":{"population":515329,"capital":4,"place":"city","name":"32F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.27799,-1.9012]},"properties":{"population":230258,"capital":4,"place":"city","name":"32Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.45308,13.36052]},"properties":{"population":660258,"capital":4,"place":"city","name":"32G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.2008,12.79369]},"properties":{"population":542495,"capital":4,"place":"city","name":"32G07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.65004,12.50971]},"properties":{"population":451085,"capital":4,"place":"city","name":"32G08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.39568,12.87023]},"properties":{"population":520400,"capital":4,"place":"city","name":"32G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.56029,13.07681]},"properties":{"population":644888,"capital":4,"place":"city","name":"32G13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.47978,13.89304]},"properties":{"population":781035,"capital":4,"place":"city","name":"32G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.22955,12.89705]},"properties":{"population":653378,"capital":4,"place":"city","name":"32G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.94528,13.70705]},"properties":{"population":577765,"capital":4,"place":"city","name":"32G34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.70325,13.02647]},"properties":{"population":581711,"capital":4,"place":"city","name":"32G81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.57466,13.03527]},"properties":{"population":460517,"capital":4,"place":"city","name":"32G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.04479,12.68263]},"properties":{"population":349650,"capital":4,"place":"city","name":"32Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.87382,13.42244]},"properties":{"population":714991,"capital":4,"place":"city","name":"32H02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.05833,18.48867]},"properties":{"population":504985,"capital":4,"place":"city","name":"32H04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.15186,22.12343]},"properties":{"population":321887,"capital":4,"place":"city","name":"32H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.91465,21.07785]},"properties":{"population":299573,"capital":4,"place":"city","name":"32H12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.38515,22.48239]},"properties":{"population":109861,"capital":4,"place":"city","name":"32H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.33604,15.56687]},"properties":{"population":579301,"capital":4,"place":"city","name":"32H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.83663,14.71153]},"properties":{"population":654965,"capital":4,"place":"city","name":"32H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.98498,15.29511]},"properties":{"population":604025,"capital":4,"place":"city","name":"32H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.259,17.03772]},"properties":{"population":474493,"capital":4,"place":"city","name":"32H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.72153,13.35638]},"properties":{"population":656385,"capital":4,"place":"city","name":"32H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.62209,-1.02788]},"properties":{"population":663463,"capital":4,"place":"city","name":"32H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.46246,22.2782]},"properties":{"population":207944,"capital":4,"place":"city","name":"32Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.19,14.9391]},"properties":{"population":536597,"capital":4,"place":"city","name":"32J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.37425,19.46853]},"properties":{"population":417438,"capital":4,"place":"city","name":"32J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.01726,12.42009]},"properties":{"population":647389,"capital":4,"place":"city","name":"32J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.05769,16.27977]},"properties":{"population":510594,"capital":4,"place":"city","name":"32J17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.16417,16.64583]},"properties":{"population":507517,"capital":4,"place":"city","name":"32J18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.51484,13.90781]},"properties":{"population":650578,"capital":4,"place":"city","name":"32J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.85173,1.23096]},"properties":{"population":626530,"capital":4,"place":"city","name":"32J27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.3787,17.57628]},"properties":{"population":551745,"capital":4,"place":"city","name":"32J81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.03375,14.8791]},"properties":{"population":578382,"capital":4,"place":"city","name":"32J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.08661,21.4511]},"properties":{"population":194591,"capital":4,"place":"city","name":"32Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.56169,5.92484]},"properties":{"population":560947,"capital":4,"place":"city","name":"32K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.04084,6.74129]},"properties":{"population":230258,"capital":4,"place":"city","name":"32K07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.94892,6.44766]},"properties":{"population":304452,"capital":4,"place":"city","name":"32K12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.44033,5.78287]},"properties":{"population":476217,"capital":4,"place":"city","name":"32K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.14,6.27537]},"properties":{"population":469134,"capital":4,"place":"city","name":"32K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.29771,-4.93925]},"properties":{"population":710906,"capital":4,"place":"city","name":"32L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.10407,21.91709]},"properties":{"population":69314,"capital":4,"place":"city","name":"32L07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.70042,13.09091]},"properties":{"population":682546,"capital":4,"place":"city","name":"32L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.57573,19.26435]},"properties":{"population":371357,"capital":4,"place":"city","name":"32L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.37621,13.60984]},"properties":{"population":607993,"capital":4,"place":"city","name":"32L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.35033,13.34684]},"properties":{"population":608677,"capital":4,"place":"city","name":"32L25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.04558,22.02939]},"properties":{"population":69314,"capital":4,"place":"city","name":"32L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.12033,15.80889]},"properties":{"population":449980,"capital":4,"place":"city","name":"32L81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.14375,13.28945]},"properties":{"population":493447,"capital":4,"place":"city","name":"32L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.06965,21.87278]},"properties":{"population":248490,"capital":4,"place":"city","name":"32Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.19228,1.55925]},"properties":{"population":715851,"capital":4,"place":"city","name":"32M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.31622,13.46788]},"properties":{"population":664378,"capital":4,"place":"city","name":"32M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.36114,19.83806]},"properties":{"population":406044,"capital":4,"place":"city","name":"32M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.20021,10.34034]},"properties":{"population":721007,"capital":4,"place":"city","name":"32M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.38015,20.28686]},"properties":{"population":472738,"capital":4,"place":"city","name":"32M17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.26565,14.64874]},"properties":{"population":550125,"capital":4,"place":"city","name":"32M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.89857,14.05204]},"properties":{"population":528826,"capital":4,"place":"city","name":"32M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.22984,21.72151]},"properties":{"population":179175,"capital":4,"place":"city","name":"32Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.15512,11.57691]},"properties":{"population":468213,"capital":4,"place":"city","name":"32N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.28211,11.45273]},"properties":{"population":533753,"capital":4,"place":"city","name":"32N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.53429,10.27835]},"properties":{"population":506259,"capital":4,"place":"city","name":"32N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.10054,11.57239]},"properties":{"population":409434,"capital":4,"place":"city","name":"32N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.24364,11.87921]},"properties":{"population":179175,"capital":4,"place":"city","name":"32Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.13529,-5.36617]},"properties":{"population":600141,"capital":4,"place":"city","name":"32P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.75751,22.26378]},"properties":{"population":109861,"capital":4,"place":"city","name":"32P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.79951,22.2472]},"properties":{"population":69314,"capital":4,"place":"city","name":"32Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.42751,14.19724]},"properties":{"population":352636,"capital":4,"place":"city","name":"32Q02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27444,14.42855]},"properties":{"population":435670,"capital":4,"place":"city","name":"32Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.32938,14.14352]},"properties":{"population":398898,"capital":4,"place":"city","name":"32Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.40001,20.86473]},"properties":{"population":715929,"capital":4,"place":"city","name":"32Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.69553,14.49383]},"properties":{"population":647697,"capital":4,"place":"city","name":"32Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.78276,14.64182]},"properties":{"population":646146,"capital":4,"place":"city","name":"32Q25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.09672,14.2233]},"properties":{"population":441884,"capital":4,"place":"city","name":"32Q26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.47343,16.25373]},"properties":{"population":536597,"capital":4,"place":"city","name":"32Q28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.99209,14.50109]},"properties":{"population":444265,"capital":4,"place":"city","name":"32Q30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.35334,14.27205]},"properties":{"population":343398,"capital":4,"place":"city","name":"32Q35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.35493,14.25326]},"properties":{"population":452178,"capital":4,"place":"city","name":"32Q40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.97139,14.43366]},"properties":{"population":677078,"capital":4,"place":"city","name":"32Q45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.09727,14.36799]},"properties":{"population":511799,"capital":4,"place":"city","name":"32Q55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.71787,14.3505]},"properties":{"population":239789,"capital":4,"place":"city","name":"32Q56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.12992,14.10058]},"properties":{"population":435670,"capital":4,"place":"city","name":"32Q57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.40532,16.81297]},"properties":{"population":577455,"capital":4,"place":"city","name":"32Q60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.46181,16.27527]},"properties":{"population":547646,"capital":4,"place":"city","name":"32Q65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.18474,14.78902]},"properties":{"population":683625,"capital":4,"place":"city","name":"32Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.25842,14.38228]},"properties":{"population":358351,"capital":4,"place":"city","name":"32Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.33619,13.51486]},"properties":{"population":723417,"capital":4,"place":"city","name":"32S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.26037,11.89281]},"properties":{"population":501063,"capital":4,"place":"city","name":"32S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.24569,11.79468]},"properties":{"population":565948,"capital":4,"place":"city","name":"32S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.0969,12.27688]},"properties":{"population":556452,"capital":4,"place":"city","name":"32S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.332,12.07133]},"properties":{"population":602827,"capital":4,"place":"city","name":"32S22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.32633,13.35487]},"properties":{"population":675343,"capital":4,"place":"city","name":"32S25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.01041,13.03697]},"properties":{"population":649526,"capital":4,"place":"city","name":"32S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.12751,12.21522]},"properties":{"population":554517,"capital":4,"place":"city","name":"32S35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.9538,14.24914]},"properties":{"population":639191,"capital":4,"place":"city","name":"32S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.31124,12.53487]},"properties":{"population":644571,"capital":4,"place":"city","name":"32S45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.20021,13.02081]},"properties":{"population":584064,"capital":4,"place":"city","name":"32S50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.17886,12.76447]},"properties":{"population":614418,"capital":4,"place":"city","name":"32S55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.11723,13.29143]},"properties":{"population":613339,"capital":4,"place":"city","name":"32S60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.43516,13.93313]},"properties":{"population":672142,"capital":4,"place":"city","name":"32S65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.41245,11.96775]},"properties":{"population":397029,"capital":4,"place":"city","name":"32S70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.37555,11.9904]},"properties":{"population":445434,"capital":4,"place":"city","name":"32S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.08681,13.11675]},"properties":{"population":655819,"capital":4,"place":"city","name":"32Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.19607,19.87495]},"properties":{"population":431748,"capital":4,"place":"city","name":"32T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.36086,15.21773]},"properties":{"population":548893,"capital":4,"place":"city","name":"32T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.56769,20.44326]},"properties":{"population":294443,"capital":4,"place":"city","name":"32T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.20378,18.55103]},"properties":{"population":510594,"capital":4,"place":"city","name":"32T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.41939,18.67007]},"properties":{"population":407753,"capital":4,"place":"city","name":"32T27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.10404,20.09536]},"properties":{"population":368887,"capital":4,"place":"city","name":"32T35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.2532,20.22592]},"properties":{"population":378418,"capital":4,"place":"city","name":"32T40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.20947,1.80277]},"properties":{"population":727378,"capital":4,"place":"city","name":"32T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.07558,21.85004]},"properties":{"population":207944,"capital":4,"place":"city","name":"32Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.01855,3.96341]},"properties":{"population":723993,"capital":4,"place":"city","name":"32U05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.72327,1.43769]},"properties":{"population":431748,"capital":4,"place":"city","name":"32U10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.23325,1.68368]},"properties":{"population":546383,"capital":4,"place":"city","name":"32U15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.28982,-0.01392]},"properties":{"population":458496,"capital":4,"place":"city","name":"32U20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.10487,-0.5295]},"properties":{"population":448863,"capital":4,"place":"city","name":"32U25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.91821,-0.59065]},"properties":{"population":283321,"capital":4,"place":"city","name":"32U30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.98691,2.11126]},"properties":{"population":537063,"capital":4,"place":"city","name":"32U35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.2993,-0.28207]},"properties":{"population":563121,"capital":4,"place":"city","name":"32U40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.27443,-0.24702]},"properties":{"population":294443,"capital":4,"place":"city","name":"32U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.91959,-0.70199]},"properties":{"population":248490,"capital":4,"place":"city","name":"32Uxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.91799,-4.10755]},"properties":{"population":645519,"capital":4,"place":"city","name":"32V05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.28495,17.04624]},"properties":{"population":488280,"capital":4,"place":"city","name":"32V10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.36262,17.56359]},"properties":{"population":486753,"capital":4,"place":"city","name":"32V15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.13124,12.1173]},"properties":{"population":590808,"capital":4,"place":"city","name":"32V20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.4851,16.91485]},"properties":{"population":533271,"capital":4,"place":"city","name":"32V25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.55093,15.28622]},"properties":{"population":543807,"capital":4,"place":"city","name":"32V30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.07276,19.69817]},"properties":{"population":452178,"capital":4,"place":"city","name":"32V35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.79483,-3.89858]},"properties":{"population":703966,"capital":4,"place":"city","name":"32V40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.57862,13.55337]},"properties":{"population":559098,"capital":4,"place":"city","name":"32V99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.0652,22.08522]},"properties":{"population":256494,"capital":4,"place":"city","name":"32Vxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.55535,-5.07962]},"properties":{"population":712447,"capital":4,"place":"city","name":"32W05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.80552,17.22403]},"properties":{"population":490527,"capital":4,"place":"city","name":"32W10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.28741,12.88165]},"properties":{"population":638519,"capital":4,"place":"city","name":"32W20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.10232,16.89701]},"properties":{"population":373766,"capital":4,"place":"city","name":"32W25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.64578,16.88764]},"properties":{"population":385014,"capital":4,"place":"city","name":"32W30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.1153,12.35504]},"properties":{"population":516478,"capital":4,"place":"city","name":"32W50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.40502,20.57288]},"properties":{"population":368887,"capital":4,"place":"city","name":"32W99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.59173,21.41163]},"properties":{"population":194591,"capital":4,"place":"city","name":"32Wxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.59025,2.3649]},"properties":{"population":407753,"capital":4,"place":"city","name":"33-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.74267,2.94283]},"properties":{"population":557594,"capital":4,"place":"city","name":"33-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.4401,3.24195]},"properties":{"population":566642,"capital":4,"place":"city","name":"33-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.94413,2.30428]},"properties":{"population":505624,"capital":4,"place":"city","name":"33-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.77631,2.82824]},"properties":{"population":530330,"capital":4,"place":"city","name":"33-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.70008,4.71839]},"properties":{"population":528320,"capital":4,"place":"city","name":"33-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.07029,2.41589]},"properties":{"population":553338,"capital":4,"place":"city","name":"33-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.69598,7.89502]},"properties":{"population":727378,"capital":4,"place":"city","name":"33B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.99261,5.64011]},"properties":{"population":790285,"capital":4,"place":"city","name":"33B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.05052,11.6497]},"properties":{"population":638519,"capital":4,"place":"city","name":"33B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.80706,-4.97968]},"properties":{"population":554517,"capital":4,"place":"city","name":"33B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.88798,15.70356]},"properties":{"population":526269,"capital":4,"place":"city","name":"33B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.33827,21.99827]},"properties":{"population":277258,"capital":4,"place":"city","name":"33Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.94941,-4.14532]},"properties":{"population":817018,"capital":4,"place":"city","name":"33C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.59812,17.42596]},"properties":{"population":816451,"capital":4,"place":"city","name":"33C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.39738,6.35233]},"properties":{"population":669456,"capital":4,"place":"city","name":"33C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.0238,8.63387]},"properties":{"population":776259,"capital":4,"place":"city","name":"33C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.69948,-5.23263]},"properties":{"population":919421,"capital":4,"place":"city","name":"33C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.94556,7.60787]},"properties":{"population":675693,"capital":4,"place":"city","name":"33C47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.29269,6.99377]},"properties":{"population":657507,"capital":4,"place":"city","name":"33C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.16554,12.13532]},"properties":{"population":545958,"capital":4,"place":"city","name":"33C52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.67352,3.36015]},"properties":{"population":736010,"capital":4,"place":"city","name":"33C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.6984,-0.06729]},"properties":{"population":793379,"capital":4,"place":"city","name":"33C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.7995,-0.64381]},"properties":{"population":698100,"capital":4,"place":"city","name":"33C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.46301,14.36088]},"properties":{"population":508759,"capital":4,"place":"city","name":"33C67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.96365,11.55836]},"properties":{"population":695177,"capital":4,"place":"city","name":"33C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.22184,13.13682]},"properties":{"population":548063,"capital":4,"place":"city","name":"33C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.08611,-4.86405]},"properties":{"population":730114,"capital":4,"place":"city","name":"33C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.22471,-7.37974]},"properties":{"population":686901,"capital":4,"place":"city","name":"33C90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.01595,10.87409]},"properties":{"population":595842,"capital":4,"place":"city","name":"33C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.46698,18.93275]},"properties":{"population":449980,"capital":4,"place":"city","name":"33Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.91912,17.46577]},"properties":{"population":622455,"capital":4,"place":"city","name":"33D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.08641,15.83837]},"properties":{"population":418965,"capital":4,"place":"city","name":"33D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.03837,19.06002]},"properties":{"population":759839,"capital":4,"place":"city","name":"33D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.97156,16.05879]},"properties":{"population":482028,"capital":4,"place":"city","name":"33D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.99551,20.72042]},"properties":{"population":697914,"capital":4,"place":"city","name":"33D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.85806,16.3025]},"properties":{"population":436944,"capital":4,"place":"city","name":"33D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.2273,17.12457]},"properties":{"population":570711,"capital":4,"place":"city","name":"33D52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.18221,16.16681]},"properties":{"population":349650,"capital":4,"place":"city","name":"33D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.85299,16.38812]},"properties":{"population":534233,"capital":4,"place":"city","name":"33D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.93345,15.67246]},"properties":{"population":436944,"capital":4,"place":"city","name":"33D65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.99533,15.79651]},"properties":{"population":489783,"capital":4,"place":"city","name":"33D67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.82289,16.68552]},"properties":{"population":536597,"capital":4,"place":"city","name":"33D70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.69883,16.66715]},"properties":{"population":652062,"capital":4,"place":"city","name":"33D80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.50407,16.89873]},"properties":{"population":537063,"capital":4,"place":"city","name":"33D90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.38507,17.16144]},"properties":{"population":496981,"capital":4,"place":"city","name":"33D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.09392,15.72334]},"properties":{"population":299573,"capital":4,"place":"city","name":"33Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.10236,1.51797]},"properties":{"population":787966,"capital":4,"place":"city","name":"33E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.29345,7.72653]},"properties":{"population":603308,"capital":4,"place":"city","name":"33E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.24609,0.40125]},"properties":{"population":671174,"capital":4,"place":"city","name":"33E12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.07164,11.59255]},"properties":{"population":480402,"capital":4,"place":"city","name":"33E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.05102,12.74799]},"properties":{"population":591889,"capital":4,"place":"city","name":"33E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.80397,9.4712]},"properties":{"population":690475,"capital":4,"place":"city","name":"33E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.99929,8.89047]},"properties":{"population":617170,"capital":4,"place":"city","name":"33E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.34745,18.36998]},"properties":{"population":495582,"capital":4,"place":"city","name":"33E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.0128,7.02925]},"properties":{"population":684374,"capital":4,"place":"city","name":"33E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.79248,22.3504]},"properties":{"population":289037,"capital":4,"place":"city","name":"33Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.55695,17.06529]},"properties":{"population":652502,"capital":4,"place":"city","name":"33F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.8984,12.71445]},"properties":{"population":607304,"capital":4,"place":"city","name":"33F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.35905,7.8592]},"properties":{"population":361091,"capital":4,"place":"city","name":"33F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.37148,7.36208]},"properties":{"population":219722,"capital":4,"place":"city","name":"33Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.64916,19.76036]},"properties":{"population":382864,"capital":4,"place":"city","name":"34-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.51971,-11.45231]},"properties":{"population":740062,"capital":4,"place":"city","name":"34-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.61926,-11.99815]},"properties":{"population":720785,"capital":4,"place":"city","name":"34-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.92269,13.55272]},"properties":{"population":581413,"capital":4,"place":"city","name":"34-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.19719,5.79606]},"properties":{"population":639024,"capital":4,"place":"city","name":"34-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.83349,11.49827]},"properties":{"population":720934,"capital":4,"place":"city","name":"34-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.92753,17.92458]},"properties":{"population":755118,"capital":4,"place":"city","name":"34-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.91354,-7.60689]},"properties":{"population":823137,"capital":4,"place":"city","name":"34A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.13825,15.66913]},"properties":{"population":382864,"capital":4,"place":"city","name":"34A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.42093,11.22954]},"properties":{"population":636818,"capital":4,"place":"city","name":"34A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.42884,10.16617]},"properties":{"population":886191,"capital":4,"place":"city","name":"34A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.26082,-13.01017]},"properties":{"population":757967,"capital":4,"place":"city","name":"34A09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.061,-12.42955]},"properties":{"population":868355,"capital":4,"place":"city","name":"34A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.31886,20.71603]},"properties":{"population":230258,"capital":4,"place":"city","name":"34A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.98823,7.2655]},"properties":{"population":825997,"capital":4,"place":"city","name":"34A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.99147,-3.5312]},"properties":{"population":694215,"capital":4,"place":"city","name":"34A26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58659,-13.33018]},"properties":{"population":918132,"capital":4,"place":"city","name":"34A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.61788,9.21447]},"properties":{"population":585793,"capital":4,"place":"city","name":"34A33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.84429,-13.1969]},"properties":{"population":935539,"capital":4,"place":"city","name":"34A34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.82216,5.60116]},"properties":{"population":619644,"capital":4,"place":"city","name":"34A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.35346,2.94317]},"properties":{"population":723489,"capital":4,"place":"city","name":"34A36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.6466,-7.32439]},"properties":{"population":813856,"capital":4,"place":"city","name":"34A37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.59583,11.59193]},"properties":{"population":515905,"capital":4,"place":"city","name":"34A38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.02661,-7.97025]},"properties":{"population":714124,"capital":4,"place":"city","name":"34A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.11182,-13.01831]},"properties":{"population":823932,"capital":4,"place":"city","name":"34A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.64496,20.66029]},"properties":{"population":69314,"capital":4,"place":"city","name":"34A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.25665,-13.63213]},"properties":{"population":786595,"capital":4,"place":"city","name":"34A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.87987,-7.67693]},"properties":{"population":818339,"capital":4,"place":"city","name":"34A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.84958,-6.48693]},"properties":{"population":726542,"capital":4,"place":"city","name":"34A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.67531,17.93349]},"properties":{"population":515329,"capital":4,"place":"city","name":"34Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.92567,-7.63027]},"properties":{"population":845425,"capital":4,"place":"city","name":"34B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.8251,11.46053]},"properties":{"population":515905,"capital":4,"place":"city","name":"34B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.76791,10.00717]},"properties":{"population":643294,"capital":4,"place":"city","name":"34B08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.8203,7.35304]},"properties":{"population":709589,"capital":4,"place":"city","name":"34B09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.05493,0.00029]},"properties":{"population":858932,"capital":4,"place":"city","name":"34B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.55843,-13.42696]},"properties":{"population":953546,"capital":4,"place":"city","name":"34B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.48251,4.32163]},"properties":{"population":776217,"capital":4,"place":"city","name":"34B16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.69818,6.62058]},"properties":{"population":851418,"capital":4,"place":"city","name":"34B18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.82521,-7.60969]},"properties":{"population":674523,"capital":4,"place":"city","name":"34B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.20076,4.94703]},"properties":{"population":823164,"capital":4,"place":"city","name":"34B24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.69898,4.22985]},"properties":{"population":748493,"capital":4,"place":"city","name":"34B27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.48255,-6.7042]},"properties":{"population":726122,"capital":4,"place":"city","name":"34B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.9656,11.22009]},"properties":{"population":683840,"capital":4,"place":"city","name":"34B37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.45954,-3.54987]},"properties":{"population":706902,"capital":4,"place":"city","name":"34B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.08617,3.28806]},"properties":{"population":653524,"capital":4,"place":"city","name":"34B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.14044,9.00108]},"properties":{"population":540717,"capital":4,"place":"city","name":"34B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.6957,8.26268]},"properties":{"population":625766,"capital":4,"place":"city","name":"34B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.15846,11.75422]},"properties":{"population":476217,"capital":4,"place":"city","name":"34Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.75318,3.5405]},"properties":{"population":910664,"capital":4,"place":"city","name":"34C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.86624,10.37814]},"properties":{"population":740184,"capital":4,"place":"city","name":"34C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.89458,11.31836]},"properties":{"population":594279,"capital":4,"place":"city","name":"34C08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.84507,-12.52442]},"properties":{"population":887402,"capital":4,"place":"city","name":"34C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.49175,-12.77766]},"properties":{"population":815478,"capital":4,"place":"city","name":"34C11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.76465,11.89985]},"properties":{"population":530826,"capital":4,"place":"city","name":"34C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.46769,-0.54743]},"properties":{"population":747477,"capital":4,"place":"city","name":"34C14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.56232,-12.78166]},"properties":{"population":872209,"capital":4,"place":"city","name":"34C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.08819,-12.53795]},"properties":{"population":815277,"capital":4,"place":"city","name":"34C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.35817,11.21107]},"properties":{"population":896239,"capital":4,"place":"city","name":"34C23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.84947,-7.33788]},"properties":{"population":946024,"capital":4,"place":"city","name":"34C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.15425,11.07946]},"properties":{"population":561312,"capital":4,"place":"city","name":"34C26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.05982,-13.26665]},"properties":{"population":751752,"capital":4,"place":"city","name":"34C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.16367,-5.00191]},"properties":{"population":808332,"capital":4,"place":"city","name":"34C28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.59596,2.99549]},"properties":{"population":745645,"capital":4,"place":"city","name":"34C29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.23725,-12.87679]},"properties":{"population":717242,"capital":4,"place":"city","name":"34C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.59053,17.58311]},"properties":{"population":179175,"capital":4,"place":"city","name":"34C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.24992,4.35954]},"properties":{"population":789580,"capital":4,"place":"city","name":"34C37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.63743,-6.46798]},"properties":{"population":654247,"capital":4,"place":"city","name":"34C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.97288,11.03498]},"properties":{"population":566296,"capital":4,"place":"city","name":"34C41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.77402,5.78896]},"properties":{"population":702375,"capital":4,"place":"city","name":"34C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.13109,11.19467]},"properties":{"population":477068,"capital":4,"place":"city","name":"34C46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.74768,9.68384]},"properties":{"population":557594,"capital":4,"place":"city","name":"34C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.07947,4.71437]},"properties":{"population":875147,"capital":4,"place":"city","name":"34C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.46725,0.47554]},"properties":{"population":682762,"capital":4,"place":"city","name":"34C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.84988,11.83151]},"properties":{"population":552545,"capital":4,"place":"city","name":"34Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.67143,-12.83551]},"properties":{"population":884332,"capital":4,"place":"city","name":"34D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.59944,5.65006]},"properties":{"population":796067,"capital":4,"place":"city","name":"34D06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.94636,-1.38811]},"properties":{"population":713329,"capital":4,"place":"city","name":"34D08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.39111,9.25768]},"properties":{"population":648157,"capital":4,"place":"city","name":"34D09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.53794,-12.88686]},"properties":{"population":773455,"capital":4,"place":"city","name":"34D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.84855,-11.94962]},"properties":{"population":686275,"capital":4,"place":"city","name":"34D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.70102,-7.22943]},"properties":{"population":936314,"capital":4,"place":"city","name":"34D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.32439,5.81223]},"properties":{"population":798002,"capital":4,"place":"city","name":"34D23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.86811,15.43704]},"properties":{"population":109861,"capital":4,"place":"city","name":"34D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.01981,-3.4459]},"properties":{"population":689871,"capital":4,"place":"city","name":"34D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.05662,8.02828]},"properties":{"population":605912,"capital":4,"place":"city","name":"34D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.20679,11.91399]},"properties":{"population":551745,"capital":4,"place":"city","name":"34D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.80466,-5.32921]},"properties":{"population":728550,"capital":4,"place":"city","name":"34D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.78705,-7.07774]},"properties":{"population":708924,"capital":4,"place":"city","name":"34D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.75924,11.19047]},"properties":{"population":527299,"capital":4,"place":"city","name":"34Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.90975,-6.95098]},"properties":{"population":816451,"capital":4,"place":"city","name":"34E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.89361,4.72078]},"properties":{"population":752994,"capital":4,"place":"city","name":"34E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29951,-6.13528]},"properties":{"population":676272,"capital":4,"place":"city","name":"34E13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.97197,-13.13709]},"properties":{"population":840737,"capital":4,"place":"city","name":"34E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.37125,11.82717]},"properties":{"population":512989,"capital":4,"place":"city","name":"34E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.80648,13.05352]},"properties":{"population":385014,"capital":4,"place":"city","name":"34E18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.62841,-11.99572]},"properties":{"population":728687,"capital":4,"place":"city","name":"34E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.21673,8.13211]},"properties":{"population":596100,"capital":4,"place":"city","name":"34E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.39732,14.65272]},"properties":{"population":395124,"capital":4,"place":"city","name":"34Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.4017,-12.1398]},"properties":{"population":829978,"capital":4,"place":"city","name":"34F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.6715,14.81814]},"properties":{"population":424849,"capital":4,"place":"city","name":"34F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.89795,11.37638]},"properties":{"population":490527,"capital":4,"place":"city","name":"34F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.62596,17.7328]},"properties":{"population":109861,"capital":4,"place":"city","name":"34F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.14829,18.02369]},"properties":{"population":239789,"capital":4,"place":"city","name":"34Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.57315,15.64803]},"properties":{"population":831605,"capital":4,"place":"city","name":"34G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.63739,-12.0761]},"properties":{"population":844891,"capital":4,"place":"city","name":"34G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.51898,4.83272]},"properties":{"population":693244,"capital":4,"place":"city","name":"34G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.84096,0.22796]},"properties":{"population":730787,"capital":4,"place":"city","name":"34G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.20409,12.25516]},"properties":{"population":333220,"capital":4,"place":"city","name":"34Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.29688,24.7851]},"properties":{"population":811552,"capital":4,"place":"city","name":"34H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.88887,23.7035]},"properties":{"population":746851,"capital":4,"place":"city","name":"34H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.47195,24.1068]},"properties":{"population":540717,"capital":4,"place":"city","name":"34H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.50025,23.99764]},"properties":{"population":480402,"capital":4,"place":"city","name":"34H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.48046,24.44536]},"properties":{"population":219722,"capital":4,"place":"city","name":"34H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.7779,24.09857]},"properties":{"population":299573,"capital":4,"place":"city","name":"34Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.96573,16.61821]},"properties":{"population":138629,"capital":4,"place":"city","name":"34K04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29128,-11.90191]},"properties":{"population":811880,"capital":4,"place":"city","name":"34K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.49067,6.88052]},"properties":{"population":731654,"capital":4,"place":"city","name":"34K06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.55384,11.15216]},"properties":{"population":618620,"capital":4,"place":"city","name":"34K07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.10542,11.83699]},"properties":{"population":513579,"capital":4,"place":"city","name":"34K08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.52547,11.93633]},"properties":{"population":565599,"capital":4,"place":"city","name":"34K09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.89229,-11.82152]},"properties":{"population":779358,"capital":4,"place":"city","name":"34K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.49485,10.92594]},"properties":{"population":792840,"capital":4,"place":"city","name":"34K11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.81863,11.59087]},"properties":{"population":679009,"capital":4,"place":"city","name":"34K12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.08556,5.3824]},"properties":{"population":828425,"capital":4,"place":"city","name":"34K13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.94921,10.92078]},"properties":{"population":676272,"capital":4,"place":"city","name":"34K14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.26437,16.40087]},"properties":{"population":109861,"capital":4,"place":"city","name":"34K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.89557,15.10097]},"properties":{"population":256494,"capital":4,"place":"city","name":"34K16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.00283,11.61958]},"properties":{"population":619440,"capital":4,"place":"city","name":"34K17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.13506,6.50259]},"properties":{"population":760040,"capital":4,"place":"city","name":"34K18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.70461,11.31689]},"properties":{"population":612686,"capital":4,"place":"city","name":"34K19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.72191,-11.2237]},"properties":{"population":917781,"capital":4,"place":"city","name":"34K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.91993,11.21521]},"properties":{"population":576832,"capital":4,"place":"city","name":"34K21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.79687,11.649]},"properties":{"population":576519,"capital":4,"place":"city","name":"34K23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.82782,15.24222]},"properties":{"population":391202,"capital":4,"place":"city","name":"34K24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.96897,5.54544]},"properties":{"population":806211,"capital":4,"place":"city","name":"34K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.50675,11.96058]},"properties":{"population":594803,"capital":4,"place":"city","name":"34K26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.40853,11.46475]},"properties":{"population":519295,"capital":4,"place":"city","name":"34K27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.50713,5.50145]},"properties":{"population":698841,"capital":4,"place":"city","name":"34K28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.89558,11.29889]},"properties":{"population":495582,"capital":4,"place":"city","name":"34K29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.11137,-5.09459]},"properties":{"population":775876,"capital":4,"place":"city","name":"34K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.08057,13.30747]},"properties":{"population":482028,"capital":4,"place":"city","name":"34K31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.51812,14.17342]},"properties":{"population":461512,"capital":4,"place":"city","name":"34K32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.90007,13.2136]},"properties":{"population":438202,"capital":4,"place":"city","name":"34K33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.84823,12.91389]},"properties":{"population":418965,"capital":4,"place":"city","name":"34K34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.11721,-1.67466]},"properties":{"population":791315,"capital":4,"place":"city","name":"34K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.55909,14.19909]},"properties":{"population":514166,"capital":4,"place":"city","name":"34K36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.27256,5.24085]},"properties":{"population":711801,"capital":4,"place":"city","name":"34K37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.19708,14.16682]},"properties":{"population":489034,"capital":4,"place":"city","name":"34K38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.19432,15.62525]},"properties":{"population":309104,"capital":4,"place":"city","name":"34K39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.44512,5.75435]},"properties":{"population":834806,"capital":4,"place":"city","name":"34K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.09295,16.40069]},"properties":{"population":239789,"capital":4,"place":"city","name":"34K41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.87229,16.28456]},"properties":{"population":333220,"capital":4,"place":"city","name":"34K42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.04455,15.19172]},"properties":{"population":336729,"capital":4,"place":"city","name":"34K43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.76675,10.13324]},"properties":{"population":767461,"capital":4,"place":"city","name":"34K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.12824,5.03689]},"properties":{"population":745587,"capital":4,"place":"city","name":"34K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.684,4.82684]},"properties":{"population":806808,"capital":4,"place":"city","name":"34K60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.69829,-12.01675]},"properties":{"population":813388,"capital":4,"place":"city","name":"34K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.06059,13.345]},"properties":{"population":488280,"capital":4,"place":"city","name":"34Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.59758,-13.31795]},"properties":{"population":773105,"capital":4,"place":"city","name":"34L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.24664,-11.50499]},"properties":{"population":732778,"capital":4,"place":"city","name":"34L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.06743,-4.95214]},"properties":{"population":751152,"capital":4,"place":"city","name":"34L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.98286,5.50095]},"properties":{"population":623832,"capital":4,"place":"city","name":"34L16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.09943,-12.35076]},"properties":{"population":698286,"capital":4,"place":"city","name":"34L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.82838,4.06794]},"properties":{"population":663200,"capital":4,"place":"city","name":"34L25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.92209,6.29278]},"properties":{"population":600388,"capital":4,"place":"city","name":"34L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.99413,-6.90408]},"properties":{"population":821039,"capital":4,"place":"city","name":"34L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.28722,5.11179]},"properties":{"population":822067,"capital":4,"place":"city","name":"34L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.0812,13.96049]},"properties":{"population":389182,"capital":4,"place":"city","name":"34Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.51869,12.71356]},"properties":{"population":623636,"capital":4,"place":"city","name":"34M03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.19751,15.74318]},"properties":{"population":294443,"capital":4,"place":"city","name":"34M04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.5315,11.6083]},"properties":{"population":680461,"capital":4,"place":"city","name":"34M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.89201,13.61698]},"properties":{"population":660934,"capital":4,"place":"city","name":"34M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.50793,13.00735]},"properties":{"population":578996,"capital":4,"place":"city","name":"34M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.10514,15.22337]},"properties":{"population":283321,"capital":4,"place":"city","name":"34M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.39128,12.27207]},"properties":{"population":552545,"capital":4,"place":"city","name":"34M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.07118,12.03437]},"properties":{"population":534233,"capital":4,"place":"city","name":"34M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.74627,10.14581]},"properties":{"population":686171,"capital":4,"place":"city","name":"34M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.45849,14.32948]},"properties":{"population":329583,"capital":4,"place":"city","name":"34M37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.55213,10.93571]},"properties":{"population":573009,"capital":4,"place":"city","name":"34M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.49036,14.02646]},"properties":{"population":473619,"capital":4,"place":"city","name":"34M45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.06966,15.96858]},"properties":{"population":69314,"capital":4,"place":"city","name":"34M46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.14458,11.96941]},"properties":{"population":550938,"capital":4,"place":"city","name":"34M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.64432,6.55456]},"properties":{"population":750604,"capital":4,"place":"city","name":"34M55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.7317,11.06283]},"properties":{"population":516478,"capital":4,"place":"city","name":"34M56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.91957,11.36404]},"properties":{"population":551342,"capital":4,"place":"city","name":"34M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.25092,15.97975]},"properties":{"population":69314,"capital":4,"place":"city","name":"34M65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.46172,-12.25812]},"properties":{"population":752940,"capital":4,"place":"city","name":"34M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.23971,14.29563]},"properties":{"population":368887,"capital":4,"place":"city","name":"34Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.63694,-6.48619]},"properties":{"population":744132,"capital":4,"place":"city","name":"34N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.50738,-0.62642]},"properties":{"population":194591,"capital":4,"place":"city","name":"34N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.64861,-0.3583]},"properties":{"population":138629,"capital":4,"place":"city","name":"34Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.6856,25.87582]},"properties":{"population":417438,"capital":4,"place":"city","name":"35-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.11953,26.08766]},"properties":{"population":699393,"capital":4,"place":"city","name":"35-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.87438,26.09937]},"properties":{"population":795191,"capital":4,"place":"city","name":"35-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.96342,26.06366]},"properties":{"population":597635,"capital":4,"place":"city","name":"35-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.29201,26.04161]},"properties":{"population":650578,"capital":4,"place":"city","name":"35-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.06953,26.14562]},"properties":{"population":804910,"capital":4,"place":"city","name":"35-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.88125,26.09141]},"properties":{"population":798854,"capital":4,"place":"city","name":"35-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.93032,19.22687]},"properties":{"population":875715,"capital":4,"place":"city","name":"35A01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.11959,18.88944]},"properties":{"population":806557,"capital":4,"place":"city","name":"35A02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.50081,-12.52463]},"properties":{"population":903574,"capital":4,"place":"city","name":"35A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.29505,10.17434]},"properties":{"population":711558,"capital":4,"place":"city","name":"35A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.24285,16.14633]},"properties":{"population":752240,"capital":4,"place":"city","name":"35A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.51788,-5.14705]},"properties":{"population":610924,"capital":4,"place":"city","name":"35A09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.93089,-4.52307]},"properties":{"population":642324,"capital":4,"place":"city","name":"35A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.26992,-15.48952]},"properties":{"population":831385,"capital":4,"place":"city","name":"35A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.17518,9.04712]},"properties":{"population":476217,"capital":4,"place":"city","name":"35A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.12238,8.36812]},"properties":{"population":445434,"capital":4,"place":"city","name":"35A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.95784,0.98919]},"properties":{"population":573334,"capital":4,"place":"city","name":"35A18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.42966,-12.8214]},"properties":{"population":775576,"capital":4,"place":"city","name":"35A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.02765,1.05132]},"properties":{"population":572031,"capital":4,"place":"city","name":"35A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.62668,18.16399]},"properties":{"population":792044,"capital":4,"place":"city","name":"35A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.6471,-15.59019]},"properties":{"population":658063,"capital":4,"place":"city","name":"35A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.1001,4.42332]},"properties":{"population":606378,"capital":4,"place":"city","name":"35A24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.42273,10.75989]},"properties":{"population":738025,"capital":4,"place":"city","name":"35A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.44697,-15.40124]},"properties":{"population":695654,"capital":4,"place":"city","name":"35A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.95801,19.0661]},"properties":{"population":846695,"capital":4,"place":"city","name":"35A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.06806,-14.74521]},"properties":{"population":837701,"capital":4,"place":"city","name":"35A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.99681,3.86907]},"properties":{"population":581711,"capital":4,"place":"city","name":"35A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.04594,11.39983]},"properties":{"population":452178,"capital":4,"place":"city","name":"35Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27038,-14.54365]},"properties":{"population":876639,"capital":4,"place":"city","name":"35B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.54905,9.14664]},"properties":{"population":738647,"capital":4,"place":"city","name":"35B06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.95852,3.95041]},"properties":{"population":568697,"capital":4,"place":"city","name":"35B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.84934,7.86573]},"properties":{"population":594017,"capital":4,"place":"city","name":"35B08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.53891,3.94236]},"properties":{"population":795962,"capital":4,"place":"city","name":"35B09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.0717,-14.79403]},"properties":{"population":846442,"capital":4,"place":"city","name":"35B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.21489,7.41256]},"properties":{"population":681454,"capital":4,"place":"city","name":"35B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.97872,19.19595]},"properties":{"population":772223,"capital":4,"place":"city","name":"35B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.84225,-14.73437]},"properties":{"population":877524,"capital":4,"place":"city","name":"35B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.82003,-15.71896]},"properties":{"population":832675,"capital":4,"place":"city","name":"35B27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.33753,-15.52066]},"properties":{"population":845829,"capital":4,"place":"city","name":"35B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.81283,-14.27497]},"properties":{"population":839231,"capital":4,"place":"city","name":"35B32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.21704,3.41228]},"properties":{"population":793737,"capital":4,"place":"city","name":"35B33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.49123,4.91941]},"properties":{"population":655535,"capital":4,"place":"city","name":"35B34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.29627,-14.46224]},"properties":{"population":924859,"capital":4,"place":"city","name":"35B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.02671,1.82594]},"properties":{"population":663200,"capital":4,"place":"city","name":"35B36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.57434,-14.17694]},"properties":{"population":735051,"capital":4,"place":"city","name":"35B37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.43748,-2.56172]},"properties":{"population":666695,"capital":4,"place":"city","name":"35B38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.77519,-14.67499]},"properties":{"population":1009542,"capital":4,"place":"city","name":"35B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.16748,7.85879]},"properties":{"population":810167,"capital":4,"place":"city","name":"35B41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.47697,8.68252]},"properties":{"population":523644,"capital":4,"place":"city","name":"35B42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.4234,15.93022]},"properties":{"population":845574,"capital":4,"place":"city","name":"35B44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.50481,-14.20061]},"properties":{"population":929541,"capital":4,"place":"city","name":"35B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.95027,-15.20312]},"properties":{"population":790728,"capital":4,"place":"city","name":"35B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.39219,3.26968]},"properties":{"population":636475,"capital":4,"place":"city","name":"35B51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.00929,2.45888]},"properties":{"population":636302,"capital":4,"place":"city","name":"35B53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.46508,6.6484]},"properties":{"population":713249,"capital":4,"place":"city","name":"35B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.48258,-14.3971]},"properties":{"population":928813,"capital":4,"place":"city","name":"35B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.97644,5.76325]},"properties":{"population":680793,"capital":4,"place":"city","name":"35B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.34357,10.4465]},"properties":{"population":495582,"capital":4,"place":"city","name":"35Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.38687,-15.23599]},"properties":{"population":849964,"capital":4,"place":"city","name":"35C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.17665,1.61825]},"properties":{"population":635088,"capital":4,"place":"city","name":"35C06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.71354,17.06163]},"properties":{"population":824433,"capital":4,"place":"city","name":"35C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.99892,13.6584]},"properties":{"population":843185,"capital":4,"place":"city","name":"35C08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.03605,11.07542]},"properties":{"population":439444,"capital":4,"place":"city","name":"35C09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.06618,-13.5185]},"properties":{"population":781802,"capital":4,"place":"city","name":"35C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.2222,8.93497]},"properties":{"population":469134,"capital":4,"place":"city","name":"35C11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.20221,-15.01863]},"properties":{"population":818924,"capital":4,"place":"city","name":"35C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.89336,-13.81368]},"properties":{"population":814002,"capital":4,"place":"city","name":"35C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.21479,1.80919]},"properties":{"population":625190,"capital":4,"place":"city","name":"35C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.42681,11.71353]},"properties":{"population":398898,"capital":4,"place":"city","name":"35Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.35161,-13.13251]},"properties":{"population":848135,"capital":4,"place":"city","name":"35D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.84817,-14.23889]},"properties":{"population":791899,"capital":4,"place":"city","name":"35D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.76399,18.78603]},"properties":{"population":851879,"capital":4,"place":"city","name":"35D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.5737,9.07325]},"properties":{"population":707749,"capital":4,"place":"city","name":"35D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.67626,3.19231]},"properties":{"population":697821,"capital":4,"place":"city","name":"35D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.07501,3.44565]},"properties":{"population":623441,"capital":4,"place":"city","name":"35D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.97807,7.17463]},"properties":{"population":471849,"capital":4,"place":"city","name":"35Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.03872,-13.81718]},"properties":{"population":629710,"capital":4,"place":"city","name":"35E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.72962,9.24746]},"properties":{"population":476217,"capital":4,"place":"city","name":"35E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.01274,0.29949]},"properties":{"population":591079,"capital":4,"place":"city","name":"35E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.65953,3.59109]},"properties":{"population":638181,"capital":4,"place":"city","name":"35E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.58414,7.19586]},"properties":{"population":551342,"capital":4,"place":"city","name":"35E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.45061,11.11296]},"properties":{"population":248490,"capital":4,"place":"city","name":"35Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.2528,25.47173]},"properties":{"population":719818,"capital":4,"place":"city","name":"35F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.03102,25.25005]},"properties":{"population":636990,"capital":4,"place":"city","name":"35F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.2262,25.26259]},"properties":{"population":652356,"capital":4,"place":"city","name":"35F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.2374,25.28944]},"properties":{"population":380666,"capital":4,"place":"city","name":"35F16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.04214,25.80635]},"properties":{"population":731188,"capital":4,"place":"city","name":"35F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.65332,25.84144]},"properties":{"population":674051,"capital":4,"place":"city","name":"35F21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.55778,25.30571]},"properties":{"population":688857,"capital":4,"place":"city","name":"35F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.92308,25.05117]},"properties":{"population":634913,"capital":4,"place":"city","name":"35F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.30732,25.1104]},"properties":{"population":430406,"capital":4,"place":"city","name":"35F31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.72502,25.1342]},"properties":{"population":446590,"capital":4,"place":"city","name":"35F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.17129,25.2432]},"properties":{"population":343398,"capital":4,"place":"city","name":"35F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.47672,25.13304]},"properties":{"population":376120,"capital":4,"place":"city","name":"35F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.31369,25.18374]},"properties":{"population":346573,"capital":4,"place":"city","name":"35F46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.825,25.29703]},"properties":{"population":469134,"capital":4,"place":"city","name":"35F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.51345,24.94897]},"properties":{"population":420469,"capital":4,"place":"city","name":"35F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.29729,25.23257]},"properties":{"population":343398,"capital":4,"place":"city","name":"35F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.6687,25.08077]},"properties":{"population":389182,"capital":4,"place":"city","name":"35F61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.72389,25.32052]},"properties":{"population":478749,"capital":4,"place":"city","name":"35F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.17759,25.30033]},"properties":{"population":391202,"capital":4,"place":"city","name":"35Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.25435,6.3659]},"properties":{"population":734083,"capital":4,"place":"city","name":"35G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.285,-13.32672]},"properties":{"population":792768,"capital":4,"place":"city","name":"35G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.19928,-11.3915]},"properties":{"population":727100,"capital":4,"place":"city","name":"35G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.68443,8.93723]},"properties":{"population":480402,"capital":4,"place":"city","name":"35G16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.58558,15.33405]},"properties":{"population":768753,"capital":4,"place":"city","name":"35G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.96626,-14.44284]},"properties":{"population":738461,"capital":4,"place":"city","name":"35G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.57634,5.57648]},"properties":{"population":684268,"capital":4,"place":"city","name":"35G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.45837,3.79702]},"properties":{"population":554517,"capital":4,"place":"city","name":"35G31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.78447,9.81324]},"properties":{"population":389182,"capital":4,"place":"city","name":"35G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.45623,10.27852]},"properties":{"population":355534,"capital":4,"place":"city","name":"35G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.06597,11.77461]},"properties":{"population":294443,"capital":4,"place":"city","name":"35G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.36302,9.38184]},"properties":{"population":400733,"capital":4,"place":"city","name":"35G46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.73747,4.68026]},"properties":{"population":518738,"capital":4,"place":"city","name":"35G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.66174,7.43136]},"properties":{"population":517048,"capital":4,"place":"city","name":"35G55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.31993,10.51802]},"properties":{"population":352636,"capital":4,"place":"city","name":"35G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.09625,7.92311]},"properties":{"population":482831,"capital":4,"place":"city","name":"35G61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.16087,7.39772]},"properties":{"population":486753,"capital":4,"place":"city","name":"35G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.88756,11.89138]},"properties":{"population":317805,"capital":4,"place":"city","name":"35Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.9794,21.33899]},"properties":{"population":714677,"capital":4,"place":"city","name":"35H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.02914,20.77708]},"properties":{"population":655961,"capital":4,"place":"city","name":"35H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.83651,19.49006]},"properties":{"population":460517,"capital":4,"place":"city","name":"35H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.48864,20.48521]},"properties":{"population":333220,"capital":4,"place":"city","name":"35H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.97675,20.26699]},"properties":{"population":138629,"capital":4,"place":"city","name":"35Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.63876,-15.33687]},"properties":{"population":960676,"capital":4,"place":"city","name":"35J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.29729,8.99766]},"properties":{"population":665415,"capital":4,"place":"city","name":"35J08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.88754,-15.22869]},"properties":{"population":883360,"capital":4,"place":"city","name":"35J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.89824,-15.81489]},"properties":{"population":823880,"capital":4,"place":"city","name":"35J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.61915,19.20365]},"properties":{"population":886446,"capital":4,"place":"city","name":"35J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.60402,-15.65886]},"properties":{"population":989469,"capital":4,"place":"city","name":"35J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.94023,6.48379]},"properties":{"population":747760,"capital":4,"place":"city","name":"35J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.47783,8.62981]},"properties":{"population":699484,"capital":4,"place":"city","name":"35J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.87957,18.3029]},"properties":{"population":828374,"capital":4,"place":"city","name":"35J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.63105,5.44347]},"properties":{"population":705444,"capital":4,"place":"city","name":"35J45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.50003,6.70418]},"properties":{"population":509986,"capital":4,"place":"city","name":"35J46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.70388,7.84063]},"properties":{"population":728550,"capital":4,"place":"city","name":"35J47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.1972,6.77791]},"properties":{"population":568017,"capital":4,"place":"city","name":"35J48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.62737,-14.56538]},"properties":{"population":758882,"capital":4,"place":"city","name":"35J50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.59919,-15.65944]},"properties":{"population":764252,"capital":4,"place":"city","name":"35J55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.55681,4.40364]},"properties":{"population":520400,"capital":4,"place":"city","name":"35J56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.33681,7.02002]},"properties":{"population":716084,"capital":4,"place":"city","name":"35J57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.8656,5.80097]},"properties":{"population":545958,"capital":4,"place":"city","name":"35J58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.32866,-14.96718]},"properties":{"population":957234,"capital":4,"place":"city","name":"35J60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.19463,9.6509]},"properties":{"population":785864,"capital":4,"place":"city","name":"35J61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.75853,0.44155]},"properties":{"population":767461,"capital":4,"place":"city","name":"35J62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.1413,-15.59989]},"properties":{"population":908477,"capital":4,"place":"city","name":"35J65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.66057,-1.18561]},"properties":{"population":635088,"capital":4,"place":"city","name":"35J66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.40562,-1.96104]},"properties":{"population":671052,"capital":4,"place":"city","name":"35J67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.9449,-14.63388]},"properties":{"population":853640,"capital":4,"place":"city","name":"35J70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.78634,-0.43888]},"properties":{"population":672982,"capital":4,"place":"city","name":"35J75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.62446,1.71991]},"properties":{"population":702553,"capital":4,"place":"city","name":"35J85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.61691,5.83869]},"properties":{"population":504985,"capital":4,"place":"city","name":"35J86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.93392,2.15268]},"properties":{"population":586929,"capital":4,"place":"city","name":"35J87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.94614,8.64697]},"properties":{"population":391202,"capital":4,"place":"city","name":"35J88"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.03149,1.4464]},"properties":{"population":696790,"capital":4,"place":"city","name":"35J91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.43402,-2.76587]},"properties":{"population":769848,"capital":4,"place":"city","name":"35J92"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.15815,7.60268]},"properties":{"population":537063,"capital":4,"place":"city","name":"35J93"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.57591,10.66576]},"properties":{"population":138629,"capital":4,"place":"city","name":"35J94"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.30975,-0.36569]},"properties":{"population":624027,"capital":4,"place":"city","name":"35J96"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.90091,6.96599]},"properties":{"population":683733,"capital":4,"place":"city","name":"35J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.25257,-0.17306]},"properties":{"population":613556,"capital":4,"place":"city","name":"35Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.92829,27.33034]},"properties":{"population":902340,"capital":4,"place":"city","name":"35K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.31149,0.02309]},"properties":{"population":623636,"capital":4,"place":"city","name":"35K08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.01337,17.76378]},"properties":{"population":758426,"capital":4,"place":"city","name":"35K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.06898,-15.67447]},"properties":{"population":861703,"capital":4,"place":"city","name":"35K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.95414,-14.27315]},"properties":{"population":902629,"capital":4,"place":"city","name":"35K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.77262,9.6162]},"properties":{"population":69314,"capital":4,"place":"city","name":"35K22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.09794,-15.21747]},"properties":{"population":786557,"capital":4,"place":"city","name":"35K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.25167,5.5193]},"properties":{"population":663331,"capital":4,"place":"city","name":"35K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.0969,9.67908]},"properties":{"population":729301,"capital":4,"place":"city","name":"35K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.17962,8.17072]},"properties":{"population":707665,"capital":4,"place":"city","name":"35K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.32074,6.90463]},"properties":{"population":439444,"capital":4,"place":"city","name":"35K41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.31471,-15.57166]},"properties":{"population":715148,"capital":4,"place":"city","name":"35K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.56275,7.92829]},"properties":{"population":385014,"capital":4,"place":"city","name":"35K46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.95389,10.31482]},"properties":{"population":756579,"capital":4,"place":"city","name":"35K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.7897,7.5005]},"properties":{"population":754538,"capital":4,"place":"city","name":"35K51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.97424,4.25888]},"properties":{"population":487519,"capital":4,"place":"city","name":"35K52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.72861,-14.79499]},"properties":{"population":954973,"capital":4,"place":"city","name":"35K55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.2239,-14.4249]},"properties":{"population":949777,"capital":4,"place":"city","name":"35K57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.93162,9.0705]},"properties":{"population":769211,"capital":4,"place":"city","name":"35K58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.64818,7.48977]},"properties":{"population":751316,"capital":4,"place":"city","name":"35K59"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.23815,-14.59071]},"properties":{"population":861177,"capital":4,"place":"city","name":"35K60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.69465,0.50753]},"properties":{"population":633150,"capital":4,"place":"city","name":"35K61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.3743,-14.62192]},"properties":{"population":871997,"capital":4,"place":"city","name":"35K65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.07986,1.12616]},"properties":{"population":609582,"capital":4,"place":"city","name":"35K67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.29315,1.82043]},"properties":{"population":688755,"capital":4,"place":"city","name":"35K70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.66552,-14.7407]},"properties":{"population":649375,"capital":4,"place":"city","name":"35K85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.20629,1.57582]},"properties":{"population":539816,"capital":4,"place":"city","name":"35K86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.08476,7.28611]},"properties":{"population":385014,"capital":4,"place":"city","name":"35K87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.92082,6.40165]},"properties":{"population":708506,"capital":4,"place":"city","name":"35K90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.43541,3.1842]},"properties":{"population":558724,"capital":4,"place":"city","name":"35K91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.37602,2.96022]},"properties":{"population":640191,"capital":4,"place":"city","name":"35K92"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.44441,7.26857]},"properties":{"population":482831,"capital":4,"place":"city","name":"35K93"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.23275,7.8338]},"properties":{"population":462497,"capital":4,"place":"city","name":"35K96"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.54468,-15.27677]},"properties":{"population":679009,"capital":4,"place":"city","name":"35K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.07519,1.72689]},"properties":{"population":592425,"capital":4,"place":"city","name":"35Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.02693,-5.40281]},"properties":{"population":552942,"capital":4,"place":"city","name":"35L02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.32885,2.61642]},"properties":{"population":509986,"capital":4,"place":"city","name":"35L03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.97296,-3.2865]},"properties":{"population":527811,"capital":4,"place":"city","name":"35L04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.25695,-13.38478]},"properties":{"population":885637,"capital":4,"place":"city","name":"35L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.1848,-11.61854]},"properties":{"population":706731,"capital":4,"place":"city","name":"35L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.90915,-14.03208]},"properties":{"population":823589,"capital":4,"place":"city","name":"35L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.22953,-14.97893]},"properties":{"population":857187,"capital":4,"place":"city","name":"35L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.3316,-12.13146]},"properties":{"population":612249,"capital":4,"place":"city","name":"35L25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.47107,-12.9035]},"properties":{"population":678105,"capital":4,"place":"city","name":"35L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.41771,5.08065]},"properties":{"population":699850,"capital":4,"place":"city","name":"35L35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.31077,-12.64845]},"properties":{"population":686380,"capital":4,"place":"city","name":"35L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.61202,17.52667]},"properties":{"population":796797,"capital":4,"place":"city","name":"35L45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.62351,17.15026]},"properties":{"population":745124,"capital":4,"place":"city","name":"35L50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.11067,5.07182]},"properties":{"population":481218,"capital":4,"place":"city","name":"35L51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.44739,4.57985]},"properties":{"population":538449,"capital":4,"place":"city","name":"35L52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.56888,-1.07228]},"properties":{"population":613988,"capital":4,"place":"city","name":"35L53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.81076,4.85676]},"properties":{"population":669084,"capital":4,"place":"city","name":"35L55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.72183,8.67062]},"properties":{"population":358351,"capital":4,"place":"city","name":"35L56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.22274,7.43435]},"properties":{"population":435670,"capital":4,"place":"city","name":"35L57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.64325,-15.41584]},"properties":{"population":830819,"capital":4,"place":"city","name":"35L60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.03356,-13.68958]},"properties":{"population":902014,"capital":4,"place":"city","name":"35L65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.24752,-12.19987]},"properties":{"population":805610,"capital":4,"place":"city","name":"35L67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.36473,-11.97124]},"properties":{"population":872404,"capital":4,"place":"city","name":"35L70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.72832,3.64411]},"properties":{"population":738770,"capital":4,"place":"city","name":"35L71"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.24549,-0.91055]},"properties":{"population":628971,"capital":4,"place":"city","name":"35L72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.16742,0.09403]},"properties":{"population":684694,"capital":4,"place":"city","name":"35L75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.64566,4.30142]},"properties":{"population":571702,"capital":4,"place":"city","name":"35L76"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.86845,6.01811]},"properties":{"population":459511,"capital":4,"place":"city","name":"35L77"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.65087,-14.07141]},"properties":{"population":696885,"capital":4,"place":"city","name":"35L80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.1277,6.78093]},"properties":{"population":439444,"capital":4,"place":"city","name":"35L81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.27991,5.96678]},"properties":{"population":497673,"capital":4,"place":"city","name":"35L82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.16338,1.64899]},"properties":{"population":545958,"capital":4,"place":"city","name":"35L85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.60577,7.28958]},"properties":{"population":371357,"capital":4,"place":"city","name":"35L86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.64188,8.52599]},"properties":{"population":289037,"capital":4,"place":"city","name":"35L87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.16996,-1.14308]},"properties":{"population":621460,"capital":4,"place":"city","name":"35L90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.11465,9.3996]},"properties":{"population":653524,"capital":4,"place":"city","name":"35L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.67815,2.08181]},"properties":{"population":552146,"capital":4,"place":"city","name":"35Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.83993,-13.93201]},"properties":{"population":747816,"capital":4,"place":"city","name":"35M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.59905,8.7884]},"properties":{"population":400733,"capital":4,"place":"city","name":"35M11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.93114,5.54794]},"properties":{"population":608221,"capital":4,"place":"city","name":"35M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.59973,6.23128]},"properties":{"population":517048,"capital":4,"place":"city","name":"35M13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.34836,2.01093]},"properties":{"population":570044,"capital":4,"place":"city","name":"35M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.9165,4.19162]},"properties":{"population":482831,"capital":4,"place":"city","name":"35M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.26947,8.00398]},"properties":{"population":417438,"capital":4,"place":"city","name":"35M31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.66797,9.47081]},"properties":{"population":352636,"capital":4,"place":"city","name":"35M32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.8412,1.60662]},"properties":{"population":540267,"capital":4,"place":"city","name":"35M33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.11803,10.40244]},"properties":{"population":109861,"capital":4,"place":"city","name":"35M85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.5907,9.34562]},"properties":{"population":309104,"capital":4,"place":"city","name":"35M86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.35405,10.05795]},"properties":{"population":239789,"capital":4,"place":"city","name":"35M87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.97449,-0.30642]},"properties":{"population":739449,"capital":4,"place":"city","name":"35M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.29601,10.0107]},"properties":{"population":329583,"capital":4,"place":"city","name":"35Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.2104,5.53087]},"properties":{"population":569373,"capital":4,"place":"city","name":"35N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.4099,2.6603]},"properties":{"population":628971,"capital":4,"place":"city","name":"35N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.90998,-11.15445]},"properties":{"population":662007,"capital":4,"place":"city","name":"35N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.98135,9.69377]},"properties":{"population":283321,"capital":4,"place":"city","name":"35N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.13601,5.87621]},"properties":{"population":514166,"capital":4,"place":"city","name":"35N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.49486,9.76012]},"properties":{"population":270805,"capital":4,"place":"city","name":"35N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.76835,7.7993]},"properties":{"population":481218,"capital":4,"place":"city","name":"35N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.34867,10.53416]},"properties":{"population":160943,"capital":4,"place":"city","name":"35Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.0292,-14.60831]},"properties":{"population":840380,"capital":4,"place":"city","name":"35P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.0261,12.11964]},"properties":{"population":719967,"capital":4,"place":"city","name":"35P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.30082,-11.82613]},"properties":{"population":843836,"capital":4,"place":"city","name":"35P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.70298,-14.80894]},"properties":{"population":805006,"capital":4,"place":"city","name":"35P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.17623,-11.55066]},"properties":{"population":861031,"capital":4,"place":"city","name":"35P25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.91345,-14.81237]},"properties":{"population":772929,"capital":4,"place":"city","name":"35P30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.84303,9.93932]},"properties":{"population":672022,"capital":4,"place":"city","name":"35P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.27232,6.10547]},"properties":{"population":436944,"capital":4,"place":"city","name":"35Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.28372,6.70665]},"properties":{"population":674641,"capital":4,"place":"city","name":"35Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.7404,7.02641]},"properties":{"population":69314,"capital":4,"place":"city","name":"35Q07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.27034,-15.05033]},"properties":{"population":705703,"capital":4,"place":"city","name":"35Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.98466,15.36992]},"properties":{"population":726192,"capital":4,"place":"city","name":"35Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.55467,-15.73089]},"properties":{"population":949852,"capital":4,"place":"city","name":"35Q30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.56705,18.10884]},"properties":{"population":781156,"capital":4,"place":"city","name":"35Q31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.77761,-14.54496]},"properties":{"population":993129,"capital":4,"place":"city","name":"35Q35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.27426,-14.76126]},"properties":{"population":863958,"capital":4,"place":"city","name":"35Q40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.80324,18.61868]},"properties":{"population":757609,"capital":4,"place":"city","name":"35Q41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.28583,-4.44996]},"properties":{"population":469134,"capital":4,"place":"city","name":"35Q49"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.57141,-12.61922]},"properties":{"population":892465,"capital":4,"place":"city","name":"35Q51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.87208,-15.63134]},"properties":{"population":975103,"capital":4,"place":"city","name":"35Q53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.36769,-15.02047]},"properties":{"population":950911,"capital":4,"place":"city","name":"35Q55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.00974,9.26448]},"properties":{"population":692067,"capital":4,"place":"city","name":"35Q56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.07622,4.1518]},"properties":{"population":731322,"capital":4,"place":"city","name":"35Q58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.4068,-14.25055]},"properties":{"population":892359,"capital":4,"place":"city","name":"35Q60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.19291,15.02925]},"properties":{"population":714677,"capital":4,"place":"city","name":"35Q61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.23122,1.21342]},"properties":{"population":400733,"capital":4,"place":"city","name":"35Q62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.7924,-2.36517]},"properties":{"population":444265,"capital":4,"place":"city","name":"35Q68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.29437,4.00689]},"properties":{"population":570044,"capital":4,"place":"city","name":"35Q70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.84844,-15.22217]},"properties":{"population":814060,"capital":4,"place":"city","name":"35Q72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.58036,-14.92918]},"properties":{"population":827918,"capital":4,"place":"city","name":"35Q74"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.06913,9.49487]},"properties":{"population":732448,"capital":4,"place":"city","name":"35Q75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.24856,-0.19952]},"properties":{"population":544673,"capital":4,"place":"city","name":"35Q76"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.25334,15.79446]},"properties":{"population":695368,"capital":4,"place":"city","name":"35Q79"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.68052,11.62217]},"properties":{"population":677992,"capital":4,"place":"city","name":"35Q80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.47398,2.90781]},"properties":{"population":340119,"capital":4,"place":"city","name":"35Q81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.05452,15.12735]},"properties":{"population":683303,"capital":4,"place":"city","name":"35Q82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.77016,7.17225]},"properties":{"population":674758,"capital":4,"place":"city","name":"35Q83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.24014,18.7608]},"properties":{"population":708840,"capital":4,"place":"city","name":"35Q84"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.9651,-3.02218]},"properties":{"population":504985,"capital":4,"place":"city","name":"35Q85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.6648,8.33361]},"properties":{"population":666949,"capital":4,"place":"city","name":"35Q86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.65262,5.63688]},"properties":{"population":270805,"capital":4,"place":"city","name":"35Q89"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.90839,1.11528]},"properties":{"population":412713,"capital":4,"place":"city","name":"35Q90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.69711,8.18922]},"properties":{"population":690073,"capital":4,"place":"city","name":"35Q91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.00636,-14.35654]},"properties":{"population":890476,"capital":4,"place":"city","name":"35Q92"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.90249,12.60175]},"properties":{"population":706731,"capital":4,"place":"city","name":"35Q93"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.77266,2.35785]},"properties":{"population":478749,"capital":4,"place":"city","name":"35Q94"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.21668,24.43189]},"properties":{"population":901857,"capital":4,"place":"city","name":"35Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.45358,7.26167]},"properties":{"population":638350,"capital":4,"place":"city","name":"35Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.09634,7.15045]},"properties":{"population":655819,"capital":4,"place":"city","name":"35R01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.52594,10.91017]},"properties":{"population":621660,"capital":4,"place":"city","name":"35R02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.3764,-0.81925]},"properties":{"population":630627,"capital":4,"place":"city","name":"35R03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.18937,-13.38336]},"properties":{"population":792912,"capital":4,"place":"city","name":"35R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.23911,0.09498]},"properties":{"population":588610,"capital":4,"place":"city","name":"35R06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.06088,-3.11903]},"properties":{"population":391202,"capital":4,"place":"city","name":"35R07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.42552,19.10635]},"properties":{"population":784971,"capital":4,"place":"city","name":"35R09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.13092,17.55251]},"properties":{"population":812385,"capital":4,"place":"city","name":"35R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.01868,22.99761]},"properties":{"population":898205,"capital":4,"place":"city","name":"35R11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.54189,-1.07186]},"properties":{"population":585507,"capital":4,"place":"city","name":"35R12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.77844,1.71748]},"properties":{"population":438202,"capital":4,"place":"city","name":"35R13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.89408,8.89647]},"properties":{"population":644571,"capital":4,"place":"city","name":"35R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.66528,10.81021]},"properties":{"population":733236,"capital":4,"place":"city","name":"35R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.34373,-15.52874]},"properties":{"population":762119,"capital":4,"place":"city","name":"35R25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.28539,-12.72748]},"properties":{"population":941401,"capital":4,"place":"city","name":"35R30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.65641,-15.33951]},"properties":{"population":897638,"capital":4,"place":"city","name":"35R35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.43012,9.89938]},"properties":{"population":576519,"capital":4,"place":"city","name":"35R37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.32937,5.45061]},"properties":{"population":684694,"capital":4,"place":"city","name":"35R45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.31867,-2.32766]},"properties":{"population":482028,"capital":4,"place":"city","name":"35R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.66919,-14.8776]},"properties":{"population":863123,"capital":4,"place":"city","name":"35R60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.23688,0.72723]},"properties":{"population":641673,"capital":4,"place":"city","name":"35R70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.93614,2.56641]},"properties":{"population":528320,"capital":4,"place":"city","name":"35R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.91624,2.80258]},"properties":{"population":468213,"capital":4,"place":"city","name":"35Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.99499,-11.20427]},"properties":{"population":824564,"capital":4,"place":"city","name":"35S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.94448,-11.12045]},"properties":{"population":657786,"capital":4,"place":"city","name":"35S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.69851,6.82351]},"properties":{"population":389182,"capital":4,"place":"city","name":"35S11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.79932,-14.36418]},"properties":{"population":695272,"capital":4,"place":"city","name":"35S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.99463,8.1611]},"properties":{"population":138629,"capital":4,"place":"city","name":"35S16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.14962,-0.15664]},"properties":{"population":644888,"capital":4,"place":"city","name":"35S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.33282,7.12091]},"properties":{"population":443081,"capital":4,"place":"city","name":"35S35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.38832,-11.41175]},"properties":{"population":488280,"capital":4,"place":"city","name":"35S50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.55943,-4.08498]},"properties":{"population":598141,"capital":4,"place":"city","name":"35S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.03077,7.23285]},"properties":{"population":380666,"capital":4,"place":"city","name":"35Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.66219,-2.15966]},"properties":{"population":263905,"capital":4,"place":"city","name":"37-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.01819,4.40016]},"properties":{"population":620253,"capital":4,"place":"city","name":"37-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.297,17.61465]},"properties":{"population":706390,"capital":4,"place":"city","name":"37-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.97821,-6.79582]},"properties":{"population":554907,"capital":4,"place":"city","name":"37-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.69639,-2.91396]},"properties":{"population":512396,"capital":4,"place":"city","name":"37-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.53479,3.68936]},"properties":{"population":697821,"capital":4,"place":"city","name":"37-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.59724,-11.35016]},"properties":{"population":827944,"capital":4,"place":"city","name":"37-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.03509,5.42246]},"properties":{"population":731853,"capital":4,"place":"city","name":"37A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.80574,-6.29354]},"properties":{"population":540717,"capital":4,"place":"city","name":"37A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.66387,-1.1163]},"properties":{"population":636475,"capital":4,"place":"city","name":"37A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.81253,-9.05762]},"properties":{"population":551745,"capital":4,"place":"city","name":"37A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.00898,2.952]},"properties":{"population":663463,"capital":4,"place":"city","name":"37A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.51638,13.26774]},"properties":{"population":760539,"capital":4,"place":"city","name":"37A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.62825,-6.79098]},"properties":{"population":748717,"capital":4,"place":"city","name":"37A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.33143,5.01638]},"properties":{"population":730451,"capital":4,"place":"city","name":"37A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.40079,-3.73678]},"properties":{"population":568697,"capital":4,"place":"city","name":"37A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.1695,-3.79801]},"properties":{"population":478749,"capital":4,"place":"city","name":"37A44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.26936,0.61565]},"properties":{"population":686693,"capital":4,"place":"city","name":"37A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.68546,-1.06234]},"properties":{"population":256494,"capital":4,"place":"city","name":"37A46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.50142,6.88277]},"properties":{"population":692853,"capital":4,"place":"city","name":"37A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.99672,-2.4634]},"properties":{"population":552545,"capital":4,"place":"city","name":"37A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.35609,2.40396]},"properties":{"population":630627,"capital":4,"place":"city","name":"37A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.76401,-7.47926]},"properties":{"population":769621,"capital":4,"place":"city","name":"37A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.97678,-9.2347]},"properties":{"population":498360,"capital":4,"place":"city","name":"37Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.70126,27.82199]},"properties":{"population":517614,"capital":4,"place":"city","name":"37B02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.60827,27.90164]},"properties":{"population":739510,"capital":4,"place":"city","name":"37B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.49618,27.7596]},"properties":{"population":802943,"capital":4,"place":"city","name":"37B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.5144,27.51039]},"properties":{"population":728550,"capital":4,"place":"city","name":"37B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.84248,27.64019]},"properties":{"population":677650,"capital":4,"place":"city","name":"37B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.02043,27.67847]},"properties":{"population":695939,"capital":4,"place":"city","name":"37B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.26888,27.7208]},"properties":{"population":633327,"capital":4,"place":"city","name":"37B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.51491,27.61511]},"properties":{"population":560580,"capital":4,"place":"city","name":"37B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.37922,27.82067]},"properties":{"population":757763,"capital":4,"place":"city","name":"37B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.65103,27.87914]},"properties":{"population":584354,"capital":4,"place":"city","name":"37B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.42669,27.56605]},"properties":{"population":631896,"capital":4,"place":"city","name":"37B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.67018,27.74342]},"properties":{"population":445434,"capital":4,"place":"city","name":"37B51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.47842,28.05403]},"properties":{"population":352636,"capital":4,"place":"city","name":"37B52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.62466,27.42431]},"properties":{"population":660800,"capital":4,"place":"city","name":"37B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.89618,27.85631]},"properties":{"population":418965,"capital":4,"place":"city","name":"37B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.22931,27.40368]},"properties":{"population":753315,"capital":4,"place":"city","name":"37B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.84178,27.92651]},"properties":{"population":418965,"capital":4,"place":"city","name":"37Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.99235,3.27085]},"properties":{"population":698656,"capital":4,"place":"city","name":"37C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.2355,-11.9208]},"properties":{"population":801268,"capital":4,"place":"city","name":"37C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.51338,10.11627]},"properties":{"population":694601,"capital":4,"place":"city","name":"37C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.8294,10.01821]},"properties":{"population":654821,"capital":4,"place":"city","name":"37C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.70301,-11.31024]},"properties":{"population":762364,"capital":4,"place":"city","name":"37C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.82178,5.79702]},"properties":{"population":675809,"capital":4,"place":"city","name":"37C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.27939,5.87443]},"properties":{"population":708757,"capital":4,"place":"city","name":"37C29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.67573,5.94856]},"properties":{"population":692165,"capital":4,"place":"city","name":"37C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.82608,-5.60391]},"properties":{"population":546383,"capital":4,"place":"city","name":"37C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.26421,9.40778]},"properties":{"population":703614,"capital":4,"place":"city","name":"37C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.75488,8.80503]},"properties":{"population":695272,"capital":4,"place":"city","name":"37C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.23938,3.00046]},"properties":{"population":645047,"capital":4,"place":"city","name":"37C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.45711,2.51739]},"properties":{"population":628599,"capital":4,"place":"city","name":"37C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58655,2.67653]},"properties":{"population":680793,"capital":4,"place":"city","name":"37C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.31326,-5.6706]},"properties":{"population":495582,"capital":4,"place":"city","name":"37C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.64796,-12.68696]},"properties":{"population":792551,"capital":4,"place":"city","name":"37C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.14168,21.90406]},"properties":{"population":788645,"capital":4,"place":"city","name":"37C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.52185,-9.15906]},"properties":{"population":366356,"capital":4,"place":"city","name":"37C79"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.99225,13.61269]},"properties":{"population":699301,"capital":4,"place":"city","name":"37C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.83805,-5.14137]},"properties":{"population":194591,"capital":4,"place":"city","name":"37C81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.42091,-6.96642]},"properties":{"population":463472,"capital":4,"place":"city","name":"37C83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.82106,6.59421]},"properties":{"population":709423,"capital":4,"place":"city","name":"37C85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.85536,-8.99281]},"properties":{"population":333220,"capital":4,"place":"city","name":"37C86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.23187,0.79129]},"properties":{"population":550533,"capital":4,"place":"city","name":"37C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.42105,-0.99202]},"properties":{"population":564544,"capital":4,"place":"city","name":"37Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.29766,4.35665]},"properties":{"population":630078,"capital":4,"place":"city","name":"37D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.68457,3.69764]},"properties":{"population":665929,"capital":4,"place":"city","name":"37D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.05675,3.68081]},"properties":{"population":650428,"capital":4,"place":"city","name":"37D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.45458,6.81337]},"properties":{"population":742833,"capital":4,"place":"city","name":"37D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.55678,5.19043]},"properties":{"population":711558,"capital":4,"place":"city","name":"37D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.38333,3.34417]},"properties":{"population":662007,"capital":4,"place":"city","name":"37D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.60417,9.54434]},"properties":{"population":689365,"capital":4,"place":"city","name":"37D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.86365,10.33141]},"properties":{"population":756631,"capital":4,"place":"city","name":"37D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.73555,-11.65442]},"properties":{"population":949852,"capital":4,"place":"city","name":"37D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.16228,6.18957]},"properties":{"population":687316,"capital":4,"place":"city","name":"37D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.36915,-12.08237]},"properties":{"population":748493,"capital":4,"place":"city","name":"37D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.57224,-7.06977]},"properties":{"population":485203,"capital":4,"place":"city","name":"37Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.09852,29.64503]},"properties":{"population":784188,"capital":4,"place":"city","name":"37E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.14013,30.02382]},"properties":{"population":680017,"capital":4,"place":"city","name":"37E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.53086,31.23824]},"properties":{"population":600881,"capital":4,"place":"city","name":"37E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.62644,30.34235]},"properties":{"population":578996,"capital":4,"place":"city","name":"37E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.69536,30.26776]},"properties":{"population":608904,"capital":4,"place":"city","name":"37E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.4595,30.01607]},"properties":{"population":713009,"capital":4,"place":"city","name":"37E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.8239,30.7762]},"properties":{"population":600881,"capital":4,"place":"city","name":"37E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.84507,30.30754]},"properties":{"population":569709,"capital":4,"place":"city","name":"37E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.68704,30.13859]},"properties":{"population":624222,"capital":4,"place":"city","name":"37E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.14481,30.43261]},"properties":{"population":721303,"capital":4,"place":"city","name":"37E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.63249,31.1584]},"properties":{"population":406044,"capital":4,"place":"city","name":"37Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.76359,-4.40964]},"properties":{"population":421950,"capital":4,"place":"city","name":"37F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.95676,3.6706]},"properties":{"population":778655,"capital":4,"place":"city","name":"37F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.67962,-2.76268]},"properties":{"population":270805,"capital":4,"place":"city","name":"37F12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.68342,-7.50957]},"properties":{"population":560580,"capital":4,"place":"city","name":"37F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.78604,-7.06644]},"properties":{"population":585793,"capital":4,"place":"city","name":"37F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.62415,-7.66598]},"properties":{"population":528826,"capital":4,"place":"city","name":"37F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.98779,-5.34912]},"properties":{"population":590263,"capital":4,"place":"city","name":"37F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.42821,-3.27955]},"properties":{"population":263905,"capital":4,"place":"city","name":"37F31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.44024,-3.21501]},"properties":{"population":239789,"capital":4,"place":"city","name":"37F32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.32897,-3.60429]},"properties":{"population":294443,"capital":4,"place":"city","name":"37F34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.46784,-6.21151]},"properties":{"population":615697,"capital":4,"place":"city","name":"37F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.81967,-5.23142]},"properties":{"population":382864,"capital":4,"place":"city","name":"37F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.44547,-3.18765]},"properties":{"population":329583,"capital":4,"place":"city","name":"37F44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.89371,-6.77723]},"properties":{"population":651323,"capital":4,"place":"city","name":"37F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.13734,-3.85529]},"properties":{"population":382864,"capital":4,"place":"city","name":"37F46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.8074,-2.55766]},"properties":{"population":710085,"capital":4,"place":"city","name":"37F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.5003,-2.41519]},"properties":{"population":641836,"capital":4,"place":"city","name":"37F75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.32625,-3.66332]},"properties":{"population":304452,"capital":4,"place":"city","name":"37F80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.22417,-0.87755]},"properties":{"population":627098,"capital":4,"place":"city","name":"37F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.75849,-4.19439]},"properties":{"population":411087,"capital":4,"place":"city","name":"37Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.19799,-6.87637]},"properties":{"population":674523,"capital":4,"place":"city","name":"37G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.35505,10.47216]},"properties":{"population":692657,"capital":4,"place":"city","name":"37G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.00294,14.07005]},"properties":{"population":774759,"capital":4,"place":"city","name":"37G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.35279,-1.61503]},"properties":{"population":572684,"capital":4,"place":"city","name":"37G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.1099,-4.63563]},"properties":{"population":542934,"capital":4,"place":"city","name":"37G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.52699,-9.25619]},"properties":{"population":361091,"capital":4,"place":"city","name":"37G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.25586,3.92934]},"properties":{"population":630261,"capital":4,"place":"city","name":"37G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.15187,2.76906]},"properties":{"population":634563,"capital":4,"place":"city","name":"37G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.45671,-7.21856]},"properties":{"population":821662,"capital":4,"place":"city","name":"37G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.47053,-5.12081]},"properties":{"population":468213,"capital":4,"place":"city","name":"37Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.24432,31.69046]},"properties":{"population":523110,"capital":4,"place":"city","name":"37H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.41616,30.34438]},"properties":{"population":711476,"capital":4,"place":"city","name":"37H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.13731,31.78303]},"properties":{"population":349650,"capital":4,"place":"city","name":"37H12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.8549,30.62256]},"properties":{"population":622059,"capital":4,"place":"city","name":"37H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.24767,31.74982]},"properties":{"population":513579,"capital":4,"place":"city","name":"37H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.18156,31.85391]},"properties":{"population":393182,"capital":4,"place":"city","name":"37H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.3659,31.14608]},"properties":{"population":603308,"capital":4,"place":"city","name":"37H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.30554,31.57827]},"properties":{"population":333220,"capital":4,"place":"city","name":"37Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.64054,14.7294]},"properties":{"population":717778,"capital":4,"place":"city","name":"37J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.66298,-5.49957]},"properties":{"population":454329,"capital":4,"place":"city","name":"37J06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.58954,3.80011]},"properties":{"population":648463,"capital":4,"place":"city","name":"37J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.60165,-7.22157]},"properties":{"population":385014,"capital":4,"place":"city","name":"37J11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.85474,-8.75114]},"properties":{"population":256494,"capital":4,"place":"city","name":"37J12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.63254,8.78886]},"properties":{"population":696979,"capital":4,"place":"city","name":"37J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.64736,3.88573]},"properties":{"population":608677,"capital":4,"place":"city","name":"37J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.88178,2.91181]},"properties":{"population":632614,"capital":4,"place":"city","name":"37J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.43903,-2.88296]},"properties":{"population":583773,"capital":4,"place":"city","name":"37J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.59067,-11.79644]},"properties":{"population":859526,"capital":4,"place":"city","name":"37J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.14296,-9.40649]},"properties":{"population":358351,"capital":4,"place":"city","name":"37J37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.44172,-9.333]},"properties":{"population":313549,"capital":4,"place":"city","name":"37J38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.89147,-4.13357]},"properties":{"population":485981,"capital":4,"place":"city","name":"37J39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.31254,-11.77162]},"properties":{"population":777022,"capital":4,"place":"city","name":"37J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.31454,5.38706]},"properties":{"population":747022,"capital":4,"place":"city","name":"37J45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.47321,-6.96603]},"properties":{"population":489783,"capital":4,"place":"city","name":"37J46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.19032,-0.05276]},"properties":{"population":582600,"capital":4,"place":"city","name":"37J50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.02344,-7.63297]},"properties":{"population":407753,"capital":4,"place":"city","name":"37J51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.76738,-5.33976]},"properties":{"population":478749,"capital":4,"place":"city","name":"37J55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.78279,-0.24846]},"properties":{"population":615060,"capital":4,"place":"city","name":"37J60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.27916,-8.39352]},"properties":{"population":299573,"capital":4,"place":"city","name":"37J65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.15819,-9.1554]},"properties":{"population":343398,"capital":4,"place":"city","name":"37J70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.5693,-11.3777]},"properties":{"population":864576,"capital":4,"place":"city","name":"37J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.75122,-2.21956]},"properties":{"population":482831,"capital":4,"place":"city","name":"37Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.77465,-11.74312]},"properties":{"population":742595,"capital":4,"place":"city","name":"37K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.35324,-0.60185]},"properties":{"population":459511,"capital":4,"place":"city","name":"37K06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.87392,-11.00459]},"properties":{"population":928256,"capital":4,"place":"city","name":"37K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.31271,6.96657]},"properties":{"population":710824,"capital":4,"place":"city","name":"37K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.70163,8.49484]},"properties":{"population":678671,"capital":4,"place":"city","name":"37K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.64677,5.31396]},"properties":{"population":655108,"capital":4,"place":"city","name":"37K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.05609,9.63141]},"properties":{"population":694505,"capital":4,"place":"city","name":"37K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.68175,8.20781]},"properties":{"population":763191,"capital":4,"place":"city","name":"37K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.19559,15.01377]},"properties":{"population":800101,"capital":4,"place":"city","name":"37K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.04686,3.58785]},"properties":{"population":623441,"capital":4,"place":"city","name":"37K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.54827,4.2481]},"properties":{"population":553733,"capital":4,"place":"city","name":"37K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.12374,4.66137]},"properties":{"population":623832,"capital":4,"place":"city","name":"37K55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.1007,-6.52257]},"properties":{"population":256494,"capital":4,"place":"city","name":"37K58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.39174,6.81991]},"properties":{"population":725134,"capital":4,"place":"city","name":"37K60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.17758,5.75987]},"properties":{"population":527811,"capital":4,"place":"city","name":"37K65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.03748,0.8596]},"properties":{"population":453259,"capital":4,"place":"city","name":"37K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.94173,-4.43346]},"properties":{"population":439444,"capital":4,"place":"city","name":"37Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.73837,27.38362]},"properties":{"population":632435,"capital":4,"place":"city","name":"37L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.56465,27.34686]},"properties":{"population":610479,"capital":4,"place":"city","name":"37L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.29856,27.71956]},"properties":{"population":591620,"capital":4,"place":"city","name":"37L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.07637,27.69824]},"properties":{"population":459511,"capital":4,"place":"city","name":"37L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.36488,27.43789]},"properties":{"population":561312,"capital":4,"place":"city","name":"37L25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.50462,27.60966]},"properties":{"population":734083,"capital":4,"place":"city","name":"37L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.70758,27.23348]},"properties":{"population":522035,"capital":4,"place":"city","name":"37L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.14745,27.88023]},"properties":{"population":440671,"capital":4,"place":"city","name":"37L45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.1068,27.55353]},"properties":{"population":496981,"capital":4,"place":"city","name":"37L50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.41711,27.36309]},"properties":{"population":610924,"capital":4,"place":"city","name":"37L55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.3052,27.84112]},"properties":{"population":629526,"capital":4,"place":"city","name":"37L60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.32456,27.22861]},"properties":{"population":494875,"capital":4,"place":"city","name":"37L65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.37213,27.48859]},"properties":{"population":498360,"capital":4,"place":"city","name":"37L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.20744,27.78427]},"properties":{"population":289037,"capital":4,"place":"city","name":"37Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.63683,27.70138]},"properties":{"population":709672,"capital":4,"place":"city","name":"37M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.74123,28.28861]},"properties":{"population":721964,"capital":4,"place":"city","name":"37M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.33199,28.26194]},"properties":{"population":699759,"capital":4,"place":"city","name":"37M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.30665,28.77062]},"properties":{"population":644571,"capital":4,"place":"city","name":"37M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.01527,28.95115]},"properties":{"population":385014,"capital":4,"place":"city","name":"37M21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.06589,29.16427]},"properties":{"population":395124,"capital":4,"place":"city","name":"37M22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.5392,28.61153]},"properties":{"population":651323,"capital":4,"place":"city","name":"37M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.00819,28.91016]},"properties":{"population":622257,"capital":4,"place":"city","name":"37M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.72772,29.29218]},"properties":{"population":352636,"capital":4,"place":"city","name":"37Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.01323,9.28986]},"properties":{"population":742356,"capital":4,"place":"city","name":"37N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.04822,-11.32702]},"properties":{"population":767554,"capital":4,"place":"city","name":"37N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.2738,9.82591]},"properties":{"population":629894,"capital":4,"place":"city","name":"37N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.65328,-12.07603]},"properties":{"population":790100,"capital":4,"place":"city","name":"37N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.36685,21.79492]},"properties":{"population":876545,"capital":4,"place":"city","name":"37N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.82145,2.35186]},"properties":{"population":530330,"capital":4,"place":"city","name":"37N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.76491,15.81654]},"properties":{"population":775533,"capital":4,"place":"city","name":"37N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.76338,11.34603]},"properties":{"population":726403,"capital":4,"place":"city","name":"37N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.78192,-11.70413]},"properties":{"population":757609,"capital":4,"place":"city","name":"37N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.26283,-4.77332]},"properties":{"population":520948,"capital":4,"place":"city","name":"37Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.56135,1.67209]},"properties":{"population":574300,"capital":4,"place":"city","name":"37P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.67895,1.63786]},"properties":{"population":294443,"capital":4,"place":"city","name":"37P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.96714,1.47725]},"properties":{"population":453259,"capital":4,"place":"city","name":"37P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.91299,2.00539]},"properties":{"population":496284,"capital":4,"place":"city","name":"37P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.1783,1.45717]},"properties":{"population":417438,"capital":4,"place":"city","name":"37P25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.77202,1.92126]},"properties":{"population":492725,"capital":4,"place":"city","name":"37P30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.96567,1.55775]},"properties":{"population":458496,"capital":4,"place":"city","name":"37P35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.22893,1.64712]},"properties":{"population":336729,"capital":4,"place":"city","name":"37P40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.81437,1.33362]},"properties":{"population":407753,"capital":4,"place":"city","name":"37P45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.7298,1.54628]},"properties":{"population":385014,"capital":4,"place":"city","name":"37P50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.79889,1.42803]},"properties":{"population":470953,"capital":4,"place":"city","name":"37P55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.46043,1.75185]},"properties":{"population":317805,"capital":4,"place":"city","name":"37P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.84387,1.46063]},"properties":{"population":239789,"capital":4,"place":"city","name":"37Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.94249,3.44234]},"properties":{"population":321887,"capital":4,"place":"city","name":"39-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.56651,3.78786]},"properties":{"population":462497,"capital":4,"place":"city","name":"39-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.53791,3.65069]},"properties":{"population":515329,"capital":4,"place":"city","name":"39-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.0591,3.34221]},"properties":{"population":406044,"capital":4,"place":"city","name":"39-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.3755,3.9942]},"properties":{"population":355534,"capital":4,"place":"city","name":"39-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.40273,3.60534]},"properties":{"population":548479,"capital":4,"place":"city","name":"39-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.77696,3.19367]},"properties":{"population":69314,"capital":4,"place":"city","name":"39-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.7988,3.2679]},"properties":{"population":537527,"capital":4,"place":"city","name":"39-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.07295,1.65337]},"properties":{"population":657088,"capital":4,"place":"city","name":"39A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.62779,-1.35614]},"properties":{"population":644094,"capital":4,"place":"city","name":"39A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.67884,0.11181]},"properties":{"population":885008,"capital":4,"place":"city","name":"39A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.7572,-4.82275]},"properties":{"population":828803,"capital":4,"place":"city","name":"39A11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.14422,16.44492]},"properties":{"population":874608,"capital":4,"place":"city","name":"39A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.30804,-2.59406]},"properties":{"population":697354,"capital":4,"place":"city","name":"39A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.64923,-4.45085]},"properties":{"population":618620,"capital":4,"place":"city","name":"39A14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.62286,3.71318]},"properties":{"population":701929,"capital":4,"place":"city","name":"39A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.30252,4.43373]},"properties":{"population":641017,"capital":4,"place":"city","name":"39A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.07216,3.28813]},"properties":{"population":694215,"capital":4,"place":"city","name":"39A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.95193,3.73858]},"properties":{"population":674405,"capital":4,"place":"city","name":"39A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.81857,6.10168]},"properties":{"population":481218,"capital":4,"place":"city","name":"39A24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.5586,7.13455]},"properties":{"population":179175,"capital":4,"place":"city","name":"39A26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.57601,7.1265]},"properties":{"population":435670,"capital":4,"place":"city","name":"39A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.76369,2.76646]},"properties":{"population":573979,"capital":4,"place":"city","name":"39A28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.43518,-1.55302]},"properties":{"population":741397,"capital":4,"place":"city","name":"39A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.23841,5.17232]},"properties":{"population":517614,"capital":4,"place":"city","name":"39A33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.77951,6.87132]},"properties":{"population":423410,"capital":4,"place":"city","name":"39A36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.13984,5.38101]},"properties":{"population":529831,"capital":4,"place":"city","name":"39A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.3535,-0.05296]},"properties":{"population":532787,"capital":4,"place":"city","name":"39A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.01936,-2.20886]},"properties":{"population":634563,"capital":4,"place":"city","name":"39A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.90688,5.49421]},"properties":{"population":740974,"capital":4,"place":"city","name":"39A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.28808,-5.44732]},"properties":{"population":567675,"capital":4,"place":"city","name":"39A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.0585,5.71878]},"properties":{"population":427666,"capital":4,"place":"city","name":"39Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.48492,29.93022]},"properties":{"population":682654,"capital":4,"place":"city","name":"39B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.24581,30.00436]},"properties":{"population":741637,"capital":4,"place":"city","name":"39B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.77714,30.40888]},"properties":{"population":742952,"capital":4,"place":"city","name":"39B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.12224,28.93912]},"properties":{"population":645362,"capital":4,"place":"city","name":"39B32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.71111,29.94752]},"properties":{"population":597380,"capital":4,"place":"city","name":"39B42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.05225,28.74359]},"properties":{"population":830375,"capital":4,"place":"city","name":"39B52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.9136,30.3504]},"properties":{"population":542053,"capital":4,"place":"city","name":"39B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.78829,29.93951]},"properties":{"population":714677,"capital":4,"place":"city","name":"39B62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.81729,30.14857]},"properties":{"population":688346,"capital":4,"place":"city","name":"39B72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.52251,29.00077]},"properties":{"population":794979,"capital":4,"place":"city","name":"39B82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.11832,29.84305]},"properties":{"population":699942,"capital":4,"place":"city","name":"39B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.82119,30.4088]},"properties":{"population":478749,"capital":4,"place":"city","name":"39Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.57013,-5.42954]},"properties":{"population":313549,"capital":4,"place":"city","name":"40-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.6409,-6.06237]},"properties":{"population":547227,"capital":4,"place":"city","name":"40-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.66591,-6.32359]},"properties":{"population":439444,"capital":4,"place":"city","name":"40-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.82993,-3.47122]},"properties":{"population":505624,"capital":4,"place":"city","name":"40-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.99273,-5.77725]},"properties":{"population":313549,"capital":4,"place":"city","name":"40-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.5659,-5.28575]},"properties":{"population":358351,"capital":4,"place":"city","name":"40-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.86769,-5.02842]},"properties":{"population":466343,"capital":4,"place":"city","name":"40-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.46399,4.38421]},"properties":{"population":816820,"capital":4,"place":"city","name":"40A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.62876,4.0843]},"properties":{"population":550533,"capital":4,"place":"city","name":"40A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.12752,-2.93873]},"properties":{"population":690675,"capital":4,"place":"city","name":"40A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.3545,6.51576]},"properties":{"population":508759,"capital":4,"place":"city","name":"40A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.82264,2.494]},"properties":{"population":667834,"capital":4,"place":"city","name":"40A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.25318,-4.66829]},"properties":{"population":668210,"capital":4,"place":"city","name":"40A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.5933,5.24941]},"properties":{"population":682219,"capital":4,"place":"city","name":"40A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.05208,2.17041]},"properties":{"population":554907,"capital":4,"place":"city","name":"40A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.57922,8.87445]},"properties":{"population":358351,"capital":4,"place":"city","name":"40Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.38634,1.0112]},"properties":{"population":668210,"capital":4,"place":"city","name":"40B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.01897,-3.93497]},"properties":{"population":179175,"capital":4,"place":"city","name":"40B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.71424,18.12754]},"properties":{"population":759739,"capital":4,"place":"city","name":"40C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.10186,13.63278]},"properties":{"population":508759,"capital":4,"place":"city","name":"40C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.30092,13.51735]},"properties":{"population":507517,"capital":4,"place":"city","name":"40C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.00608,13.51837]},"properties":{"population":482028,"capital":4,"place":"city","name":"40C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.65115,12.75923]},"properties":{"population":256494,"capital":4,"place":"city","name":"40Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.27627,4.07049]},"properties":{"population":536597,"capital":4,"place":"city","name":"40D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.34325,8.07684]},"properties":{"population":424849,"capital":4,"place":"city","name":"40D09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.12377,8.92868]},"properties":{"population":336729,"capital":4,"place":"city","name":"40D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.45306,4.33446]},"properties":{"population":629156,"capital":4,"place":"city","name":"40D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.26346,7.59484]},"properties":{"population":461512,"capital":4,"place":"city","name":"40D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.96984,6.15377]},"properties":{"population":624804,"capital":4,"place":"city","name":"40D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.82503,8.54655]},"properties":{"population":363758,"capital":4,"place":"city","name":"40D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.8502,9.27345]},"properties":{"population":194591,"capital":4,"place":"city","name":"40Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.5847,5.47103]},"properties":{"population":696885,"capital":4,"place":"city","name":"40E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.88093,1.37755]},"properties":{"population":343398,"capital":4,"place":"city","name":"40E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.59306,1.12174]},"properties":{"population":333220,"capital":4,"place":"city","name":"40E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.53686,1.07352]},"properties":{"population":239789,"capital":4,"place":"city","name":"40E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.39354,1.04808]},"properties":{"population":329583,"capital":4,"place":"city","name":"40E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.504,1.06127]},"properties":{"population":109861,"capital":4,"place":"city","name":"40Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.81231,-6.36548]},"properties":{"population":709174,"capital":4,"place":"city","name":"40F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.38636,8.50539]},"properties":{"population":138629,"capital":4,"place":"city","name":"40F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.56476,8.46008]},"properties":{"population":69314,"capital":4,"place":"city","name":"40Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.15464,1.84188]},"properties":{"population":767415,"capital":4,"place":"city","name":"40G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.67982,1.49503]},"properties":{"population":648157,"capital":4,"place":"city","name":"40G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.96869,6.79798]},"properties":{"population":535185,"capital":4,"place":"city","name":"40G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[27.07699,1.95518]},"properties":{"population":671659,"capital":4,"place":"city","name":"40G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.70764,7.87374]},"properties":{"population":263905,"capital":4,"place":"city","name":"40Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.94169,-1.42413]},"properties":{"population":647850,"capital":4,"place":"city","name":"40H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.19921,-5.48336]},"properties":{"population":138629,"capital":4,"place":"city","name":"40H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.28106,4.22134]},"properties":{"population":644413,"capital":4,"place":"city","name":"40J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.87238,9.22965]},"properties":{"population":179175,"capital":4,"place":"city","name":"40J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.98656,18.74938]},"properties":{"population":404305,"capital":4,"place":"city","name":"41-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.38458,18.48418]},"properties":{"population":545532,"capital":4,"place":"city","name":"41-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.3803,20.01085]},"properties":{"population":607993,"capital":4,"place":"city","name":"41-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.06694,18.44646]},"properties":{"population":504985,"capital":4,"place":"city","name":"41-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.14714,19.26408]},"properties":{"population":512989,"capital":4,"place":"city","name":"41-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.77217,18.53804]},"properties":{"population":605443,"capital":4,"place":"city","name":"41-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.09558,18.30646]},"properties":{"population":588332,"capital":4,"place":"city","name":"41-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.7409,-0.68807]},"properties":{"population":901079,"capital":4,"place":"city","name":"41A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.55588,-1.26636]},"properties":{"population":887374,"capital":4,"place":"city","name":"41A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.63522,18.74743]},"properties":{"population":890136,"capital":4,"place":"city","name":"41A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.49453,-1.44231]},"properties":{"population":787739,"capital":4,"place":"city","name":"41A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.60656,-0.89325]},"properties":{"population":803430,"capital":4,"place":"city","name":"41A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.79452,-1.66719]},"properties":{"population":761972,"capital":4,"place":"city","name":"41A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.3079,14.19856]},"properties":{"population":878339,"capital":4,"place":"city","name":"41A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.60301,1.92828]},"properties":{"population":594017,"capital":4,"place":"city","name":"41A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.30703,0.30012]},"properties":{"population":665672,"capital":4,"place":"city","name":"41A28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.14058,-5.92898]},"properties":{"population":669084,"capital":4,"place":"city","name":"41A29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.06042,14.46501]},"properties":{"population":823164,"capital":4,"place":"city","name":"41A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.60193,11.07504]},"properties":{"population":797074,"capital":4,"place":"city","name":"41A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.05116,-0.11878]},"properties":{"population":827359,"capital":4,"place":"city","name":"41A36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.19348,2.72015]},"properties":{"population":600635,"capital":4,"place":"city","name":"41A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.09393,-3.82425]},"properties":{"population":616541,"capital":4,"place":"city","name":"41A44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.70454,-3.4241]},"properties":{"population":655961,"capital":4,"place":"city","name":"41A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.59078,-4.45432]},"properties":{"population":721155,"capital":4,"place":"city","name":"41A46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.30955,-4.99606]},"properties":{"population":878477,"capital":4,"place":"city","name":"41A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.45415,0.50415]},"properties":{"population":611368,"capital":4,"place":"city","name":"41A52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.68845,-4.25987]},"properties":{"population":860483,"capital":4,"place":"city","name":"41A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.75326,14.01618]},"properties":{"population":731188,"capital":4,"place":"city","name":"41A58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.84311,19.33906]},"properties":{"population":813446,"capital":4,"place":"city","name":"41A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.74945,-0.77922]},"properties":{"population":844031,"capital":4,"place":"city","name":"41A63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.43952,-4.49471]},"properties":{"population":870913,"capital":4,"place":"city","name":"41A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.31938,1.96241]},"properties":{"population":604025,"capital":4,"place":"city","name":"41A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.19081,6.90856]},"properties":{"population":317805,"capital":4,"place":"city","name":"41A81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.23713,-2.41564]},"properties":{"population":604263,"capital":4,"place":"city","name":"41A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.60394,6.72941]},"properties":{"population":479579,"capital":4,"place":"city","name":"41Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.99829,20.97656]},"properties":{"population":361091,"capital":4,"place":"city","name":"42-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58273,21.52829]},"properties":{"population":641999,"capital":4,"place":"city","name":"42-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.5021,21.49398]},"properties":{"population":612029,"capital":4,"place":"city","name":"42-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.88382,21.27565]},"properties":{"population":519295,"capital":4,"place":"city","name":"42-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.12159,21.27467]},"properties":{"population":451085,"capital":4,"place":"city","name":"42-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.45117,19.74515]},"properties":{"population":602827,"capital":4,"place":"city","name":"42-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.74391,21.24767]},"properties":{"population":555682,"capital":4,"place":"city","name":"42-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.45613,1.59914]},"properties":{"population":724779,"capital":4,"place":"city","name":"42A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.54988,6.40711]},"properties":{"population":815737,"capital":4,"place":"city","name":"42A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.00819,0.36226]},"properties":{"population":675925,"capital":4,"place":"city","name":"42A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.38638,-1.15836]},"properties":{"population":793987,"capital":4,"place":"city","name":"42A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.95502,0.37881]},"properties":{"population":790728,"capital":4,"place":"city","name":"42A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.73944,-4.65337]},"properties":{"population":764156,"capital":4,"place":"city","name":"42A24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.91047,-2.78207]},"properties":{"population":654821,"capital":4,"place":"city","name":"42A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.49452,19.95219]},"properties":{"population":838753,"capital":4,"place":"city","name":"42A38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.14695,-1.68668]},"properties":{"population":677878,"capital":4,"place":"city","name":"42A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.14593,-2.986]},"properties":{"population":706304,"capital":4,"place":"city","name":"42A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.11657,-4.54567]},"properties":{"population":631354,"capital":4,"place":"city","name":"42A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.72651,-1.80523]},"properties":{"population":552146,"capital":4,"place":"city","name":"42A61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.91472,2.73809]},"properties":{"population":537527,"capital":4,"place":"city","name":"42A63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.33321,-2.19222]},"properties":{"population":578689,"capital":4,"place":"city","name":"42A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.2503,-2.96065]},"properties":{"population":526269,"capital":4,"place":"city","name":"42A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.40326,3.62547]},"properties":{"population":684800,"capital":4,"place":"city","name":"42A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.44196,-1.38221]},"properties":{"population":602102,"capital":4,"place":"city","name":"42A82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.14213,5.88215]},"properties":{"population":654965,"capital":4,"place":"city","name":"42A85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.98931,0.99993]},"properties":{"population":572358,"capital":4,"place":"city","name":"42A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.25031,4.01158]},"properties":{"population":439444,"capital":4,"place":"city","name":"42Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.76523,6.71453]},"properties":{"population":755328,"capital":4,"place":"city","name":"42B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.23577,2.00934]},"properties":{"population":651767,"capital":4,"place":"city","name":"42B08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.11753,15.58798]},"properties":{"population":785825,"capital":4,"place":"city","name":"42B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.40458,5.07212]},"properties":{"population":748997,"capital":4,"place":"city","name":"42B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.00291,8.3189]},"properties":{"population":879011,"capital":4,"place":"city","name":"42B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.07131,6.45216]},"properties":{"population":883083,"capital":4,"place":"city","name":"42B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.458,4.0794]},"properties":{"population":777821,"capital":4,"place":"city","name":"42B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.25079,7.51191]},"properties":{"population":818979,"capital":4,"place":"city","name":"42B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.72518,1.16611]},"properties":{"population":655677,"capital":4,"place":"city","name":"42B37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.45871,-0.77058]},"properties":{"population":653669,"capital":4,"place":"city","name":"42B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.05255,5.5768]},"properties":{"population":395124,"capital":4,"place":"city","name":"42Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.81231,-4.67863]},"properties":{"population":854110,"capital":4,"place":"city","name":"42C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.54464,-1.42491]},"properties":{"population":832385,"capital":4,"place":"city","name":"42C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.19943,-1.00647]},"properties":{"population":858821,"capital":4,"place":"city","name":"42C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.89685,-3.14247]},"properties":{"population":574939,"capital":4,"place":"city","name":"42C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.89188,2.66272]},"properties":{"population":514166,"capital":4,"place":"city","name":"42C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.12288,-2.81739]},"properties":{"population":640687,"capital":4,"place":"city","name":"42C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.75443,-1.51119]},"properties":{"population":908318,"capital":4,"place":"city","name":"42C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.59034,-7.52388]},"properties":{"population":563835,"capital":4,"place":"city","name":"42C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.50587,3.96546]},"properties":{"population":343398,"capital":4,"place":"city","name":"42Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.21312,4.19865]},"properties":{"population":294443,"capital":4,"place":"city","name":"43-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.79589,4.37025]},"properties":{"population":433073,"capital":4,"place":"city","name":"43-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.33321,4.66491]},"properties":{"population":563478,"capital":4,"place":"city","name":"43-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.17428,4.41425]},"properties":{"population":420469,"capital":4,"place":"city","name":"43-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.57871,4.32376]},"properties":{"population":179175,"capital":4,"place":"city","name":"43-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.77387,11.31141]},"properties":{"population":537989,"capital":4,"place":"city","name":"43-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.06297,4.35453]},"properties":{"population":409434,"capital":4,"place":"city","name":"43-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.27632,0.2908]},"properties":{"population":729165,"capital":4,"place":"city","name":"43A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.20787,-3.83374]},"properties":{"population":733823,"capital":4,"place":"city","name":"43A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.11245,-3.80129]},"properties":{"population":663463,"capital":4,"place":"city","name":"43A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.38116,4.43989]},"properties":{"population":751643,"capital":4,"place":"city","name":"43A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.87213,2.18855]},"properties":{"population":523644,"capital":4,"place":"city","name":"43A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.56423,-3.21797]},"properties":{"population":714834,"capital":4,"place":"city","name":"43A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.64514,-5.25047]},"properties":{"population":696508,"capital":4,"place":"city","name":"43A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.2952,-4.71088]},"properties":{"population":694505,"capital":4,"place":"city","name":"43A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.22098,-4.48747]},"properties":{"population":678558,"capital":4,"place":"city","name":"43A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.41035,-5.82309]},"properties":{"population":653378,"capital":4,"place":"city","name":"43A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.86475,0.56227]},"properties":{"population":669950,"capital":4,"place":"city","name":"43A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.61968,-2.21804]},"properties":{"population":630078,"capital":4,"place":"city","name":"43A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.57195,-1.08205]},"properties":{"population":624610,"capital":4,"place":"city","name":"43A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.84347,-1.69643]},"properties":{"population":643454,"capital":4,"place":"city","name":"43A46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.37985,0.85402]},"properties":{"population":554907,"capital":4,"place":"city","name":"43A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.63569,-5.68407]},"properties":{"population":506259,"capital":4,"place":"city","name":"43A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.32894,-1.95068]},"properties":{"population":693244,"capital":4,"place":"city","name":"43A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.96539,-3.77286]},"properties":{"population":627852,"capital":4,"place":"city","name":"43A62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.8229,2.88837]},"properties":{"population":704664,"capital":4,"place":"city","name":"43A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.55406,-4.9685]},"properties":{"population":638687,"capital":4,"place":"city","name":"43A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.17993,-2.26951]},"properties":{"population":611589,"capital":4,"place":"city","name":"43A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.09583,1.72389]},"properties":{"population":541610,"capital":4,"place":"city","name":"43A77"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.4588,2.9678]},"properties":{"population":748380,"capital":4,"place":"city","name":"43A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.38691,10.04271]},"properties":{"population":781075,"capital":4,"place":"city","name":"43A85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.52402,-0.26579]},"properties":{"population":682437,"capital":4,"place":"city","name":"43A90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.13249,5.65605]},"properties":{"population":361091,"capital":4,"place":"city","name":"43A95"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.37308,-3.71308]},"properties":{"population":560580,"capital":4,"place":"city","name":"43A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.55138,5.86639]},"properties":{"population":321887,"capital":4,"place":"city","name":"43Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.35245,10.91236]},"properties":{"population":304452,"capital":4,"place":"city","name":"44-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.06746,8.21243]},"properties":{"population":545532,"capital":4,"place":"city","name":"44-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.24404,7.17142]},"properties":{"population":484418,"capital":4,"place":"city","name":"44-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.47599,10.78572]},"properties":{"population":368887,"capital":4,"place":"city","name":"44-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.27365,10.72539]},"properties":{"population":248490,"capital":4,"place":"city","name":"44-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.65785,11.00833]},"properties":{"population":440671,"capital":4,"place":"city","name":"44-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.10629,10.4195]},"properties":{"population":397029,"capital":4,"place":"city","name":"44-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.34292,-1.98078]},"properties":{"population":623048,"capital":4,"place":"city","name":"44A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.17099,21.58566]},"properties":{"population":833134,"capital":4,"place":"city","name":"44A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.55177,-1.24548]},"properties":{"population":762070,"capital":4,"place":"city","name":"44A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.53398,-4.94348]},"properties":{"population":855506,"capital":4,"place":"city","name":"44A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.13978,0.14573]},"properties":{"population":699117,"capital":4,"place":"city","name":"44A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.22326,-0.33177]},"properties":{"population":580211,"capital":4,"place":"city","name":"44A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.63346,7.4394]},"properties":{"population":746336,"capital":4,"place":"city","name":"44A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.50332,0.32537]},"properties":{"population":657368,"capital":4,"place":"city","name":"44A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.12718,-6.10885]},"properties":{"population":641017,"capital":4,"place":"city","name":"44A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.00222,0.00101]},"properties":{"population":501727,"capital":4,"place":"city","name":"44A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.29846,5.43298]},"properties":{"population":707665,"capital":4,"place":"city","name":"44A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.5058,-4.0329]},"properties":{"population":499721,"capital":4,"place":"city","name":"44A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.77053,5.96453]},"properties":{"population":340119,"capital":4,"place":"city","name":"44Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.03448,17.93656]},"properties":{"population":309104,"capital":4,"place":"city","name":"45-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.96515,18.26103]},"properties":{"population":501063,"capital":4,"place":"city","name":"45-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.19999,18.93214]},"properties":{"population":523110,"capital":4,"place":"city","name":"45-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.8667,17.79502]},"properties":{"population":415888,"capital":4,"place":"city","name":"45-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.77141,17.71967]},"properties":{"population":343398,"capital":4,"place":"city","name":"45-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.83113,17.88608]},"properties":{"population":507517,"capital":4,"place":"city","name":"45-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.55622,18.50656]},"properties":{"population":589715,"capital":4,"place":"city","name":"45-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.43596,26.12835]},"properties":{"population":727031,"capital":4,"place":"city","name":"45A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.10936,23.39647]},"properties":{"population":160943,"capital":4,"place":"city","name":"45A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.04651,23.30779]},"properties":{"population":219722,"capital":4,"place":"city","name":"45Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.37044,-4.49877]},"properties":{"population":838411,"capital":4,"place":"city","name":"45B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.78064,5.46987]},"properties":{"population":256494,"capital":4,"place":"city","name":"45B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.34108,5.74052]},"properties":{"population":138629,"capital":4,"place":"city","name":"45Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.12967,23.36078]},"properties":{"population":707834,"capital":4,"place":"city","name":"45C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.22726,20.68109]},"properties":{"population":69314,"capital":4,"place":"city","name":"45C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.30294,20.60125]},"properties":{"population":69314,"capital":4,"place":"city","name":"45Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.47946,28.5491]},"properties":{"population":848094,"capital":4,"place":"city","name":"45D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.7994,28.77509]},"properties":{"population":283321,"capital":4,"place":"city","name":"45D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.43158,28.48142]},"properties":{"population":194591,"capital":4,"place":"city","name":"45Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.18251,-4.15306]},"properties":{"population":803592,"capital":4,"place":"city","name":"45E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29011,-4.65394]},"properties":{"population":835936,"capital":4,"place":"city","name":"45E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.38772,8.03315]},"properties":{"population":645833,"capital":4,"place":"city","name":"45E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.62329,-3.17506]},"properties":{"population":519295,"capital":4,"place":"city","name":"45Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.54605,-3.44364]},"properties":{"population":646769,"capital":4,"place":"city","name":"45F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.0256,-3.04094]},"properties":{"population":609130,"capital":4,"place":"city","name":"45F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.39602,0.1517]},"properties":{"population":630991,"capital":4,"place":"city","name":"45F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.21559,2.58532]},"properties":{"population":382864,"capital":4,"place":"city","name":"45F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.9811,4.71908]},"properties":{"population":256494,"capital":4,"place":"city","name":"45Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.00644,8.96821]},"properties":{"population":714755,"capital":4,"place":"city","name":"45G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.55045,-3.94727]},"properties":{"population":862801,"capital":4,"place":"city","name":"45G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.20674,5.08658]},"properties":{"population":669703,"capital":4,"place":"city","name":"45G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.88797,3.10702]},"properties":{"population":395124,"capital":4,"place":"city","name":"45G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.49937,3.43281]},"properties":{"population":406044,"capital":4,"place":"city","name":"45Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.78356,19.9867]},"properties":{"population":677878,"capital":4,"place":"city","name":"45H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.27162,17.46539]},"properties":{"population":160943,"capital":4,"place":"city","name":"45H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.25952,17.3856]},"properties":{"population":69314,"capital":4,"place":"city","name":"45Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.90396,-9.99613]},"properties":{"population":879467,"capital":4,"place":"city","name":"45J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.78358,3.5812]},"properties":{"population":418965,"capital":4,"place":"city","name":"45J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.6158,5.3108]},"properties":{"population":230258,"capital":4,"place":"city","name":"45Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.87886,-9.86781]},"properties":{"population":882746,"capital":4,"place":"city","name":"45K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.13288,4.64068]},"properties":{"population":230258,"capital":4,"place":"city","name":"45K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.64416,5.15475]},"properties":{"population":256494,"capital":4,"place":"city","name":"45Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.4873,21.11948]},"properties":{"population":750769,"capital":4,"place":"city","name":"45L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.10045,19.63242]},"properties":{"population":69314,"capital":4,"place":"city","name":"45L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.06553,19.81136]},"properties":{"population":69314,"capital":4,"place":"city","name":"45L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.4664,21.80312]},"properties":{"population":731588,"capital":4,"place":"city","name":"45M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.87631,20.86864]},"properties":{"population":703966,"capital":4,"place":"city","name":"45M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.73774,19.89604]},"properties":{"population":594542,"capital":4,"place":"city","name":"45M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.59672,20.86241]},"properties":{"population":622653,"capital":4,"place":"city","name":"45M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.80333,21.04264]},"properties":{"population":530826,"capital":4,"place":"city","name":"45M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.24061,20.72253]},"properties":{"population":283321,"capital":4,"place":"city","name":"45Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.50334,-1.7134]},"properties":{"population":754538,"capital":4,"place":"city","name":"45N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.4164,5.40627]},"properties":{"population":109861,"capital":4,"place":"city","name":"45N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.05337,22.1696]},"properties":{"population":764921,"capital":4,"place":"city","name":"45P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.98075,20.25129]},"properties":{"population":109861,"capital":4,"place":"city","name":"45P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.09628,20.31212]},"properties":{"population":207944,"capital":4,"place":"city","name":"45Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.84393,18.91516]},"properties":{"population":641836,"capital":4,"place":"city","name":"45Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.15857,19.72225]},"properties":{"population":109861,"capital":4,"place":"city","name":"45Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.4237,5.17685]},"properties":{"population":532300,"capital":4,"place":"city","name":"45R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.58461,2.18442]},"properties":{"population":69314,"capital":4,"place":"city","name":"45R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.29737,2.20821]},"properties":{"population":109861,"capital":4,"place":"city","name":"45Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.81531,24.54801]},"properties":{"population":455387,"capital":4,"place":"city","name":"46-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.22339,24.94736]},"properties":{"population":695177,"capital":4,"place":"city","name":"46-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.72723,25.53465]},"properties":{"population":735627,"capital":4,"place":"city","name":"46-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.73081,24.72647]},"properties":{"population":586078,"capital":4,"place":"city","name":"46-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.77583,24.37265]},"properties":{"population":160943,"capital":4,"place":"city","name":"46-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.82952,24.23957]},"properties":{"population":705012,"capital":4,"place":"city","name":"46-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.61378,24.32747]},"properties":{"population":681344,"capital":4,"place":"city","name":"46-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.69878,2.86541]},"properties":{"population":768891,"capital":4,"place":"city","name":"46A03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.13576,2.19479]},"properties":{"population":728824,"capital":4,"place":"city","name":"46A04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.87163,-4.03919]},"properties":{"population":715226,"capital":4,"place":"city","name":"46A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.96502,2.34496]},"properties":{"population":697821,"capital":4,"place":"city","name":"46A11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.80768,-0.85021]},"properties":{"population":698193,"capital":4,"place":"city","name":"46A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.68516,-0.45698]},"properties":{"population":655819,"capital":4,"place":"city","name":"46A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.41275,-9.40779]},"properties":{"population":550938,"capital":4,"place":"city","name":"46A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.99801,-1.36534]},"properties":{"population":661204,"capital":4,"place":"city","name":"46A19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.06857,2.95397]},"properties":{"population":710496,"capital":4,"place":"city","name":"46A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.86705,-4.40664]},"properties":{"population":667959,"capital":4,"place":"city","name":"46A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.44146,-5.933]},"properties":{"population":543807,"capital":4,"place":"city","name":"46A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.15851,1.26713]},"properties":{"population":669579,"capital":4,"place":"city","name":"46A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.39991,-2.36071]},"properties":{"population":659304,"capital":4,"place":"city","name":"46A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.95149,-1.80302]},"properties":{"population":701391,"capital":4,"place":"city","name":"46A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.51641,4.33314]},"properties":{"population":811790,"capital":4,"place":"city","name":"46A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.89853,-2.11082]},"properties":{"population":832989,"capital":4,"place":"city","name":"46A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.44117,-2.98067]},"properties":{"population":655677,"capital":4,"place":"city","name":"46A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.2787,3.95528]},"properties":{"population":753796,"capital":4,"place":"city","name":"46A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.91926,2.04951]},"properties":{"population":325809,"capital":4,"place":"city","name":"46A61"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.03292,-1.86776]},"properties":{"population":475359,"capital":4,"place":"city","name":"46A63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.4535,-2.44136]},"properties":{"population":555295,"capital":4,"place":"city","name":"46A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.6946,-7.53892]},"properties":{"population":566642,"capital":4,"place":"city","name":"46A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.12468,-1.86633]},"properties":{"population":647697,"capital":4,"place":"city","name":"46A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.72253,-0.75327]},"properties":{"population":476217,"capital":4,"place":"city","name":"46Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.98937,4.52574]},"properties":{"population":766715,"capital":4,"place":"city","name":"46B03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.97376,1.27056]},"properties":{"population":732317,"capital":4,"place":"city","name":"46B04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.72032,-3.12069]},"properties":{"population":509986,"capital":4,"place":"city","name":"46B06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.15623,2.82522]},"properties":{"population":653669,"capital":4,"place":"city","name":"46B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.277,-5.51563]},"properties":{"population":495582,"capital":4,"place":"city","name":"46B08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.96411,-2.72752]},"properties":{"population":622653,"capital":4,"place":"city","name":"46B09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29803,3.924]},"properties":{"population":766575,"capital":4,"place":"city","name":"46B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.9916,3.61141]},"properties":{"population":809346,"capital":4,"place":"city","name":"46B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.98634,-3.67294]},"properties":{"population":898619,"capital":4,"place":"city","name":"46B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.44337,-0.89336]},"properties":{"population":711395,"capital":4,"place":"city","name":"46B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.70734,1.96614]},"properties":{"population":772444,"capital":4,"place":"city","name":"46B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.79661,-5.35997]},"properties":{"population":617170,"capital":4,"place":"city","name":"46B26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.56598,4.02154]},"properties":{"population":722620,"capital":4,"place":"city","name":"46B28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.26085,0.43651]},"properties":{"population":631896,"capital":4,"place":"city","name":"46B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.00688,5.80155]},"properties":{"population":765064,"capital":4,"place":"city","name":"46B42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.29287,-3.40336]},"properties":{"population":712929,"capital":4,"place":"city","name":"46B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.05665,-2.2515]},"properties":{"population":612905,"capital":4,"place":"city","name":"46B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[25.16002,-4.03329]},"properties":{"population":719067,"capital":4,"place":"city","name":"46B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.77408,0.41423]},"properties":{"population":465396,"capital":4,"place":"city","name":"46B80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.9893,-3.59212]},"properties":{"population":560580,"capital":4,"place":"city","name":"46B85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.8265,1.10257]},"properties":{"population":309104,"capital":4,"place":"city","name":"46B87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.14586,-4.26931]},"properties":{"population":771690,"capital":4,"place":"city","name":"46B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.2776,-5.07033]},"properties":{"population":514749,"capital":4,"place":"city","name":"46Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.58811,-4.12973]},"properties":{"population":755538,"capital":4,"place":"city","name":"46C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.3393,-5.27745]},"properties":{"population":481218,"capital":4,"place":"city","name":"46C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.67691,-2.52313]},"properties":{"population":614418,"capital":4,"place":"city","name":"46C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.25549,2.99248]},"properties":{"population":674993,"capital":4,"place":"city","name":"46C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.30285,-7.22054]},"properties":{"population":595324,"capital":4,"place":"city","name":"46C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.42169,12.04747]},"properties":{"population":726822,"capital":4,"place":"city","name":"46C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.32953,-4.81528]},"properties":{"population":470048,"capital":4,"place":"city","name":"46Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.1095,-0.83498]},"properties":{"population":615060,"capital":4,"place":"city","name":"46E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.86656,-4.22813]},"properties":{"population":785515,"capital":4,"place":"city","name":"46E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.38284,8.01671]},"properties":{"population":836962,"capital":4,"place":"city","name":"46E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.71666,8.69637]},"properties":{"population":707749,"capital":4,"place":"city","name":"46E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.67467,15.49032]},"properties":{"population":746393,"capital":4,"place":"city","name":"46E22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.22833,0.34133]},"properties":{"population":698749,"capital":4,"place":"city","name":"46E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.03423,4.40276]},"properties":{"population":641999,"capital":4,"place":"city","name":"46E27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.75313,-4.04514]},"properties":{"population":935988,"capital":4,"place":"city","name":"46E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.21774,-3.32072]},"properties":{"population":945430,"capital":4,"place":"city","name":"46E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.10321,-5.0488]},"properties":{"population":389182,"capital":4,"place":"city","name":"46E36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.27595,0.93297]},"properties":{"population":524174,"capital":4,"place":"city","name":"46E39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.74063,7.74409]},"properties":{"population":794200,"capital":4,"place":"city","name":"46E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.68286,-2.55899]},"properties":{"population":609130,"capital":4,"place":"city","name":"46E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.71444,2.01821]},"properties":{"population":604025,"capital":4,"place":"city","name":"46E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.38879,-5.75012]},"properties":{"population":478749,"capital":4,"place":"city","name":"46Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.22853,5.09386]},"properties":{"population":773848,"capital":4,"place":"city","name":"46F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.94761,-2.88541]},"properties":{"population":806306,"capital":4,"place":"city","name":"46F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.96401,-3.06051]},"properties":{"population":769439,"capital":4,"place":"city","name":"46F12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.25135,-3.78403]},"properties":{"population":674288,"capital":4,"place":"city","name":"46F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27814,-10.1957]},"properties":{"population":599893,"capital":4,"place":"city","name":"46F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.61698,-1.19607]},"properties":{"population":619847,"capital":4,"place":"city","name":"46F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.42977,1.45615]},"properties":{"population":628226,"capital":4,"place":"city","name":"46F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.61493,4.20155]},"properties":{"population":635088,"capital":4,"place":"city","name":"46F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.18106,-5.3888]},"properties":{"population":485203,"capital":4,"place":"city","name":"46Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.65599,7.53951]},"properties":{"population":778655,"capital":4,"place":"city","name":"46G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.99448,-3.57629]},"properties":{"population":799934,"capital":4,"place":"city","name":"46G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.29295,-4.37295]},"properties":{"population":694697,"capital":4,"place":"city","name":"46G12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.87509,-4.28091]},"properties":{"population":509986,"capital":4,"place":"city","name":"46G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.69463,-1.61613]},"properties":{"population":734407,"capital":4,"place":"city","name":"46G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.95905,-4.14234]},"properties":{"population":666313,"capital":4,"place":"city","name":"46G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.29339,-6.48304]},"properties":{"population":576519,"capital":4,"place":"city","name":"46G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.83097,-0.32158]},"properties":{"population":270805,"capital":4,"place":"city","name":"46Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.70695,18.24921]},"properties":{"population":790396,"capital":4,"place":"city","name":"46H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.9171,17.80961]},"properties":{"population":664768,"capital":4,"place":"city","name":"46H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.76685,17.50645]},"properties":{"population":584064,"capital":4,"place":"city","name":"46H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.90142,17.76474]},"properties":{"population":673578,"capital":4,"place":"city","name":"46H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.64437,18.01191]},"properties":{"population":750052,"capital":4,"place":"city","name":"46H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.72657,17.8388]},"properties":{"population":605678,"capital":4,"place":"city","name":"46H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.63718,17.74902]},"properties":{"population":523644,"capital":4,"place":"city","name":"46H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.77278,17.17748]},"properties":{"population":592425,"capital":4,"place":"city","name":"46H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.86817,17.35562]},"properties":{"population":602344,"capital":4,"place":"city","name":"46H70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.53995,18.11587]},"properties":{"population":630627,"capital":4,"place":"city","name":"46H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.87154,17.17645]},"properties":{"population":402535,"capital":4,"place":"city","name":"46Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.84925,21.95696]},"properties":{"population":686171,"capital":4,"place":"city","name":"46J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.19012,20.80682]},"properties":{"population":783991,"capital":4,"place":"city","name":"46J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.68039,20.71557]},"properties":{"population":808548,"capital":4,"place":"city","name":"46J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.04682,21.73188]},"properties":{"population":714203,"capital":4,"place":"city","name":"46J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.7508,21.75891]},"properties":{"population":528826,"capital":4,"place":"city","name":"46J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.16354,21.54305]},"properties":{"population":556834,"capital":4,"place":"city","name":"46J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.31133,21.65961]},"properties":{"population":529831,"capital":4,"place":"city","name":"46J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.45274,21.425]},"properties":{"population":443081,"capital":4,"place":"city","name":"46J45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.8961,21.91247]},"properties":{"population":549716,"capital":4,"place":"city","name":"46J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.39763,21.78782]},"properties":{"population":389182,"capital":4,"place":"city","name":"46Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.80277,4.23498]},"properties":{"population":633859,"capital":4,"place":"city","name":"46K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.41531,4.37742]},"properties":{"population":618620,"capital":4,"place":"city","name":"46K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.93411,4.51474]},"properties":{"population":548893,"capital":4,"place":"city","name":"46K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.3113,4.23557]},"properties":{"population":444265,"capital":4,"place":"city","name":"46K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.24613,4.34969]},"properties":{"population":508140,"capital":4,"place":"city","name":"46K70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.42252,4.26975]},"properties":{"population":520400,"capital":4,"place":"city","name":"46K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.07256,4.17816]},"properties":{"population":248490,"capital":4,"place":"city","name":"46Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.27171,7.21159]},"properties":{"population":888044,"capital":4,"place":"city","name":"46L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.83093,-2.63815]},"properties":{"population":573009,"capital":4,"place":"city","name":"46L06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.01466,2.73278]},"properties":{"population":691869,"capital":4,"place":"city","name":"46L07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.04152,1.99991]},"properties":{"population":686901,"capital":4,"place":"city","name":"46L08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.55718,-3.39523]},"properties":{"population":524702,"capital":4,"place":"city","name":"46L09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.14357,6.7055]},"properties":{"population":818200,"capital":4,"place":"city","name":"46L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.22744,1.61277]},"properties":{"population":700850,"capital":4,"place":"city","name":"46L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.59262,-1.15343]},"properties":{"population":762803,"capital":4,"place":"city","name":"46L35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.60667,-4.57747]},"properties":{"population":542053,"capital":4,"place":"city","name":"46L36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.60545,-6.79855]},"properties":{"population":661472,"capital":4,"place":"city","name":"46L37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.07868,-3.13873]},"properties":{"population":713886,"capital":4,"place":"city","name":"46L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.90622,-4.25496]},"properties":{"population":549306,"capital":4,"place":"city","name":"46L45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.66116,-0.67261]},"properties":{"population":138629,"capital":4,"place":"city","name":"46L50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.99895,-10.54445]},"properties":{"population":738647,"capital":4,"place":"city","name":"46L51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.43563,-3.55659]},"properties":{"population":645833,"capital":4,"place":"city","name":"46L52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.75725,-3.81958]},"properties":{"population":764012,"capital":4,"place":"city","name":"46L53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.81176,-1.82768]},"properties":{"population":764969,"capital":4,"place":"city","name":"46L54"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.53014,8.43552]},"properties":{"population":821554,"capital":4,"place":"city","name":"46L55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.44252,-3.73839]},"properties":{"population":649375,"capital":4,"place":"city","name":"46L57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.54202,4.54206]},"properties":{"population":756371,"capital":4,"place":"city","name":"46L60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.80359,-4.1867]},"properties":{"population":637842,"capital":4,"place":"city","name":"46L65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.19616,-2.04237]},"properties":{"population":397029,"capital":4,"place":"city","name":"46L67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.29595,-6.2067]},"properties":{"population":622653,"capital":4,"place":"city","name":"46L70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.69321,6.80118]},"properties":{"population":779893,"capital":4,"place":"city","name":"46L80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.5206,0.04046]},"properties":{"population":700760,"capital":4,"place":"city","name":"46L85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.50535,2.43823]},"properties":{"population":733171,"capital":4,"place":"city","name":"46L87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.40053,-1.541]},"properties":{"population":659987,"capital":4,"place":"city","name":"46L89"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.49905,0.34604]},"properties":{"population":635262,"capital":4,"place":"city","name":"46L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.88489,-4.13746]},"properties":{"population":504342,"capital":4,"place":"city","name":"46Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.49971,1.05922]},"properties":{"population":745529,"capital":4,"place":"city","name":"46M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.11294,-4.37972]},"properties":{"population":463472,"capital":4,"place":"city","name":"46M07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.81345,-6.49068]},"properties":{"population":592692,"capital":4,"place":"city","name":"46M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.31313,-5.88076]},"properties":{"population":631716,"capital":4,"place":"city","name":"46M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.01643,-5.98003]},"properties":{"population":563478,"capital":4,"place":"city","name":"46M18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.12779,3.40806]},"properties":{"population":718006,"capital":4,"place":"city","name":"46M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.99341,-4.24522]},"properties":{"population":725841,"capital":4,"place":"city","name":"46M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.69575,-6.54223]},"properties":{"population":619236,"capital":4,"place":"city","name":"46M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.71183,-5.88131]},"properties":{"population":501727,"capital":4,"place":"city","name":"46M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.87582,-1.70514]},"properties":{"population":270805,"capital":4,"place":"city","name":"46Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.09187,6.94295]},"properties":{"population":712769,"capital":4,"place":"city","name":"46N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.88596,-2.34424]},"properties":{"population":625958,"capital":4,"place":"city","name":"46N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.36231,9.81141]},"properties":{"population":659578,"capital":4,"place":"city","name":"46N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.12825,0.51763]},"properties":{"population":489783,"capital":4,"place":"city","name":"46N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.42477,5.05095]},"properties":{"population":751643,"capital":4,"place":"city","name":"46N50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.42576,-2.13583]},"properties":{"population":529330,"capital":4,"place":"city","name":"46N55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.58884,-1.71574]},"properties":{"population":470048,"capital":4,"place":"city","name":"46N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.45108,15.21249]},"properties":{"population":727862,"capital":4,"place":"city","name":"46N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.7587,-1.50142]},"properties":{"population":277258,"capital":4,"place":"city","name":"46Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.34385,0.15299]},"properties":{"population":248490,"capital":4,"place":"city","name":"46S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.36656,-4.86126]},"properties":{"population":734148,"capital":4,"place":"city","name":"46S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.13686,-5.53515]},"properties":{"population":586078,"capital":4,"place":"city","name":"46S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.96861,-4.68781]},"properties":{"population":499043,"capital":4,"place":"city","name":"46S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.72288,-3.43292]},"properties":{"population":682437,"capital":4,"place":"city","name":"46S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.27996,-6.59044]},"properties":{"population":575257,"capital":4,"place":"city","name":"46S50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.13107,-4.69105]},"properties":{"population":566642,"capital":4,"place":"city","name":"46S60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.46611,-1.63945]},"properties":{"population":355534,"capital":4,"place":"city","name":"46S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.50941,-0.13825]},"properties":{"population":109861,"capital":4,"place":"city","name":"46Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.75261,21.01117]},"properties":{"population":475359,"capital":4,"place":"city","name":"46T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.31084,20.56835]},"properties":{"population":385014,"capital":4,"place":"city","name":"46T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.25155,21.20962]},"properties":{"population":485203,"capital":4,"place":"city","name":"46T12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.51786,20.9592]},"properties":{"population":586646,"capital":4,"place":"city","name":"46T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.19156,19.54746]},"properties":{"population":433073,"capital":4,"place":"city","name":"46T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.79556,19.50611]},"properties":{"population":467282,"capital":4,"place":"city","name":"46T30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.16714,19.86146]},"properties":{"population":479579,"capital":4,"place":"city","name":"46T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.5938,20.67916]},"properties":{"population":263905,"capital":4,"place":"city","name":"46Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.69776,10.581]},"properties":{"population":352636,"capital":4,"place":"city","name":"47-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.34554,13.38593]},"properties":{"population":630809,"capital":4,"place":"city","name":"47-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.18776,18.84287]},"properties":{"population":724992,"capital":4,"place":"city","name":"47-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.68094,6.08814]},"properties":{"population":525227,"capital":4,"place":"city","name":"47-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.19024,10.80529]},"properties":{"population":349650,"capital":4,"place":"city","name":"47-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.17531,-4.87149]},"properties":{"population":689162,"capital":4,"place":"city","name":"47-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.68774,5.93479]},"properties":{"population":69314,"capital":4,"place":"city","name":"47-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.40876,8.41911]},"properties":{"population":749609,"capital":4,"place":"city","name":"47-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.45242,17.82604]},"properties":{"population":808023,"capital":4,"place":"city","name":"47A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.86852,7.33781]},"properties":{"population":600881,"capital":4,"place":"city","name":"47A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.33731,10.3237]},"properties":{"population":589440,"capital":4,"place":"city","name":"47A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.91916,8.08747]},"properties":{"population":366356,"capital":4,"place":"city","name":"47A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.70095,-13.41929]},"properties":{"population":909582,"capital":4,"place":"city","name":"47A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.82374,9.9345]},"properties":{"population":654965,"capital":4,"place":"city","name":"47A11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.2697,11.91049]},"properties":{"population":722766,"capital":4,"place":"city","name":"47A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.03645,11.13504]},"properties":{"population":689568,"capital":4,"place":"city","name":"47A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.77946,13.96308]},"properties":{"population":805515,"capital":4,"place":"city","name":"47A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.08045,8.76943]},"properties":{"population":707749,"capital":4,"place":"city","name":"47A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.33978,-6.70974]},"properties":{"population":778655,"capital":4,"place":"city","name":"47A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.6218,10.65447]},"properties":{"population":625958,"capital":4,"place":"city","name":"47A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.43631,15.49018]},"properties":{"population":777904,"capital":4,"place":"city","name":"47A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.31977,17.0701]},"properties":{"population":761726,"capital":4,"place":"city","name":"47A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.48646,-6.79992]},"properties":{"population":778364,"capital":4,"place":"city","name":"47A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.40519,12.36811]},"properties":{"population":724136,"capital":4,"place":"city","name":"47A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.48843,8.11028]},"properties":{"population":435670,"capital":4,"place":"city","name":"47A46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.25556,8.26713]},"properties":{"population":602827,"capital":4,"place":"city","name":"47A48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.85616,19.07059]},"properties":{"population":798514,"capital":4,"place":"city","name":"47A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.65267,18.28252]},"properties":{"population":687626,"capital":4,"place":"city","name":"47A52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.57975,19.93766]},"properties":{"population":817526,"capital":4,"place":"city","name":"47A53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.71786,18.37462]},"properties":{"population":826642,"capital":4,"place":"city","name":"47A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.03067,16.3426]},"properties":{"population":759538,"capital":4,"place":"city","name":"47A56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.39184,13.52239]},"properties":{"population":715851,"capital":4,"place":"city","name":"47A57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.2337,-11.75505]},"properties":{"population":658755,"capital":4,"place":"city","name":"47A58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.48337,19.17631]},"properties":{"population":781439,"capital":4,"place":"city","name":"47A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.13113,11.60094]},"properties":{"population":647079,"capital":4,"place":"city","name":"47A62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.39441,10.55543]},"properties":{"population":758477,"capital":4,"place":"city","name":"47A63"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.77824,8.77706]},"properties":{"population":624804,"capital":4,"place":"city","name":"47A64"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.65442,9.38748]},"properties":{"population":695463,"capital":4,"place":"city","name":"47A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.79811,6.52789]},"properties":{"population":560947,"capital":4,"place":"city","name":"47A66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.13601,8.82148]},"properties":{"population":585793,"capital":4,"place":"city","name":"47A67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.20929,17.53871]},"properties":{"population":698564,"capital":4,"place":"city","name":"47A68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.81604,19.58504]},"properties":{"population":732974,"capital":4,"place":"city","name":"47A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.22198,18.6745]},"properties":{"population":718311,"capital":4,"place":"city","name":"47A75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.43656,8.46485]},"properties":{"population":553338,"capital":4,"place":"city","name":"47A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.49735,10.63117]},"properties":{"population":619847,"capital":4,"place":"city","name":"47A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.35295,6.12327]},"properties":{"population":539362,"capital":4,"place":"city","name":"47Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.18688,6.25501]},"properties":{"population":417438,"capital":4,"place":"city","name":"47B01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.90105,6.50174]},"properties":{"population":441884,"capital":4,"place":"city","name":"47B02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.72823,18.45245]},"properties":{"population":759839,"capital":4,"place":"city","name":"47B06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.52888,13.78626]},"properties":{"population":719893,"capital":4,"place":"city","name":"47B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.8544,-11.68769]},"properties":{"population":825816,"capital":4,"place":"city","name":"47B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.19392,6.22712]},"properties":{"population":219722,"capital":4,"place":"city","name":"47B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58736,6.08496]},"properties":{"population":256494,"capital":4,"place":"city","name":"47B13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.30845,-11.53062]},"properties":{"population":787016,"capital":4,"place":"city","name":"47B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.00408,8.35983]},"properties":{"population":792371,"capital":4,"place":"city","name":"47B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.59337,-13.34768]},"properties":{"population":812799,"capital":4,"place":"city","name":"47B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.26752,6.34502]},"properties":{"population":263905,"capital":4,"place":"city","name":"47B28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.76774,12.06087]},"properties":{"population":665672,"capital":4,"place":"city","name":"47B32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.6757,8.13053]},"properties":{"population":779729,"capital":4,"place":"city","name":"47B33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.08608,8.3112]},"properties":{"population":551342,"capital":4,"place":"city","name":"47B34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.90568,18.99417]},"properties":{"population":854325,"capital":4,"place":"city","name":"47B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.24193,12.42889]},"properties":{"population":665027,"capital":4,"place":"city","name":"47B36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.28448,-6.6894]},"properties":{"population":801301,"capital":4,"place":"city","name":"47B37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.72939,18.84481]},"properties":{"population":883200,"capital":4,"place":"city","name":"47B38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.81164,15.292]},"properties":{"population":697728,"capital":4,"place":"city","name":"47B39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.55494,13.33748]},"properties":{"population":724636,"capital":4,"place":"city","name":"47B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.67579,16.85866]},"properties":{"population":688653,"capital":4,"place":"city","name":"47B44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.02282,10.06611]},"properties":{"population":791461,"capital":4,"place":"city","name":"47B47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.36621,9.21548]},"properties":{"population":665027,"capital":4,"place":"city","name":"47B48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.40496,8.90684]},"properties":{"population":676619,"capital":4,"place":"city","name":"47B49"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.82748,11.71672]},"properties":{"population":716549,"capital":4,"place":"city","name":"47B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.38671,13.17119]},"properties":{"population":739939,"capital":4,"place":"city","name":"47B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.00939,11.95128]},"properties":{"population":732778,"capital":4,"place":"city","name":"47B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.05798,17.73676]},"properties":{"population":640522,"capital":4,"place":"city","name":"47B80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.2925,7.13538]},"properties":{"population":138629,"capital":4,"place":"city","name":"47B90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.15253,7.15988]},"properties":{"population":138629,"capital":4,"place":"city","name":"47B91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.63097,6.30081]},"properties":{"population":263905,"capital":4,"place":"city","name":"47B93"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.05842,11.46766]},"properties":{"population":681563,"capital":4,"place":"city","name":"47B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.2953,7.33203]},"properties":{"population":495582,"capital":4,"place":"city","name":"47Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.71445,18.70753]},"properties":{"population":613122,"capital":4,"place":"city","name":"47C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.06081,18.23904]},"properties":{"population":534233,"capital":4,"place":"city","name":"47C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.38594,18.86589]},"properties":{"population":658202,"capital":4,"place":"city","name":"47C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.25171,18.06434]},"properties":{"population":371357,"capital":4,"place":"city","name":"47C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.24953,17.56465]},"properties":{"population":69314,"capital":4,"place":"city","name":"47Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.20344,-6.83356]},"properties":{"population":846273,"capital":4,"place":"city","name":"47D03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.10263,-6.63329]},"properties":{"population":856598,"capital":4,"place":"city","name":"47D06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.4621,19.75142]},"properties":{"population":733302,"capital":4,"place":"city","name":"47D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.14075,12.44309]},"properties":{"population":562040,"capital":4,"place":"city","name":"47D08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.84072,7.4969]},"properties":{"population":614846,"capital":4,"place":"city","name":"47D09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.7168,11.65272]},"properties":{"population":248490,"capital":4,"place":"city","name":"47D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.07749,11.33819]},"properties":{"population":160943,"capital":4,"place":"city","name":"47D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.91525,11.35]},"properties":{"population":69314,"capital":4,"place":"city","name":"47D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.20246,11.32827]},"properties":{"population":109861,"capital":4,"place":"city","name":"47D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.40374,7.29945]},"properties":{"population":593224,"capital":4,"place":"city","name":"47D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.20461,6.69227]},"properties":{"population":560947,"capital":4,"place":"city","name":"47D62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.31374,7.67632]},"properties":{"population":584932,"capital":4,"place":"city","name":"47D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.61547,9.52721]},"properties":{"population":400733,"capital":4,"place":"city","name":"47Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.3202,26.06678]},"properties":{"population":859267,"capital":4,"place":"city","name":"47E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.96232,24.25916]},"properties":{"population":160943,"capital":4,"place":"city","name":"47E07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.95278,24.23449]},"properties":{"population":207944,"capital":4,"place":"city","name":"47E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.95711,24.23926]},"properties":{"population":256494,"capital":4,"place":"city","name":"47Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.41436,-11.58495]},"properties":{"population":858410,"capital":4,"place":"city","name":"47F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.73489,9.36171]},"properties":{"population":329583,"capital":4,"place":"city","name":"47F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.77707,11.31604]},"properties":{"population":207944,"capital":4,"place":"city","name":"47F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.56341,11.64252]},"properties":{"population":270805,"capital":4,"place":"city","name":"47Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.24279,-7.70256]},"properties":{"population":805864,"capital":4,"place":"city","name":"47G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.2306,18.2943]},"properties":{"population":686066,"capital":4,"place":"city","name":"47G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.24178,-13.15267]},"properties":{"population":769666,"capital":4,"place":"city","name":"47G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.1065,6.66394]},"properties":{"population":484418,"capital":4,"place":"city","name":"47G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.12221,6.46047]},"properties":{"population":441884,"capital":4,"place":"city","name":"47G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.79946,17.30918]},"properties":{"population":777317,"capital":4,"place":"city","name":"47Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.00943,14.79788]},"properties":{"population":799463,"capital":4,"place":"city","name":"47H04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.31724,18.67324]},"properties":{"population":841560,"capital":4,"place":"city","name":"47H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.4417,-13.39955]},"properties":{"population":757044,"capital":4,"place":"city","name":"47H06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.45529,-7.77188]},"properties":{"population":736707,"capital":4,"place":"city","name":"47H07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29413,8.85708]},"properties":{"population":633327,"capital":4,"place":"city","name":"47H08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.38071,-7.67533]},"properties":{"population":907909,"capital":4,"place":"city","name":"47H09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.54748,-13.36276]},"properties":{"population":981547,"capital":4,"place":"city","name":"47H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.28982,13.59639]},"properties":{"population":725770,"capital":4,"place":"city","name":"47H11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.21695,11.3687]},"properties":{"population":109861,"capital":4,"place":"city","name":"47H12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.39015,8.50023]},"properties":{"population":570378,"capital":4,"place":"city","name":"47H14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.05058,11.51555]},"properties":{"population":239789,"capital":4,"place":"city","name":"47H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.05599,9.09327]},"properties":{"population":391202,"capital":4,"place":"city","name":"47H17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.41309,11.5688]},"properties":{"population":160943,"capital":4,"place":"city","name":"47H19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.24692,18.35965]},"properties":{"population":768616,"capital":4,"place":"city","name":"47H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.63117,8.49974]},"properties":{"population":474493,"capital":4,"place":"city","name":"47H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.31773,12.29788]},"properties":{"population":706304,"capital":4,"place":"city","name":"47H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.19907,8.04239]},"properties":{"population":615485,"capital":4,"place":"city","name":"47H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.649,9.38934]},"properties":{"population":363758,"capital":4,"place":"city","name":"47H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58084,7.71393]},"properties":{"population":593224,"capital":4,"place":"city","name":"47H60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.04884,14.26259]},"properties":{"population":677308,"capital":4,"place":"city","name":"47H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.60112,6.14575]},"properties":{"population":500394,"capital":4,"place":"city","name":"47Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.5118,-13.49959]},"properties":{"population":846800,"capital":4,"place":"city","name":"47J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.77824,10.66306]},"properties":{"population":643615,"capital":4,"place":"city","name":"47J06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.89966,7.12783]},"properties":{"population":529330,"capital":4,"place":"city","name":"47J07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.19816,16.35855]},"properties":{"population":686380,"capital":4,"place":"city","name":"47J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.44502,11.73727]},"properties":{"population":617378,"capital":4,"place":"city","name":"47J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.31568,17.98428]},"properties":{"population":817159,"capital":4,"place":"city","name":"47J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.30104,7.98252]},"properties":{"population":638181,"capital":4,"place":"city","name":"47J22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.36855,-7.79628]},"properties":{"population":922660,"capital":4,"place":"city","name":"47J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.57685,6.08065]},"properties":{"population":536129,"capital":4,"place":"city","name":"47J26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.55268,16.78325]},"properties":{"population":728000,"capital":4,"place":"city","name":"47J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.66884,16.19568]},"properties":{"population":679122,"capital":4,"place":"city","name":"47J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.90171,8.78975]},"properties":{"population":538449,"capital":4,"place":"city","name":"47J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.38588,6.74542]},"properties":{"population":346573,"capital":4,"place":"city","name":"47J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.92844,6.18539]},"properties":{"population":420469,"capital":4,"place":"city","name":"47Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.09387,22.71056]},"properties":{"population":690675,"capital":4,"place":"city","name":"47L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.74041,22.83828]},"properties":{"population":610479,"capital":4,"place":"city","name":"47L07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.48936,22.74216]},"properties":{"population":721890,"capital":4,"place":"city","name":"47L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.28795,22.83595]},"properties":{"population":464439,"capital":4,"place":"city","name":"47L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.79822,22.98397]},"properties":{"population":653233,"capital":4,"place":"city","name":"47L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.81445,22.27028]},"properties":{"population":465396,"capital":4,"place":"city","name":"47L22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.65853,22.79187]},"properties":{"population":610479,"capital":4,"place":"city","name":"47L25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.91735,22.62528]},"properties":{"population":746049,"capital":4,"place":"city","name":"47L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.82411,22.76385]},"properties":{"population":626339,"capital":4,"place":"city","name":"47L35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.95006,22.28937]},"properties":{"population":491998,"capital":4,"place":"city","name":"47L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.85085,22.33189]},"properties":{"population":503043,"capital":4,"place":"city","name":"47L45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.80113,22.31599]},"properties":{"population":550533,"capital":4,"place":"city","name":"47L50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.93235,22.56715]},"properties":{"population":525227,"capital":4,"place":"city","name":"47L55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.28999,22.87914]},"properties":{"population":628785,"capital":4,"place":"city","name":"47L60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.61426,22.75938]},"properties":{"population":492725,"capital":4,"place":"city","name":"47L65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.75773,22.46568]},"properties":{"population":343398,"capital":4,"place":"city","name":"47L70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.27057,22.48179]},"properties":{"population":486753,"capital":4,"place":"city","name":"47L75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.59828,22.88527]},"properties":{"population":632972,"capital":4,"place":"city","name":"47L80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.03832,23.04642]},"properties":{"population":573979,"capital":4,"place":"city","name":"47L90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.82671,22.92832]},"properties":{"population":414313,"capital":4,"place":"city","name":"47L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.74965,22.672]},"properties":{"population":294443,"capital":4,"place":"city","name":"47Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.76944,26.19318]},"properties":{"population":710742,"capital":4,"place":"city","name":"47N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.1377,25.94188]},"properties":{"population":897246,"capital":4,"place":"city","name":"47N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.64247,26.39875]},"properties":{"population":579605,"capital":4,"place":"city","name":"47N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.61776,27.21243]},"properties":{"population":522035,"capital":4,"place":"city","name":"47N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.3621,26.01941]},"properties":{"population":778280,"capital":4,"place":"city","name":"47N50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.61922,27.41958]},"properties":{"population":569373,"capital":4,"place":"city","name":"47N55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.61956,27.20635]},"properties":{"population":561677,"capital":4,"place":"city","name":"47N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.76208,27.33137]},"properties":{"population":615273,"capital":4,"place":"city","name":"47N70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.87026,27.47192]},"properties":{"population":452178,"capital":4,"place":"city","name":"47N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.57881,27.8187]},"properties":{"population":358351,"capital":4,"place":"city","name":"47Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.73481,18.87859]},"properties":{"population":299573,"capital":4,"place":"city","name":"47S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.76726,19.3361]},"properties":{"population":576832,"capital":4,"place":"city","name":"47S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.27096,19.02995]},"properties":{"population":431748,"capital":4,"place":"city","name":"47S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.16133,19.06838]},"properties":{"population":407753,"capital":4,"place":"city","name":"47S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27289,18.38248]},"properties":{"population":590808,"capital":4,"place":"city","name":"47S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.40541,18.93714]},"properties":{"population":544673,"capital":4,"place":"city","name":"47S50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.62561,18.83488]},"properties":{"population":294443,"capital":4,"place":"city","name":"47S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.59968,18.86855]},"properties":{"population":69314,"capital":4,"place":"city","name":"47Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.03886,25.56174]},"properties":{"population":373766,"capital":4,"place":"city","name":"49-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.83888,26.23819]},"properties":{"population":659030,"capital":4,"place":"city","name":"49-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.63361,26.96519]},"properties":{"population":693147,"capital":4,"place":"city","name":"49-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.51363,25.80489]},"properties":{"population":540717,"capital":4,"place":"city","name":"49-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.1412,25.87351]},"properties":{"population":475359,"capital":4,"place":"city","name":"49-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.8772,25.83528]},"properties":{"population":690775,"capital":4,"place":"city","name":"49-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.99204,25.57465]},"properties":{"population":630991,"capital":4,"place":"city","name":"49-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.08886,11.01391]},"properties":{"population":591350,"capital":4,"place":"city","name":"49J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.83759,10.25661]},"properties":{"population":698749,"capital":4,"place":"city","name":"49J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.87797,19.43159]},"properties":{"population":833014,"capital":4,"place":"city","name":"49J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.96827,-13.44067]},"properties":{"population":868406,"capital":4,"place":"city","name":"49J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.54317,9.43065]},"properties":{"population":581413,"capital":4,"place":"city","name":"49J21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.34157,5.82312]},"properties":{"population":510594,"capital":4,"place":"city","name":"49J22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.17945,4.08483]},"properties":{"population":604500,"capital":4,"place":"city","name":"49J24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.1734,3.63752]},"properties":{"population":540267,"capital":4,"place":"city","name":"49J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.33953,-7.20713]},"properties":{"population":770391,"capital":4,"place":"city","name":"49J27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.80645,11.62858]},"properties":{"population":718992,"capital":4,"place":"city","name":"49J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.48483,18.31859]},"properties":{"population":769712,"capital":4,"place":"city","name":"49J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.6797,20.9636]},"properties":{"population":933220,"capital":4,"place":"city","name":"49J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.64301,19.53134]},"properties":{"population":860959,"capital":4,"place":"city","name":"49J45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.98705,-11.62016]},"properties":{"population":675460,"capital":4,"place":"city","name":"49J50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.99277,-11.63454]},"properties":{"population":860721,"capital":4,"place":"city","name":"49J52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.90162,8.697]},"properties":{"population":800636,"capital":4,"place":"city","name":"49J53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.86432,13.2771]},"properties":{"population":697354,"capital":4,"place":"city","name":"49J55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.66879,11.14595]},"properties":{"population":698007,"capital":4,"place":"city","name":"49J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.5357,1.20563]},"properties":{"population":482028,"capital":4,"place":"city","name":"49Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.48506,10.01855]},"properties":{"population":641509,"capital":4,"place":"city","name":"49K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.00001,6.33467]},"properties":{"population":663987,"capital":4,"place":"city","name":"49K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.81867,19.13338]},"properties":{"population":858671,"capital":4,"place":"city","name":"49K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.28924,-7.29837]},"properties":{"population":840536,"capital":4,"place":"city","name":"49K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.89131,5.84443]},"properties":{"population":632435,"capital":4,"place":"city","name":"49K21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.85639,2.14832]},"properties":{"population":486753,"capital":4,"place":"city","name":"49K22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.327,-11.44751]},"properties":{"population":571373,"capital":4,"place":"city","name":"49K24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.55201,-1.60419]},"properties":{"population":550125,"capital":4,"place":"city","name":"49K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.17204,7.92154]},"properties":{"population":759789,"capital":4,"place":"city","name":"49K27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.90218,9.91643]},"properties":{"population":705272,"capital":4,"place":"city","name":"49K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.52566,12.07158]},"properties":{"population":709174,"capital":4,"place":"city","name":"49K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.13362,16.78737]},"properties":{"population":789170,"capital":4,"place":"city","name":"49K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.01318,12.76073]},"properties":{"population":715539,"capital":4,"place":"city","name":"49K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.6383,8.46587]},"properties":{"population":725841,"capital":4,"place":"city","name":"49K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.3394,-0.35588]},"properties":{"population":459511,"capital":4,"place":"city","name":"49Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.0533,29.40998]},"properties":{"population":138629,"capital":4,"place":"city","name":"49L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.03408,29.33112]},"properties":{"population":219722,"capital":4,"place":"city","name":"49L12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.98706,28.60512]},"properties":{"population":806714,"capital":4,"place":"city","name":"49L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.84538,28.61133]},"properties":{"population":764491,"capital":4,"place":"city","name":"49L25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.70157,29.17252]},"properties":{"population":739939,"capital":4,"place":"city","name":"49L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.99993,29.47516]},"properties":{"population":400733,"capital":4,"place":"city","name":"49Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.72644,26.38101]},"properties":{"population":716703,"capital":4,"place":"city","name":"49M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.21812,27.09793]},"properties":{"population":138629,"capital":4,"place":"city","name":"49M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.14301,25.6321]},"properties":{"population":799564,"capital":4,"place":"city","name":"49M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.14785,26.48507]},"properties":{"population":643294,"capital":4,"place":"city","name":"49M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.97465,25.69751]},"properties":{"population":795331,"capital":4,"place":"city","name":"49M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.42223,26.37708]},"properties":{"population":702997,"capital":4,"place":"city","name":"49M27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.38852,26.33997]},"properties":{"population":672503,"capital":4,"place":"city","name":"49M29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.36264,25.31969]},"properties":{"population":802387,"capital":4,"place":"city","name":"49M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.95275,26.01769]},"properties":{"population":805864,"capital":4,"place":"city","name":"49M37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.14931,27.08017]},"properties":{"population":69314,"capital":4,"place":"city","name":"49M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.22113,26.8618]},"properties":{"population":411087,"capital":4,"place":"city","name":"49M41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.13611,26.80831]},"properties":{"population":69314,"capital":4,"place":"city","name":"49M45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.52645,26.3649]},"properties":{"population":707072,"capital":4,"place":"city","name":"49M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.04536,26.64589]},"properties":{"population":484418,"capital":4,"place":"city","name":"49Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.70981,28.781]},"properties":{"population":627287,"capital":4,"place":"city","name":"49N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.00536,26.47892]},"properties":{"population":788795,"capital":4,"place":"city","name":"49N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.1064,26.59292]},"properties":{"population":779975,"capital":4,"place":"city","name":"49N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.30156,28.80742]},"properties":{"population":496284,"capital":4,"place":"city","name":"49N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.60095,28.87204]},"properties":{"population":615485,"capital":4,"place":"city","name":"49N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.55617,28.81008]},"properties":{"population":520400,"capital":4,"place":"city","name":"49N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.54224,28.40715]},"properties":{"population":683840,"capital":4,"place":"city","name":"49N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.97637,27.53595]},"properties":{"population":685856,"capital":4,"place":"city","name":"49N45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.65463,29.05121]},"properties":{"population":109861,"capital":4,"place":"city","name":"49N55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.08569,26.61375]},"properties":{"population":748155,"capital":4,"place":"city","name":"49N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.08744,26.9454]},"properties":{"population":749220,"capital":4,"place":"city","name":"49N70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.78351,28.03127]},"properties":{"population":695272,"capital":4,"place":"city","name":"49N75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.5041,28.8907]},"properties":{"population":378418,"capital":4,"place":"city","name":"49N80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.77863,26.06257]},"properties":{"population":817526,"capital":4,"place":"city","name":"49N90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.04368,27.29105]},"properties":{"population":578382,"capital":4,"place":"city","name":"49N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.83141,28.84637]},"properties":{"population":329583,"capital":4,"place":"city","name":"49Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.41553,9.69122]},"properties":{"population":749831,"capital":4,"place":"city","name":"49Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.12481,-5.76316]},"properties":{"population":777863,"capital":4,"place":"city","name":"49Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.05287,7.3112]},"properties":{"population":599146,"capital":4,"place":"city","name":"49Q12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.37804,8.2913]},"properties":{"population":718614,"capital":4,"place":"city","name":"49Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.60477,19.28232]},"properties":{"population":797384,"capital":4,"place":"city","name":"49Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.21439,-0.89125]},"properties":{"population":456434,"capital":4,"place":"city","name":"49Q22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.66181,-3.51614]},"properties":{"population":69314,"capital":4,"place":"city","name":"49Q25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.40805,10.107]},"properties":{"population":590536,"capital":4,"place":"city","name":"49Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.30183,-2.68221]},"properties":{"population":313549,"capital":4,"place":"city","name":"49Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.7923,7.12472]},"properties":{"population":594279,"capital":4,"place":"city","name":"49R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.13035,-2.98369]},"properties":{"population":69314,"capital":4,"place":"city","name":"49R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.48729,-6.79612]},"properties":{"population":687316,"capital":4,"place":"city","name":"49R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.432,-3.09226]},"properties":{"population":160943,"capital":4,"place":"city","name":"49R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.62582,-2.81483]},"properties":{"population":207944,"capital":4,"place":"city","name":"49Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.63976,28.74616]},"properties":{"population":816451,"capital":4,"place":"city","name":"49S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.77898,28.71828]},"properties":{"population":256494,"capital":4,"place":"city","name":"49S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.79157,28.70002]},"properties":{"population":160943,"capital":4,"place":"city","name":"49Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.81267,10.16971]},"properties":{"population":382864,"capital":4,"place":"city","name":"51-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.47815,11.44782]},"properties":{"population":731853,"capital":4,"place":"city","name":"51-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.97038,11.72339]},"properties":{"population":617794,"capital":4,"place":"city","name":"51-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.88729,11.23525]},"properties":{"population":729641,"capital":4,"place":"city","name":"51-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.76933,11.20379]},"properties":{"population":542934,"capital":4,"place":"city","name":"51-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.86743,10.82879]},"properties":{"population":567675,"capital":4,"place":"city","name":"51-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.74856,9.65402]},"properties":{"population":109861,"capital":4,"place":"city","name":"51-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.79157,10.02171]},"properties":{"population":615273,"capital":4,"place":"city","name":"51-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.45102,5.07262]},"properties":{"population":665415,"capital":4,"place":"city","name":"51A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.40093,2.94627]},"properties":{"population":582008,"capital":4,"place":"city","name":"51A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.69119,2.83556]},"properties":{"population":583773,"capital":4,"place":"city","name":"51A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.84789,3.28606]},"properties":{"population":631354,"capital":4,"place":"city","name":"51A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.96486,3.42424]},"properties":{"population":595583,"capital":4,"place":"city","name":"51A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.67842,3.13508]},"properties":{"population":620657,"capital":4,"place":"city","name":"51A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.27041,3.88301]},"properties":{"population":604737,"capital":4,"place":"city","name":"51A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.6868,2.95138]},"properties":{"population":686171,"capital":4,"place":"city","name":"51A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.06633,3.15674]},"properties":{"population":675460,"capital":4,"place":"city","name":"51A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.0925,3.13996]},"properties":{"population":615060,"capital":4,"place":"city","name":"51A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.11067,3.65369]},"properties":{"population":584354,"capital":4,"place":"city","name":"51A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.772,2.7731]},"properties":{"population":343398,"capital":4,"place":"city","name":"51Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.97573,2.1803]},"properties":{"population":585793,"capital":4,"place":"city","name":"51B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.11225,2.20731]},"properties":{"population":582894,"capital":4,"place":"city","name":"51B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.20744,1.96413]},"properties":{"population":534233,"capital":4,"place":"city","name":"51B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.4977,2.25148]},"properties":{"population":605443,"capital":4,"place":"city","name":"51B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.27473,1.91319]},"properties":{"population":460517,"capital":4,"place":"city","name":"51B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.38237,1.88746]},"properties":{"population":449980,"capital":4,"place":"city","name":"51B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.21484,1.88266]},"properties":{"population":160943,"capital":4,"place":"city","name":"51Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.30562,1.89937]},"properties":{"population":593489,"capital":4,"place":"city","name":"51C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.96542,1.98322]},"properties":{"population":160943,"capital":4,"place":"city","name":"51C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.00581,2.04582]},"properties":{"population":69314,"capital":4,"place":"city","name":"51Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.76384,-6.34068]},"properties":{"population":277258,"capital":4,"place":"city","name":"51D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.09064,-6.17833]},"properties":{"population":333220,"capital":4,"place":"city","name":"51D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.85857,-6.16731]},"properties":{"population":426267,"capital":4,"place":"city","name":"51D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.21048,-4.96926]},"properties":{"population":602102,"capital":4,"place":"city","name":"51D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.34998,-6.24987]},"properties":{"population":506259,"capital":4,"place":"city","name":"51D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.87765,-6.29075]},"properties":{"population":270805,"capital":4,"place":"city","name":"51D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.11846,-5.97691]},"properties":{"population":352636,"capital":4,"place":"city","name":"51D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.65619,-6.52183]},"properties":{"population":179175,"capital":4,"place":"city","name":"51Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.89024,4.09578]},"properties":{"population":597888,"capital":4,"place":"city","name":"51E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.34164,4.59661]},"properties":{"population":640522,"capital":4,"place":"city","name":"51E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.48637,4.31151]},"properties":{"population":677650,"capital":4,"place":"city","name":"51E12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.07621,4.28657]},"properties":{"population":577144,"capital":4,"place":"city","name":"51E14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.53936,4.44948]},"properties":{"population":767925,"capital":4,"place":"city","name":"51E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.96994,4.25543]},"properties":{"population":779811,"capital":4,"place":"city","name":"51E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.58968,4.52421]},"properties":{"population":682871,"capital":4,"place":"city","name":"51E21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.81258,3.49108]},"properties":{"population":598141,"capital":4,"place":"city","name":"51E22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.96661,4.19097]},"properties":{"population":565248,"capital":4,"place":"city","name":"51E23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.19313,4.66965]},"properties":{"population":691473,"capital":4,"place":"city","name":"51E24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.0353,3.8321]},"properties":{"population":547646,"capital":4,"place":"city","name":"51E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.68206,3.54989]},"properties":{"population":504985,"capital":4,"place":"city","name":"51E26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.07265,4.48021]},"properties":{"population":638519,"capital":4,"place":"city","name":"51E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.00676,4.25501]},"properties":{"population":522574,"capital":4,"place":"city","name":"51E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.72557,4.16545]},"properties":{"population":380666,"capital":4,"place":"city","name":"51Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.59284,12.83168]},"properties":{"population":522035,"capital":4,"place":"city","name":"51F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.689,12.73765]},"properties":{"population":404305,"capital":4,"place":"city","name":"51F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.89438,15.794]},"properties":{"population":704228,"capital":4,"place":"city","name":"51F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.73255,13.08225]},"properties":{"population":547646,"capital":4,"place":"city","name":"51F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.5439,13.55437]},"properties":{"population":547227,"capital":4,"place":"city","name":"51F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.70684,12.98482]},"properties":{"population":329583,"capital":4,"place":"city","name":"51F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.09669,15.68445]},"properties":{"population":680572,"capital":4,"place":"city","name":"51F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.6013,12.73649]},"properties":{"population":283321,"capital":4,"place":"city","name":"51Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.15129,-0.3652]},"properties":{"population":553733,"capital":4,"place":"city","name":"51G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.27881,0.34176]},"properties":{"population":109861,"capital":4,"place":"city","name":"51G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.38873,1.29727]},"properties":{"population":371357,"capital":4,"place":"city","name":"51H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.46371,1.46573]},"properties":{"population":592958,"capital":4,"place":"city","name":"51H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.27905,1.43234]},"properties":{"population":488280,"capital":4,"place":"city","name":"51H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.86516,1.71098]},"properties":{"population":493447,"capital":4,"place":"city","name":"51H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.89712,4.47536]},"properties":{"population":438202,"capital":4,"place":"city","name":"51H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.36409,1.40459]},"properties":{"population":398898,"capital":4,"place":"city","name":"51H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.77073,1.57279]},"properties":{"population":387120,"capital":4,"place":"city","name":"51H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.29693,1.28372]},"properties":{"population":109861,"capital":4,"place":"city","name":"51Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.39077,1.10498]},"properties":{"population":333220,"capital":4,"place":"city","name":"51J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.19592,1.20976]},"properties":{"population":363758,"capital":4,"place":"city","name":"51J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.27632,0.63642]},"properties":{"population":449980,"capital":4,"place":"city","name":"51J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.29567,1.10807]},"properties":{"population":373766,"capital":4,"place":"city","name":"51J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.33698,1.30519]},"properties":{"population":277258,"capital":4,"place":"city","name":"51J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.14517,1.03746]},"properties":{"population":69314,"capital":4,"place":"city","name":"51Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.79077,13.12428]},"properties":{"population":640852,"capital":4,"place":"city","name":"51K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.94541,11.47513]},"properties":{"population":551342,"capital":4,"place":"city","name":"51K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.2276,12.86605]},"properties":{"population":536597,"capital":4,"place":"city","name":"51K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.06726,10.43881]},"properties":{"population":219722,"capital":4,"place":"city","name":"51Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.90612,-8.66197]},"properties":{"population":256494,"capital":4,"place":"city","name":"51L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.12707,-8.47248]},"properties":{"population":352636,"capital":4,"place":"city","name":"51L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.24814,-8.16052]},"properties":{"population":404305,"capital":4,"place":"city","name":"51L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.08119,-8.52725]},"properties":{"population":294443,"capital":4,"place":"city","name":"51L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.08717,-8.37668]},"properties":{"population":248490,"capital":4,"place":"city","name":"51L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.73157,-5.51853]},"properties":{"population":807215,"capital":4,"place":"city","name":"51M04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.88148,-5.15796]},"properties":{"population":737838,"capital":4,"place":"city","name":"51M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.22697,-3.39449]},"properties":{"population":613556,"capital":4,"place":"city","name":"51M09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.47504,2.94237]},"properties":{"population":755590,"capital":4,"place":"city","name":"51M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.38708,-5.82359]},"properties":{"population":753849,"capital":4,"place":"city","name":"51M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.28755,-4.47892]},"properties":{"population":680239,"capital":4,"place":"city","name":"51M16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.08498,-6.04203]},"properties":{"population":797143,"capital":4,"place":"city","name":"51M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.01047,-4.19511]},"properties":{"population":709837,"capital":4,"place":"city","name":"51M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.96865,-1.76844]},"properties":{"population":591079,"capital":4,"place":"city","name":"51M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.63916,-8.85789]},"properties":{"population":582894,"capital":4,"place":"city","name":"51M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.95363,-6.8143]},"properties":{"population":655535,"capital":4,"place":"city","name":"51M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.88525,2.63885]},"properties":{"population":329583,"capital":4,"place":"city","name":"51Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.14287,23.65063]},"properties":{"population":710987,"capital":4,"place":"city","name":"51N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.21734,23.48553]},"properties":{"population":688448,"capital":4,"place":"city","name":"51N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.34178,23.67727]},"properties":{"population":716703,"capital":4,"place":"city","name":"51N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.47149,23.52892]},"properties":{"population":765728,"capital":4,"place":"city","name":"51N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.73825,23.4265]},"properties":{"population":626339,"capital":4,"place":"city","name":"51N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.66332,23.40877]},"properties":{"population":535185,"capital":4,"place":"city","name":"51N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.38537,23.64435]},"properties":{"population":678558,"capital":4,"place":"city","name":"51N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.20651,23.64651]},"properties":{"population":616541,"capital":4,"place":"city","name":"51N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.10186,23.33838]},"properties":{"population":452178,"capital":4,"place":"city","name":"51Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.63349,8.2622]},"properties":{"population":584064,"capital":4,"place":"city","name":"51P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.57516,3.93095]},"properties":{"population":194591,"capital":4,"place":"city","name":"51P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.21931,3.8904]},"properties":{"population":138629,"capital":4,"place":"city","name":"51Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.73964,9.61416]},"properties":{"population":256494,"capital":4,"place":"city","name":"52-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.65672,10.23864]},"properties":{"population":542495,"capital":4,"place":"city","name":"52-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.52083,11.70657]},"properties":{"population":607304,"capital":4,"place":"city","name":"52-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.70464,9.85157]},"properties":{"population":477912,"capital":4,"place":"city","name":"52-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.60177,10.36271]},"properties":{"population":534233,"capital":4,"place":"city","name":"52-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.07194,11.02306]},"properties":{"population":565948,"capital":4,"place":"city","name":"52-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.82822,9.47993]},"properties":{"population":109861,"capital":4,"place":"city","name":"52-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.76987,9.6342]},"properties":{"population":507517,"capital":4,"place":"city","name":"52-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.06611,-4.11197]},"properties":{"population":685961,"capital":4,"place":"city","name":"52A01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.01279,-6.63336]},"properties":{"population":670686,"capital":4,"place":"city","name":"52A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.1619,-6.13858]},"properties":{"population":671174,"capital":4,"place":"city","name":"52A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.74388,6.31249]},"properties":{"population":777148,"capital":4,"place":"city","name":"52A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.03509,-3.02572]},"properties":{"population":652795,"capital":4,"place":"city","name":"52A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.74322,-5.60295]},"properties":{"population":833758,"capital":4,"place":"city","name":"52A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.82853,-4.11307]},"properties":{"population":656526,"capital":4,"place":"city","name":"52A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.01839,0.70833]},"properties":{"population":721303,"capital":4,"place":"city","name":"52A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.33622,-2.92234]},"properties":{"population":496981,"capital":4,"place":"city","name":"52A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.18345,-3.15726]},"properties":{"population":575890,"capital":4,"place":"city","name":"52A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.52908,1.58126]},"properties":{"population":679794,"capital":4,"place":"city","name":"52A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.54461,-5.6]},"properties":{"population":663331,"capital":4,"place":"city","name":"52A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.92715,-3.99101]},"properties":{"population":654965,"capital":4,"place":"city","name":"52A37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.25317,-3.38996]},"properties":{"population":697821,"capital":4,"place":"city","name":"52A38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.43509,-5.51076]},"properties":{"population":623832,"capital":4,"place":"city","name":"52A39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.90858,6.51168]},"properties":{"population":823642,"capital":4,"place":"city","name":"52A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.84768,-3.18053]},"properties":{"population":674523,"capital":4,"place":"city","name":"52A41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.12797,-3.70787]},"properties":{"population":572684,"capital":4,"place":"city","name":"52A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.43308,-5.69945]},"properties":{"population":580814,"capital":4,"place":"city","name":"52A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.63177,2.18232]},"properties":{"population":395124,"capital":4,"place":"city","name":"52Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.01843,-0.93447]},"properties":{"population":727655,"capital":4,"place":"city","name":"52B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.46815,-0.62252]},"properties":{"population":687005,"capital":4,"place":"city","name":"52B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.96587,1.49376]},"properties":{"population":720117,"capital":4,"place":"city","name":"52B11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.23876,-0.1903]},"properties":{"population":696413,"capital":4,"place":"city","name":"52B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.63637,-3.00022]},"properties":{"population":603068,"capital":4,"place":"city","name":"52B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.71913,-4.06762]},"properties":{"population":713966,"capital":4,"place":"city","name":"52B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.99137,1.86321]},"properties":{"population":469134,"capital":4,"place":"city","name":"52B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.79001,2.55558]},"properties":{"population":109861,"capital":4,"place":"city","name":"52B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.98121,1.456]},"properties":{"population":439444,"capital":4,"place":"city","name":"52B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.58714,-3.06088]},"properties":{"population":657228,"capital":4,"place":"city","name":"52B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.44305,-2.98016]},"properties":{"population":611146,"capital":4,"place":"city","name":"52B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.2143,1.21672]},"properties":{"population":718841,"capital":4,"place":"city","name":"52B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.04412,-3.84887]},"properties":{"population":495582,"capital":4,"place":"city","name":"52B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.25225,-5.26965]},"properties":{"population":657925,"capital":4,"place":"city","name":"52B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.43701,1.41911]},"properties":{"population":582600,"capital":4,"place":"city","name":"52B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.10736,-1.51527]},"properties":{"population":746794,"capital":4,"place":"city","name":"52Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.04689,-4.41813]},"properties":{"population":584064,"capital":4,"place":"city","name":"52C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.61758,-2.26674]},"properties":{"population":694601,"capital":4,"place":"city","name":"52C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.64086,-2.61576]},"properties":{"population":680461,"capital":4,"place":"city","name":"52C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.30949,-1.26497]},"properties":{"population":685540,"capital":4,"place":"city","name":"52C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.68449,1.90965]},"properties":{"population":778696,"capital":4,"place":"city","name":"52C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.95483,-3.00594]},"properties":{"population":712044,"capital":4,"place":"city","name":"52C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.49512,-4.29716]},"properties":{"population":691075,"capital":4,"place":"city","name":"52C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.4565,-3.06514]},"properties":{"population":637842,"capital":4,"place":"city","name":"52C23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.52321,-3.39186]},"properties":{"population":621660,"capital":4,"place":"city","name":"52C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.72711,-2.10915]},"properties":{"population":563835,"capital":4,"place":"city","name":"52C26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.78409,-0.04926]},"properties":{"population":556452,"capital":4,"place":"city","name":"52C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.64264,-3.85293]},"properties":{"population":720489,"capital":4,"place":"city","name":"52C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.21595,-0.92624]},"properties":{"population":526269,"capital":4,"place":"city","name":"52C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.11454,-5.95349]},"properties":{"population":585793,"capital":4,"place":"city","name":"52C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.21947,-7.31267]},"properties":{"population":595064,"capital":4,"place":"city","name":"52C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.12451,0.96327]},"properties":{"population":406044,"capital":4,"place":"city","name":"52Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.94868,23.69808]},"properties":{"population":361091,"capital":4,"place":"city","name":"53-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.81375,23.08158]},"properties":{"population":695368,"capital":4,"place":"city","name":"53-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.98725,23.44766]},"properties":{"population":761431,"capital":4,"place":"city","name":"53-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.26348,22.63243]},"properties":{"population":631354,"capital":4,"place":"city","name":"53-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.63485,23.60389]},"properties":{"population":517614,"capital":4,"place":"city","name":"53-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.27697,23.9061]},"properties":{"population":730518,"capital":4,"place":"city","name":"53-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.10492,23.61356]},"properties":{"population":138629,"capital":4,"place":"city","name":"53-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.70186,23.88724]},"properties":{"population":635957,"capital":4,"place":"city","name":"53-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.87958,9.6232]},"properties":{"population":817357,"capital":4,"place":"city","name":"53A04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.37308,-0.27728]},"properties":{"population":856807,"capital":4,"place":"city","name":"53A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.96422,4.37062]},"properties":{"population":792624,"capital":4,"place":"city","name":"53A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.62647,10.37346]},"properties":{"population":851559,"capital":4,"place":"city","name":"53A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.71291,5.38587]},"properties":{"population":744014,"capital":4,"place":"city","name":"53A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.53966,-0.56013]},"properties":{"population":687935,"capital":4,"place":"city","name":"53A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.93232,1.11535]},"properties":{"population":741577,"capital":4,"place":"city","name":"53A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.63744,-4.54161]},"properties":{"population":705272,"capital":4,"place":"city","name":"53A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.14697,4.85967]},"properties":{"population":746393,"capital":4,"place":"city","name":"53A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.69319,-5.11905]},"properties":{"population":309104,"capital":4,"place":"city","name":"53A31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.874,7.28389]},"properties":{"population":756060,"capital":4,"place":"city","name":"53A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.44398,3.55261]},"properties":{"population":663463,"capital":4,"place":"city","name":"53A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.26129,6.88193]},"properties":{"population":718916,"capital":4,"place":"city","name":"53A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.06466,7.36623]},"properties":{"population":722766,"capital":4,"place":"city","name":"53A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.29078,-3.25968]},"properties":{"population":666822,"capital":4,"place":"city","name":"53A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.08112,-4.13755]},"properties":{"population":317805,"capital":4,"place":"city","name":"53A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.55955,-0.73662]},"properties":{"population":574300,"capital":4,"place":"city","name":"53A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.64707,-1.5531]},"properties":{"population":519295,"capital":4,"place":"city","name":"53Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.54365,6.9463]},"properties":{"population":775018,"capital":4,"place":"city","name":"53B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.60814,-3.30656]},"properties":{"population":626909,"capital":4,"place":"city","name":"53B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.44873,-5.22843]},"properties":{"population":340119,"capital":4,"place":"city","name":"53B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.13571,2.42791]},"properties":{"population":701391,"capital":4,"place":"city","name":"53B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.34359,7.41819]},"properties":{"population":813035,"capital":4,"place":"city","name":"53B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.18905,7.1132]},"properties":{"population":649526,"capital":4,"place":"city","name":"53B21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.36547,-0.12348]},"properties":{"population":833158,"capital":4,"place":"city","name":"53B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.65698,9.03167]},"properties":{"population":826821,"capital":4,"place":"city","name":"53B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.00555,4.3205]},"properties":{"population":747760,"capital":4,"place":"city","name":"53B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.94538,9.57026]},"properties":{"population":820138,"capital":4,"place":"city","name":"53B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.27223,6.45819]},"properties":{"population":814002,"capital":4,"place":"city","name":"53B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.46204,0.99894]},"properties":{"population":637331,"capital":4,"place":"city","name":"53B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.47811,-4.37601]},"properties":{"population":361091,"capital":4,"place":"city","name":"53Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.6705,6.99634]},"properties":{"population":808085,"capital":4,"place":"city","name":"53C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.82885,-0.67916]},"properties":{"population":755276,"capital":4,"place":"city","name":"53C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.21825,-3.02562]},"properties":{"population":508140,"capital":4,"place":"city","name":"53C08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.8677,9.50836]},"properties":{"population":718690,"capital":4,"place":"city","name":"53C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.82517,7.13977]},"properties":{"population":765112,"capital":4,"place":"city","name":"53C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.00489,6.46968]},"properties":{"population":909683,"capital":4,"place":"city","name":"53C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.84796,9.06682]},"properties":{"population":706047,"capital":4,"place":"city","name":"53C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.14039,-4.1431]},"properties":{"population":443081,"capital":4,"place":"city","name":"53C18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.94057,-3.594]},"properties":{"population":911866,"capital":4,"place":"city","name":"53C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.88816,-2.78649]},"properties":{"population":845574,"capital":4,"place":"city","name":"53C21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.66977,-9.91584]},"properties":{"population":817244,"capital":4,"place":"city","name":"53C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.73308,-4.64374]},"properties":{"population":750218,"capital":4,"place":"city","name":"53C23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.07512,4.6499]},"properties":{"population":705531,"capital":4,"place":"city","name":"53C24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.41483,6.2869]},"properties":{"population":888958,"capital":4,"place":"city","name":"53C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.83617,3.13683]},"properties":{"population":708673,"capital":4,"place":"city","name":"53C26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.21275,8.91552]},"properties":{"population":740062,"capital":4,"place":"city","name":"53C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.69974,0.48781]},"properties":{"population":627664,"capital":4,"place":"city","name":"53C28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.93826,5.87861]},"properties":{"population":671901,"capital":4,"place":"city","name":"53C29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.6441,6.29762]},"properties":{"population":836100,"capital":4,"place":"city","name":"53C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.57098,7.54415]},"properties":{"population":815593,"capital":4,"place":"city","name":"53C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.59314,3.32253]},"properties":{"population":593224,"capital":4,"place":"city","name":"53C38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.48721,-4.5992]},"properties":{"population":896775,"capital":4,"place":"city","name":"53C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.21283,9.36413]},"properties":{"population":889494,"capital":4,"place":"city","name":"53C42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.46448,3.31346]},"properties":{"population":743189,"capital":4,"place":"city","name":"53C43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.87999,8.63816]},"properties":{"population":801697,"capital":4,"place":"city","name":"53C44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.47641,4.0777]},"properties":{"population":708422,"capital":4,"place":"city","name":"53C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.41712,8.51148]},"properties":{"population":854422,"capital":4,"place":"city","name":"53C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.34944,-4.35189]},"properties":{"population":871964,"capital":4,"place":"city","name":"53C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.82962,-0.34567]},"properties":{"population":659987,"capital":4,"place":"city","name":"53C56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.69468,9.10399]},"properties":{"population":792732,"capital":4,"place":"city","name":"53C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.81912,-4.39212]},"properties":{"population":776004,"capital":4,"place":"city","name":"53C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.45788,-3.95673]},"properties":{"population":591620,"capital":4,"place":"city","name":"53C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.71418,-5.59415]},"properties":{"population":477068,"capital":4,"place":"city","name":"53C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.49413,14.00602]},"properties":{"population":851278,"capital":4,"place":"city","name":"53C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.82044,3.67412]},"properties":{"population":645676,"capital":4,"place":"city","name":"53C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.25382,-3.14788]},"properties":{"population":551342,"capital":4,"place":"city","name":"53Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.8208,6.51512]},"properties":{"population":751316,"capital":4,"place":"city","name":"53D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.76165,10.0872]},"properties":{"population":735627,"capital":4,"place":"city","name":"53D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.55004,9.06181]},"properties":{"population":725134,"capital":4,"place":"city","name":"53D12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.45232,-4.46465]},"properties":{"population":674758,"capital":4,"place":"city","name":"53D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.17658,9.73569]},"properties":{"population":757404,"capital":4,"place":"city","name":"53D17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.09125,-5.59077]},"properties":{"population":527299,"capital":4,"place":"city","name":"53D18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.78056,6.73062]},"properties":{"population":716239,"capital":4,"place":"city","name":"53D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.81991,-1.95808]},"properties":{"population":474493,"capital":4,"place":"city","name":"53D22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.99178,4.64587]},"properties":{"population":716084,"capital":4,"place":"city","name":"53D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.55462,-1.63851]},"properties":{"population":574300,"capital":4,"place":"city","name":"53D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.02346,5.16626]},"properties":{"population":707072,"capital":4,"place":"city","name":"53D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.78646,-3.78319]},"properties":{"population":589715,"capital":4,"place":"city","name":"53D37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.7487,3.26039]},"properties":{"population":669332,"capital":4,"place":"city","name":"53D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.54123,-0.64299]},"properties":{"population":519295,"capital":4,"place":"city","name":"53D42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.22979,-0.14599]},"properties":{"population":693925,"capital":4,"place":"city","name":"53D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.59351,4.89187]},"properties":{"population":777064,"capital":4,"place":"city","name":"53D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.10335,5.66739]},"properties":{"population":728000,"capital":4,"place":"city","name":"53D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.41797,3.25628]},"properties":{"population":503043,"capital":4,"place":"city","name":"53D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.58556,-5.10915]},"properties":{"population":443081,"capital":4,"place":"city","name":"53Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.05824,7.95898]},"properties":{"population":551745,"capital":4,"place":"city","name":"53E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.33064,9.03579]},"properties":{"population":522574,"capital":4,"place":"city","name":"53E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.56528,8.78916]},"properties":{"population":433073,"capital":4,"place":"city","name":"53E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.77097,9.79096]},"properties":{"population":263905,"capital":4,"place":"city","name":"53E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.68955,9.88332]},"properties":{"population":239789,"capital":4,"place":"city","name":"53E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.18939,9.25334]},"properties":{"population":446590,"capital":4,"place":"city","name":"53E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.62752,9.96759]},"properties":{"population":194591,"capital":4,"place":"city","name":"53Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.96061,23.61197]},"properties":{"population":899652,"capital":4,"place":"city","name":"53Z05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.7738,23.26483]},"properties":{"population":194591,"capital":4,"place":"city","name":"53Z10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.43526,23.15753]},"properties":{"population":69314,"capital":4,"place":"city","name":"53Z15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.60458,23.29405]},"properties":{"population":248490,"capital":4,"place":"city","name":"53Z30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.74582,23.4347]},"properties":{"population":194591,"capital":4,"place":"city","name":"53Z50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.51561,23.4314]},"properties":{"population":277258,"capital":4,"place":"city","name":"53Z99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.63426,23.10014]},"properties":{"population":69314,"capital":4,"place":"city","name":"53Zxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.43843,2.43464]},"properties":{"population":329583,"capital":4,"place":"city","name":"54-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.83883,2.79036]},"properties":{"population":631716,"capital":4,"place":"city","name":"54-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.88947,3.57055]},"properties":{"population":649526,"capital":4,"place":"city","name":"54-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.35487,2.21454]},"properties":{"population":589440,"capital":4,"place":"city","name":"54-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.10101,1.92502]},"properties":{"population":299573,"capital":4,"place":"city","name":"54-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.21222,2.0301]},"properties":{"population":626909,"capital":4,"place":"city","name":"54-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.16981,2.10367]},"properties":{"population":628971,"capital":4,"place":"city","name":"54-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.5227,-6.13042]},"properties":{"population":817131,"capital":4,"place":"city","name":"54A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.7138,-2.38874]},"properties":{"population":743070,"capital":4,"place":"city","name":"54A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.57515,-7.80801]},"properties":{"population":487519,"capital":4,"place":"city","name":"54A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.29929,1.58542]},"properties":{"population":785554,"capital":4,"place":"city","name":"54A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.45926,-6.16777]},"properties":{"population":791169,"capital":4,"place":"city","name":"54A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.23287,-6.7253]},"properties":{"population":692755,"capital":4,"place":"city","name":"54A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.0549,0.88496]},"properties":{"population":876499,"capital":4,"place":"city","name":"54A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.87065,-5.9221]},"properties":{"population":591079,"capital":4,"place":"city","name":"54A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.80027,-9.27349]},"properties":{"population":325809,"capital":4,"place":"city","name":"54Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.3218,-4.6673]},"properties":{"population":647079,"capital":4,"place":"city","name":"54B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.96737,-6.03294]},"properties":{"population":774630,"capital":4,"place":"city","name":"54B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.08212,-5.55933]},"properties":{"population":674523,"capital":4,"place":"city","name":"54B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.43417,-8.32471]},"properties":{"population":453259,"capital":4,"place":"city","name":"54B17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.32098,-5.49003]},"properties":{"population":772223,"capital":4,"place":"city","name":"54B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.52062,-6.05285]},"properties":{"population":759538,"capital":4,"place":"city","name":"54B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.76666,-2.45738]},"properties":{"population":658892,"capital":4,"place":"city","name":"54B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.12238,-8.00857]},"properties":{"population":473619,"capital":4,"place":"city","name":"54B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.31018,-7.87777]},"properties":{"population":560947,"capital":4,"place":"city","name":"54B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.02455,-8.26555]},"properties":{"population":366356,"capital":4,"place":"city","name":"54Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.79132,-1.94074]},"properties":{"population":756112,"capital":4,"place":"city","name":"54C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.28511,-6.2561]},"properties":{"population":794449,"capital":4,"place":"city","name":"54C08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.59679,0.12421]},"properties":{"population":835137,"capital":4,"place":"city","name":"54C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.64991,-6.26298]},"properties":{"population":604263,"capital":4,"place":"city","name":"54C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.43015,0.52334]},"properties":{"population":698007,"capital":4,"place":"city","name":"54C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.42479,-6.25105]},"properties":{"population":659304,"capital":4,"place":"city","name":"54C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.21758,-3.06059]},"properties":{"population":725347,"capital":4,"place":"city","name":"54C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.97615,-0.89702]},"properties":{"population":793307,"capital":4,"place":"city","name":"54C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.84316,-5.63073]},"properties":{"population":679234,"capital":4,"place":"city","name":"54C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.99168,-8.38232]},"properties":{"population":565599,"capital":4,"place":"city","name":"54C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.02201,-3.92963]},"properties":{"population":638519,"capital":4,"place":"city","name":"54C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.80693,-5.69111]},"properties":{"population":710085,"capital":4,"place":"city","name":"54C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.61661,-6.62542]},"properties":{"population":659714,"capital":4,"place":"city","name":"54C56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.04811,3.85096]},"properties":{"population":843185,"capital":4,"place":"city","name":"54C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.94951,-3.24273]},"properties":{"population":708506,"capital":4,"place":"city","name":"54C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.33799,-1.44641]},"properties":{"population":638519,"capital":4,"place":"city","name":"54C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.89705,-6.5588]},"properties":{"population":606610,"capital":4,"place":"city","name":"54C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.94014,-8.43072]},"properties":{"population":333220,"capital":4,"place":"city","name":"54Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.10037,-2.76215]},"properties":{"population":736518,"capital":4,"place":"city","name":"54D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.84248,-6.04587]},"properties":{"population":776259,"capital":4,"place":"city","name":"54D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.8359,-6.22477]},"properties":{"population":760539,"capital":4,"place":"city","name":"54D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.66848,-5.87276]},"properties":{"population":827817,"capital":4,"place":"city","name":"54D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.03219,-7.83191]},"properties":{"population":624610,"capital":4,"place":"city","name":"54D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.03051,-6.30637]},"properties":{"population":809193,"capital":4,"place":"city","name":"54D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.76154,-3.8976]},"properties":{"population":799934,"capital":4,"place":"city","name":"54D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.46986,-9.0649]},"properties":{"population":641345,"capital":4,"place":"city","name":"54D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.871,-4.04323]},"properties":{"population":644571,"capital":4,"place":"city","name":"54D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.40308,-4.26278]},"properties":{"population":607304,"capital":4,"place":"city","name":"54D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.42674,-7.82951]},"properties":{"population":672982,"capital":4,"place":"city","name":"54D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.936,-8.06353]},"properties":{"population":624997,"capital":4,"place":"city","name":"54D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.2414,-7.49407]},"properties":{"population":605208,"capital":4,"place":"city","name":"54D65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.83222,-8.58584]},"properties":{"population":548479,"capital":4,"place":"city","name":"54D70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.85545,-7.53238]},"properties":{"population":623048,"capital":4,"place":"city","name":"54D80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.11204,-7.48161]},"properties":{"population":633682,"capital":4,"place":"city","name":"54D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.78189,-8.77691]},"properties":{"population":371357,"capital":4,"place":"city","name":"54Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.96259,-3.35333]},"properties":{"population":672862,"capital":4,"place":"city","name":"54E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.55968,-3.45235]},"properties":{"population":793808,"capital":4,"place":"city","name":"54E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.11372,-7.49356]},"properties":{"population":560211,"capital":4,"place":"city","name":"54E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.31211,-7.7534]},"properties":{"population":606145,"capital":4,"place":"city","name":"54E18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.3409,-7.38807]},"properties":{"population":644094,"capital":4,"place":"city","name":"54E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.53758,-7.40949]},"properties":{"population":579909,"capital":4,"place":"city","name":"54E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.97324,-7.24581]},"properties":{"population":624610,"capital":4,"place":"city","name":"54E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.20593,-6.23719]},"properties":{"population":852277,"capital":4,"place":"city","name":"54E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.83735,-0.35029]},"properties":{"population":818757,"capital":4,"place":"city","name":"54E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.07209,-5.79355]},"properties":{"population":675925,"capital":4,"place":"city","name":"54E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.37555,-1.27454]},"properties":{"population":752563,"capital":4,"place":"city","name":"54E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.71293,-2.60602]},"properties":{"population":709257,"capital":4,"place":"city","name":"54E52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.90093,-0.01542]},"properties":{"population":715929,"capital":4,"place":"city","name":"54E55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.97201,-2.17149]},"properties":{"population":676619,"capital":4,"place":"city","name":"54E70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.66796,-4.79662]},"properties":{"population":667582,"capital":4,"place":"city","name":"54E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.73969,-9.15953]},"properties":{"population":309104,"capital":4,"place":"city","name":"54Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.10481,17.48374]},"properties":{"population":777191,"capital":4,"place":"city","name":"54F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.41188,16.50168]},"properties":{"population":803106,"capital":4,"place":"city","name":"54F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.43296,14.64607]},"properties":{"population":355534,"capital":4,"place":"city","name":"54F16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.48644,14.54577]},"properties":{"population":289037,"capital":4,"place":"city","name":"54F17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.38849,14.90188]},"properties":{"population":463472,"capital":4,"place":"city","name":"54F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.06161,17.52984]},"properties":{"population":778655,"capital":4,"place":"city","name":"54F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.33539,15.11476]},"properties":{"population":705272,"capital":4,"place":"city","name":"54F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.37702,14.5403]},"properties":{"population":543807,"capital":4,"place":"city","name":"54F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.17802,17.28287]},"properties":{"population":675343,"capital":4,"place":"city","name":"54F65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.38385,14.73228]},"properties":{"population":576519,"capital":4,"place":"city","name":"54F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.46024,14.53329]},"properties":{"population":179175,"capital":4,"place":"city","name":"54Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.61094,-2.38943]},"properties":{"population":659441,"capital":4,"place":"city","name":"54G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.47742,-2.56488]},"properties":{"population":537063,"capital":4,"place":"city","name":"54G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.67485,0.03264]},"properties":{"population":552545,"capital":4,"place":"city","name":"54G12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.55642,-2.45585]},"properties":{"population":529330,"capital":4,"place":"city","name":"54G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.8009,-2.08449]},"properties":{"population":696979,"capital":4,"place":"city","name":"54G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.82171,-2.11584]},"properties":{"population":519849,"capital":4,"place":"city","name":"54G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.30805,-2.65546]},"properties":{"population":69314,"capital":4,"place":"city","name":"54Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.59002,0.19496]},"properties":{"population":739387,"capital":4,"place":"city","name":"54H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.95242,-6.01683]},"properties":{"population":604263,"capital":4,"place":"city","name":"54H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.33236,-6.3324]},"properties":{"population":726682,"capital":4,"place":"city","name":"54H11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.61248,-2.6496]},"properties":{"population":602102,"capital":4,"place":"city","name":"54H12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.60714,-6.76129]},"properties":{"population":584064,"capital":4,"place":"city","name":"54H13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.10306,4.69592]},"properties":{"population":729097,"capital":4,"place":"city","name":"54H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.79821,3.63971]},"properties":{"population":859248,"capital":4,"place":"city","name":"54H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.96659,-5.74124]},"properties":{"population":965470,"capital":4,"place":"city","name":"54H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.31212,-7.02884]},"properties":{"population":289037,"capital":4,"place":"city","name":"54H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.16319,1.67724]},"properties":{"population":617794,"capital":4,"place":"city","name":"54H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.81189,-7.13133]},"properties":{"population":309104,"capital":4,"place":"city","name":"54Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.2412,-0.8269]},"properties":{"population":566642,"capital":4,"place":"city","name":"54J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.06778,-5.26055]},"properties":{"population":160943,"capital":4,"place":"city","name":"54J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.01969,4.36968]},"properties":{"population":277258,"capital":4,"place":"city","name":"55-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.21411,5.15687]},"properties":{"population":590536,"capital":4,"place":"city","name":"55-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.77576,7.70703]},"properties":{"population":630627,"capital":4,"place":"city","name":"55-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.22199,4.34454]},"properties":{"population":545958,"capital":4,"place":"city","name":"55-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.84912,7.41572]},"properties":{"population":485981,"capital":4,"place":"city","name":"55-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.86032,4.76295]},"properties":{"population":591889,"capital":4,"place":"city","name":"55-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.03919,4.53591]},"properties":{"population":138629,"capital":4,"place":"city","name":"55-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.50527,4.71634]},"properties":{"population":503043,"capital":4,"place":"city","name":"55-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.23149,21.01801]},"properties":{"population":540267,"capital":4,"place":"city","name":"55M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.42244,20.97248]},"properties":{"population":634388,"capital":4,"place":"city","name":"55M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.64334,20.72811]},"properties":{"population":526785,"capital":4,"place":"city","name":"55M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.28297,21.38177]},"properties":{"population":777527,"capital":4,"place":"city","name":"55M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.12506,21.67323]},"properties":{"population":691473,"capital":4,"place":"city","name":"55M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.27496,21.04529]},"properties":{"population":663856,"capital":4,"place":"city","name":"55M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.45417,20.99888]},"properties":{"population":616751,"capital":4,"place":"city","name":"55M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.10944,21.12325]},"properties":{"population":529330,"capital":4,"place":"city","name":"55M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.65816,20.80807]},"properties":{"population":230258,"capital":4,"place":"city","name":"55Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.43195,22.22775]},"properties":{"population":622851,"capital":4,"place":"city","name":"55N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.89379,22.18308]},"properties":{"population":497673,"capital":4,"place":"city","name":"55N07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.16119,22.68977]},"properties":{"population":622455,"capital":4,"place":"city","name":"55N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.05128,22.50884]},"properties":{"population":715070,"capital":4,"place":"city","name":"55N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.0193,22.11949]},"properties":{"population":700397,"capital":4,"place":"city","name":"55N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.11505,22.24053]},"properties":{"population":695939,"capital":4,"place":"city","name":"55N22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.00402,22.27664]},"properties":{"population":625958,"capital":4,"place":"city","name":"55N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.38424,22.38707]},"properties":{"population":628039,"capital":4,"place":"city","name":"55N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.14231,22.79195]},"properties":{"population":482831,"capital":4,"place":"city","name":"55N31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.66584,22.28825]},"properties":{"population":421950,"capital":4,"place":"city","name":"55N32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.45135,22.26232]},"properties":{"population":539816,"capital":4,"place":"city","name":"55N33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.58093,21.98432]},"properties":{"population":498360,"capital":4,"place":"city","name":"55N34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.11739,22.86715]},"properties":{"population":691075,"capital":4,"place":"city","name":"55N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.96416,22.01717]},"properties":{"population":512396,"capital":4,"place":"city","name":"55N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.46205,22.43201]},"properties":{"population":518738,"capital":4,"place":"city","name":"55N45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.22957,20.95411]},"properties":{"population":682762,"capital":4,"place":"city","name":"55N91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.73049,22.7652]},"properties":{"population":650428,"capital":4,"place":"city","name":"55N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.85526,22.24648]},"properties":{"population":409434,"capital":4,"place":"city","name":"55Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.53393,-2.47046]},"properties":{"population":573657,"capital":4,"place":"city","name":"55P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.17748,-3.73978]},"properties":{"population":709007,"capital":4,"place":"city","name":"55P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.49022,1.51243]},"properties":{"population":699025,"capital":4,"place":"city","name":"55P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.59626,-6.42427]},"properties":{"population":581711,"capital":4,"place":"city","name":"55P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.10539,-2.01958]},"properties":{"population":463472,"capital":4,"place":"city","name":"55P25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.31563,0.84809]},"properties":{"population":443081,"capital":4,"place":"city","name":"55P30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.41387,-1.77086]},"properties":{"population":704664,"capital":4,"place":"city","name":"55P35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.26027,-5.99617]},"properties":{"population":570378,"capital":4,"place":"city","name":"55P40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.86982,0.71618]},"properties":{"population":723345,"capital":4,"place":"city","name":"55P42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.41062,-1.53305]},"properties":{"population":588887,"capital":4,"place":"city","name":"55P43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.5094,-4.26577]},"properties":{"population":661069,"capital":4,"place":"city","name":"55P45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.26066,-6.4558]},"properties":{"population":582008,"capital":4,"place":"city","name":"55P47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.80621,-6.7523]},"properties":{"population":605912,"capital":4,"place":"city","name":"55P48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.84947,-1.32152]},"properties":{"population":461512,"capital":4,"place":"city","name":"55P50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.24923,-2.95621]},"properties":{"population":673696,"capital":4,"place":"city","name":"55P55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.45771,0.60768]},"properties":{"population":363758,"capital":4,"place":"city","name":"55P57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.66779,-5.87888]},"properties":{"population":681454,"capital":4,"place":"city","name":"55P60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.79447,-6.52835]},"properties":{"population":710906,"capital":4,"place":"city","name":"55P62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.75511,-4.0644]},"properties":{"population":556068,"capital":4,"place":"city","name":"55P65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.04531,-5.19824]},"properties":{"population":650128,"capital":4,"place":"city","name":"55P91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.3204,-0.19072]},"properties":{"population":321887,"capital":4,"place":"city","name":"55P92"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.02371,-1.51969]},"properties":{"population":707580,"capital":4,"place":"city","name":"55P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.20844,-1.55818]},"properties":{"population":453259,"capital":4,"place":"city","name":"55Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.49441,6.8309]},"properties":{"population":702019,"capital":4,"place":"city","name":"55Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.97544,5.00421]},"properties":{"population":453259,"capital":4,"place":"city","name":"55Q07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.35158,5.35277]},"properties":{"population":595842,"capital":4,"place":"city","name":"55Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.21748,5.13873]},"properties":{"population":570711,"capital":4,"place":"city","name":"55Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.29035,5.32904]},"properties":{"population":503043,"capital":4,"place":"city","name":"55Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.41173,5.54712]},"properties":{"population":556452,"capital":4,"place":"city","name":"55Q25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.6176,4.99282]},"properties":{"population":491998,"capital":4,"place":"city","name":"55Q35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.77381,5.66893]},"properties":{"population":591620,"capital":4,"place":"city","name":"55Q40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.07043,6.17878]},"properties":{"population":641836,"capital":4,"place":"city","name":"55Q45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.55245,5.33345]},"properties":{"population":543372,"capital":4,"place":"city","name":"55Q50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.87178,5.01216]},"properties":{"population":453259,"capital":4,"place":"city","name":"55Q51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.8759,6.11231]},"properties":{"population":638012,"capital":4,"place":"city","name":"55Q52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.12635,5.26292]},"properties":{"population":503043,"capital":4,"place":"city","name":"55Q55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.2737,5.25869]},"properties":{"population":513579,"capital":4,"place":"city","name":"55Q70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.65112,5.58073]},"properties":{"population":491265,"capital":4,"place":"city","name":"55Q91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.14836,7.50202]},"properties":{"population":534710,"capital":4,"place":"city","name":"55Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.66744,4.82949]},"properties":{"population":349650,"capital":4,"place":"city","name":"55Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.40571,-5.60304]},"properties":{"population":662273,"capital":4,"place":"city","name":"55R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.64803,3.88692]},"properties":{"population":725347,"capital":4,"place":"city","name":"55R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.1261,-4.34806]},"properties":{"population":499043,"capital":4,"place":"city","name":"55R12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.01761,-5.55692]},"properties":{"population":544241,"capital":4,"place":"city","name":"55R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.95008,-5.08175]},"properties":{"population":588332,"capital":4,"place":"city","name":"55R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.23868,-1.61865]},"properties":{"population":680793,"capital":4,"place":"city","name":"55R25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.81741,-2.34185]},"properties":{"population":702197,"capital":4,"place":"city","name":"55R35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.55829,-2.83536]},"properties":{"population":407753,"capital":4,"place":"city","name":"55R37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.16177,-3.24123]},"properties":{"population":709007,"capital":4,"place":"city","name":"55R40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.58903,-5.73363]},"properties":{"population":536597,"capital":4,"place":"city","name":"55R45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.85983,-5.44454]},"properties":{"population":626339,"capital":4,"place":"city","name":"55R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.80861,-6.55107]},"properties":{"population":486753,"capital":4,"place":"city","name":"55R55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.55944,-1.84896]},"properties":{"population":389182,"capital":4,"place":"city","name":"55R60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.80714,-5.1812]},"properties":{"population":604025,"capital":4,"place":"city","name":"55R65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.87107,-2.2629]},"properties":{"population":479579,"capital":4,"place":"city","name":"55R70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.94704,-1.32615]},"properties":{"population":626149,"capital":4,"place":"city","name":"55R80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.20008,-7.15469]},"properties":{"population":545532,"capital":4,"place":"city","name":"55R91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.72572,-5.82875]},"properties":{"population":554517,"capital":4,"place":"city","name":"55R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.06982,-2.18857]},"properties":{"population":352636,"capital":4,"place":"city","name":"55Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.73756,3.77623]},"properties":{"population":562401,"capital":4,"place":"city","name":"55S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.35562,3.89914]},"properties":{"population":684694,"capital":4,"place":"city","name":"55S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.97906,3.5789]},"properties":{"population":499721,"capital":4,"place":"city","name":"55S12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.71075,3.66616]},"properties":{"population":436944,"capital":4,"place":"city","name":"55S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.25527,3.48832]},"properties":{"population":553733,"capital":4,"place":"city","name":"55S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.35547,3.79154]},"properties":{"population":586078,"capital":4,"place":"city","name":"55S25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.82619,3.68651]},"properties":{"population":532787,"capital":4,"place":"city","name":"55S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.4272,4.31133]},"properties":{"population":605912,"capital":4,"place":"city","name":"55S35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.89219,3.68499]},"properties":{"population":380666,"capital":4,"place":"city","name":"55S36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.16579,3.84289]},"properties":{"population":499043,"capital":4,"place":"city","name":"55S37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.56418,3.53243]},"properties":{"population":525227,"capital":4,"place":"city","name":"55S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.26166,3.6197]},"properties":{"population":559842,"capital":4,"place":"city","name":"55S45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.57384,3.91742]},"properties":{"population":373766,"capital":4,"place":"city","name":"55S91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.17032,3.63231]},"properties":{"population":444265,"capital":4,"place":"city","name":"55S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.88475,3.80724]},"properties":{"population":248490,"capital":4,"place":"city","name":"55Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.47578,-1.64359]},"properties":{"population":501727,"capital":4,"place":"city","name":"55T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.82289,-4.38081]},"properties":{"population":557594,"capital":4,"place":"city","name":"55T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.71716,-1.35876]},"properties":{"population":650279,"capital":4,"place":"city","name":"55T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.36394,-0.97073]},"properties":{"population":535658,"capital":4,"place":"city","name":"55T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.83134,-2.53974]},"properties":{"population":550938,"capital":4,"place":"city","name":"55T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.66804,-5.81521]},"properties":{"population":594017,"capital":4,"place":"city","name":"55T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.25224,0.92825]},"properties":{"population":270805,"capital":4,"place":"city","name":"55Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.28085,14.22619]},"properties":{"population":512989,"capital":4,"place":"city","name":"55U05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.11768,17.26627]},"properties":{"population":707580,"capital":4,"place":"city","name":"55U10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.89962,15.38917]},"properties":{"population":614418,"capital":4,"place":"city","name":"55U15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.89744,14.65249]},"properties":{"population":417438,"capital":4,"place":"city","name":"55U20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.24551,13.68822]},"properties":{"population":475359,"capital":4,"place":"city","name":"55U25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.35728,13.79941]},"properties":{"population":467282,"capital":4,"place":"city","name":"55U30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.83146,15.06653]},"properties":{"population":705012,"capital":4,"place":"city","name":"55U35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.24185,14.43775]},"properties":{"population":559471,"capital":4,"place":"city","name":"55U40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.28393,15.00794]},"properties":{"population":568357,"capital":4,"place":"city","name":"55U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.45417,13.84536]},"properties":{"population":343398,"capital":4,"place":"city","name":"55Uxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.08845,14.39626]},"properties":{"population":299573,"capital":4,"place":"city","name":"57-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.68995,16.35619]},"properties":{"population":601859,"capital":4,"place":"city","name":"57-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.38612,17.36005]},"properties":{"population":690875,"capital":4,"place":"city","name":"57-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.20222,14.6528]},"properties":{"population":554517,"capital":4,"place":"city","name":"57-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.8892,15.5597]},"properties":{"population":489034,"capital":4,"place":"city","name":"57-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.9271,15.30155]},"properties":{"population":651323,"capital":4,"place":"city","name":"57-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.15405,14.17783]},"properties":{"population":138629,"capital":4,"place":"city","name":"57-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.06119,14.46922]},"properties":{"population":560947,"capital":4,"place":"city","name":"57-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.84169,8.96296]},"properties":{"population":629341,"capital":4,"place":"city","name":"57K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.98432,7.16731]},"properties":{"population":465396,"capital":4,"place":"city","name":"57K12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.76278,8.15316]},"properties":{"population":476217,"capital":4,"place":"city","name":"57K14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.63458,7.87461]},"properties":{"population":409434,"capital":4,"place":"city","name":"57K16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.90735,7.50378]},"properties":{"population":496981,"capital":4,"place":"city","name":"57K18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.71606,8.24344]},"properties":{"population":505624,"capital":4,"place":"city","name":"57K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.98514,7.94569]},"properties":{"population":485981,"capital":4,"place":"city","name":"57K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.75944,8.07775]},"properties":{"population":449980,"capital":4,"place":"city","name":"57K31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.74628,7.59283]},"properties":{"population":393182,"capital":4,"place":"city","name":"57K32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.80115,7.04086]},"properties":{"population":363758,"capital":4,"place":"city","name":"57K33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.47613,7.14471]},"properties":{"population":230258,"capital":4,"place":"city","name":"57K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.66006,7.34803]},"properties":{"population":417438,"capital":4,"place":"city","name":"57K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.76915,7.49263]},"properties":{"population":270805,"capital":4,"place":"city","name":"57K41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.68925,7.11861]},"properties":{"population":248490,"capital":4,"place":"city","name":"57K43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.85841,8.06575]},"properties":{"population":409434,"capital":4,"place":"city","name":"57K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.77003,7.22679]},"properties":{"population":138629,"capital":4,"place":"city","name":"57K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.81321,7.53169]},"properties":{"population":230258,"capital":4,"place":"city","name":"57K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.86508,7.74629]},"properties":{"population":239789,"capital":4,"place":"city","name":"57Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.71292,-5.88997]},"properties":{"population":773848,"capital":4,"place":"city","name":"57M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.24205,-3.11496]},"properties":{"population":756008,"capital":4,"place":"city","name":"57M07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.20495,1.23862]},"properties":{"population":699393,"capital":4,"place":"city","name":"57M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.07314,2.30894]},"properties":{"population":686901,"capital":4,"place":"city","name":"57M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.80369,4.0341]},"properties":{"population":740306,"capital":4,"place":"city","name":"57M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.8292,-0.03315]},"properties":{"population":669456,"capital":4,"place":"city","name":"57M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.9635,-0.10789]},"properties":{"population":912652,"capital":4,"place":"city","name":"57M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.79196,4.68797]},"properties":{"population":833710,"capital":4,"place":"city","name":"57M27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.51328,-5.98964]},"properties":{"population":550938,"capital":4,"place":"city","name":"57M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.88577,-5.86652]},"properties":{"population":522574,"capital":4,"place":"city","name":"57M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.38811,-3.31323]},"properties":{"population":568017,"capital":4,"place":"city","name":"57M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.03813,0.94392]},"properties":{"population":815046,"capital":4,"place":"city","name":"57M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.81795,1.01777]},"properties":{"population":696979,"capital":4,"place":"city","name":"57M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.10303,4.51135]},"properties":{"population":718916,"capital":4,"place":"city","name":"57M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.86998,-6.53914]},"properties":{"population":448863,"capital":4,"place":"city","name":"57Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.59104,-6.54855]},"properties":{"population":737400,"capital":4,"place":"city","name":"57N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.32281,-6.41042]},"properties":{"population":840043,"capital":4,"place":"city","name":"57N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.9077,-3.38388]},"properties":{"population":542053,"capital":4,"place":"city","name":"57N12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.58369,4.42857]},"properties":{"population":722110,"capital":4,"place":"city","name":"57N13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.6845,-1.66518]},"properties":{"population":701121,"capital":4,"place":"city","name":"57N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.34053,-4.92382]},"properties":{"population":563835,"capital":4,"place":"city","name":"57N16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.01966,-6.74149]},"properties":{"population":504985,"capital":4,"place":"city","name":"57N17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.49233,-0.53668]},"properties":{"population":653087,"capital":4,"place":"city","name":"57N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.33612,-4.34416]},"properties":{"population":545103,"capital":4,"place":"city","name":"57N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.40172,-3.77104]},"properties":{"population":387120,"capital":4,"place":"city","name":"57N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.9428,-2.28474]},"properties":{"population":668710,"capital":4,"place":"city","name":"57N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.49696,-4.87313]},"properties":{"population":493447,"capital":4,"place":"city","name":"57N37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.29545,-6.49356]},"properties":{"population":468213,"capital":4,"place":"city","name":"57N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.94724,-2.90598]},"properties":{"population":547646,"capital":4,"place":"city","name":"57N45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.52732,-7.21845]},"properties":{"population":473619,"capital":4,"place":"city","name":"57N50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.37468,-5.21064]},"properties":{"population":427666,"capital":4,"place":"city","name":"57N55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.98836,-5.78409]},"properties":{"population":562762,"capital":4,"place":"city","name":"57N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.99938,-0.31461]},"properties":{"population":682001,"capital":4,"place":"city","name":"57N65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.09426,-4.78586]},"properties":{"population":560947,"capital":4,"place":"city","name":"57N70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.30478,-4.4851]},"properties":{"population":463472,"capital":4,"place":"city","name":"57N75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.27256,-1.94157]},"properties":{"population":584643,"capital":4,"place":"city","name":"57N80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.04429,-2.19138]},"properties":{"population":580814,"capital":4,"place":"city","name":"57N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.77126,-6.58498]},"properties":{"population":304452,"capital":4,"place":"city","name":"57Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.83358,-0.80913]},"properties":{"population":491265,"capital":4,"place":"city","name":"57P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.12648,-0.28271]},"properties":{"population":590536,"capital":4,"place":"city","name":"57P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.33732,0.00147]},"properties":{"population":543372,"capital":4,"place":"city","name":"57P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.40046,-0.93891]},"properties":{"population":109861,"capital":4,"place":"city","name":"57Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.70143,-5.14102]},"properties":{"population":651471,"capital":4,"place":"city","name":"57Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.15672,-0.50664]},"properties":{"population":649677,"capital":4,"place":"city","name":"57Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.95066,-6.0556]},"properties":{"population":455387,"capital":4,"place":"city","name":"57Q12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.3351,-3.28229]},"properties":{"population":668210,"capital":4,"place":"city","name":"57Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.28382,-6.08982]},"properties":{"population":495582,"capital":4,"place":"city","name":"57Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.8904,-5.74913]},"properties":{"population":533753,"capital":4,"place":"city","name":"57Q25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.65433,-6.35537]},"properties":{"population":358351,"capital":4,"place":"city","name":"57Q30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.80558,-5.50619]},"properties":{"population":647543,"capital":4,"place":"city","name":"57Q35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.45128,-6.35924]},"properties":{"population":430406,"capital":4,"place":"city","name":"57Q37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.95808,-6.87849]},"properties":{"population":449980,"capital":4,"place":"city","name":"57Q40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.16063,-0.9561]},"properties":{"population":732909,"capital":4,"place":"city","name":"57Q45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.60483,-6.23459]},"properties":{"population":439444,"capital":4,"place":"city","name":"57Q50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.2391,-7.20825]},"properties":{"population":471849,"capital":4,"place":"city","name":"57Q55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.08669,-5.21155]},"properties":{"population":505624,"capital":4,"place":"city","name":"57Q60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.60136,-7.62203]},"properties":{"population":420469,"capital":4,"place":"city","name":"57Q65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.75207,-5.93727]},"properties":{"population":270805,"capital":4,"place":"city","name":"57Q70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.84488,-6.23033]},"properties":{"population":398898,"capital":4,"place":"city","name":"57Q91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.90406,-6.67935]},"properties":{"population":637161,"capital":4,"place":"city","name":"57Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.23074,-6.02877]},"properties":{"population":304452,"capital":4,"place":"city","name":"57Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.37529,-3.9924]},"properties":{"population":496981,"capital":4,"place":"city","name":"57R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.49413,-1.11783]},"properties":{"population":528826,"capital":4,"place":"city","name":"57R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.92094,-3.15887]},"properties":{"population":429045,"capital":4,"place":"city","name":"57R12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.49599,-6.28368]},"properties":{"population":675460,"capital":4,"place":"city","name":"57R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.03961,4.36789]},"properties":{"population":730451,"capital":4,"place":"city","name":"57R17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.88305,-0.47779]},"properties":{"population":603787,"capital":4,"place":"city","name":"57R18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.9503,-6.14898]},"properties":{"population":681344,"capital":4,"place":"city","name":"57R19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.45392,0.40548]},"properties":{"population":803138,"capital":4,"place":"city","name":"57R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.22996,2.75698]},"properties":{"population":641345,"capital":4,"place":"city","name":"57R22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.15814,2.74135]},"properties":{"population":691968,"capital":4,"place":"city","name":"57R25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.08799,-2.62951]},"properties":{"population":518738,"capital":4,"place":"city","name":"57R27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.80548,0.68746]},"properties":{"population":808456,"capital":4,"place":"city","name":"57R30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.48404,-2.05391]},"properties":{"population":589440,"capital":4,"place":"city","name":"57R32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.53891,-0.13459]},"properties":{"population":599146,"capital":4,"place":"city","name":"57R35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.72625,-6.63474]},"properties":{"population":706390,"capital":4,"place":"city","name":"57R40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.28888,2.74039]},"properties":{"population":675460,"capital":4,"place":"city","name":"57R42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[21.2234,-6.42615]},"properties":{"population":731455,"capital":4,"place":"city","name":"57R45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.13253,0.74136]},"properties":{"population":689264,"capital":4,"place":"city","name":"57R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.63284,0.83362]},"properties":{"population":556834,"capital":4,"place":"city","name":"57R52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.10261,-2.82335]},"properties":{"population":645519,"capital":4,"place":"city","name":"57R55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.82101,-1.24337]},"properties":{"population":647079,"capital":4,"place":"city","name":"57R56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.09064,-0.07945]},"properties":{"population":694215,"capital":4,"place":"city","name":"57R57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.6006,-1.00818]},"properties":{"population":653958,"capital":4,"place":"city","name":"57R58"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.1405,-6.16046]},"properties":{"population":559098,"capital":4,"place":"city","name":"57R60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.74693,-3.05672]},"properties":{"population":706731,"capital":4,"place":"city","name":"57R65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.35123,-6.01418]},"properties":{"population":632256,"capital":4,"place":"city","name":"57R67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.12317,5.39003]},"properties":{"population":677193,"capital":4,"place":"city","name":"57R70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.38918,-5.53673]},"properties":{"population":565599,"capital":4,"place":"city","name":"57R75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.17713,-6.55042]},"properties":{"population":537989,"capital":4,"place":"city","name":"57R77"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.8865,-1.29447]},"properties":{"population":544673,"capital":4,"place":"city","name":"57R80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.4841,-5.7714]},"properties":{"population":631354,"capital":4,"place":"city","name":"57R85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.3888,-2.85814]},"properties":{"population":657507,"capital":4,"place":"city","name":"57R90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.49024,-0.02382]},"properties":{"population":584932,"capital":4,"place":"city","name":"57R91"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.15995,-7.01746]},"properties":{"population":559098,"capital":4,"place":"city","name":"57R95"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.04284,-0.29103]},"properties":{"population":615697,"capital":4,"place":"city","name":"57R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.39906,-6.01487]},"properties":{"population":417438,"capital":4,"place":"city","name":"57Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.53808,-2.91959]},"properties":{"population":658202,"capital":4,"place":"city","name":"57S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.32851,-4.32892]},"properties":{"population":612029,"capital":4,"place":"city","name":"57S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.08371,-6.86755]},"properties":{"population":263905,"capital":4,"place":"city","name":"57S12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.94602,-0.38753]},"properties":{"population":713807,"capital":4,"place":"city","name":"57S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.32391,3.51141]},"properties":{"population":740792,"capital":4,"place":"city","name":"57S17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.54789,-0.51152]},"properties":{"population":649375,"capital":4,"place":"city","name":"57S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.57268,1.08975]},"properties":{"population":773324,"capital":4,"place":"city","name":"57S25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.91793,-4.53323]},"properties":{"population":677422,"capital":4,"place":"city","name":"57S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.53698,-2.5908]},"properties":{"population":616961,"capital":4,"place":"city","name":"57S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.9319,-6.69753]},"properties":{"population":355534,"capital":4,"place":"city","name":"57Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.61153,3.90697]},"properties":{"population":604263,"capital":4,"place":"city","name":"57T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.67873,3.91641]},"properties":{"population":611809,"capital":4,"place":"city","name":"57T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.02107,3.74799]},"properties":{"population":631716,"capital":4,"place":"city","name":"57T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.03107,3.81877]},"properties":{"population":571042,"capital":4,"place":"city","name":"57T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.73167,3.98282]},"properties":{"population":538907,"capital":4,"place":"city","name":"57T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.60391,3.92013]},"properties":{"population":515329,"capital":4,"place":"city","name":"57T30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.4377,3.80603]},"properties":{"population":451085,"capital":4,"place":"city","name":"57T35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.33096,3.88029]},"properties":{"population":559842,"capital":4,"place":"city","name":"57T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.32616,3.84155]},"properties":{"population":294443,"capital":4,"place":"city","name":"57Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.27828,-4.77142]},"properties":{"population":179175,"capital":4,"place":"city","name":"57Z05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.83255,-4.6379]},"properties":{"population":256494,"capital":4,"place":"city","name":"57Z10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.40907,-4.97757]},"properties":{"population":109861,"capital":4,"place":"city","name":"57Z15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.53907,-4.97348]},"properties":{"population":138629,"capital":4,"place":"city","name":"57Z20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.50203,-4.74874]},"properties":{"population":109861,"capital":4,"place":"city","name":"57Z25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.68532,-4.90153]},"properties":{"population":69314,"capital":4,"place":"city","name":"57Z99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.05075,23.09886]},"properties":{"population":263905,"capital":4,"place":"city","name":"58-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.5583,23.41371]},"properties":{"population":620050,"capital":4,"place":"city","name":"58-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.54234,23.82423]},"properties":{"population":691770,"capital":4,"place":"city","name":"58-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.16227,21.88219]},"properties":{"population":477912,"capital":4,"place":"city","name":"58-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.01649,23.06578]},"properties":{"population":443081,"capital":4,"place":"city","name":"58-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.88121,22.82185]},"properties":{"population":712528,"capital":4,"place":"city","name":"58-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.04842,22.92448]},"properties":{"population":69314,"capital":4,"place":"city","name":"58-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.11107,23.00289]},"properties":{"population":471849,"capital":4,"place":"city","name":"58-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.80128,10.39459]},"properties":{"population":438202,"capital":4,"place":"city","name":"58A03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.69097,6.63911]},"properties":{"population":672982,"capital":4,"place":"city","name":"58A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.62868,9.83044]},"properties":{"population":544673,"capital":4,"place":"city","name":"58A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.41606,14.54483]},"properties":{"population":752833,"capital":4,"place":"city","name":"58A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.54389,9.80996]},"properties":{"population":661204,"capital":4,"place":"city","name":"58A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.97002,10.08101]},"properties":{"population":636647,"capital":4,"place":"city","name":"58A14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.30268,11.21673]},"properties":{"population":680572,"capital":4,"place":"city","name":"58A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.34177,9.4897]},"properties":{"population":634563,"capital":4,"place":"city","name":"58A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.75617,10.18415]},"properties":{"population":750878,"capital":4,"place":"city","name":"58A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.89374,6.00853]},"properties":{"population":594279,"capital":4,"place":"city","name":"58A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.83287,9.24358]},"properties":{"population":678105,"capital":4,"place":"city","name":"58A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.31123,10.34269]},"properties":{"population":564897,"capital":4,"place":"city","name":"58A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.48587,8.3849]},"properties":{"population":586929,"capital":4,"place":"city","name":"58A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.66888,8.43217]},"properties":{"population":564897,"capital":4,"place":"city","name":"58A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.52797,-9.11841]},"properties":{"population":678671,"capital":4,"place":"city","name":"58A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.5335,6.26884]},"properties":{"population":621860,"capital":4,"place":"city","name":"58A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.91765,10.63581]},"properties":{"population":404305,"capital":4,"place":"city","name":"58Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.03994,21.12839]},"properties":{"population":587211,"capital":4,"place":"city","name":"58B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.86728,21.01432]},"properties":{"population":563121,"capital":4,"place":"city","name":"58B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.3889,21.15943]},"properties":{"population":499721,"capital":4,"place":"city","name":"58B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.86388,21.24994]},"properties":{"population":547227,"capital":4,"place":"city","name":"58B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.51204,22.33815]},"properties":{"population":697541,"capital":4,"place":"city","name":"58B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.97996,21.08893]},"properties":{"population":609130,"capital":4,"place":"city","name":"58B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.16087,21.18768]},"properties":{"population":69314,"capital":4,"place":"city","name":"58B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.94222,20.23744]},"properties":{"population":643775,"capital":4,"place":"city","name":"58B32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.98804,21.56426]},"properties":{"population":716162,"capital":4,"place":"city","name":"58B34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.9371,21.02115]},"properties":{"population":554126,"capital":4,"place":"city","name":"58B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.27858,21.35152]},"properties":{"population":239789,"capital":4,"place":"city","name":"58Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.48513,5.97681]},"properties":{"population":548479,"capital":4,"place":"city","name":"58C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.70891,5.96338]},"properties":{"population":576832,"capital":4,"place":"city","name":"58C06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.92085,10.0147]},"properties":{"population":468213,"capital":4,"place":"city","name":"58C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.897,6.24353]},"properties":{"population":560211,"capital":4,"place":"city","name":"58C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.93178,8.32545]},"properties":{"population":633859,"capital":4,"place":"city","name":"58C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.9022,8.95926]},"properties":{"population":683733,"capital":4,"place":"city","name":"58C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.96831,-4.17834]},"properties":{"population":774153,"capital":4,"place":"city","name":"58C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.50338,7.92908]},"properties":{"population":69314,"capital":4,"place":"city","name":"58C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.05861,6.09476]},"properties":{"population":613772,"capital":4,"place":"city","name":"58C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.75783,10.87138]},"properties":{"population":629894,"capital":4,"place":"city","name":"58C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.469,-9.68033]},"properties":{"population":668959,"capital":4,"place":"city","name":"58C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.13351,8.28237]},"properties":{"population":607304,"capital":4,"place":"city","name":"58C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.7528,6.28112]},"properties":{"population":557972,"capital":4,"place":"city","name":"58C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.31248,9.42658]},"properties":{"population":304452,"capital":4,"place":"city","name":"58Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.515,9.74881]},"properties":{"population":697073,"capital":4,"place":"city","name":"58D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.57275,10.41735]},"properties":{"population":406044,"capital":4,"place":"city","name":"58D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.00811,-4.13143]},"properties":{"population":571702,"capital":4,"place":"city","name":"58D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.99352,8.42143]},"properties":{"population":655108,"capital":4,"place":"city","name":"58D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.80285,7.23749]},"properties":{"population":615697,"capital":4,"place":"city","name":"58D17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.12183,6.23795]},"properties":{"population":575257,"capital":4,"place":"city","name":"58D19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.99505,6.68735]},"properties":{"population":538907,"capital":4,"place":"city","name":"58D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.19279,-10.0456]},"properties":{"population":619644,"capital":4,"place":"city","name":"58D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.37823,7.75355]},"properties":{"population":670196,"capital":4,"place":"city","name":"58D27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.01989,9.23958]},"properties":{"population":545103,"capital":4,"place":"city","name":"58D29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.24281,15.69817]},"properties":{"population":675809,"capital":4,"place":"city","name":"58D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.67355,9.59351]},"properties":{"population":509375,"capital":4,"place":"city","name":"58D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.3574,6.48626]},"properties":{"population":219722,"capital":4,"place":"city","name":"58Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.24211,18.63364]},"properties":{"population":837286,"capital":4,"place":"city","name":"58E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.30262,15.45797]},"properties":{"population":656103,"capital":4,"place":"city","name":"58E07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.87433,9.91211]},"properties":{"population":549716,"capital":4,"place":"city","name":"58E09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.25075,13.41646]},"properties":{"population":664378,"capital":4,"place":"city","name":"58E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.61612,8.91914]},"properties":{"population":588887,"capital":4,"place":"city","name":"58E11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.16229,10.78544]},"properties":{"population":619847,"capital":4,"place":"city","name":"58E12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.4265,11.51657]},"properties":{"population":671780,"capital":4,"place":"city","name":"58E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.54678,11.08539]},"properties":{"population":516478,"capital":4,"place":"city","name":"58E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.14608,-9.42073]},"properties":{"population":806400,"capital":4,"place":"city","name":"58E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.34998,11.09892]},"properties":{"population":525749,"capital":4,"place":"city","name":"58E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.13059,16.44689]},"properties":{"population":735115,"capital":4,"place":"city","name":"58E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.48998,11.89643]},"properties":{"population":629341,"capital":4,"place":"city","name":"58E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.39467,7.52404]},"properties":{"population":516478,"capital":4,"place":"city","name":"58E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.31461,15.75017]},"properties":{"population":699117,"capital":4,"place":"city","name":"58E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.14251,10.2415]},"properties":{"population":564190,"capital":4,"place":"city","name":"58E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.13273,6.80916]},"properties":{"population":395124,"capital":4,"place":"city","name":"58Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.15383,6.06379]},"properties":{"population":69314,"capital":4,"place":"city","name":"58F03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.10931,7.50922]},"properties":{"population":138629,"capital":4,"place":"city","name":"58F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.7828,7.65529]},"properties":{"population":109861,"capital":4,"place":"city","name":"58F07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.27268,6.13957]},"properties":{"population":69314,"capital":4,"place":"city","name":"58F08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.08347,6.393]},"properties":{"population":109861,"capital":4,"place":"city","name":"58F14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.07333,6.19748]},"properties":{"population":179175,"capital":4,"place":"city","name":"58F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.32524,6.14796]},"properties":{"population":179175,"capital":4,"place":"city","name":"58F17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.26182,6.24064]},"properties":{"population":69314,"capital":4,"place":"city","name":"58F21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.08558,6.07958]},"properties":{"population":69314,"capital":4,"place":"city","name":"58F22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.08637,6.84221]},"properties":{"population":109861,"capital":4,"place":"city","name":"58F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.27445,6.01312]},"properties":{"population":69314,"capital":4,"place":"city","name":"58F32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.25895,5.90587]},"properties":{"population":109861,"capital":4,"place":"city","name":"58Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.74823,8.0768]},"properties":{"population":69314,"capital":4,"place":"city","name":"58G07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.58897,7.99897]},"properties":{"population":69314,"capital":4,"place":"city","name":"58G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.86552,8.06727]},"properties":{"population":69314,"capital":4,"place":"city","name":"58G11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.11613,7.75073]},"properties":{"population":109861,"capital":4,"place":"city","name":"58G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.71474,8.21182]},"properties":{"population":69314,"capital":4,"place":"city","name":"58G16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.59138,8.22511]},"properties":{"population":69314,"capital":4,"place":"city","name":"58G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.67775,7.93709]},"properties":{"population":69314,"capital":4,"place":"city","name":"58G32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.51676,7.77535]},"properties":{"population":109861,"capital":4,"place":"city","name":"58G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.94341,10.80115]},"properties":{"population":677764,"capital":4,"place":"city","name":"58H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.74565,9.55007]},"properties":{"population":539816,"capital":4,"place":"city","name":"58H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.80095,10.55019]},"properties":{"population":589440,"capital":4,"place":"city","name":"58H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.64129,9.19153]},"properties":{"population":448863,"capital":4,"place":"city","name":"58H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.58879,8.79737]},"properties":{"population":194591,"capital":4,"place":"city","name":"58Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.2642,-6.0156]},"properties":{"population":759990,"capital":4,"place":"city","name":"58J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.6934,11.50838]},"properties":{"population":686797,"capital":4,"place":"city","name":"58J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.73083,7.74283]},"properties":{"population":584932,"capital":4,"place":"city","name":"58J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.76599,-9.53362]},"properties":{"population":737588,"capital":4,"place":"city","name":"58J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.44954,7.24875]},"properties":{"population":627476,"capital":4,"place":"city","name":"58J22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.75632,6.39906]},"properties":{"population":542053,"capital":4,"place":"city","name":"58J26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.66801,13.65343]},"properties":{"population":714282,"capital":4,"place":"city","name":"58J28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.68226,7.46171]},"properties":{"population":515329,"capital":4,"place":"city","name":"58J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.26984,15.2786]},"properties":{"population":703085,"capital":4,"place":"city","name":"58J32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.96402,18.74475]},"properties":{"population":776937,"capital":4,"place":"city","name":"58J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.90643,14.02596]},"properties":{"population":633859,"capital":4,"place":"city","name":"58J37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.41511,-5.40348]},"properties":{"population":753689,"capital":4,"place":"city","name":"58J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.10676,7.01]},"properties":{"population":599146,"capital":4,"place":"city","name":"58J42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.23392,15.52609]},"properties":{"population":688038,"capital":4,"place":"city","name":"58J45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.05897,9.99128]},"properties":{"population":612029,"capital":4,"place":"city","name":"58J47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.88148,-9.54477]},"properties":{"population":841980,"capital":4,"place":"city","name":"58J50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.37423,6.48148]},"properties":{"population":506259,"capital":4,"place":"city","name":"58J51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.61792,-6.1682]},"properties":{"population":660800,"capital":4,"place":"city","name":"58J52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.05762,8.17037]},"properties":{"population":578689,"capital":4,"place":"city","name":"58J53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.89112,7.07727]},"properties":{"population":440671,"capital":4,"place":"city","name":"58J55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.42515,-6.51572]},"properties":{"population":734083,"capital":4,"place":"city","name":"58J60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.53214,-9.58412]},"properties":{"population":769621,"capital":4,"place":"city","name":"58J65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.48628,-5.13975]},"properties":{"population":762851,"capital":4,"place":"city","name":"58J70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.40224,7.49234]},"properties":{"population":671295,"capital":4,"place":"city","name":"58J72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.32288,15.96205]},"properties":{"population":665929,"capital":4,"place":"city","name":"58J90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.88245,11.47422]},"properties":{"population":678558,"capital":4,"place":"city","name":"58J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.13418,8.17753]},"properties":{"population":469134,"capital":4,"place":"city","name":"58Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.33354,7.99589]},"properties":{"population":628785,"capital":4,"place":"city","name":"58K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.03825,6.61787]},"properties":{"population":455387,"capital":4,"place":"city","name":"58K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.75115,9.63105]},"properties":{"population":526785,"capital":4,"place":"city","name":"58K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.66238,8.59821]},"properties":{"population":465396,"capital":4,"place":"city","name":"58K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.70123,8.80692]},"properties":{"population":476217,"capital":4,"place":"city","name":"58K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.26107,9.13465]},"properties":{"population":487519,"capital":4,"place":"city","name":"58K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.88185,16.66468]},"properties":{"population":629526,"capital":4,"place":"city","name":"58K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.24799,8.60343]},"properties":{"population":576832,"capital":4,"place":"city","name":"58K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.70561,9.92322]},"properties":{"population":519849,"capital":4,"place":"city","name":"58K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.62485,9.51498]},"properties":{"population":496284,"capital":4,"place":"city","name":"58K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.57039,10.63093]},"properties":{"population":391202,"capital":4,"place":"city","name":"58K55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.68267,8.1303]},"properties":{"population":490527,"capital":4,"place":"city","name":"58K60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.34758,10.52871]},"properties":{"population":500394,"capital":4,"place":"city","name":"58K65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.86138,10.89909]},"properties":{"population":436944,"capital":4,"place":"city","name":"58K70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.83733,-4.21838]},"properties":{"population":727170,"capital":4,"place":"city","name":"58K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.83847,5.84073]},"properties":{"population":352636,"capital":4,"place":"city","name":"58Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.65107,21.2505]},"properties":{"population":772973,"capital":4,"place":"city","name":"58Z05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.07166,21.09109]},"properties":{"population":194591,"capital":4,"place":"city","name":"58Z99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.21325,20.9906]},"properties":{"population":69314,"capital":4,"place":"city","name":"58Zxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.45757,31.65836]},"properties":{"population":427666,"capital":4,"place":"city","name":"60-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.87967,30.58487]},"properties":{"population":774975,"capital":4,"place":"city","name":"60-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.05189,30.43107]},"properties":{"population":776811,"capital":4,"place":"city","name":"60-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.12283,31.36376]},"properties":{"population":700850,"capital":4,"place":"city","name":"60-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.75465,31.2564]},"properties":{"population":513579,"capital":4,"place":"city","name":"60-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.17324,30.97347]},"properties":{"population":753955,"capital":4,"place":"city","name":"60-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.29022,31.08431]},"properties":{"population":522035,"capital":4,"place":"city","name":"60-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.50938,31.5933]},"properties":{"population":717548,"capital":4,"place":"city","name":"60-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.82306,24.25269]},"properties":{"population":760787,"capital":4,"place":"city","name":"60A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.68792,24.30086]},"properties":{"population":717395,"capital":4,"place":"city","name":"60A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.1515,24.08751]},"properties":{"population":610255,"capital":4,"place":"city","name":"60A86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.97441,24.19405]},"properties":{"population":711720,"capital":4,"place":"city","name":"60A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.90384,24.72876]},"properties":{"population":443081,"capital":4,"place":"city","name":"60Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27657,-9.68442]},"properties":{"population":772621,"capital":4,"place":"city","name":"60B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.283,14.81581]},"properties":{"population":786172,"capital":4,"place":"city","name":"60B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.1914,4.79959]},"properties":{"population":733563,"capital":4,"place":"city","name":"60B11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.32579,-9.83901]},"properties":{"population":758120,"capital":4,"place":"city","name":"60B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.83367,-5.76231]},"properties":{"population":794590,"capital":4,"place":"city","name":"60B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.05621,-9.89878]},"properties":{"population":775918,"capital":4,"place":"city","name":"60B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.37748,4.84105]},"properties":{"population":704228,"capital":4,"place":"city","name":"60B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.04915,-2.76956]},"properties":{"population":434380,"capital":4,"place":"city","name":"60Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.11662,24.21971]},"properties":{"population":870516,"capital":4,"place":"city","name":"60C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.1184,24.39781]},"properties":{"population":378418,"capital":4,"place":"city","name":"60C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.16651,24.30953]},"properties":{"population":194591,"capital":4,"place":"city","name":"60Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.89352,24.83998]},"properties":{"population":850855,"capital":4,"place":"city","name":"60D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.62399,24.47689]},"properties":{"population":385014,"capital":4,"place":"city","name":"60D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.79453,24.56958]},"properties":{"population":277258,"capital":4,"place":"city","name":"60Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.51251,20.90609]},"properties":{"population":903515,"capital":4,"place":"city","name":"60E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.74726,13.00996]},"properties":{"population":804910,"capital":4,"place":"city","name":"60E07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.3,13.27271]},"properties":{"population":787092,"capital":4,"place":"city","name":"60E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.63741,-9.5078]},"properties":{"population":883010,"capital":4,"place":"city","name":"60E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.7869,13.94386]},"properties":{"population":747760,"capital":4,"place":"city","name":"60E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.52625,-2.97613]},"properties":{"population":491265,"capital":4,"place":"city","name":"60Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.75153,-9.55267]},"properties":{"population":975056,"capital":4,"place":"city","name":"60F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.54495,20.81317]},"properties":{"population":886827,"capital":4,"place":"city","name":"60F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.14441,-9.44125]},"properties":{"population":910453,"capital":4,"place":"city","name":"60F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.05995,19.08332]},"properties":{"population":849882,"capital":4,"place":"city","name":"60F17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.15229,-4.72881]},"properties":{"population":612249,"capital":4,"place":"city","name":"60F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.12439,4.88824]},"properties":{"population":654102,"capital":4,"place":"city","name":"60F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.9605,-9.56635]},"properties":{"population":808764,"capital":4,"place":"city","name":"60F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.0366,1.34607]},"properties":{"population":562762,"capital":4,"place":"city","name":"60Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.45276,4.54557]},"properties":{"population":682328,"capital":4,"place":"city","name":"60G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.50442,7.54372]},"properties":{"population":702820,"capital":4,"place":"city","name":"60G07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.22418,7.50139]},"properties":{"population":650428,"capital":4,"place":"city","name":"60G09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.17023,-9.28732]},"properties":{"population":859785,"capital":4,"place":"city","name":"60G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.03015,4.97304]},"properties":{"population":659714,"capital":4,"place":"city","name":"60G12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.87319,-9.43449]},"properties":{"population":890828,"capital":4,"place":"city","name":"60G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.80611,10.2864]},"properties":{"population":801400,"capital":4,"place":"city","name":"60G17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.69608,5.71493]},"properties":{"population":713568,"capital":4,"place":"city","name":"60G18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.6989,8.70544]},"properties":{"population":723489,"capital":4,"place":"city","name":"60G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.74224,15.15196]},"properties":{"population":760489,"capital":4,"place":"city","name":"60G22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.65359,11.96008]},"properties":{"population":717548,"capital":4,"place":"city","name":"60G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.47111,-4.61292]},"properties":{"population":651323,"capital":4,"place":"city","name":"60G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.69084,23.34802]},"properties":{"population":861938,"capital":4,"place":"city","name":"60G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.93795,20.47639]},"properties":{"population":854051,"capital":4,"place":"city","name":"60G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.81631,10.91205]},"properties":{"population":785786,"capital":4,"place":"city","name":"60G42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.72419,-5.32611]},"properties":{"population":823880,"capital":4,"place":"city","name":"60G44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.34897,3.32867]},"properties":{"population":678671,"capital":4,"place":"city","name":"60G46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.87035,6.20541]},"properties":{"population":757609,"capital":4,"place":"city","name":"60G48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.6567,-9.26586]},"properties":{"population":923522,"capital":4,"place":"city","name":"60G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.53304,-9.26755]},"properties":{"population":817695,"capital":4,"place":"city","name":"60G51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.09954,4.55133]},"properties":{"population":707834,"capital":4,"place":"city","name":"60G52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.45109,-2.27515]},"properties":{"population":219722,"capital":4,"place":"city","name":"60G53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.91338,21.05803]},"properties":{"population":846632,"capital":4,"place":"city","name":"60G55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.67866,15.14212]},"properties":{"population":773368,"capital":4,"place":"city","name":"60G57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.60473,21.66841]},"properties":{"population":844139,"capital":4,"place":"city","name":"60G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.91744,0.14692]},"properties":{"population":380666,"capital":4,"place":"city","name":"60G65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.33706,13.35471]},"properties":{"population":790470,"capital":4,"place":"city","name":"60G70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.16061,15.79427]},"properties":{"population":774370,"capital":4,"place":"city","name":"60G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.92517,9.14737]},"properties":{"population":602344,"capital":4,"place":"city","name":"60Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.47107,14.42205]},"properties":{"population":847532,"capital":4,"place":"city","name":"60H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.78764,9.53592]},"properties":{"population":775619,"capital":4,"place":"city","name":"60H07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.9064,-5.05355]},"properties":{"population":969965,"capital":4,"place":"city","name":"60H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.10124,22.77204]},"properties":{"population":910775,"capital":4,"place":"city","name":"60H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.34244,-0.36129]},"properties":{"population":207944,"capital":4,"place":"city","name":"60H17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.43466,12.57281]},"properties":{"population":761775,"capital":4,"place":"city","name":"60H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.07965,-4.94281]},"properties":{"population":776514,"capital":4,"place":"city","name":"60H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.9629,-6.00179]},"properties":{"population":854772,"capital":4,"place":"city","name":"60H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.84976,21.34975]},"properties":{"population":798616,"capital":4,"place":"city","name":"60H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.88362,18.84193]},"properties":{"population":731255,"capital":4,"place":"city","name":"60H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.85621,2.77509]},"properties":{"population":329583,"capital":4,"place":"city","name":"60H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.68873,16.15881]},"properties":{"population":766246,"capital":4,"place":"city","name":"60H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.25159,5.98882]},"properties":{"population":575257,"capital":4,"place":"city","name":"60Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.21754,19.60463]},"properties":{"population":789431,"capital":4,"place":"city","name":"60J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.07291,21.98476]},"properties":{"population":894910,"capital":4,"place":"city","name":"60J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.72774,-0.18846]},"properties":{"population":160943,"capital":4,"place":"city","name":"60J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.89491,-9.23578]},"properties":{"population":823217,"capital":4,"place":"city","name":"60J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.42017,20.58069]},"properties":{"population":729097,"capital":4,"place":"city","name":"60J22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.65622,20.19037]},"properties":{"population":835585,"capital":4,"place":"city","name":"60J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.92486,19.62827]},"properties":{"population":793951,"capital":4,"place":"city","name":"60J27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.8132,11.65569]},"properties":{"population":667456,"capital":4,"place":"city","name":"60J28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.64775,-0.25923]},"properties":{"population":69314,"capital":4,"place":"city","name":"60J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.05947,-5.27717]},"properties":{"population":765775,"capital":4,"place":"city","name":"60J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.95773,2.13404]},"properties":{"population":573979,"capital":4,"place":"city","name":"60J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.31288,5.19291]},"properties":{"population":779399,"capital":4,"place":"city","name":"60J45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.1917,0.28108]},"properties":{"population":263905,"capital":4,"place":"city","name":"60J46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.87939,3.91681]},"properties":{"population":664898,"capital":4,"place":"city","name":"60J50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.17753,6.6443]},"properties":{"population":765586,"capital":4,"place":"city","name":"60J55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.49729,2.57669]},"properties":{"population":507517,"capital":4,"place":"city","name":"60J57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.91193,-9.87114]},"properties":{"population":910308,"capital":4,"place":"city","name":"60J60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.13534,-9.74918]},"properties":{"population":903037,"capital":4,"place":"city","name":"60J65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.32403,2.80114]},"properties":{"population":568357,"capital":4,"place":"city","name":"60J67"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.19098,1.05688]},"properties":{"population":496284,"capital":4,"place":"city","name":"60J68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.3692,-9.17844]},"properties":{"population":805801,"capital":4,"place":"city","name":"60J70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.15169,5.75555]},"properties":{"population":498360,"capital":4,"place":"city","name":"60J74"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.56296,19.95948]},"properties":{"population":837678,"capital":4,"place":"city","name":"60J75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.45625,3.9472]},"properties":{"population":508759,"capital":4,"place":"city","name":"60J76"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.31774,15.39674]},"properties":{"population":875762,"capital":4,"place":"city","name":"60J80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.81384,12.28525]},"properties":{"population":746049,"capital":4,"place":"city","name":"60J85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.10964,0.10866]},"properties":{"population":299573,"capital":4,"place":"city","name":"60J90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.48422,19.72384]},"properties":{"population":783794,"capital":4,"place":"city","name":"60J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.97109,3.34952]},"properties":{"population":604737,"capital":4,"place":"city","name":"60Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.72346,11.77622]},"properties":{"population":788004,"capital":4,"place":"city","name":"60K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.02266,-9.3679]},"properties":{"population":826847,"capital":4,"place":"city","name":"60K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.93738,10.86658]},"properties":{"population":763530,"capital":4,"place":"city","name":"60K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.47015,-9.26018]},"properties":{"population":783399,"capital":4,"place":"city","name":"60K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.20636,-9.19795]},"properties":{"population":954866,"capital":4,"place":"city","name":"60K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.04255,-5.70461]},"properties":{"population":763771,"capital":4,"place":"city","name":"60K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.79816,19.96703]},"properties":{"population":927958,"capital":4,"place":"city","name":"60K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27192,6.74307]},"properties":{"population":752940,"capital":4,"place":"city","name":"60K37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.78171,17.13224]},"properties":{"population":767136,"capital":4,"place":"city","name":"60K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.45055,-1.24399]},"properties":{"population":363758,"capital":4,"place":"city","name":"60K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.64817,-8.9714]},"properties":{"population":738894,"capital":4,"place":"city","name":"60K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.95295,4.05999]},"properties":{"population":516478,"capital":4,"place":"city","name":"60Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.21014,2.53733]},"properties":{"population":179175,"capital":4,"place":"city","name":"60L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.30835,2.79541]},"properties":{"population":382864,"capital":4,"place":"city","name":"60L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.99802,2.44672]},"properties":{"population":270805,"capital":4,"place":"city","name":"60L30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.99113,2.60144]},"properties":{"population":160943,"capital":4,"place":"city","name":"60L40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.8784,2.27687]},"properties":{"population":230258,"capital":4,"place":"city","name":"60L50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.40289,2.77647]},"properties":{"population":294443,"capital":4,"place":"city","name":"60L90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.38069,0.7601]},"properties":{"population":585507,"capital":4,"place":"city","name":"62-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.97133,14.93562]},"properties":{"population":847595,"capital":4,"place":"city","name":"62-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.64939,17.74533]},"properties":{"population":812148,"capital":4,"place":"city","name":"62-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.43133,7.52456]},"properties":{"population":720414,"capital":4,"place":"city","name":"62-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.97216,12.83727]},"properties":{"population":777359,"capital":4,"place":"city","name":"62-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.5239,5.83587]},"properties":{"population":789506,"capital":4,"place":"city","name":"62-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.79088,-10.04336]},"properties":{"population":818562,"capital":4,"place":"city","name":"62-07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.99362,6.588]},"properties":{"population":759538,"capital":4,"place":"city","name":"62-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.58405,5.90349]},"properties":{"population":701750,"capital":4,"place":"city","name":"62-09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.18689,-3.42297]},"properties":{"population":109861,"capital":4,"place":"city","name":"62-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.92339,0.72242]},"properties":{"population":912303,"capital":4,"place":"city","name":"62-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.47824,-10.01445]},"properties":{"population":831115,"capital":4,"place":"city","name":"62A01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.12014,-4.72115]},"properties":{"population":424849,"capital":4,"place":"city","name":"62A09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.47943,-5.27639]},"properties":{"population":490527,"capital":4,"place":"city","name":"62A86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.1408,-3.12954]},"properties":{"population":485981,"capital":4,"place":"city","name":"62A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.29046,-2.00029]},"properties":{"population":69314,"capital":4,"place":"city","name":"62Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.48065,21.32899]},"properties":{"population":676734,"capital":4,"place":"city","name":"62B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.04251,23.5645]},"properties":{"population":844419,"capital":4,"place":"city","name":"62B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.22673,24.14654]},"properties":{"population":349650,"capital":4,"place":"city","name":"62B11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.82766,24.17725]},"properties":{"population":626530,"capital":4,"place":"city","name":"62B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.32595,23.94378]},"properties":{"population":69314,"capital":4,"place":"city","name":"62B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.13188,24.1106]},"properties":{"population":368887,"capital":4,"place":"city","name":"62B86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.11077,24.1866]},"properties":{"population":596357,"capital":4,"place":"city","name":"62B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.32276,23.87889]},"properties":{"population":263905,"capital":4,"place":"city","name":"62Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.21504,4.42574]},"properties":{"population":670318,"capital":4,"place":"city","name":"62C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.33132,-2.64615]},"properties":{"population":528826,"capital":4,"place":"city","name":"62C07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.44711,13.87091]},"properties":{"population":816479,"capital":4,"place":"city","name":"62C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.41423,-10.03698]},"properties":{"population":734407,"capital":4,"place":"city","name":"62C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.97761,3.23421]},"properties":{"population":739326,"capital":4,"place":"city","name":"62C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.00643,-9.94381]},"properties":{"population":747533,"capital":4,"place":"city","name":"62C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.91167,0.83399]},"properties":{"population":569035,"capital":4,"place":"city","name":"62C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.9146,-1.54561]},"properties":{"population":507517,"capital":4,"place":"city","name":"62C86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.00649,-4.04527]},"properties":{"population":710249,"capital":4,"place":"city","name":"62C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.4695,-4.54129]},"properties":{"population":447733,"capital":4,"place":"city","name":"62Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.58859,21.49736]},"properties":{"population":892983,"capital":4,"place":"city","name":"62D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.84887,23.23943]},"properties":{"population":563121,"capital":4,"place":"city","name":"62D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.7073,23.14667]},"properties":{"population":509375,"capital":4,"place":"city","name":"62D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.90506,23.21403]},"properties":{"population":397029,"capital":4,"place":"city","name":"62D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.07195,23.0709]},"properties":{"population":263905,"capital":4,"place":"city","name":"62Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.54027,-4.36798]},"properties":{"population":870483,"capital":4,"place":"city","name":"62E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.35983,-4.58544]},"properties":{"population":870267,"capital":4,"place":"city","name":"62E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.74488,5.89205]},"properties":{"population":734277,"capital":4,"place":"city","name":"62E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.49883,-9.21931]},"properties":{"population":943196,"capital":4,"place":"city","name":"62E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.5212,-3.20102]},"properties":{"population":69314,"capital":4,"place":"city","name":"62E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.76299,-3.12185]},"properties":{"population":69314,"capital":4,"place":"city","name":"62E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.45919,-4.64373]},"properties":{"population":321887,"capital":4,"place":"city","name":"62E86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.5049,8.83761]},"properties":{"population":696885,"capital":4,"place":"city","name":"62E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.05233,-5.02585]},"properties":{"population":431748,"capital":4,"place":"city","name":"62Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.32595,7.93264]},"properties":{"population":893022,"capital":4,"place":"city","name":"62F03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.47468,11.06918]},"properties":{"population":783991,"capital":4,"place":"city","name":"62F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29978,6.1436]},"properties":{"population":754960,"capital":4,"place":"city","name":"62F07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.32901,-4.22707]},"properties":{"population":958692,"capital":4,"place":"city","name":"62F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.2347,-9.2707]},"properties":{"population":910242,"capital":4,"place":"city","name":"62F12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.19064,-8.88531]},"properties":{"population":969035,"capital":4,"place":"city","name":"62F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.92528,6.7796]},"properties":{"population":833686,"capital":4,"place":"city","name":"62F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.36739,10.54245]},"properties":{"population":712125,"capital":4,"place":"city","name":"62F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.88409,-9.04623]},"properties":{"population":834948,"capital":4,"place":"city","name":"62F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.88942,10.35964]},"properties":{"population":738523,"capital":4,"place":"city","name":"62F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.37117,-2.22722]},"properties":{"population":520948,"capital":4,"place":"city","name":"62F86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.37722,4.45257]},"properties":{"population":688448,"capital":4,"place":"city","name":"62F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.5693,-0.80896]},"properties":{"population":482831,"capital":4,"place":"city","name":"62Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.54873,-8.94564]},"properties":{"population":950613,"capital":4,"place":"city","name":"62G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.01634,-8.88043]},"properties":{"population":893392,"capital":4,"place":"city","name":"62G07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.23932,10.40055]},"properties":{"population":905637,"capital":4,"place":"city","name":"62G08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.7796,7.83218]},"properties":{"population":792588,"capital":4,"place":"city","name":"62G09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.44793,6.86019]},"properties":{"population":929486,"capital":4,"place":"city","name":"62G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.48668,8.94762]},"properties":{"population":775319,"capital":4,"place":"city","name":"62G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.4353,-9.25242]},"properties":{"population":945665,"capital":4,"place":"city","name":"62G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.98259,-4.33894]},"properties":{"population":873069,"capital":4,"place":"city","name":"62G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.80572,11.23071]},"properties":{"population":790948,"capital":4,"place":"city","name":"62G32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.33233,10.09211]},"properties":{"population":760837,"capital":4,"place":"city","name":"62G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.64005,-4.19601]},"properties":{"population":411087,"capital":4,"place":"city","name":"62G86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.98458,-4.23692]},"properties":{"population":735755,"capital":4,"place":"city","name":"62G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.61165,-2.87568]},"properties":{"population":523644,"capital":4,"place":"city","name":"62Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.8717,-9.23172]},"properties":{"population":836030,"capital":4,"place":"city","name":"62H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.38477,6.42272]},"properties":{"population":839140,"capital":4,"place":"city","name":"62H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.74583,6.34428]},"properties":{"population":740913,"capital":4,"place":"city","name":"62H11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.74159,-4.44421]},"properties":{"population":927790,"capital":4,"place":"city","name":"62H12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.90105,-4.34795]},"properties":{"population":854012,"capital":4,"place":"city","name":"62H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.76134,6.35813]},"properties":{"population":762997,"capital":4,"place":"city","name":"62H17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.10145,10.3442]},"properties":{"population":841227,"capital":4,"place":"city","name":"62H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.67713,-2.01468]},"properties":{"population":547227,"capital":4,"place":"city","name":"62H22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.20737,14.71379]},"properties":{"population":847553,"capital":4,"place":"city","name":"62H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.98677,18.88908]},"properties":{"population":952251,"capital":4,"place":"city","name":"62H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.74264,4.94865]},"properties":{"population":726473,"capital":4,"place":"city","name":"62H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.62882,4.81485]},"properties":{"population":620455,"capital":4,"place":"city","name":"62H86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.4813,-8.8312]},"properties":{"population":785049,"capital":4,"place":"city","name":"62H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.92013,-1.781]},"properties":{"population":557972,"capital":4,"place":"city","name":"62Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.43043,-4.31898]},"properties":{"population":837930,"capital":4,"place":"city","name":"62J02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.22989,-4.07933]},"properties":{"population":960116,"capital":4,"place":"city","name":"62J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.84722,8.18706]},"properties":{"population":805610,"capital":4,"place":"city","name":"62J07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.61004,-9.47756]},"properties":{"population":847345,"capital":4,"place":"city","name":"62J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.25012,6.03876]},"properties":{"population":845616,"capital":4,"place":"city","name":"62J12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.26897,-8.99096]},"properties":{"population":765159,"capital":4,"place":"city","name":"62J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.80523,3.7194]},"properties":{"population":705444,"capital":4,"place":"city","name":"62J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.52978,-0.5743]},"properties":{"population":557215,"capital":4,"place":"city","name":"62J86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.6163,5.87934]},"properties":{"population":794661,"capital":4,"place":"city","name":"62J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.83694,-1.36683]},"properties":{"population":541164,"capital":4,"place":"city","name":"62Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.93575,23.02917]},"properties":{"population":847741,"capital":4,"place":"city","name":"62K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.06924,22.7897]},"properties":{"population":804141,"capital":4,"place":"city","name":"62K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.25412,22.6555]},"properties":{"population":786633,"capital":4,"place":"city","name":"62K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.31375,22.49927]},"properties":{"population":620455,"capital":4,"place":"city","name":"62K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.40205,22.5818]},"properties":{"population":548063,"capital":4,"place":"city","name":"62K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.67733,22.34279]},"properties":{"population":283321,"capital":4,"place":"city","name":"62K86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.01123,21.12904]},"properties":{"population":742476,"capital":4,"place":"city","name":"62K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.63214,21.03706]},"properties":{"population":534710,"capital":4,"place":"city","name":"62Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.31123,21.51936]},"properties":{"population":709257,"capital":4,"place":"city","name":"62L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.94316,21.49568]},"properties":{"population":798036,"capital":4,"place":"city","name":"62L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.02196,24.0693]},"properties":{"population":763288,"capital":4,"place":"city","name":"62L12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.6037,23.76972]},"properties":{"population":733432,"capital":4,"place":"city","name":"62L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.49704,23.91552]},"properties":{"population":745818,"capital":4,"place":"city","name":"62L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.79947,23.35502]},"properties":{"population":248490,"capital":4,"place":"city","name":"62L86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.63686,21.43797]},"properties":{"population":602827,"capital":4,"place":"city","name":"62L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.79886,23.36267]},"properties":{"population":321887,"capital":4,"place":"city","name":"62Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.73947,5.93262]},"properties":{"population":675576,"capital":4,"place":"city","name":"62M02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.45181,7.25559]},"properties":{"population":836217,"capital":4,"place":"city","name":"62M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.15853,5.17588]},"properties":{"population":719818,"capital":4,"place":"city","name":"62M07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.9219,-9.00334]},"properties":{"population":800269,"capital":4,"place":"city","name":"62M09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.5049,-9.23164]},"properties":{"population":1000816,"capital":4,"place":"city","name":"62M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.78363,-8.98096]},"properties":{"population":815679,"capital":4,"place":"city","name":"62M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.60336,17.78238]},"properties":{"population":891784,"capital":4,"place":"city","name":"62M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.5023,4.54084]},"properties":{"population":771289,"capital":4,"place":"city","name":"62M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.51364,4.72893]},"properties":{"population":741095,"capital":4,"place":"city","name":"62M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.98427,4.4213]},"properties":{"population":661338,"capital":4,"place":"city","name":"62M45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.12788,-0.40059]},"properties":{"population":532300,"capital":4,"place":"city","name":"62M86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.26801,-8.99589]},"properties":{"population":773848,"capital":4,"place":"city","name":"62M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.01716,-3.18362]},"properties":{"population":457471,"capital":4,"place":"city","name":"62Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.71615,3.87187]},"properties":{"population":821039,"capital":4,"place":"city","name":"62N01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.81856,7.06289]},"properties":{"population":846421,"capital":4,"place":"city","name":"62N02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.23963,2.1923]},"properties":{"population":714045,"capital":4,"place":"city","name":"62N03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.56187,-10.11657]},"properties":{"population":930692,"capital":4,"place":"city","name":"62N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.77487,-3.958]},"properties":{"population":109861,"capital":4,"place":"city","name":"62N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.07653,-4.01678]},"properties":{"population":445434,"capital":4,"place":"city","name":"62N86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.26778,6.50029]},"properties":{"population":707326,"capital":4,"place":"city","name":"62N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.72637,-4.57818]},"properties":{"population":482028,"capital":4,"place":"city","name":"62Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.72289,7.05234]},"properties":{"population":933873,"capital":4,"place":"city","name":"62P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.1092,-9.32417]},"properties":{"population":1013974,"capital":4,"place":"city","name":"62P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.35671,6.36325]},"properties":{"population":818060,"capital":4,"place":"city","name":"62P12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.02613,4.08133]},"properties":{"population":801301,"capital":4,"place":"city","name":"62P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.51614,6.63239]},"properties":{"population":952988,"capital":4,"place":"city","name":"62P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.0563,9.86168]},"properties":{"population":777106,"capital":4,"place":"city","name":"62P25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.5281,15.91166]},"properties":{"population":872550,"capital":4,"place":"city","name":"62P30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.38914,15.62941]},"properties":{"population":740123,"capital":4,"place":"city","name":"62P35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.82527,-9.41479]},"properties":{"population":819146,"capital":4,"place":"city","name":"62P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.40754,3.51318]},"properties":{"population":623832,"capital":4,"place":"city","name":"62Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.59884,1.51569]},"properties":{"population":725417,"capital":4,"place":"city","name":"62Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.16435,-0.09895]},"properties":{"population":109861,"capital":4,"place":"city","name":"62Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.03009,-0.21514]},"properties":{"population":138629,"capital":4,"place":"city","name":"62Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.31506,4.93183]},"properties":{"population":407753,"capital":4,"place":"city","name":"62R01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.06931,5.18029]},"properties":{"population":625575,"capital":4,"place":"city","name":"62R07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.03852,4.94842]},"properties":{"population":599146,"capital":4,"place":"city","name":"62R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.16092,4.86491]},"properties":{"population":371357,"capital":4,"place":"city","name":"62R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.8273,4.97056]},"properties":{"population":486753,"capital":4,"place":"city","name":"62R30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.85585,5.04693]},"properties":{"population":454329,"capital":4,"place":"city","name":"62R40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.11264,4.89475]},"properties":{"population":69314,"capital":4,"place":"city","name":"62R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.14363,5.00222]},"properties":{"population":219722,"capital":4,"place":"city","name":"62Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.75977,25.68066]},"properties":{"population":496981,"capital":4,"place":"city","name":"65-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.36763,25.81384]},"properties":{"population":771957,"capital":4,"place":"city","name":"65-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.84856,25.85302]},"properties":{"population":784109,"capital":4,"place":"city","name":"65-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.18063,25.31845]},"properties":{"population":622653,"capital":4,"place":"city","name":"65-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.48629,25.27629]},"properties":{"population":681344,"capital":4,"place":"city","name":"65-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.71527,25.4015]},"properties":{"population":363758,"capital":4,"place":"city","name":"65-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.422,25.47256]},"properties":{"population":822496,"capital":4,"place":"city","name":"65-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.79693,25.50183]},"properties":{"population":832482,"capital":4,"place":"city","name":"65-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.45589,20.24795]},"properties":{"population":626149,"capital":4,"place":"city","name":"65A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.44831,19.69558]},"properties":{"population":248490,"capital":4,"place":"city","name":"65A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.47597,19.68965]},"properties":{"population":230258,"capital":4,"place":"city","name":"65Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.50842,27.49396]},"properties":{"population":687005,"capital":4,"place":"city","name":"65B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.275,27.46649]},"properties":{"population":655108,"capital":4,"place":"city","name":"65B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.9744,27.38375]},"properties":{"population":575890,"capital":4,"place":"city","name":"65B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.1862,27.50584]},"properties":{"population":565248,"capital":4,"place":"city","name":"65B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.79011,27.2613]},"properties":{"population":380666,"capital":4,"place":"city","name":"65Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.28057,-12.7619]},"properties":{"population":950278,"capital":4,"place":"city","name":"65C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.99655,22.16393]},"properties":{"population":785670,"capital":4,"place":"city","name":"65C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.72643,-8.49263]},"properties":{"population":840737,"capital":4,"place":"city","name":"65C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.01655,26.96098]},"properties":{"population":819671,"capital":4,"place":"city","name":"65C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.94685,19.99903]},"properties":{"population":599645,"capital":4,"place":"city","name":"65C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.83104,24.25897]},"properties":{"population":784619,"capital":4,"place":"city","name":"65C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.09948,22.24529]},"properties":{"population":687005,"capital":4,"place":"city","name":"65C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.58527,24.50866]},"properties":{"population":950873,"capital":4,"place":"city","name":"65C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.41927,-11.00448]},"properties":{"population":853149,"capital":4,"place":"city","name":"65C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.33817,19.84731]},"properties":{"population":522574,"capital":4,"place":"city","name":"65Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.94523,-13.06034]},"properties":{"population":865277,"capital":4,"place":"city","name":"65D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.86199,-13.3098]},"properties":{"population":870649,"capital":4,"place":"city","name":"65D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.61276,-13.60278]},"properties":{"population":805927,"capital":4,"place":"city","name":"65D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.58015,18.80272]},"properties":{"population":434380,"capital":4,"place":"city","name":"65D12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.23721,24.13077]},"properties":{"population":792334,"capital":4,"place":"city","name":"65D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.20486,24.70175]},"properties":{"population":856445,"capital":4,"place":"city","name":"65D17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.62897,25.32395]},"properties":{"population":851177,"capital":4,"place":"city","name":"65D18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.41241,18.54477]},"properties":{"population":434380,"capital":4,"place":"city","name":"65D19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.00367,-13.34804]},"properties":{"population":786557,"capital":4,"place":"city","name":"65D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.60691,24.86426]},"properties":{"population":735627,"capital":4,"place":"city","name":"65D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.32565,27.52072]},"properties":{"population":824433,"capital":4,"place":"city","name":"65D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.48242,-10.09268]},"properties":{"population":862497,"capital":4,"place":"city","name":"65D32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.00248,17.46838]},"properties":{"population":230258,"capital":4,"place":"city","name":"65D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.35783,20.37093]},"properties":{"population":634212,"capital":4,"place":"city","name":"65D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.90234,19.59146]},"properties":{"population":680682,"capital":4,"place":"city","name":"65Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.43917,27.22654]},"properties":{"population":727170,"capital":4,"place":"city","name":"65E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.97883,25.54646]},"properties":{"population":219722,"capital":4,"place":"city","name":"65E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.95298,25.53092]},"properties":{"population":207944,"capital":4,"place":"city","name":"65E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.925,25.56199]},"properties":{"population":109861,"capital":4,"place":"city","name":"65Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.65888,-13.36213]},"properties":{"population":869081,"capital":4,"place":"city","name":"65F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.1579,27.23727]},"properties":{"population":780832,"capital":4,"place":"city","name":"65F08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.30342,-11.55815]},"properties":{"population":959390,"capital":4,"place":"city","name":"65F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.1354,-13.52599]},"properties":{"population":891489,"capital":4,"place":"city","name":"65F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.9182,18.37732]},"properties":{"population":616120,"capital":4,"place":"city","name":"65F18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.87883,-13.60762]},"properties":{"population":820958,"capital":4,"place":"city","name":"65F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.9015,22.39982]},"properties":{"population":707242,"capital":4,"place":"city","name":"65F22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.47364,-8.32245]},"properties":{"population":665929,"capital":4,"place":"city","name":"65F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.08226,-13.68865]},"properties":{"population":873085,"capital":4,"place":"city","name":"65F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.23856,-11.70892]},"properties":{"population":848549,"capital":4,"place":"city","name":"65F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.13955,18.51132]},"properties":{"population":616961,"capital":4,"place":"city","name":"65F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.20815,18.17753]},"properties":{"population":497673,"capital":4,"place":"city","name":"65F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.37734,-11.25894]},"properties":{"population":829129,"capital":4,"place":"city","name":"65F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.39537,19.16327]},"properties":{"population":484418,"capital":4,"place":"city","name":"65F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.18288,17.98864]},"properties":{"population":592425,"capital":4,"place":"city","name":"65F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.97945,21.14763]},"properties":{"population":662007,"capital":4,"place":"city","name":"65F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.08264,20.82084]},"properties":{"population":691869,"capital":4,"place":"city","name":"65Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.84947,17.99419]},"properties":{"population":615485,"capital":4,"place":"city","name":"65G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.2371,-13.3795]},"properties":{"population":793808,"capital":4,"place":"city","name":"65G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.63882,19.58858]},"properties":{"population":646302,"capital":4,"place":"city","name":"65G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.40864,-13.60878]},"properties":{"population":755695,"capital":4,"place":"city","name":"65G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.35206,19.00129]},"properties":{"population":607534,"capital":4,"place":"city","name":"65G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.71655,18.40085]},"properties":{"population":466343,"capital":4,"place":"city","name":"65Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.03497,19.16824]},"properties":{"population":635262,"capital":4,"place":"city","name":"65H04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.38138,-13.41715]},"properties":{"population":837516,"capital":4,"place":"city","name":"65H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.23566,-8.48959]},"properties":{"population":883025,"capital":4,"place":"city","name":"65H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.95867,16.04643]},"properties":{"population":299573,"capital":4,"place":"city","name":"65H14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.66518,19.11625]},"properties":{"population":639859,"capital":4,"place":"city","name":"65H17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.36657,19.73221]},"properties":{"population":665801,"capital":4,"place":"city","name":"65H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.92899,18.46179]},"properties":{"population":482028,"capital":4,"place":"city","name":"65H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.79926,19.23144]},"properties":{"population":600141,"capital":4,"place":"city","name":"65Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.86388,28.00363]},"properties":{"population":743129,"capital":4,"place":"city","name":"65J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.88589,28.1864]},"properties":{"population":546383,"capital":4,"place":"city","name":"65J08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.27499,28.30766]},"properties":{"population":796728,"capital":4,"place":"city","name":"65J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.42901,28.38178]},"properties":{"population":855140,"capital":4,"place":"city","name":"65J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.05745,28.28452]},"properties":{"population":752510,"capital":4,"place":"city","name":"65J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.03799,27.95555]},"properties":{"population":666822,"capital":4,"place":"city","name":"65J22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.18806,28.21827]},"properties":{"population":702553,"capital":4,"place":"city","name":"65J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.85147,28.35537]},"properties":{"population":474493,"capital":4,"place":"city","name":"65Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.46729,-13.49094]},"properties":{"population":992529,"capital":4,"place":"city","name":"65K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.27136,-8.41511]},"properties":{"population":928711,"capital":4,"place":"city","name":"65K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.62304,18.7537]},"properties":{"population":709090,"capital":4,"place":"city","name":"65K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.51178,19.11039]},"properties":{"population":529330,"capital":4,"place":"city","name":"65K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.06254,18.35419]},"properties":{"population":525227,"capital":4,"place":"city","name":"65Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.7916,18.03995]},"properties":{"population":636647,"capital":4,"place":"city","name":"65L03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.79825,20.12046]},"properties":{"population":632435,"capital":4,"place":"city","name":"65L04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.41105,-11.55917]},"properties":{"population":942995,"capital":4,"place":"city","name":"65L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.29929,-11.45794]},"properties":{"population":874113,"capital":4,"place":"city","name":"65L06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.14866,20.82825]},"properties":{"population":665286,"capital":4,"place":"city","name":"65L07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.60858,19.27788]},"properties":{"population":431748,"capital":4,"place":"city","name":"65L08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.64365,19.73367]},"properties":{"population":590536,"capital":4,"place":"city","name":"65L09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.0264,-8.43986]},"properties":{"population":891891,"capital":4,"place":"city","name":"65L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.01587,17.86837]},"properties":{"population":616120,"capital":4,"place":"city","name":"65L11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.73392,-13.73382]},"properties":{"population":784463,"capital":4,"place":"city","name":"65L12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.1248,23.47728]},"properties":{"population":744658,"capital":4,"place":"city","name":"65L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.80051,-13.47979]},"properties":{"population":866336,"capital":4,"place":"city","name":"65L20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.45526,-9.00323]},"properties":{"population":726752,"capital":4,"place":"city","name":"65L50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.49244,-13.35468]},"properties":{"population":812799,"capital":4,"place":"city","name":"65L60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.0008,-8.54208]},"properties":{"population":773105,"capital":4,"place":"city","name":"65L70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.9403,20.62994]},"properties":{"population":685751,"capital":4,"place":"city","name":"65L80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.26661,24.45926]},"properties":{"population":742892,"capital":4,"place":"city","name":"65L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.47605,18.10674]},"properties":{"population":650578,"capital":4,"place":"city","name":"65Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.91086,-9.90627]},"properties":{"population":975481,"capital":4,"place":"city","name":"65M06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.45277,27.3291]},"properties":{"population":798241,"capital":4,"place":"city","name":"65M08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.94418,-9.67675]},"properties":{"population":957310,"capital":4,"place":"city","name":"65M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.47841,-12.04238]},"properties":{"population":884994,"capital":4,"place":"city","name":"65M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.45509,-12.0732]},"properties":{"population":806117,"capital":4,"place":"city","name":"65M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.54809,24.68505]},"properties":{"population":657925,"capital":4,"place":"city","name":"65M22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.77697,27.27767]},"properties":{"population":709423,"capital":4,"place":"city","name":"65M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.46281,22.5764]},"properties":{"population":685224,"capital":4,"place":"city","name":"65M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.39033,24.96806]},"properties":{"population":757967,"capital":4,"place":"city","name":"65M32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.0912,20.47142]},"properties":{"population":597126,"capital":4,"place":"city","name":"65M38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.78321,-9.99184]},"properties":{"population":796658,"capital":4,"place":"city","name":"65M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.20835,27.29814]},"properties":{"population":772267,"capital":4,"place":"city","name":"65M55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.958,-9.81677]},"properties":{"population":944549,"capital":4,"place":"city","name":"65M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.61047,-10.42842]},"properties":{"population":863852,"capital":4,"place":"city","name":"65M70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.31694,23.88953]},"properties":{"population":698933,"capital":4,"place":"city","name":"65M75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.12327,19.09225]},"properties":{"population":543807,"capital":4,"place":"city","name":"65M80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.30279,18.36948]},"properties":{"population":458496,"capital":4,"place":"city","name":"65M85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.31118,27.80185]},"properties":{"population":803333,"capital":4,"place":"city","name":"65M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.20049,19.58386]},"properties":{"population":642324,"capital":4,"place":"city","name":"65Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.56104,-11.8802]},"properties":{"population":884779,"capital":4,"place":"city","name":"65N06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.25194,23.07501]},"properties":{"population":665801,"capital":4,"place":"city","name":"65N08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.60143,-10.61748]},"properties":{"population":917211,"capital":4,"place":"city","name":"65N12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.6764,27.65093]},"properties":{"population":911140,"capital":4,"place":"city","name":"65N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.2942,19.49431]},"properties":{"population":589440,"capital":4,"place":"city","name":"65N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.60145,24.7897]},"properties":{"population":743720,"capital":4,"place":"city","name":"65N21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.46329,-10.82771]},"properties":{"population":829779,"capital":4,"place":"city","name":"65N22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.00991,-12.69053]},"properties":{"population":779193,"capital":4,"place":"city","name":"65N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.71513,-12.33882]},"properties":{"population":1016296,"capital":4,"place":"city","name":"65N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.01812,-9.4436]},"properties":{"population":837862,"capital":4,"place":"city","name":"65N35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.44333,-11.88345]},"properties":{"population":837216,"capital":4,"place":"city","name":"65N38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.23629,20.42173]},"properties":{"population":619644,"capital":4,"place":"city","name":"65N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.79091,18.95176]},"properties":{"population":366356,"capital":4,"place":"city","name":"65N45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.97814,27.04398]},"properties":{"population":848446,"capital":4,"place":"city","name":"65N50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.42298,-10.92919]},"properties":{"population":864170,"capital":4,"place":"city","name":"65N55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.9984,19.06991]},"properties":{"population":579301,"capital":4,"place":"city","name":"65N75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.46309,22.57743]},"properties":{"population":638181,"capital":4,"place":"city","name":"65N80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.06036,18.59982]},"properties":{"population":485981,"capital":4,"place":"city","name":"65N85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.38601,27.51052]},"properties":{"population":788457,"capital":4,"place":"city","name":"65N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.0558,19.19846]},"properties":{"population":636475,"capital":4,"place":"city","name":"65Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.32957,27.62194]},"properties":{"population":69314,"capital":4,"place":"city","name":"65P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.91767,27.95083]},"properties":{"population":735627,"capital":4,"place":"city","name":"65P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.43034,27.68361]},"properties":{"population":629341,"capital":4,"place":"city","name":"65P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.70911,27.97393]},"properties":{"population":630809,"capital":4,"place":"city","name":"65P30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.74084,27.70046]},"properties":{"population":580513,"capital":4,"place":"city","name":"65P40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.83895,27.8791]},"properties":{"population":564544,"capital":4,"place":"city","name":"65P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.34653,27.50996]},"properties":{"population":393182,"capital":4,"place":"city","name":"65Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.31925,24.08582]},"properties":{"population":693537,"capital":4,"place":"city","name":"65Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.54532,23.2669]},"properties":{"population":561677,"capital":4,"place":"city","name":"65Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.86151,23.05009]},"properties":{"population":430406,"capital":4,"place":"city","name":"65Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.59675,23.39484]},"properties":{"population":528320,"capital":4,"place":"city","name":"65Q30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.76286,22.92137]},"properties":{"population":239789,"capital":4,"place":"city","name":"65Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.67542,23.04041]},"properties":{"population":270805,"capital":4,"place":"city","name":"65Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.26287,-13.22832]},"properties":{"population":771289,"capital":4,"place":"city","name":"65R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.37716,16.87523]},"properties":{"population":219722,"capital":4,"place":"city","name":"65R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.56818,-8.88112]},"properties":{"population":951399,"capital":4,"place":"city","name":"65R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.78901,-8.64057]},"properties":{"population":690274,"capital":4,"place":"city","name":"65R30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.81388,17.96994]},"properties":{"population":655819,"capital":4,"place":"city","name":"65R32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.76609,18.68944]},"properties":{"population":457471,"capital":4,"place":"city","name":"65R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.08331,17.21315]},"properties":{"population":417438,"capital":4,"place":"city","name":"65Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58279,18.4761]},"properties":{"population":625958,"capital":4,"place":"city","name":"65S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.2357,19.67935]},"properties":{"population":69314,"capital":4,"place":"city","name":"65Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.82243,19.07813]},"properties":{"population":755171,"capital":4,"place":"city","name":"65T40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.32084,-8.14309]},"properties":{"population":805038,"capital":4,"place":"city","name":"65T50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.52491,25.099]},"properties":{"population":852654,"capital":4,"place":"city","name":"65T60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.97914,18.59032]},"properties":{"population":558349,"capital":4,"place":"city","name":"65T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.06001,17.06271]},"properties":{"population":439444,"capital":4,"place":"city","name":"65Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.36124,13.94478]},"properties":{"population":109861,"capital":4,"place":"city","name":"65U05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.85505,18.66908]},"properties":{"population":616961,"capital":4,"place":"city","name":"65Y04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.7303,-13.18227]},"properties":{"population":920271,"capital":4,"place":"city","name":"65Y05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.35248,-8.38079]},"properties":{"population":712608,"capital":4,"place":"city","name":"65Y10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.02822,21.36537]},"properties":{"population":737211,"capital":4,"place":"city","name":"65Y15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.99057,-13.45425]},"properties":{"population":831678,"capital":4,"place":"city","name":"65Y20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.31384,19.96207]},"properties":{"population":628599,"capital":4,"place":"city","name":"65Y99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.0906,19.59502]},"properties":{"population":535658,"capital":4,"place":"city","name":"65Yxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.77825,-8.688]},"properties":{"population":898544,"capital":4,"place":"city","name":"65Z05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.8095,17.40485]},"properties":{"population":460517,"capital":4,"place":"city","name":"65Z99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.45148,16.52687]},"properties":{"population":321887,"capital":4,"place":"city","name":"65Zxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.79068,-5.33711]},"properties":{"population":631716,"capital":4,"place":"city","name":"68-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.3515,-2.49657]},"properties":{"population":900981,"capital":4,"place":"city","name":"68-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.16668,-5.38196]},"properties":{"population":867436,"capital":4,"place":"city","name":"68-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.05224,1.51426]},"properties":{"population":699301,"capital":4,"place":"city","name":"68-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.65622,1.50988]},"properties":{"population":589715,"capital":4,"place":"city","name":"68-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.28024,-3.97356]},"properties":{"population":954438,"capital":4,"place":"city","name":"68-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.80667,-0.47384]},"properties":{"population":820028,"capital":4,"place":"city","name":"68-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29624,1.80768]},"properties":{"population":614846,"capital":4,"place":"city","name":"68M01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.32246,-5.112]},"properties":{"population":786557,"capital":4,"place":"city","name":"68M07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.72881,-5.41731]},"properties":{"population":961933,"capital":4,"place":"city","name":"68M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.95507,6.36432]},"properties":{"population":768570,"capital":4,"place":"city","name":"68M11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.1967,4.94116]},"properties":{"population":828702,"capital":4,"place":"city","name":"68M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.52443,8.48061]},"properties":{"population":885666,"capital":4,"place":"city","name":"68M14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.74286,6.56521]},"properties":{"population":818674,"capital":4,"place":"city","name":"68M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.1179,-1.54283]},"properties":{"population":447733,"capital":4,"place":"city","name":"68M18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[22.8406,-5.61864]},"properties":{"population":937847,"capital":4,"place":"city","name":"68M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.1022,-1.12997]},"properties":{"population":562040,"capital":4,"place":"city","name":"68M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.06385,-2.6474]},"properties":{"population":789133,"capital":4,"place":"city","name":"68M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.4724,-0.38648]},"properties":{"population":544673,"capital":4,"place":"city","name":"68Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.8218,5.68034]},"properties":{"population":907909,"capital":4,"place":"city","name":"68N01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.74398,-5.43165]},"properties":{"population":870930,"capital":4,"place":"city","name":"68N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.51982,4.07042]},"properties":{"population":866163,"capital":4,"place":"city","name":"68N17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.05097,1.55658]},"properties":{"population":817723,"capital":4,"place":"city","name":"68N18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.24414,7.96911]},"properties":{"population":812503,"capital":4,"place":"city","name":"68N19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.28907,-5.40672]},"properties":{"population":788945,"capital":4,"place":"city","name":"68N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.27797,-5.56274]},"properties":{"population":849515,"capital":4,"place":"city","name":"68N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.63452,3.48663]},"properties":{"population":871226,"capital":4,"place":"city","name":"68N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.44129,10.13507]},"properties":{"population":881626,"capital":4,"place":"city","name":"68N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.97075,-1.58191]},"properties":{"population":646614,"capital":4,"place":"city","name":"68Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.58742,-0.49601]},"properties":{"population":554126,"capital":4,"place":"city","name":"68P01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.54464,-5.63138]},"properties":{"population":915873,"capital":4,"place":"city","name":"68P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.25531,6.72641]},"properties":{"population":862263,"capital":4,"place":"city","name":"68P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.33689,5.15564]},"properties":{"population":912041,"capital":4,"place":"city","name":"68P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.15069,8.32847]},"properties":{"population":890490,"capital":4,"place":"city","name":"68P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.61947,2.18259]},"properties":{"population":902701,"capital":4,"place":"city","name":"68P25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.27783,-1.56102]},"properties":{"population":527299,"capital":4,"place":"city","name":"68P27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.03914,4.25887]},"properties":{"population":795892,"capital":4,"place":"city","name":"68P30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.04656,1.46346]},"properties":{"population":628599,"capital":4,"place":"city","name":"68P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.36029,-1.7903]},"properties":{"population":443081,"capital":4,"place":"city","name":"68Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.58447,1.00976]},"properties":{"population":524174,"capital":4,"place":"city","name":"68Q01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.57382,-0.11247]},"properties":{"population":439444,"capital":4,"place":"city","name":"68Q04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.69187,-5.35151]},"properties":{"population":897676,"capital":4,"place":"city","name":"68Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.86157,1.41721]},"properties":{"population":511799,"capital":4,"place":"city","name":"68Q06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.94841,1.75019]},"properties":{"population":570711,"capital":4,"place":"city","name":"68Q07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.76849,1.45169]},"properties":{"population":449980,"capital":4,"place":"city","name":"68Q09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.07082,12.69051]},"properties":{"population":874305,"capital":4,"place":"city","name":"68Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.4936,0.05601]},"properties":{"population":446590,"capital":4,"place":"city","name":"68Q11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.97093,-5.51005]},"properties":{"population":722838,"capital":4,"place":"city","name":"68Q12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.25052,6.4029]},"properties":{"population":846526,"capital":4,"place":"city","name":"68Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.57254,12.16343]},"properties":{"population":927603,"capital":4,"place":"city","name":"68Q17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.05343,0.7702]},"properties":{"population":624997,"capital":4,"place":"city","name":"68Q19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.4525,-0.81595]},"properties":{"population":138629,"capital":4,"place":"city","name":"68Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.4773,-0.80082]},"properties":{"population":160943,"capital":4,"place":"city","name":"68Q22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.04929,14.12395]},"properties":{"population":1032914,"capital":4,"place":"city","name":"68Q25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.73771,1.35275]},"properties":{"population":590808,"capital":4,"place":"city","name":"68Q27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.50629,6.10906]},"properties":{"population":741095,"capital":4,"place":"city","name":"68Q30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.53848,5.5028]},"properties":{"population":762070,"capital":4,"place":"city","name":"68Q32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.26921,-0.9406]},"properties":{"population":109861,"capital":4,"place":"city","name":"68Q40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.16183,6.1235]},"properties":{"population":890964,"capital":4,"place":"city","name":"68Q42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.98228,12.56948]},"properties":{"population":1022600,"capital":4,"place":"city","name":"68Q45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.24085,-2.69018]},"properties":{"population":875809,"capital":4,"place":"city","name":"68Q55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.88806,10.60102]},"properties":{"population":959872,"capital":4,"place":"city","name":"68Q60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.44188,3.07443]},"properties":{"population":809955,"capital":4,"place":"city","name":"68Q65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.35594,-0.79984]},"properties":{"population":109861,"capital":4,"place":"city","name":"68Q68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.3723,3.20156]},"properties":{"population":794979,"capital":4,"place":"city","name":"68Q70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.66815,14.46097]},"properties":{"population":835537,"capital":4,"place":"city","name":"68Q80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.07181,11.77646]},"properties":{"population":926511,"capital":4,"place":"city","name":"68Q85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.27862,5.99021]},"properties":{"population":746450,"capital":4,"place":"city","name":"68Q87"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.94208,7.05464]},"properties":{"population":654391,"capital":4,"place":"city","name":"68Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.18749,0.42094]},"properties":{"population":782484,"capital":4,"place":"city","name":"68Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.68316,-0.36678]},"properties":{"population":451085,"capital":4,"place":"city","name":"68R01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.12423,5.67961]},"properties":{"population":775018,"capital":4,"place":"city","name":"68R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.15478,-0.28955]},"properties":{"population":514749,"capital":4,"place":"city","name":"68R07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.98535,-3.14515]},"properties":{"population":1002127,"capital":4,"place":"city","name":"68R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.5666,-1.47669]},"properties":{"population":299573,"capital":4,"place":"city","name":"68R12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.82331,1.192]},"properties":{"population":825816,"capital":4,"place":"city","name":"68R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.08576,3.3986]},"properties":{"population":706731,"capital":4,"place":"city","name":"68R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.29032,-1.02819]},"properties":{"population":654821,"capital":4,"place":"city","name":"68Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.10584,-1.474]},"properties":{"population":449980,"capital":4,"place":"city","name":"68S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.84973,5.10164]},"properties":{"population":728756,"capital":4,"place":"city","name":"68T01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.13893,-3.02727]},"properties":{"population":1048525,"capital":4,"place":"city","name":"68T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.9267,4.70539]},"properties":{"population":634035,"capital":4,"place":"city","name":"68T07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.91413,-0.4149]},"properties":{"population":511198,"capital":4,"place":"city","name":"68T09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.12571,12.86566]},"properties":{"population":969892,"capital":4,"place":"city","name":"68T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.09642,3.24695]},"properties":{"population":909884,"capital":4,"place":"city","name":"68T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.18782,12.89866]},"properties":{"population":938067,"capital":4,"place":"city","name":"68T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.7669,5.63626]},"properties":{"population":889027,"capital":4,"place":"city","name":"68T27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.26977,-5.59251]},"properties":{"population":895944,"capital":4,"place":"city","name":"68T30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.89768,-5.63438]},"properties":{"population":842617,"capital":4,"place":"city","name":"68T35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.32244,6.00286]},"properties":{"population":907829,"capital":4,"place":"city","name":"68T37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.9134,9.52448]},"properties":{"population":828450,"capital":4,"place":"city","name":"68T40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.16167,7.23634]},"properties":{"population":812058,"capital":4,"place":"city","name":"68T42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.52163,6.06338]},"properties":{"population":854791,"capital":4,"place":"city","name":"68T45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.51443,2.88673]},"properties":{"population":828702,"capital":4,"place":"city","name":"68T50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.91414,-3.99727]},"properties":{"population":806526,"capital":4,"place":"city","name":"68T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.60893,0.6255]},"properties":{"population":691075,"capital":4,"place":"city","name":"68Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.9908,-0.39625]},"properties":{"population":435670,"capital":4,"place":"city","name":"68U01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.85078,-0.59571]},"properties":{"population":361091,"capital":4,"place":"city","name":"68U03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.1341,11.18029]},"properties":{"population":960177,"capital":4,"place":"city","name":"68U05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.73246,5.87017]},"properties":{"population":764060,"capital":4,"place":"city","name":"68U07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.00053,-5.45745]},"properties":{"population":957254,"capital":4,"place":"city","name":"68U10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.53605,-0.0167]},"properties":{"population":670196,"capital":4,"place":"city","name":"68U15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.25302,23.62177]},"properties":{"population":863177,"capital":4,"place":"city","name":"68U20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.92398,5.79535]},"properties":{"population":857414,"capital":4,"place":"city","name":"68U35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.0494,-3.70355]},"properties":{"population":1008822,"capital":4,"place":"city","name":"68U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.93096,-0.82861]},"properties":{"population":474493,"capital":4,"place":"city","name":"68Uxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.56706,-1.35008]},"properties":{"population":263905,"capital":4,"place":"city","name":"68V05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.97762,-0.91035]},"properties":{"population":624610,"capital":4,"place":"city","name":"68V15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.03908,-1.03952]},"properties":{"population":494875,"capital":4,"place":"city","name":"68V20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.53095,-1.4229]},"properties":{"population":239789,"capital":4,"place":"city","name":"68V25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.63146,-1.513]},"properties":{"population":361091,"capital":4,"place":"city","name":"68V30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.77468,-1.54367]},"properties":{"population":376120,"capital":4,"place":"city","name":"68V35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.65724,-1.43365]},"properties":{"population":109861,"capital":4,"place":"city","name":"68V99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.77921,-1.28806]},"properties":{"population":160943,"capital":4,"place":"city","name":"68Vxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.26751,3.09196]},"properties":{"population":594017,"capital":4,"place":"city","name":"68W01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.95907,6.63913]},"properties":{"population":869064,"capital":4,"place":"city","name":"68W05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[24.31164,-5.39619]},"properties":{"population":848280,"capital":4,"place":"city","name":"68W10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.55934,-5.50715]},"properties":{"population":847595,"capital":4,"place":"city","name":"68W15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.99962,6.24996]},"properties":{"population":823244,"capital":4,"place":"city","name":"68W20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.55304,9.23117]},"properties":{"population":900687,"capital":4,"place":"city","name":"68W25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.74676,2.5001]},"properties":{"population":757198,"capital":4,"place":"city","name":"68W27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.18001,21.87038]},"properties":{"population":934836,"capital":4,"place":"city","name":"68W30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.98207,1.19026]},"properties":{"population":742892,"capital":4,"place":"city","name":"68W32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.21885,1.50587]},"properties":{"population":675343,"capital":4,"place":"city","name":"68W35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.81466,7.89237]},"properties":{"population":852674,"capital":4,"place":"city","name":"68W40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.87827,0.96075]},"properties":{"population":530826,"capital":4,"place":"city","name":"68W50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.19479,8.71841]},"properties":{"population":843533,"capital":4,"place":"city","name":"68W99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.36322,0.72767]},"properties":{"population":732052,"capital":4,"place":"city","name":"68Wxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.18364,20.59272]},"properties":{"population":515905,"capital":4,"place":"city","name":"70-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.22519,19.50657]},"properties":{"population":713568,"capital":4,"place":"city","name":"70-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.64135,20.49868]},"properties":{"population":697728,"capital":4,"place":"city","name":"70-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.27019,19.41634]},"properties":{"population":716317,"capital":4,"place":"city","name":"70-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.17748,20.26884]},"properties":{"population":586363,"capital":4,"place":"city","name":"70-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.90025,20.49347]},"properties":{"population":576832,"capital":4,"place":"city","name":"70-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.94217,20.4254]},"properties":{"population":661204,"capital":4,"place":"city","name":"70-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.64537,19.33168]},"properties":{"population":803203,"capital":4,"place":"city","name":"70-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.49197,20.49253]},"properties":{"population":355534,"capital":4,"place":"city","name":"70-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.20098,20.55569]},"properties":{"population":661740,"capital":4,"place":"city","name":"70-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.83214,9.67845]},"properties":{"population":666313,"capital":4,"place":"city","name":"70A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.04522,7.23444]},"properties":{"population":194591,"capital":4,"place":"city","name":"70A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.17779,7.18533]},"properties":{"population":160943,"capital":4,"place":"city","name":"70Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.40338,20.40938]},"properties":{"population":569709,"capital":4,"place":"city","name":"70B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.66967,20.41629]},"properties":{"population":656948,"capital":4,"place":"city","name":"70B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.85391,19.23884]},"properties":{"population":856978,"capital":4,"place":"city","name":"70B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.78742,20.35876]},"properties":{"population":543807,"capital":4,"place":"city","name":"70B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.93141,20.26612]},"properties":{"population":429045,"capital":4,"place":"city","name":"70Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.42024,9.08265]},"properties":{"population":639359,"capital":4,"place":"city","name":"70C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.82525,9.10725]},"properties":{"population":160943,"capital":4,"place":"city","name":"70C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.20926,7.35558]},"properties":{"population":207944,"capital":4,"place":"city","name":"70Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.4723,-2.06856]},"properties":{"population":138629,"capital":4,"place":"city","name":"70D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.17096,-2.17522]},"properties":{"population":69314,"capital":4,"place":"city","name":"70D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.18948,-1.94076]},"properties":{"population":69314,"capital":4,"place":"city","name":"70D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.72134,22.09189]},"properties":{"population":739572,"capital":4,"place":"city","name":"70E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.16028,21.46233]},"properties":{"population":69314,"capital":4,"place":"city","name":"70E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.47518,21.6697]},"properties":{"population":779729,"capital":4,"place":"city","name":"70E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.89058,21.78788]},"properties":{"population":651025,"capital":4,"place":"city","name":"70E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.95006,20.75494]},"properties":{"population":628599,"capital":4,"place":"city","name":"70E18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.8883,21.67932]},"properties":{"population":541164,"capital":4,"place":"city","name":"70E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.15907,21.47264]},"properties":{"population":606378,"capital":4,"place":"city","name":"70E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.0875,21.42406]},"properties":{"population":406044,"capital":4,"place":"city","name":"70E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.72072,21.62604]},"properties":{"population":653233,"capital":4,"place":"city","name":"70E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.56579,21.14539]},"properties":{"population":756008,"capital":4,"place":"city","name":"70E55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.60761,21.4103]},"properties":{"population":768982,"capital":4,"place":"city","name":"70E60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.42633,21.58268]},"properties":{"population":696979,"capital":4,"place":"city","name":"70E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.16069,21.45338]},"properties":{"population":502388,"capital":4,"place":"city","name":"70Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.13494,10.68063]},"properties":{"population":721597,"capital":4,"place":"city","name":"70F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.78245,9.56798]},"properties":{"population":783518,"capital":4,"place":"city","name":"70F07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.23972,-2.57451]},"properties":{"population":788193,"capital":4,"place":"city","name":"70F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.91136,6.15517]},"properties":{"population":855756,"capital":4,"place":"city","name":"70F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.43295,-1.52176]},"properties":{"population":525749,"capital":4,"place":"city","name":"70F16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.14847,-2.56092]},"properties":{"population":481218,"capital":4,"place":"city","name":"70F17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.47615,6.07389]},"properties":{"population":669950,"capital":4,"place":"city","name":"70F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.73187,7.55565]},"properties":{"population":756992,"capital":4,"place":"city","name":"70F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.21037,9.2752]},"properties":{"population":655819,"capital":4,"place":"city","name":"70F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.7934,7.78345]},"properties":{"population":650428,"capital":4,"place":"city","name":"70F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.7176,3.8758]},"properties":{"population":514166,"capital":4,"place":"city","name":"70F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.16637,6.62824]},"properties":{"population":725205,"capital":4,"place":"city","name":"70F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.64383,-0.96526]},"properties":{"population":519295,"capital":4,"place":"city","name":"70Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.81831,20.73142]},"properties":{"population":109861,"capital":4,"place":"city","name":"70G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.38518,20.80617]},"properties":{"population":714203,"capital":4,"place":"city","name":"70G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.63616,19.07786]},"properties":{"population":463472,"capital":4,"place":"city","name":"70G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.54127,21.0969]},"properties":{"population":740306,"capital":4,"place":"city","name":"70G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.84244,20.6941]},"properties":{"population":109861,"capital":4,"place":"city","name":"70G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.99936,18.89235]},"properties":{"population":426267,"capital":4,"place":"city","name":"70G55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.34789,20.87654]},"properties":{"population":501063,"capital":4,"place":"city","name":"70G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.30698,21.07079]},"properties":{"population":641181,"capital":4,"place":"city","name":"70G65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.73494,20.81325]},"properties":{"population":256494,"capital":4,"place":"city","name":"70G70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.88538,20.79415]},"properties":{"population":570044,"capital":4,"place":"city","name":"70G75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.72596,21.00169]},"properties":{"population":628413,"capital":4,"place":"city","name":"70G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.74764,20.66018]},"properties":{"population":418965,"capital":4,"place":"city","name":"70Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.90742,-9.24455]},"properties":{"population":770481,"capital":4,"place":"city","name":"70H03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.12805,-9.41878]},"properties":{"population":833086,"capital":4,"place":"city","name":"70H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.37381,8.90207]},"properties":{"population":709506,"capital":4,"place":"city","name":"70H06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.26074,0.89696]},"properties":{"population":524174,"capital":4,"place":"city","name":"70H07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.50302,5.81465]},"properties":{"population":634388,"capital":4,"place":"city","name":"70H08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.80164,2.71297]},"properties":{"population":560211,"capital":4,"place":"city","name":"70H09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.27011,-1.38634]},"properties":{"population":69314,"capital":4,"place":"city","name":"70H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.67119,2.81954]},"properties":{"population":519849,"capital":4,"place":"city","name":"70H11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.44502,1.84319]},"properties":{"population":583773,"capital":4,"place":"city","name":"70H12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.2448,6.5849]},"properties":{"population":589989,"capital":4,"place":"city","name":"70H14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.54285,10.78561]},"properties":{"population":665027,"capital":4,"place":"city","name":"70H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.63827,6.22149]},"properties":{"population":709090,"capital":4,"place":"city","name":"70H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.60509,7.39793]},"properties":{"population":640522,"capital":4,"place":"city","name":"70H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.0699,8.29387]},"properties":{"population":631354,"capital":4,"place":"city","name":"70H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.11414,6.6092]},"properties":{"population":735627,"capital":4,"place":"city","name":"70H33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.99373,-1.2894]},"properties":{"population":138629,"capital":4,"place":"city","name":"70H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.7025,7.06208]},"properties":{"population":714282,"capital":4,"place":"city","name":"70H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.85741,4.95817]},"properties":{"population":647389,"capital":4,"place":"city","name":"70H45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.93725,-0.49224]},"properties":{"population":483628,"capital":4,"place":"city","name":"70H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.15063,8.96664]},"properties":{"population":697541,"capital":4,"place":"city","name":"70H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.90368,2.22633]},"properties":{"population":570044,"capital":4,"place":"city","name":"70Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.39619,9.53111]},"properties":{"population":637842,"capital":4,"place":"city","name":"70J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.96889,7.96335]},"properties":{"population":642971,"capital":4,"place":"city","name":"70J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.81532,5.6037]},"properties":{"population":620859,"capital":4,"place":"city","name":"70J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.08677,5.37625]},"properties":{"population":576832,"capital":4,"place":"city","name":"70J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.8205,3.09157]},"properties":{"population":481218,"capital":4,"place":"city","name":"70J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.23064,-9.82118]},"properties":{"population":532300,"capital":4,"place":"city","name":"70J50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.79417,10.03107]},"properties":{"population":694601,"capital":4,"place":"city","name":"70J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.03645,0.73567]},"properties":{"population":504985,"capital":4,"place":"city","name":"70Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.04031,6.91895]},"properties":{"population":656807,"capital":4,"place":"city","name":"70K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.05879,-2.08788]},"properties":{"population":109861,"capital":4,"place":"city","name":"70K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.32653,-9.55627]},"properties":{"population":794093,"capital":4,"place":"city","name":"70K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.22493,-4.11794]},"properties":{"population":506259,"capital":4,"place":"city","name":"70K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.27485,3.43934]},"properties":{"population":586363,"capital":4,"place":"city","name":"70K28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.68293,7.03115]},"properties":{"population":695081,"capital":4,"place":"city","name":"70K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.57743,6.38569]},"properties":{"population":740792,"capital":4,"place":"city","name":"70K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.71268,5.46453]},"properties":{"population":604025,"capital":4,"place":"city","name":"70K42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.4491,5.06136]},"properties":{"population":559098,"capital":4,"place":"city","name":"70K43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.87794,3.62373]},"properties":{"population":542053,"capital":4,"place":"city","name":"70K44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.20469,-3.60384]},"properties":{"population":503043,"capital":4,"place":"city","name":"70K45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.38239,-9.94916]},"properties":{"population":750384,"capital":4,"place":"city","name":"70K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.84142,7.4349]},"properties":{"population":702375,"capital":4,"place":"city","name":"70K55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.27208,2.90877]},"properties":{"population":544241,"capital":4,"place":"city","name":"70K60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.26752,1.23119]},"properties":{"population":540717,"capital":4,"place":"city","name":"70K65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.0005,3.29409]},"properties":{"population":546383,"capital":4,"place":"city","name":"70K70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.03846,3.38479]},"properties":{"population":478749,"capital":4,"place":"city","name":"70K75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.55549,7.33532]},"properties":{"population":748155,"capital":4,"place":"city","name":"70K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.86631,3.33156]},"properties":{"population":545958,"capital":4,"place":"city","name":"70Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.45876,12.59385]},"properties":{"population":732184,"capital":4,"place":"city","name":"70L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.0265,8.8607]},"properties":{"population":109861,"capital":4,"place":"city","name":"70L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.73219,8.93028]},"properties":{"population":109861,"capital":4,"place":"city","name":"70Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.14866,15.63749]},"properties":{"population":813358,"capital":4,"place":"city","name":"70M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.85822,13.98302]},"properties":{"population":179175,"capital":4,"place":"city","name":"70M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.98311,13.7985]},"properties":{"population":219722,"capital":4,"place":"city","name":"70Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.21199,10.70977]},"properties":{"population":669332,"capital":4,"place":"city","name":"70P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.71857,8.08636]},"properties":{"population":69314,"capital":4,"place":"city","name":"70P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.88154,19.24817]},"properties":{"population":866371,"capital":4,"place":"city","name":"70Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.751,18.67859]},"properties":{"population":160943,"capital":4,"place":"city","name":"70Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.29815,16.85026]},"properties":{"population":697073,"capital":4,"place":"city","name":"70S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.79086,16.24385]},"properties":{"population":587773,"capital":4,"place":"city","name":"70S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.6599,17.40987]},"properties":{"population":716317,"capital":4,"place":"city","name":"70S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.79243,16.60768]},"properties":{"population":470953,"capital":4,"place":"city","name":"70S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.87929,16.71708]},"properties":{"population":409434,"capital":4,"place":"city","name":"70S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.63268,17.43119]},"properties":{"population":585507,"capital":4,"place":"city","name":"70Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.48844,5.55486]},"properties":{"population":69314,"capital":4,"place":"city","name":"73D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.61139,5.58063]},"properties":{"population":69314,"capital":4,"place":"city","name":"73V25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.82526,22.8856]},"properties":{"population":576832,"capital":4,"place":"city","name":"74-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.14746,23.79]},"properties":{"population":713249,"capital":4,"place":"city","name":"74-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.52823,24.20987]},"properties":{"population":787016,"capital":4,"place":"city","name":"74-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.77034,22.68474]},"properties":{"population":591079,"capital":4,"place":"city","name":"74-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.2596,23.24237]},"properties":{"population":723345,"capital":4,"place":"city","name":"74-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.52098,23.5294]},"properties":{"population":789580,"capital":4,"place":"city","name":"74-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.0541,23.23047]},"properties":{"population":772267,"capital":4,"place":"city","name":"74-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.70227,22.86175]},"properties":{"population":583773,"capital":4,"place":"city","name":"74-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.48399,23.00945]},"properties":{"population":849064,"capital":4,"place":"city","name":"74-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.05157,9.78633]},"properties":{"population":653813,"capital":4,"place":"city","name":"74A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.46986,10.73663]},"properties":{"population":730921,"capital":4,"place":"city","name":"74A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.50664,-8.04836]},"properties":{"population":874766,"capital":4,"place":"city","name":"74A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.57509,13.49964]},"properties":{"population":805452,"capital":4,"place":"city","name":"74A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.23857,10.11721]},"properties":{"population":657507,"capital":4,"place":"city","name":"74A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.66241,-8.22588]},"properties":{"population":565248,"capital":4,"place":"city","name":"74A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.01171,9.22491]},"properties":{"population":716703,"capital":4,"place":"city","name":"74A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.92114,13.06282]},"properties":{"population":761972,"capital":4,"place":"city","name":"74A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.1682,9.68158]},"properties":{"population":736897,"capital":4,"place":"city","name":"74A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.81703,10.99353]},"properties":{"population":650727,"capital":4,"place":"city","name":"74A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.3413,11.37996]},"properties":{"population":817046,"capital":4,"place":"city","name":"74A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.89742,12.87047]},"properties":{"population":797762,"capital":4,"place":"city","name":"74A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.70641,5.11424]},"properties":{"population":368887,"capital":4,"place":"city","name":"74A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.7725,5.38966]},"properties":{"population":343398,"capital":4,"place":"city","name":"74A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.57384,14.14701]},"properties":{"population":764252,"capital":4,"place":"city","name":"74A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.55031,7.68391]},"properties":{"population":613556,"capital":4,"place":"city","name":"74Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.68357,-8.42064]},"properties":{"population":879452,"capital":4,"place":"city","name":"74B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.91159,13.2505]},"properties":{"population":760589,"capital":4,"place":"city","name":"74B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.53423,8.59592]},"properties":{"population":563121,"capital":4,"place":"city","name":"74B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.30423,-11.95222]},"properties":{"population":909358,"capital":4,"place":"city","name":"74B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.05763,15.23565]},"properties":{"population":880821,"capital":4,"place":"city","name":"74B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.69128,6.66916]},"properties":{"population":575257,"capital":4,"place":"city","name":"74Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.77407,19.06023]},"properties":{"population":796554,"capital":4,"place":"city","name":"74C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.98142,18.33177]},"properties":{"population":738025,"capital":4,"place":"city","name":"74C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.00564,18.90132]},"properties":{"population":775491,"capital":4,"place":"city","name":"74C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.03667,18.31116]},"properties":{"population":745760,"capital":4,"place":"city","name":"74C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.25494,18.89824]},"properties":{"population":889041,"capital":4,"place":"city","name":"74C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.28553,18.2122]},"properties":{"population":514166,"capital":4,"place":"city","name":"74Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.37377,18.75756]},"properties":{"population":783518,"capital":4,"place":"city","name":"74D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.6004,18.52889]},"properties":{"population":764826,"capital":4,"place":"city","name":"74D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.00727,19.54356]},"properties":{"population":792876,"capital":4,"place":"city","name":"74D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.23624,18.71139]},"properties":{"population":420469,"capital":4,"place":"city","name":"74Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.18611,-12.01525]},"properties":{"population":843923,"capital":4,"place":"city","name":"74E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.63982,13.14467]},"properties":{"population":878109,"capital":4,"place":"city","name":"74E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.69574,12.09465]},"properties":{"population":761579,"capital":4,"place":"city","name":"74E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.07122,9.58583]},"properties":{"population":720042,"capital":4,"place":"city","name":"74E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.96173,7.94293]},"properties":{"population":491998,"capital":4,"place":"city","name":"74E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.25532,15.33214]},"properties":{"population":934766,"capital":4,"place":"city","name":"74E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.06014,6.78003]},"properties":{"population":608221,"capital":4,"place":"city","name":"74E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.92326,7.70757]},"properties":{"population":472738,"capital":4,"place":"city","name":"74E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.887,7.64762]},"properties":{"population":492725,"capital":4,"place":"city","name":"74E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.65893,7.10887]},"properties":{"population":366356,"capital":4,"place":"city","name":"74Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.9427,15.93634]},"properties":{"population":910375,"capital":4,"place":"city","name":"74F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.89297,-7.98924]},"properties":{"population":935781,"capital":4,"place":"city","name":"74F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.01372,-12.40433]},"properties":{"population":890463,"capital":4,"place":"city","name":"74F15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.0367,7.56269]},"properties":{"population":517614,"capital":4,"place":"city","name":"74F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.54999,6.31834]},"properties":{"population":557594,"capital":4,"place":"city","name":"74F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.01171,6.85687]},"properties":{"population":414313,"capital":4,"place":"city","name":"74F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.03381,4.30525]},"properties":{"population":321887,"capital":4,"place":"city","name":"74Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.24491,6.29234]},"properties":{"population":690173,"capital":4,"place":"city","name":"74G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.2738,-12.01366]},"properties":{"population":742117,"capital":4,"place":"city","name":"74G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.65786,6.1762]},"properties":{"population":612029,"capital":4,"place":"city","name":"74G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.05451,8.13139]},"properties":{"population":475359,"capital":4,"place":"city","name":"74G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.33298,4.78437]},"properties":{"population":346573,"capital":4,"place":"city","name":"74G22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.36411,6.36268]},"properties":{"population":626149,"capital":4,"place":"city","name":"74G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.6762,8.33656]},"properties":{"population":717625,"capital":4,"place":"city","name":"74G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.28007,6.80658]},"properties":{"population":447733,"capital":4,"place":"city","name":"74G35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.49015,7.33871]},"properties":{"population":485203,"capital":4,"place":"city","name":"74G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.71443,5.79231]},"properties":{"population":368887,"capital":4,"place":"city","name":"74G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.58594,7.84259]},"properties":{"population":634738,"capital":4,"place":"city","name":"74G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.17742,6.84756]},"properties":{"population":454329,"capital":4,"place":"city","name":"74G55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.48776,-11.57002]},"properties":{"population":879875,"capital":4,"place":"city","name":"74G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.03172,-8.12304]},"properties":{"population":728482,"capital":4,"place":"city","name":"74G65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.12757,9.92647]},"properties":{"population":824485,"capital":4,"place":"city","name":"74G70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.0718,8.30776]},"properties":{"population":663594,"capital":4,"place":"city","name":"74G75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.78521,8.28288]},"properties":{"population":662671,"capital":4,"place":"city","name":"74G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.7076,4.24654]},"properties":{"population":321887,"capital":4,"place":"city","name":"74Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.50407,6.35945]},"properties":{"population":597635,"capital":4,"place":"city","name":"74H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.04688,10.47559]},"properties":{"population":714282,"capital":4,"place":"city","name":"74H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.19264,9.92026]},"properties":{"population":755903,"capital":4,"place":"city","name":"74H15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.1971,8.76371]},"properties":{"population":671659,"capital":4,"place":"city","name":"74H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.99992,9.1595]},"properties":{"population":722183,"capital":4,"place":"city","name":"74H25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.66372,7.95795]},"properties":{"population":451085,"capital":4,"place":"city","name":"74H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.29226,6.75349]},"properties":{"population":644254,"capital":4,"place":"city","name":"74H35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.91843,7.7822]},"properties":{"population":593489,"capital":4,"place":"city","name":"74H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.3392,16.46369]},"properties":{"population":974014,"capital":4,"place":"city","name":"74H45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.56831,9.4731]},"properties":{"population":728824,"capital":4,"place":"city","name":"74H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.09872,12.42597]},"properties":{"population":804076,"capital":4,"place":"city","name":"74H55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.74201,7.96422]},"properties":{"population":616961,"capital":4,"place":"city","name":"74H60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.67037,8.50368]},"properties":{"population":569373,"capital":4,"place":"city","name":"74H65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.81633,6.71573]},"properties":{"population":294443,"capital":4,"place":"city","name":"74H75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.55452,5.34362]},"properties":{"population":248490,"capital":4,"place":"city","name":"74H80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.00096,-8.01709]},"properties":{"population":837193,"capital":4,"place":"city","name":"74H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.29387,8.11744]},"properties":{"population":692951,"capital":4,"place":"city","name":"74Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.93556,-7.5296]},"properties":{"population":673459,"capital":4,"place":"city","name":"74J05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.93193,-7.33647]},"properties":{"population":815880,"capital":4,"place":"city","name":"74J10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.54066,-10.97972]},"properties":{"population":759588,"capital":4,"place":"city","name":"74J15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.94441,-7.51367]},"properties":{"population":811192,"capital":4,"place":"city","name":"74J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.15305,9.2695]},"properties":{"population":694889,"capital":4,"place":"city","name":"74J25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.9614,9.48755]},"properties":{"population":660800,"capital":4,"place":"city","name":"74J30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.3219,7.84373]},"properties":{"population":578689,"capital":4,"place":"city","name":"74J35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.73461,6.2562]},"properties":{"population":613556,"capital":4,"place":"city","name":"74J40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.61442,11.50297]},"properties":{"population":793164,"capital":4,"place":"city","name":"74J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.43819,7.41511]},"properties":{"population":487519,"capital":4,"place":"city","name":"74Jxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.74675,8.73242]},"properties":{"population":727931,"capital":4,"place":"city","name":"74K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.04227,-12.26708]},"properties":{"population":969535,"capital":4,"place":"city","name":"74K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.59185,-11.66829]},"properties":{"population":898205,"capital":4,"place":"city","name":"74K15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.81575,-12.43552]},"properties":{"population":976967,"capital":4,"place":"city","name":"74K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.34643,11.9325]},"properties":{"population":889918,"capital":4,"place":"city","name":"74K25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.50416,7.24662]},"properties":{"population":584932,"capital":4,"place":"city","name":"74K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.39798,9.03562]},"properties":{"population":669456,"capital":4,"place":"city","name":"74K35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.38133,-8.52349]},"properties":{"population":798718,"capital":4,"place":"city","name":"74K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.78433,7.90931]},"properties":{"population":586929,"capital":4,"place":"city","name":"74Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.49035,19.35031]},"properties":{"population":738336,"capital":4,"place":"city","name":"74L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.96102,18.16118]},"properties":{"population":827026,"capital":4,"place":"city","name":"74L10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.45446,19.27917]},"properties":{"population":775790,"capital":4,"place":"city","name":"74L15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.94271,18.9979]},"properties":{"population":325809,"capital":4,"place":"city","name":"74L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.84577,19.00765]},"properties":{"population":160943,"capital":4,"place":"city","name":"74Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.56627,-10.73548]},"properties":{"population":788269,"capital":4,"place":"city","name":"74M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.0083,9.15816]},"properties":{"population":765539,"capital":4,"place":"city","name":"74M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.08837,10.85381]},"properties":{"population":901857,"capital":4,"place":"city","name":"74M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.32621,-11.72125]},"properties":{"population":777359,"capital":4,"place":"city","name":"74M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.74242,10.55544]},"properties":{"population":824931,"capital":4,"place":"city","name":"74M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.26662,4.93854]},"properties":{"population":387120,"capital":4,"place":"city","name":"74M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.09807,4.39822]},"properties":{"population":294443,"capital":4,"place":"city","name":"74Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.48821,6.54303]},"properties":{"population":673933,"capital":4,"place":"city","name":"74N05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.31064,4.79913]},"properties":{"population":427666,"capital":4,"place":"city","name":"74N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.13767,-9.65326]},"properties":{"population":601371,"capital":4,"place":"city","name":"74N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.71024,7.50021]},"properties":{"population":615060,"capital":4,"place":"city","name":"74N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.82225,6.88802]},"properties":{"population":525227,"capital":4,"place":"city","name":"74N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.78751,7.05289]},"properties":{"population":562762,"capital":4,"place":"city","name":"74N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.76168,7.91725]},"properties":{"population":557594,"capital":4,"place":"city","name":"74N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.83844,2.88651]},"properties":{"population":313549,"capital":4,"place":"city","name":"74Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.89473,7.51039]},"properties":{"population":636475,"capital":4,"place":"city","name":"74P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.75057,9.59125]},"properties":{"population":812029,"capital":4,"place":"city","name":"74P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.11373,-5.36149]},"properties":{"population":716162,"capital":4,"place":"city","name":"74P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.26412,6.95419]},"properties":{"population":464439,"capital":4,"place":"city","name":"74P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.38932,7.12107]},"properties":{"population":800536,"capital":4,"place":"city","name":"74P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.51713,5.14217]},"properties":{"population":424849,"capital":4,"place":"city","name":"74Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.75003,15.45623]},"properties":{"population":731521,"capital":4,"place":"city","name":"74Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.96093,15.23038]},"properties":{"population":610924,"capital":4,"place":"city","name":"74Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.5283,16.0757]},"properties":{"population":726473,"capital":4,"place":"city","name":"74Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.91646,15.39424]},"properties":{"population":579909,"capital":4,"place":"city","name":"74Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.93296,15.47329]},"properties":{"population":476217,"capital":4,"place":"city","name":"74Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.15438,14.65978]},"properties":{"population":361091,"capital":4,"place":"city","name":"74Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.36045,6.4652]},"properties":{"population":820056,"capital":4,"place":"city","name":"74R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.39836,-11.84742]},"properties":{"population":860079,"capital":4,"place":"city","name":"74R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.27832,6.95653]},"properties":{"population":545532,"capital":4,"place":"city","name":"74R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.00878,-8.1772]},"properties":{"population":805134,"capital":4,"place":"city","name":"74R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.22003,-11.82504]},"properties":{"population":869282,"capital":4,"place":"city","name":"74R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.37474,4.93869]},"properties":{"population":553338,"capital":4,"place":"city","name":"74Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.91375,-12.2893]},"properties":{"population":1024590,"capital":4,"place":"city","name":"74S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.23519,8.93966]},"properties":{"population":594279,"capital":4,"place":"city","name":"74S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.24548,9.4258]},"properties":{"population":844311,"capital":4,"place":"city","name":"74S15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.15564,10.10531]},"properties":{"population":753583,"capital":4,"place":"city","name":"74S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.24089,8.02676]},"properties":{"population":412713,"capital":4,"place":"city","name":"74S22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.24648,6.49858]},"properties":{"population":637842,"capital":4,"place":"city","name":"74S25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.97035,-8.23323]},"properties":{"population":969116,"capital":4,"place":"city","name":"74S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.41831,7.70516]},"properties":{"population":352636,"capital":4,"place":"city","name":"74S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.41352,7.63961]},"properties":{"population":69314,"capital":4,"place":"city","name":"74S50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.63182,7.76117]},"properties":{"population":644888,"capital":4,"place":"city","name":"74S60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.29196,7.94187]},"properties":{"population":575890,"capital":4,"place":"city","name":"74S70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.36999,9.23368]},"properties":{"population":795226,"capital":4,"place":"city","name":"74S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.96539,7.81806]},"properties":{"population":531320,"capital":4,"place":"city","name":"74Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.16142,11.96371]},"properties":{"population":503043,"capital":4,"place":"city","name":"76-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.64624,-11.18838]},"properties":{"population":672862,"capital":4,"place":"city","name":"76-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.62642,17.99447]},"properties":{"population":827639,"capital":4,"place":"city","name":"76-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.24778,13.18466]},"properties":{"population":620657,"capital":4,"place":"city","name":"76-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.4044,17.11208]},"properties":{"population":700215,"capital":4,"place":"city","name":"76-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.9353,18.14635]},"properties":{"population":848570,"capital":4,"place":"city","name":"76-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.39798,17.39777]},"properties":{"population":800803,"capital":4,"place":"city","name":"76-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.65943,13.55785]},"properties":{"population":589440,"capital":4,"place":"city","name":"76-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.5537,-8.23746]},"properties":{"population":900294,"capital":4,"place":"city","name":"76-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.1207,18.11539]},"properties":{"population":707665,"capital":4,"place":"city","name":"76A02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.99928,-7.95309]},"properties":{"population":881358,"capital":4,"place":"city","name":"76A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.39539,17.69387]},"properties":{"population":853227,"capital":4,"place":"city","name":"76A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.69118,-7.99562]},"properties":{"population":707834,"capital":4,"place":"city","name":"76A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.19117,17.33891]},"properties":{"population":757353,"capital":4,"place":"city","name":"76A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.6776,14.2295]},"properties":{"population":533753,"capital":4,"place":"city","name":"76A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.39756,9.49407]},"properties":{"population":352636,"capital":4,"place":"city","name":"76A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.21399,17.81194]},"properties":{"population":744716,"capital":4,"place":"city","name":"76A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.76469,10.24147]},"properties":{"population":489034,"capital":4,"place":"city","name":"76Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.86566,15.66692]},"properties":{"population":720860,"capital":4,"place":"city","name":"76B03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.9483,9.53807]},"properties":{"population":109861,"capital":4,"place":"city","name":"76B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.52715,14.88641]},"properties":{"population":663331,"capital":4,"place":"city","name":"76B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.9935,17.76462]},"properties":{"population":857621,"capital":4,"place":"city","name":"76B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.00304,-12.88058]},"properties":{"population":930592,"capital":4,"place":"city","name":"76B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.23023,14.77424]},"properties":{"population":649677,"capital":4,"place":"city","name":"76B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.38762,16.55619]},"properties":{"population":767089,"capital":4,"place":"city","name":"76B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.3204,16.69964]},"properties":{"population":714282,"capital":4,"place":"city","name":"76B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.97058,-13.06125]},"properties":{"population":827052,"capital":4,"place":"city","name":"76B47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.61744,15.77696]},"properties":{"population":716394,"capital":4,"place":"city","name":"76B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.76467,15.70328]},"properties":{"population":631535,"capital":4,"place":"city","name":"76B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.92699,14.64703]},"properties":{"population":606842,"capital":4,"place":"city","name":"76B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.60571,13.32311]},"properties":{"population":637502,"capital":4,"place":"city","name":"76B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.93898,15.43838]},"properties":{"population":603308,"capital":4,"place":"city","name":"76B75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.72605,-7.92809]},"properties":{"population":758120,"capital":4,"place":"city","name":"76B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.64236,12.28451]},"properties":{"population":543372,"capital":4,"place":"city","name":"76Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.57997,8.45878]},"properties":{"population":109861,"capital":4,"place":"city","name":"76C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.01748,17.36515]},"properties":{"population":810076,"capital":4,"place":"city","name":"76D03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.15563,-9.21007]},"properties":{"population":997189,"capital":4,"place":"city","name":"76D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.23992,15.03194]},"properties":{"population":558724,"capital":4,"place":"city","name":"76D06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.41733,-12.82079]},"properties":{"population":860557,"capital":4,"place":"city","name":"76D07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.59183,16.64535]},"properties":{"population":737963,"capital":4,"place":"city","name":"76D08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.46495,14.13593]},"properties":{"population":528320,"capital":4,"place":"city","name":"76D09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.12931,-9.13388]},"properties":{"population":874893,"capital":4,"place":"city","name":"76D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.75164,10.38362]},"properties":{"population":69314,"capital":4,"place":"city","name":"76D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.54083,16.18166]},"properties":{"population":736327,"capital":4,"place":"city","name":"76D17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.31392,16.55283]},"properties":{"population":749942,"capital":4,"place":"city","name":"76D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.19288,-12.89827]},"properties":{"population":695272,"capital":4,"place":"city","name":"76D27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.58388,16.33161]},"properties":{"population":720340,"capital":4,"place":"city","name":"76D33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.46291,15.94141]},"properties":{"population":770029,"capital":4,"place":"city","name":"76D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.90044,14.8225]},"properties":{"population":642648,"capital":4,"place":"city","name":"76D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.07327,17.19435]},"properties":{"population":718006,"capital":4,"place":"city","name":"76D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.77147,-9.48682]},"properties":{"population":815737,"capital":4,"place":"city","name":"76D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.55064,13.75291]},"properties":{"population":538449,"capital":4,"place":"city","name":"76Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.49738,-8.12838]},"properties":{"population":798854,"capital":4,"place":"city","name":"76E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.57769,14.70329]},"properties":{"population":747420,"capital":4,"place":"city","name":"76E06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.87218,13.40452]},"properties":{"population":677078,"capital":4,"place":"city","name":"76E07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.04036,11.45291]},"properties":{"population":608677,"capital":4,"place":"city","name":"76E09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.6982,8.82169]},"properties":{"population":69314,"capital":4,"place":"city","name":"76E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.50776,16.25787]},"properties":{"population":766575,"capital":4,"place":"city","name":"76E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.92404,15.39496]},"properties":{"population":759387,"capital":4,"place":"city","name":"76E17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.63146,9.45936]},"properties":{"population":429045,"capital":4,"place":"city","name":"76E19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.17722,15.65067]},"properties":{"population":777779,"capital":4,"place":"city","name":"76E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.73234,16.05914]},"properties":{"population":799598,"capital":4,"place":"city","name":"76E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.81008,17.27828]},"properties":{"population":795331,"capital":4,"place":"city","name":"76E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.0152,-7.9802]},"properties":{"population":803560,"capital":4,"place":"city","name":"76E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.24259,10.46941]},"properties":{"population":508140,"capital":4,"place":"city","name":"76Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.59346,12.51978]},"properties":{"population":617794,"capital":4,"place":"city","name":"76F02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.60643,-11.99423]},"properties":{"population":783952,"capital":4,"place":"city","name":"76F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.3171,13.58209]},"properties":{"population":694022,"capital":4,"place":"city","name":"76F06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.87989,-12.54511]},"properties":{"population":825608,"capital":4,"place":"city","name":"76F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.42907,-8.21559]},"properties":{"population":668210,"capital":4,"place":"city","name":"76F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.69172,14.74512]},"properties":{"population":754115,"capital":4,"place":"city","name":"76F25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.6753,9.1022]},"properties":{"population":470953,"capital":4,"place":"city","name":"76F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.17912,11.95196]},"properties":{"population":637161,"capital":4,"place":"city","name":"76F35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.66509,12.82883]},"properties":{"population":737462,"capital":4,"place":"city","name":"76F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.77171,10.51312]},"properties":{"population":601371,"capital":4,"place":"city","name":"76F45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.78644,12.03581]},"properties":{"population":612029,"capital":4,"place":"city","name":"76F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.34889,14.68057]},"properties":{"population":712608,"capital":4,"place":"city","name":"76F55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.26883,13.45931]},"properties":{"population":701031,"capital":4,"place":"city","name":"76F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.18031,15.42471]},"properties":{"population":840871,"capital":4,"place":"city","name":"76F65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.66727,11.65497]},"properties":{"population":583481,"capital":4,"place":"city","name":"76F70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.79435,9.06855]},"properties":{"population":385014,"capital":4,"place":"city","name":"76F80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.84344,-12.57947]},"properties":{"population":821365,"capital":4,"place":"city","name":"76F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.56663,12.04689]},"properties":{"population":611368,"capital":4,"place":"city","name":"76Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.43467,-13.00646]},"properties":{"population":806652,"capital":4,"place":"city","name":"76G25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.45788,8.17676]},"properties":{"population":283321,"capital":4,"place":"city","name":"76G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.08392,7.33567]},"properties":{"population":160943,"capital":4,"place":"city","name":"76Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.83505,-12.96072]},"properties":{"population":786633,"capital":4,"place":"city","name":"76H05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.66528,7.12942]},"properties":{"population":69314,"capital":4,"place":"city","name":"76H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.88173,7.315]},"properties":{"population":69314,"capital":4,"place":"city","name":"76Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.67323,17.01727]},"properties":{"population":799564,"capital":4,"place":"city","name":"76J20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.53409,16.17656]},"properties":{"population":179175,"capital":4,"place":"city","name":"76J99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.23334,-7.90974]},"properties":{"population":730787,"capital":4,"place":"city","name":"76K05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.19166,6.82027]},"properties":{"population":69314,"capital":4,"place":"city","name":"76Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.69374,18.72949]},"properties":{"population":895079,"capital":4,"place":"city","name":"76L05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.60117,18.27465]},"properties":{"population":239789,"capital":4,"place":"city","name":"76L99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.45408,18.38541]},"properties":{"population":194591,"capital":4,"place":"city","name":"76Lxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.79223,-9.62772]},"properties":{"population":950046,"capital":4,"place":"city","name":"76M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.9612,17.42202]},"properties":{"population":892332,"capital":4,"place":"city","name":"76M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.24954,17.07223]},"properties":{"population":780261,"capital":4,"place":"city","name":"76M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.68211,-9.93756]},"properties":{"population":947746,"capital":4,"place":"city","name":"76M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.83317,12.6361]},"properties":{"population":391202,"capital":4,"place":"city","name":"76M21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.50698,17.10887]},"properties":{"population":769893,"capital":4,"place":"city","name":"76M22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.4458,14.95529]},"properties":{"population":609582,"capital":4,"place":"city","name":"76M23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.11755,-9.13553]},"properties":{"population":939266,"capital":4,"place":"city","name":"76M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.91533,14.14097]},"properties":{"population":545532,"capital":4,"place":"city","name":"76M27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.98583,17.4258]},"properties":{"population":829928,"capital":4,"place":"city","name":"76M28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.02448,-12.83707]},"properties":{"population":738523,"capital":4,"place":"city","name":"76M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.36252,-12.98501]},"properties":{"population":771601,"capital":4,"place":"city","name":"76M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.74053,-12.48604]},"properties":{"population":624222,"capital":4,"place":"city","name":"76M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.64839,16.23376]},"properties":{"population":795050,"capital":4,"place":"city","name":"76M45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.96912,15.08719]},"properties":{"population":633327,"capital":4,"place":"city","name":"76M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.85337,14.32265]},"properties":{"population":666313,"capital":4,"place":"city","name":"76M55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.50297,15.001]},"properties":{"population":670686,"capital":4,"place":"city","name":"76M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.73759,18.11115]},"properties":{"population":935357,"capital":4,"place":"city","name":"76M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.20918,14.35994]},"properties":{"population":632256,"capital":4,"place":"city","name":"76Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.78892,11.47433]},"properties":{"population":546383,"capital":4,"place":"city","name":"76N06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.44172,-8.88185]},"properties":{"population":871391,"capital":4,"place":"city","name":"76N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.51891,16.30997]},"properties":{"population":905613,"capital":4,"place":"city","name":"76N15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.65038,10.05946]},"properties":{"population":509986,"capital":4,"place":"city","name":"76N17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.01182,14.37932]},"properties":{"population":750933,"capital":4,"place":"city","name":"76N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.15824,14.07803]},"properties":{"population":677992,"capital":4,"place":"city","name":"76N25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.7421,9.51404]},"properties":{"population":313549,"capital":4,"place":"city","name":"76N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.90521,15.94475]},"properties":{"population":708338,"capital":4,"place":"city","name":"76N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.75356,10.71374]},"properties":{"population":592425,"capital":4,"place":"city","name":"76Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.723,-7.98809]},"properties":{"population":872696,"capital":4,"place":"city","name":"76P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.20929,7.1782]},"properties":{"population":194591,"capital":4,"place":"city","name":"76P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.97622,7.46114]},"properties":{"population":230258,"capital":4,"place":"city","name":"76Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.78901,-9.67566]},"properties":{"population":918983,"capital":4,"place":"city","name":"76Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.76524,8.59335]},"properties":{"population":256494,"capital":4,"place":"city","name":"76Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.43755,8.49891]},"properties":{"population":179175,"capital":4,"place":"city","name":"76Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.94988,14.07024]},"properties":{"population":801829,"capital":4,"place":"city","name":"76R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.46816,-7.8319]},"properties":{"population":880567,"capital":4,"place":"city","name":"76R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.94736,16.28382]},"properties":{"population":811072,"capital":4,"place":"city","name":"76R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.59743,-10.45975]},"properties":{"population":817498,"capital":4,"place":"city","name":"76R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.6524,9.41736]},"properties":{"population":578689,"capital":4,"place":"city","name":"76Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.96874,-9.38162]},"properties":{"population":987734,"capital":4,"place":"city","name":"76S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.60376,9.54436]},"properties":{"population":343398,"capital":4,"place":"city","name":"76S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.89242,9.275]},"properties":{"population":352636,"capital":4,"place":"city","name":"76Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.20714,8.84758]},"properties":{"population":109861,"capital":4,"place":"city","name":"76T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.60132,10.2569]},"properties":{"population":513579,"capital":4,"place":"city","name":"76T06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.84219,14.32409]},"properties":{"population":844655,"capital":4,"place":"city","name":"76T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.47646,12.5141]},"properties":{"population":677422,"capital":4,"place":"city","name":"76T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.12026,8.61764]},"properties":{"population":248490,"capital":4,"place":"city","name":"76T17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.96937,13.0205]},"properties":{"population":788193,"capital":4,"place":"city","name":"76T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.55975,12.29036]},"properties":{"population":701031,"capital":4,"place":"city","name":"76T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.09494,13.40628]},"properties":{"population":681783,"capital":4,"place":"city","name":"76T30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.5938,-8.05382]},"properties":{"population":904475,"capital":4,"place":"city","name":"76T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.994,9.44156]},"properties":{"population":744132,"capital":4,"place":"city","name":"76Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.80338,17.29417]},"properties":{"population":895325,"capital":4,"place":"city","name":"76U05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.9961,17.08033]},"properties":{"population":480402,"capital":4,"place":"city","name":"76U60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.85751,17.13135]},"properties":{"population":294443,"capital":4,"place":"city","name":"76U65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.99732,17.1545]},"properties":{"population":179175,"capital":4,"place":"city","name":"76U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.00795,17.02455]},"properties":{"population":109861,"capital":4,"place":"city","name":"76Uxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.80077,-10.0759]},"properties":{"population":871094,"capital":4,"place":"city","name":"76V05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.52479,6.85537]},"properties":{"population":69314,"capital":4,"place":"city","name":"76Vxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.64281,-12.16857]},"properties":{"population":964872,"capital":4,"place":"city","name":"76W05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.61013,8.41941]},"properties":{"population":289037,"capital":4,"place":"city","name":"76W99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.07917,8.51437]},"properties":{"population":346573,"capital":4,"place":"city","name":"76Wxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.39808,-8.17425]},"properties":{"population":889041,"capital":4,"place":"city","name":"76X05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.51884,7.21188]},"properties":{"population":138629,"capital":4,"place":"city","name":"76X99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.9658,6.63093]},"properties":{"population":160943,"capital":4,"place":"city","name":"76Xxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.42332,16.56013]},"properties":{"population":801135,"capital":4,"place":"city","name":"76Y05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.11347,16.68268]},"properties":{"population":109861,"capital":4,"place":"city","name":"76Y99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.32774,16.77939]},"properties":{"population":160943,"capital":4,"place":"city","name":"76Yxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.16246,18.13448]},"properties":{"population":824643,"capital":4,"place":"city","name":"76Z05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.99103,18.87918]},"properties":{"population":675343,"capital":4,"place":"city","name":"76Z10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.51962,17.91955]},"properties":{"population":604025,"capital":4,"place":"city","name":"76Z99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.07506,18.60318]},"properties":{"population":363758,"capital":4,"place":"city","name":"76Zxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.23787,7.93419]},"properties":{"population":317805,"capital":4,"place":"city","name":"78-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.77224,8.23905]},"properties":{"population":607534,"capital":4,"place":"city","name":"78-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.24631,9.50851]},"properties":{"population":622059,"capital":4,"place":"city","name":"78-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.41331,9.34826]},"properties":{"population":595842,"capital":4,"place":"city","name":"78-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.36062,8.19211]},"properties":{"population":541164,"capital":4,"place":"city","name":"78-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.31689,8.23982]},"properties":{"population":531320,"capital":4,"place":"city","name":"78-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.24528,7.8552]},"properties":{"population":579605,"capital":4,"place":"city","name":"78-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.7743,7.78453]},"properties":{"population":160943,"capital":4,"place":"city","name":"78-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.83093,8.08884]},"properties":{"population":304452,"capital":4,"place":"city","name":"78-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.89342,7.89745]},"properties":{"population":647234,"capital":4,"place":"city","name":"78-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.03305,6.3822]},"properties":{"population":588053,"capital":4,"place":"city","name":"78A02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.10469,-10.5131]},"properties":{"population":723201,"capital":4,"place":"city","name":"78A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.28854,8.04189]},"properties":{"population":698100,"capital":4,"place":"city","name":"78A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.61425,7.8583]},"properties":{"population":574300,"capital":4,"place":"city","name":"78A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.27026,5.0056]},"properties":{"population":435670,"capital":4,"place":"city","name":"78A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.64538,10.43654]},"properties":{"population":869500,"capital":4,"place":"city","name":"78A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.07058,-8.38771]},"properties":{"population":780872,"capital":4,"place":"city","name":"78A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.52404,-12.64315]},"properties":{"population":784541,"capital":4,"place":"city","name":"78A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.69003,6.49318]},"properties":{"population":547227,"capital":4,"place":"city","name":"78A37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.77125,10.27198]},"properties":{"population":842967,"capital":4,"place":"city","name":"78A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.45794,-8.17757]},"properties":{"population":866750,"capital":4,"place":"city","name":"78A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.92468,7.1108]},"properties":{"population":744541,"capital":4,"place":"city","name":"78A46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.2179,8.05503]},"properties":{"population":700397,"capital":4,"place":"city","name":"78A48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.59965,7.7497]},"properties":{"population":825374,"capital":4,"place":"city","name":"78A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.81261,9.85231]},"properties":{"population":830893,"capital":4,"place":"city","name":"78A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.83102,7.99656]},"properties":{"population":559098,"capital":4,"place":"city","name":"78A57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.53458,-8.31797]},"properties":{"population":835208,"capital":4,"place":"city","name":"78A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.18434,-8.09461]},"properties":{"population":667959,"capital":4,"place":"city","name":"78A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.65282,2.03427]},"properties":{"population":455387,"capital":4,"place":"city","name":"78A97"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.80978,-10.73428]},"properties":{"population":618620,"capital":4,"place":"city","name":"78A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.3526,1.53048]},"properties":{"population":463472,"capital":4,"place":"city","name":"78Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.50143,12.00722]},"properties":{"population":600635,"capital":4,"place":"city","name":"78M05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.45564,12.67189]},"properties":{"population":786978,"capital":4,"place":"city","name":"78M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.8873,11.0424]},"properties":{"population":474493,"capital":4,"place":"city","name":"78M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.49035,12.3626]},"properties":{"population":664639,"capital":4,"place":"city","name":"78M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.62173,12.34191]},"properties":{"population":491998,"capital":4,"place":"city","name":"78M16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.43826,12.53901]},"properties":{"population":724422,"capital":4,"place":"city","name":"78M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.87107,10.90295]},"properties":{"population":506259,"capital":4,"place":"city","name":"78M22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.58784,12.72116]},"properties":{"population":774500,"capital":4,"place":"city","name":"78M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.96861,11.23509]},"properties":{"population":586646,"capital":4,"place":"city","name":"78M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.64661,10.86849]},"properties":{"population":443081,"capital":4,"place":"city","name":"78M31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.00976,10.93849]},"properties":{"population":358351,"capital":4,"place":"city","name":"78M32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.85051,11.11647]},"properties":{"population":459511,"capital":4,"place":"city","name":"78M34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.57386,11.264]},"properties":{"population":586646,"capital":4,"place":"city","name":"78M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.04227,11.0266]},"properties":{"population":515905,"capital":4,"place":"city","name":"78M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.87671,11.64452]},"properties":{"population":661204,"capital":4,"place":"city","name":"78M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.94194,11.12422]},"properties":{"population":543372,"capital":4,"place":"city","name":"78M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.60992,11.94757]},"properties":{"population":430406,"capital":4,"place":"city","name":"78Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.63388,8.59116]},"properties":{"population":418965,"capital":4,"place":"city","name":"80-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.83531,6.22083]},"properties":{"population":564190,"capital":4,"place":"city","name":"80-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.13424,6.27901]},"properties":{"population":542053,"capital":4,"place":"city","name":"80-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.43694,8.08949]},"properties":{"population":489783,"capital":4,"place":"city","name":"80-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.60466,8.05644]},"properties":{"population":459511,"capital":4,"place":"city","name":"80-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.38426,6.60577]},"properties":{"population":604500,"capital":4,"place":"city","name":"80-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.26664,8.28802]},"properties":{"population":528320,"capital":4,"place":"city","name":"80-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.53333,8.34785]},"properties":{"population":352636,"capital":4,"place":"city","name":"80-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.44471,8.42032]},"properties":{"population":609807,"capital":4,"place":"city","name":"80-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.09766,-12.95895]},"properties":{"population":656807,"capital":4,"place":"city","name":"80A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.35429,9.82583]},"properties":{"population":799294,"capital":4,"place":"city","name":"80A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.47555,4.84106]},"properties":{"population":69314,"capital":4,"place":"city","name":"80A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.1339,8.35349]},"properties":{"population":708002,"capital":4,"place":"city","name":"80A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.3814,7.5224]},"properties":{"population":676388,"capital":4,"place":"city","name":"80A19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.17525,-8.24593]},"properties":{"population":1002557,"capital":4,"place":"city","name":"80A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.30393,6.26139]},"properties":{"population":508140,"capital":4,"place":"city","name":"80A21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.55748,-8.08722]},"properties":{"population":846905,"capital":4,"place":"city","name":"80A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.99587,7.6542]},"properties":{"population":683410,"capital":4,"place":"city","name":"80A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.32755,-12.73465]},"properties":{"population":828298,"capital":4,"place":"city","name":"80A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.04743,9.13161]},"properties":{"population":752510,"capital":4,"place":"city","name":"80A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.2385,9.40164]},"properties":{"population":763288,"capital":4,"place":"city","name":"80A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.8848,7.48171]},"properties":{"population":519849,"capital":4,"place":"city","name":"80A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.9335,-12.9626]},"properties":{"population":608904,"capital":4,"place":"city","name":"80A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.45926,5.23397]},"properties":{"population":415888,"capital":4,"place":"city","name":"80Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.95153,4.39024]},"properties":{"population":671174,"capital":4,"place":"city","name":"80M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.93809,1.91747]},"properties":{"population":554517,"capital":4,"place":"city","name":"80M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.36459,2.5277]},"properties":{"population":571373,"capital":4,"place":"city","name":"80M15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.12102,4.3369]},"properties":{"population":667076,"capital":4,"place":"city","name":"80M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.93089,1.69379]},"properties":{"population":397029,"capital":4,"place":"city","name":"80M22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.84246,4.75837]},"properties":{"population":705444,"capital":4,"place":"city","name":"80M25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.41079,2.33939]},"properties":{"population":453259,"capital":4,"place":"city","name":"80M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.06148,1.4617]},"properties":{"population":391202,"capital":4,"place":"city","name":"80M31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.81301,2.15326]},"properties":{"population":497673,"capital":4,"place":"city","name":"80M35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.05686,1.80676]},"properties":{"population":466343,"capital":4,"place":"city","name":"80M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.92498,-5.79647]},"properties":{"population":622257,"capital":4,"place":"city","name":"80M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.78499,0.66565]},"properties":{"population":248490,"capital":4,"place":"city","name":"80M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.84389,1.68194]},"properties":{"population":504342,"capital":4,"place":"city","name":"80M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.8117,0.59653]},"properties":{"population":340119,"capital":4,"place":"city","name":"80Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.9129,15.4643]},"properties":{"population":336729,"capital":4,"place":"city","name":"81-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.81517,17.33878]},"properties":{"population":750714,"capital":4,"place":"city","name":"81-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.10083,17.8894]},"properties":{"population":808579,"capital":4,"place":"city","name":"81-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.81521,16.14596]},"properties":{"population":727447,"capital":4,"place":"city","name":"81-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.45172,16.56974]},"properties":{"population":669084,"capital":4,"place":"city","name":"81-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.73973,15.61989]},"properties":{"population":570378,"capital":4,"place":"city","name":"81-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.71315,16.1915]},"properties":{"population":761775,"capital":4,"place":"city","name":"81-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.2053,17.24921]},"properties":{"population":727862,"capital":4,"place":"city","name":"81-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.84306,15.49171]},"properties":{"population":277258,"capital":4,"place":"city","name":"81-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.68517,15.64879]},"properties":{"population":655535,"capital":4,"place":"city","name":"81-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.79939,7.76286]},"properties":{"population":855410,"capital":4,"place":"city","name":"81P05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.09143,2.9309]},"properties":{"population":777695,"capital":4,"place":"city","name":"81P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.93088,-0.67612]},"properties":{"population":556452,"capital":4,"place":"city","name":"81P13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.65168,6.66212]},"properties":{"population":879709,"capital":4,"place":"city","name":"81P15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.27583,4.6372]},"properties":{"population":684587,"capital":4,"place":"city","name":"81P16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.51103,2.33496]},"properties":{"population":532787,"capital":4,"place":"city","name":"81P17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.52728,-1.71699]},"properties":{"population":468213,"capital":4,"place":"city","name":"81P18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.89142,10.00302]},"properties":{"population":729776,"capital":4,"place":"city","name":"81P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.68195,7.48912]},"properties":{"population":889986,"capital":4,"place":"city","name":"81P40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.995,0.72403]},"properties":{"population":586929,"capital":4,"place":"city","name":"81P42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.9239,-2.71214]},"properties":{"population":313549,"capital":4,"place":"city","name":"81P43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.46051,7.47363]},"properties":{"population":842310,"capital":4,"place":"city","name":"81P45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.82718,-1.28645]},"properties":{"population":490527,"capital":4,"place":"city","name":"81P47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.74717,-1.95364]},"properties":{"population":478749,"capital":4,"place":"city","name":"81P48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.53174,2.6629]},"properties":{"population":638519,"capital":4,"place":"city","name":"81P50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.6241,-1.6437]},"properties":{"population":309104,"capital":4,"place":"city","name":"81P55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.63763,0.67268]},"properties":{"population":514749,"capital":4,"place":"city","name":"81P65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.312,-5.17023]},"properties":{"population":909761,"capital":4,"place":"city","name":"81P68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.4241,2.39166]},"properties":{"population":683733,"capital":4,"place":"city","name":"81P70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.86832,-1.08322]},"properties":{"population":451085,"capital":4,"place":"city","name":"81P73"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.55424,4.74811]},"properties":{"population":767878,"capital":4,"place":"city","name":"81P94"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.4621,11.46645]},"properties":{"population":640852,"capital":4,"place":"city","name":"81P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.01252,-0.25283]},"properties":{"population":458496,"capital":4,"place":"city","name":"81Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.74802,-9.5741]},"properties":{"population":949566,"capital":4,"place":"city","name":"81Q05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.23435,-7.08004]},"properties":{"population":879664,"capital":4,"place":"city","name":"81Q10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.00744,7.10107]},"properties":{"population":637161,"capital":4,"place":"city","name":"81Q12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.43592,-9.58725]},"properties":{"population":745356,"capital":4,"place":"city","name":"81Q15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.55808,-9.63442]},"properties":{"population":812088,"capital":4,"place":"city","name":"81Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.61329,7.30539]},"properties":{"population":696318,"capital":4,"place":"city","name":"81Q30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.30714,7.11281]},"properties":{"population":714755,"capital":4,"place":"city","name":"81Q35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.19258,6.70668]},"properties":{"population":663594,"capital":4,"place":"city","name":"81Q37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.55481,10.35996]},"properties":{"population":596357,"capital":4,"place":"city","name":"81Q40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.74957,5.90718]},"properties":{"population":764921,"capital":4,"place":"city","name":"81Q50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.71807,7.01656]},"properties":{"population":763288,"capital":4,"place":"city","name":"81Q60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.85167,7.97494]},"properties":{"population":612905,"capital":4,"place":"city","name":"81Q65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.09646,-7.03598]},"properties":{"population":722620,"capital":4,"place":"city","name":"81Q70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.03902,9.54901]},"properties":{"population":641345,"capital":4,"place":"city","name":"81Q80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.19984,5.98888]},"properties":{"population":671538,"capital":4,"place":"city","name":"81Q93"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.14197,5.45672]},"properties":{"population":713089,"capital":4,"place":"city","name":"81Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.55336,7.96745]},"properties":{"population":506259,"capital":4,"place":"city","name":"81Qxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.8275,-9.15507]},"properties":{"population":844311,"capital":4,"place":"city","name":"81R05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.11643,-9.96526]},"properties":{"population":841935,"capital":4,"place":"city","name":"81R10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.32433,8.59055]},"properties":{"population":818535,"capital":4,"place":"city","name":"81R12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.44406,9.80289]},"properties":{"population":745298,"capital":4,"place":"city","name":"81R15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.04902,3.49943]},"properties":{"population":693244,"capital":4,"place":"city","name":"81R20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.04321,10.51235]},"properties":{"population":742117,"capital":4,"place":"city","name":"81R25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.43212,-9.99603]},"properties":{"population":809620,"capital":4,"place":"city","name":"81R30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.96297,7.01176]},"properties":{"population":806652,"capital":4,"place":"city","name":"81R40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.19469,-6.83178]},"properties":{"population":872436,"capital":4,"place":"city","name":"81R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.43788,8.56212]},"properties":{"population":733563,"capital":4,"place":"city","name":"81R60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.85736,1.2593]},"properties":{"population":542934,"capital":4,"place":"city","name":"81R99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.05927,1.2844]},"properties":{"population":440671,"capital":4,"place":"city","name":"81Rxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.52897,12.81255]},"properties":{"population":780261,"capital":4,"place":"city","name":"81S05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.16463,11.19912]},"properties":{"population":429045,"capital":4,"place":"city","name":"81S07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.32299,11.50628]},"properties":{"population":346573,"capital":4,"place":"city","name":"81S08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.76133,12.59351]},"properties":{"population":782803,"capital":4,"place":"city","name":"81S10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.25592,11.63038]},"properties":{"population":593224,"capital":4,"place":"city","name":"81S20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.71338,13.22721]},"properties":{"population":761431,"capital":4,"place":"city","name":"81S22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.87501,12.47856]},"properties":{"population":761923,"capital":4,"place":"city","name":"81S25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.5242,12.78222]},"properties":{"population":761775,"capital":4,"place":"city","name":"81S30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.80838,13.10188]},"properties":{"population":791205,"capital":4,"place":"city","name":"81S40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.23645,11.89041]},"properties":{"population":645676,"capital":4,"place":"city","name":"81S99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.31292,11.35307]},"properties":{"population":397029,"capital":4,"place":"city","name":"81Sxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.22043,9.70156]},"properties":{"population":768478,"capital":4,"place":"city","name":"81T05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.49546,10.22265]},"properties":{"population":753208,"capital":4,"place":"city","name":"81T08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.444,9.09202]},"properties":{"population":840849,"capital":4,"place":"city","name":"81T10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.23089,0.5272]},"properties":{"population":393182,"capital":4,"place":"city","name":"81T11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.33023,1.53801]},"properties":{"population":495582,"capital":4,"place":"city","name":"81T12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.9449,11.65448]},"properties":{"population":945548,"capital":4,"place":"city","name":"81T13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.39055,10.91355]},"properties":{"population":804141,"capital":4,"place":"city","name":"81T15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.43216,4.10887]},"properties":{"population":712769,"capital":4,"place":"city","name":"81T16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.36015,9.06266]},"properties":{"population":786633,"capital":4,"place":"city","name":"81T17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.49685,3.97474]},"properties":{"population":751261,"capital":4,"place":"city","name":"81T18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.08541,10.95338]},"properties":{"population":867658,"capital":4,"place":"city","name":"81T20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.67439,9.95549]},"properties":{"population":788306,"capital":4,"place":"city","name":"81T25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.85575,9.43114]},"properties":{"population":625190,"capital":4,"place":"city","name":"81T27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.92815,9.74551]},"properties":{"population":649978,"capital":4,"place":"city","name":"81T28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.79525,10.9973]},"properties":{"population":967464,"capital":4,"place":"city","name":"81T30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.60495,1.1094]},"properties":{"population":452178,"capital":4,"place":"city","name":"81T32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.03386,1.1962]},"properties":{"population":423410,"capital":4,"place":"city","name":"81T33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.79375,2.61291]},"properties":{"population":588887,"capital":4,"place":"city","name":"81T35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.04691,-9.54677]},"properties":{"population":924879,"capital":4,"place":"city","name":"81T40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.16037,-9.88179]},"properties":{"population":748773,"capital":4,"place":"city","name":"81T45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.94373,6.08864]},"properties":{"population":737462,"capital":4,"place":"city","name":"81T50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.57735,6.8975]},"properties":{"population":608677,"capital":4,"place":"city","name":"81T55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.51844,8.63314]},"properties":{"population":930991,"capital":4,"place":"city","name":"81T60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.74629,3.11322]},"properties":{"population":768294,"capital":4,"place":"city","name":"81T70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.17531,6.28186]},"properties":{"population":757250,"capital":4,"place":"city","name":"81T75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.57932,9.81157]},"properties":{"population":713807,"capital":4,"place":"city","name":"81T80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.38919,4.4508]},"properties":{"population":694505,"capital":4,"place":"city","name":"81T99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.9663,3.73706]},"properties":{"population":568017,"capital":4,"place":"city","name":"81Txx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.52083,8.30493]},"properties":{"population":802649,"capital":4,"place":"city","name":"81U05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.01413,-6.92863]},"properties":{"population":679234,"capital":4,"place":"city","name":"81U10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.802,6.29307]},"properties":{"population":689972,"capital":4,"place":"city","name":"81U15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.10192,-6.15494]},"properties":{"population":742535,"capital":4,"place":"city","name":"81U20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.86175,-0.6756]},"properties":{"population":304452,"capital":4,"place":"city","name":"81U24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.79666,-0.5506]},"properties":{"population":346573,"capital":4,"place":"city","name":"81U26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.18472,2.86461]},"properties":{"population":569035,"capital":4,"place":"city","name":"81U30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.89743,6.27538]},"properties":{"population":659714,"capital":4,"place":"city","name":"81U35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.39624,4.32374]},"properties":{"population":697541,"capital":4,"place":"city","name":"81U40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.82895,-0.94588]},"properties":{"population":346573,"capital":4,"place":"city","name":"81U90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.97098,7.41806]},"properties":{"population":709423,"capital":4,"place":"city","name":"81U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.37995,-0.7033]},"properties":{"population":395124,"capital":4,"place":"city","name":"81Uxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.72878,8.78657]},"properties":{"population":869198,"capital":4,"place":"city","name":"81V05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.03499,-9.50065]},"properties":{"population":831678,"capital":4,"place":"city","name":"81V10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.75062,6.04674]},"properties":{"population":721303,"capital":4,"place":"city","name":"81V15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.85717,5.85399]},"properties":{"population":785748,"capital":4,"place":"city","name":"81V17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.64471,3.10139]},"properties":{"population":525749,"capital":4,"place":"city","name":"81V19"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.76412,5.77455]},"properties":{"population":838434,"capital":4,"place":"city","name":"81V22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.09811,6.80308]},"properties":{"population":729369,"capital":4,"place":"city","name":"81V25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.35487,1.01245]},"properties":{"population":376120,"capital":4,"place":"city","name":"81V27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.59691,9.33819]},"properties":{"population":840491,"capital":4,"place":"city","name":"81V35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.17697,7.24213]},"properties":{"population":797177,"capital":4,"place":"city","name":"81V45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.11884,-6.32447]},"properties":{"population":788983,"capital":4,"place":"city","name":"81V55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.01703,0.36282]},"properties":{"population":304452,"capital":4,"place":"city","name":"81V60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.61644,5.96912]},"properties":{"population":566988,"capital":4,"place":"city","name":"81V65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.94262,-5.5048]},"properties":{"population":847177,"capital":4,"place":"city","name":"81V70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.54397,0.91515]},"properties":{"population":346573,"capital":4,"place":"city","name":"81V72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.1284,1.84099]},"properties":{"population":523644,"capital":4,"place":"city","name":"81V73"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.95435,2.63942]},"properties":{"population":512396,"capital":4,"place":"city","name":"81V74"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.87763,8.57812]},"properties":{"population":862873,"capital":4,"place":"city","name":"81V80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.79871,10.08897]},"properties":{"population":645990,"capital":4,"place":"city","name":"81V99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.57545,1.23272]},"properties":{"population":513579,"capital":4,"place":"city","name":"81Vxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[17.92611,9.90835]},"properties":{"population":346573,"capital":4,"place":"city","name":"82-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.39931,11.02719]},"properties":{"population":643294,"capital":4,"place":"city","name":"82-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.56985,12.02583]},"properties":{"population":713727,"capital":4,"place":"city","name":"82-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.38462,10.16872]},"properties":{"population":588332,"capital":4,"place":"city","name":"82-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.03495,10.36319]},"properties":{"population":613556,"capital":4,"place":"city","name":"82-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.12143,9.94755]},"properties":{"population":468213,"capital":4,"place":"city","name":"82-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.22285,10.75539]},"properties":{"population":674523,"capital":4,"place":"city","name":"82-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.28049,11.99683]},"properties":{"population":726822,"capital":4,"place":"city","name":"82-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.18628,9.8432]},"properties":{"population":325809,"capital":4,"place":"city","name":"82-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.18282,10.0695]},"properties":{"population":673221,"capital":4,"place":"city","name":"82-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.04104,7.09772]},"properties":{"population":654965,"capital":4,"place":"city","name":"82B03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.75931,-8.34842]},"properties":{"population":776004,"capital":4,"place":"city","name":"82B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.14349,5.98541]},"properties":{"population":830028,"capital":4,"place":"city","name":"82B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.37449,8.04217]},"properties":{"population":918502,"capital":4,"place":"city","name":"82B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.58279,7.55976]},"properties":{"population":684054,"capital":4,"place":"city","name":"82B21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.26081,9.80183]},"properties":{"population":801201,"capital":4,"place":"city","name":"82B23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.15366,-9.54466]},"properties":{"population":639692,"capital":4,"place":"city","name":"82B24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.42128,-9.58074]},"properties":{"population":842134,"capital":4,"place":"city","name":"82B26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.48973,7.60332]},"properties":{"population":753047,"capital":4,"place":"city","name":"82B27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.87909,7.82299]},"properties":{"population":704228,"capital":4,"place":"city","name":"82B28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.04245,7.93263]},"properties":{"population":781318,"capital":4,"place":"city","name":"82B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.02258,7.43563]},"properties":{"population":658063,"capital":4,"place":"city","name":"82B31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.60128,8.32921]},"properties":{"population":643935,"capital":4,"place":"city","name":"82B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.4362,7.38678]},"properties":{"population":748941,"capital":4,"place":"city","name":"82B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.70315,6.80179]},"properties":{"population":781963,"capital":4,"place":"city","name":"82B41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.58528,7.19896]},"properties":{"population":754644,"capital":4,"place":"city","name":"82B43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.79635,7.03736]},"properties":{"population":799463,"capital":4,"place":"city","name":"82B44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.333,8.39594]},"properties":{"population":729979,"capital":4,"place":"city","name":"82B80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.08574,10.34389]},"properties":{"population":604973,"capital":4,"place":"city","name":"82B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.55912,4.58363]},"properties":{"population":488280,"capital":4,"place":"city","name":"82Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.98923,9.07532]},"properties":{"population":604737,"capital":4,"place":"city","name":"82C03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.28,6.93855]},"properties":{"population":730182,"capital":4,"place":"city","name":"82C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.76027,7.59364]},"properties":{"population":781278,"capital":4,"place":"city","name":"82C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.1769,8.98679]},"properties":{"population":781641,"capital":4,"place":"city","name":"82C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.72088,7.32302]},"properties":{"population":644254,"capital":4,"place":"city","name":"82C21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.52836,8.94774]},"properties":{"population":818979,"capital":4,"place":"city","name":"82C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.85627,6.56667]},"properties":{"population":573009,"capital":4,"place":"city","name":"82C23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.28346,9.78416]},"properties":{"population":650876,"capital":4,"place":"city","name":"82C24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.84437,8.31301]},"properties":{"population":741697,"capital":4,"place":"city","name":"82C26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.86571,7.51153]},"properties":{"population":622851,"capital":4,"place":"city","name":"82C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.26177,7.45047]},"properties":{"population":584064,"capital":4,"place":"city","name":"82C28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.78421,9.03901]},"properties":{"population":818032,"capital":4,"place":"city","name":"82C31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.04463,8.21146]},"properties":{"population":690775,"capital":4,"place":"city","name":"82C32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.72275,7.35952]},"properties":{"population":702908,"capital":4,"place":"city","name":"82C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.63073,-7.9395]},"properties":{"population":812829,"capital":4,"place":"city","name":"82C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.19121,8.75772]},"properties":{"population":701301,"capital":4,"place":"city","name":"82C41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.63949,6.99241]},"properties":{"population":568017,"capital":4,"place":"city","name":"82C43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.6943,-10.69852]},"properties":{"population":671417,"capital":4,"place":"city","name":"82C44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.19647,9.00936]},"properties":{"population":844462,"capital":4,"place":"city","name":"82C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.11111,7.88745]},"properties":{"population":758629,"capital":4,"place":"city","name":"82C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.1535,7.10297]},"properties":{"population":597380,"capital":4,"place":"city","name":"82C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.22421,5.16332]},"properties":{"population":482831,"capital":4,"place":"city","name":"82Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.50038,6.21549]},"properties":{"population":289037,"capital":4,"place":"city","name":"82D03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.58599,7.25956]},"properties":{"population":720042,"capital":4,"place":"city","name":"82D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.78888,8.36246]},"properties":{"population":832166,"capital":4,"place":"city","name":"82D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.21558,7.08479]},"properties":{"population":720489,"capital":4,"place":"city","name":"82D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.88869,-7.7601]},"properties":{"population":753689,"capital":4,"place":"city","name":"82D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.96235,-8.20932]},"properties":{"population":827486,"capital":4,"place":"city","name":"82D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.81372,-7.82768]},"properties":{"population":794555,"capital":4,"place":"city","name":"82D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.03755,6.87798]},"properties":{"population":644888,"capital":4,"place":"city","name":"82D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.74808,7.30912]},"properties":{"population":784306,"capital":4,"place":"city","name":"82D37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.91792,7.01737]},"properties":{"population":783518,"capital":4,"place":"city","name":"82D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.59148,9.31719]},"properties":{"population":645676,"capital":4,"place":"city","name":"82D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.22149,6.97293]},"properties":{"population":690274,"capital":4,"place":"city","name":"82D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.79189,-11.26583]},"properties":{"population":806934,"capital":4,"place":"city","name":"82D55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.3545,7.02486]},"properties":{"population":803722,"capital":4,"place":"city","name":"82D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.11401,-8.25135]},"properties":{"population":683303,"capital":4,"place":"city","name":"82D75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.46726,5.55603]},"properties":{"population":577455,"capital":4,"place":"city","name":"82D77"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.85929,-9.83963]},"properties":{"population":748493,"capital":4,"place":"city","name":"82D80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.35438,6.81828]},"properties":{"population":651767,"capital":4,"place":"city","name":"82D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.23476,4.77354]},"properties":{"population":438202,"capital":4,"place":"city","name":"82Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.70872,1.27023]},"properties":{"population":343398,"capital":4,"place":"city","name":"82M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.87092,1.24351]},"properties":{"population":256494,"capital":4,"place":"city","name":"82M12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.93898,1.12979]},"properties":{"population":329583,"capital":4,"place":"city","name":"82M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.04503,1.36575]},"properties":{"population":270805,"capital":4,"place":"city","name":"82M22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.06495,1.16297]},"properties":{"population":207944,"capital":4,"place":"city","name":"82M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.61671,1.36924]},"properties":{"population":429045,"capital":4,"place":"city","name":"82M31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.67953,1.23672]},"properties":{"population":336729,"capital":4,"place":"city","name":"82M36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.05079,1.22356]},"properties":{"population":294443,"capital":4,"place":"city","name":"82M37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.60195,1.29106]},"properties":{"population":433073,"capital":4,"place":"city","name":"82M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.42946,1.00838]},"properties":{"population":366356,"capital":4,"place":"city","name":"82M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.23532,0.45683]},"properties":{"population":283321,"capital":4,"place":"city","name":"83-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.48638,0.74506]},"properties":{"population":648004,"capital":4,"place":"city","name":"83-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.04064,1.12554]},"properties":{"population":741397,"capital":4,"place":"city","name":"83-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.43717,1.04331]},"properties":{"population":700124,"capital":4,"place":"city","name":"83-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.25917,0.97821]},"properties":{"population":506259,"capital":4,"place":"city","name":"83-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.41716,1.52114]},"properties":{"population":460517,"capital":4,"place":"city","name":"83-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.0579,0.66799]},"properties":{"population":654821,"capital":4,"place":"city","name":"83-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.17408,0.88322]},"properties":{"population":670563,"capital":4,"place":"city","name":"83-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.38766,0.88288]},"properties":{"population":283321,"capital":4,"place":"city","name":"83-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.19449,0.88384]},"properties":{"population":504342,"capital":4,"place":"city","name":"83-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.36681,-5.79966]},"properties":{"population":805610,"capital":4,"place":"city","name":"83A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.16721,4.3508]},"properties":{"population":299573,"capital":4,"place":"city","name":"83A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.18017,4.30154]},"properties":{"population":179175,"capital":4,"place":"city","name":"83Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.66527,3.22102]},"properties":{"population":759940,"capital":4,"place":"city","name":"83B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.5182,3.92684]},"properties":{"population":69314,"capital":4,"place":"city","name":"83Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.33923,0.84804]},"properties":{"population":897195,"capital":4,"place":"city","name":"83C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.38273,0.50427]},"properties":{"population":846484,"capital":4,"place":"city","name":"83C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.5486,0.48976]},"properties":{"population":873311,"capital":4,"place":"city","name":"83C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.0631,0.36121]},"properties":{"population":800503,"capital":4,"place":"city","name":"83C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.08737,0.03891]},"properties":{"population":768478,"capital":4,"place":"city","name":"83C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.09238,0.19904]},"properties":{"population":780506,"capital":4,"place":"city","name":"83C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.05075,-3.42517]},"properties":{"population":660665,"capital":4,"place":"city","name":"83C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.99096,-3.18732]},"properties":{"population":671417,"capital":4,"place":"city","name":"83C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.36671,0.84601]},"properties":{"population":791680,"capital":4,"place":"city","name":"83C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.70659,0.38384]},"properties":{"population":780139,"capital":4,"place":"city","name":"83C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.40664,0.69644]},"properties":{"population":879315,"capital":4,"place":"city","name":"83C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.42619,0.876]},"properties":{"population":830300,"capital":4,"place":"city","name":"83C47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.83236,0.33636]},"properties":{"population":749498,"capital":4,"place":"city","name":"83C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.77533,0.22014]},"properties":{"population":845019,"capital":4,"place":"city","name":"83C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.73429,-2.87429]},"properties":{"population":523644,"capital":4,"place":"city","name":"83C56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.42627,0.47981]},"properties":{"population":943691,"capital":4,"place":"city","name":"83C57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.69534,0.20477]},"properties":{"population":768386,"capital":4,"place":"city","name":"83C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.91653,-3.13753]},"properties":{"population":670808,"capital":4,"place":"city","name":"83C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.53178,0.03983]},"properties":{"population":825686,"capital":4,"place":"city","name":"83C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.95943,0.10777]},"properties":{"population":720637,"capital":4,"place":"city","name":"83C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.55472,-3.66597]},"properties":{"population":718690,"capital":4,"place":"city","name":"83C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.99717,-3.25364]},"properties":{"population":511799,"capital":4,"place":"city","name":"83Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.58647,3.11806]},"properties":{"population":914889,"capital":4,"place":"city","name":"83D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.24087,3.81788]},"properties":{"population":248490,"capital":4,"place":"city","name":"83D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.24809,3.96569]},"properties":{"population":138629,"capital":4,"place":"city","name":"83Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.73368,1.92522]},"properties":{"population":747929,"capital":4,"place":"city","name":"83E05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.79936,1.90789]},"properties":{"population":854694,"capital":4,"place":"city","name":"83E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.7291,2.03071]},"properties":{"population":911921,"capital":4,"place":"city","name":"83E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.96456,1.80486]},"properties":{"population":848838,"capital":4,"place":"city","name":"83E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.78031,2.0466]},"properties":{"population":566988,"capital":4,"place":"city","name":"83E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.13514,2.31877]},"properties":{"population":294443,"capital":4,"place":"city","name":"83Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.80316,1.58559]},"properties":{"population":961700,"capital":4,"place":"city","name":"83F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.81643,1.96634]},"properties":{"population":239789,"capital":4,"place":"city","name":"83F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.89421,2.08969]},"properties":{"population":179175,"capital":4,"place":"city","name":"83Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.73002,1.79766]},"properties":{"population":366356,"capital":4,"place":"city","name":"85-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.45831,2.14114]},"properties":{"population":529831,"capital":4,"place":"city","name":"85-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.8023,2.0797]},"properties":{"population":579605,"capital":4,"place":"city","name":"85-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.23475,1.6205]},"properties":{"population":700760,"capital":4,"place":"city","name":"85-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.67859,1.93115]},"properties":{"population":429045,"capital":4,"place":"city","name":"85-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.77899,1.81972]},"properties":{"population":504342,"capital":4,"place":"city","name":"85-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.39991,1.82302]},"properties":{"population":549716,"capital":4,"place":"city","name":"85-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.54843,2.07384]},"properties":{"population":636130,"capital":4,"place":"city","name":"85-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.48947,2.13571]},"properties":{"population":270805,"capital":4,"place":"city","name":"85-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.6467,2.04119]},"properties":{"population":109861,"capital":4,"place":"city","name":"85-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.44768,2.03473]},"properties":{"population":436944,"capital":4,"place":"city","name":"85-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.11332,-0.07807]},"properties":{"population":612029,"capital":4,"place":"city","name":"85A04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.3747,0.89422]},"properties":{"population":793915,"capital":4,"place":"city","name":"85A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.81259,0.99458]},"properties":{"population":800202,"capital":4,"place":"city","name":"85A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.77139,1.0889]},"properties":{"population":610255,"capital":4,"place":"city","name":"85A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.84097,-0.42819]},"properties":{"population":767647,"capital":4,"place":"city","name":"85A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.99202,-0.21533]},"properties":{"population":786057,"capital":4,"place":"city","name":"85A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.72578,1.15341]},"properties":{"population":653669,"capital":4,"place":"city","name":"85A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.5917,1.15377]},"properties":{"population":802715,"capital":4,"place":"city","name":"85A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.07802,0.30078]},"properties":{"population":109861,"capital":4,"place":"city","name":"85A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.8293,-0.15544]},"properties":{"population":653087,"capital":4,"place":"city","name":"85A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.93025,0.20234]},"properties":{"population":294443,"capital":4,"place":"city","name":"85Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.94109,-3.82911]},"properties":{"population":424849,"capital":4,"place":"city","name":"86-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.38861,-3.8744]},"properties":{"population":505624,"capital":4,"place":"city","name":"86-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.20942,-3.77819]},"properties":{"population":566988,"capital":4,"place":"city","name":"86-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.56636,-3.99443]},"properties":{"population":504342,"capital":4,"place":"city","name":"86-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.25243,-3.71849]},"properties":{"population":455387,"capital":4,"place":"city","name":"86-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.81862,-3.77648]},"properties":{"population":358351,"capital":4,"place":"city","name":"86-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.55691,-3.59542]},"properties":{"population":568357,"capital":4,"place":"city","name":"86-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.24023,0.84439]},"properties":{"population":749720,"capital":4,"place":"city","name":"86-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.87927,-3.69907]},"properties":{"population":449980,"capital":4,"place":"city","name":"86-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.93044,-3.80902]},"properties":{"population":69314,"capital":4,"place":"city","name":"86-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.98852,-3.97222]},"properties":{"population":543807,"capital":4,"place":"city","name":"86-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.15962,0.9902]},"properties":{"population":547227,"capital":4,"place":"city","name":"86A04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.96003,-11.13027]},"properties":{"population":911964,"capital":4,"place":"city","name":"86A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.01961,1.53737]},"properties":{"population":481218,"capital":4,"place":"city","name":"86A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.87458,2.01219]},"properties":{"population":842726,"capital":4,"place":"city","name":"86A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.19113,-0.25926]},"properties":{"population":771690,"capital":4,"place":"city","name":"86A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.26196,0.66189]},"properties":{"population":660934,"capital":4,"place":"city","name":"86A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.98786,0.55991]},"properties":{"population":653524,"capital":4,"place":"city","name":"86A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.77571,0.70425]},"properties":{"population":700215,"capital":4,"place":"city","name":"86A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.3361,1.33995]},"properties":{"population":659030,"capital":4,"place":"city","name":"86A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.6258,0.92807]},"properties":{"population":708254,"capital":4,"place":"city","name":"86A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.70582,-9.13229]},"properties":{"population":760787,"capital":4,"place":"city","name":"86A32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.9674,-9.42355]},"properties":{"population":621260,"capital":4,"place":"city","name":"86A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.22706,-9.45319]},"properties":{"population":673933,"capital":4,"place":"city","name":"86A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.70444,2.90706]},"properties":{"population":230258,"capital":4,"place":"city","name":"86A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.41073,1.50424]},"properties":{"population":672022,"capital":4,"place":"city","name":"86A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.95142,2.59206]},"properties":{"population":352636,"capital":4,"place":"city","name":"86Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.30333,-0.37793]},"properties":{"population":515905,"capital":4,"place":"city","name":"90-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.00841,4.8674]},"properties":{"population":759538,"capital":4,"place":"city","name":"90-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.92626,-9.39151]},"properties":{"population":818757,"capital":4,"place":"city","name":"90-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.37093,-0.26293]},"properties":{"population":618208,"capital":4,"place":"city","name":"90-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.84601,0.97248]},"properties":{"population":648004,"capital":4,"place":"city","name":"90-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.99124,-1.90717]},"properties":{"population":138629,"capital":4,"place":"city","name":"90-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.84739,4.31568]},"properties":{"population":807589,"capital":4,"place":"city","name":"90-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.39961,4.89375]},"properties":{"population":812976,"capital":4,"place":"city","name":"90-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.46346,-4.64363]},"properties":{"population":506890,"capital":4,"place":"city","name":"90-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.92587,-1.99788]},"properties":{"population":109861,"capital":4,"place":"city","name":"90-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.71468,-2.88385]},"properties":{"population":771735,"capital":4,"place":"city","name":"90-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.06622,-0.15096]},"properties":{"population":109861,"capital":4,"place":"city","name":"90A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.11855,-0.18826]},"properties":{"population":109861,"capital":4,"place":"city","name":"90A09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.34221,-0.28549]},"properties":{"population":69314,"capital":4,"place":"city","name":"90A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27494,-9.16648]},"properties":{"population":941221,"capital":4,"place":"city","name":"90B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.96756,5.93908]},"properties":{"population":919664,"capital":4,"place":"city","name":"90B06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.14879,11.43455]},"properties":{"population":918901,"capital":4,"place":"city","name":"90B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.8461,-4.5375]},"properties":{"population":69314,"capital":4,"place":"city","name":"90B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.36941,-9.12779]},"properties":{"population":804494,"capital":4,"place":"city","name":"90B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.13026,-5.19984]},"properties":{"population":867282,"capital":4,"place":"city","name":"90B18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.80189,6.52032]},"properties":{"population":859267,"capital":4,"place":"city","name":"90B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.26118,-5.13941]},"properties":{"population":953647,"capital":4,"place":"city","name":"90B22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.27339,-9.27684]},"properties":{"population":924936,"capital":4,"place":"city","name":"90B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.13116,-9.39623]},"properties":{"population":932152,"capital":4,"place":"city","name":"90B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.93163,4.08266]},"properties":{"population":979171,"capital":4,"place":"city","name":"90B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.81663,-2.23803]},"properties":{"population":714834,"capital":4,"place":"city","name":"90B36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.41727,1.55095]},"properties":{"population":753955,"capital":4,"place":"city","name":"90B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.14726,4.51058]},"properties":{"population":951591,"capital":4,"place":"city","name":"90B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.19437,1.23063]},"properties":{"population":734277,"capital":4,"place":"city","name":"90B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.92945,0.90183]},"properties":{"population":696318,"capital":4,"place":"city","name":"90B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.02228,3.42046]},"properties":{"population":862909,"capital":4,"place":"city","name":"90B80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[18.51093,-9.09311]},"properties":{"population":695654,"capital":4,"place":"city","name":"90B85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.02221,3.07779]},"properties":{"population":828172,"capital":4,"place":"city","name":"90B90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.56538,-9.57174]},"properties":{"population":800168,"capital":4,"place":"city","name":"90B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.31549,0.75548]},"properties":{"population":722256,"capital":4,"place":"city","name":"90Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.8453,-6.53638]},"properties":{"population":957282,"capital":4,"place":"city","name":"90C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.8207,-9.25856]},"properties":{"population":774586,"capital":4,"place":"city","name":"90C06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.40441,4.1096]},"properties":{"population":806808,"capital":4,"place":"city","name":"90C08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.44422,-0.06847]},"properties":{"population":767322,"capital":4,"place":"city","name":"90C09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.39437,8.92875]},"properties":{"population":924676,"capital":4,"place":"city","name":"90C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.37576,8.92265]},"properties":{"population":860190,"capital":4,"place":"city","name":"90C11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.34542,9.94423]},"properties":{"population":898444,"capital":4,"place":"city","name":"90C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.56282,-2.62851]},"properties":{"population":521493,"capital":4,"place":"city","name":"90C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.72286,10.53002]},"properties":{"population":865678,"capital":4,"place":"city","name":"90C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.83404,7.16965]},"properties":{"population":800536,"capital":4,"place":"city","name":"90C22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.20947,-3.41409]},"properties":{"population":349650,"capital":4,"place":"city","name":"90C23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.04866,-3.84825]},"properties":{"population":239789,"capital":4,"place":"city","name":"90C24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.03922,9.71049]},"properties":{"population":930710,"capital":4,"place":"city","name":"90C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.39303,6.64846]},"properties":{"population":879951,"capital":4,"place":"city","name":"90C26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.14961,-6.52686]},"properties":{"population":965322,"capital":4,"place":"city","name":"90C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.67791,8.61594]},"properties":{"population":938084,"capital":4,"place":"city","name":"90C29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.91366,-5.64522]},"properties":{"population":995175,"capital":4,"place":"city","name":"90C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.08879,6.28188]},"properties":{"population":879163,"capital":4,"place":"city","name":"90C31"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.57541,2.964]},"properties":{"population":756527,"capital":4,"place":"city","name":"90C32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.36137,3.73635]},"properties":{"population":873584,"capital":4,"place":"city","name":"90C33"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.76923,-0.30307]},"properties":{"population":724992,"capital":4,"place":"city","name":"90C34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.02126,4.89823]},"properties":{"population":940063,"capital":4,"place":"city","name":"90C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.14499,9.75198]},"properties":{"population":889699,"capital":4,"place":"city","name":"90C39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.17376,6.2038]},"properties":{"population":842244,"capital":4,"place":"city","name":"90C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.39402,3.06519]},"properties":{"population":841316,"capital":4,"place":"city","name":"90C46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.31432,3.73705]},"properties":{"population":751370,"capital":4,"place":"city","name":"90C47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.01711,3.20494]},"properties":{"population":801002,"capital":4,"place":"city","name":"90C48"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.13158,-3.62196]},"properties":{"population":535658,"capital":4,"place":"city","name":"90C49"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.95683,3.42886]},"properties":{"population":770885,"capital":4,"place":"city","name":"90C51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.0632,1.96144]},"properties":{"population":746164,"capital":4,"place":"city","name":"90C52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.8458,-2.38429]},"properties":{"population":706304,"capital":4,"place":"city","name":"90C53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.30318,3.25584]},"properties":{"population":719368,"capital":4,"place":"city","name":"90C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.07934,-2.19536]},"properties":{"population":665801,"capital":4,"place":"city","name":"90C56"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.22003,3.38039]},"properties":{"population":824879,"capital":4,"place":"city","name":"90C57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.46361,11.76252]},"properties":{"population":980499,"capital":4,"place":"city","name":"90C59"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.99075,3.16438]},"properties":{"population":808764,"capital":4,"place":"city","name":"90C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.26132,3.57933]},"properties":{"population":844870,"capital":4,"place":"city","name":"90C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.15069,-5.76692]},"properties":{"population":950807,"capital":4,"place":"city","name":"90C90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.76114,1.88943]},"properties":{"population":751860,"capital":4,"place":"city","name":"90C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.40231,-2.30953]},"properties":{"population":693049,"capital":4,"place":"city","name":"90Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.42214,1.05444]},"properties":{"population":69314,"capital":4,"place":"city","name":"90D42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.44389,0.90261]},"properties":{"population":69314,"capital":4,"place":"city","name":"90D46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.29134,0.88678]},"properties":{"population":69314,"capital":4,"place":"city","name":"90D80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.08182,-4.41972]},"properties":{"population":430406,"capital":4,"place":"city","name":"91-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.17581,-0.21822]},"properties":{"population":715226,"capital":4,"place":"city","name":"91-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.22762,0.40806]},"properties":{"population":749331,"capital":4,"place":"city","name":"91-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.4992,-0.5636]},"properties":{"population":609807,"capital":4,"place":"city","name":"91-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.14729,-4.24965]},"properties":{"population":587493,"capital":4,"place":"city","name":"91-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.72997,-4.68078]},"properties":{"population":441884,"capital":4,"place":"city","name":"91-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.58904,0.1998]},"properties":{"population":746908,"capital":4,"place":"city","name":"91-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.0569,-4.61205]},"properties":{"population":603787,"capital":4,"place":"city","name":"91-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.76588,-4.48231]},"properties":{"population":263905,"capital":4,"place":"city","name":"91-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.72223,-4.74176]},"properties":{"population":686484,"capital":4,"place":"city","name":"91-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.78596,-2.03166]},"properties":{"population":876919,"capital":4,"place":"city","name":"91A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.84213,-2.4895]},"properties":{"population":762900,"capital":4,"place":"city","name":"91A06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.76586,1.29682]},"properties":{"population":333220,"capital":4,"place":"city","name":"91A07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.14751,-2.35669]},"properties":{"population":876139,"capital":4,"place":"city","name":"91A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.53846,0.60077]},"properties":{"population":455387,"capital":4,"place":"city","name":"91A11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.98872,-3.13421]},"properties":{"population":896801,"capital":4,"place":"city","name":"91A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.68274,-2.75816]},"properties":{"population":661069,"capital":4,"place":"city","name":"91A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.31885,1.31341]},"properties":{"population":385014,"capital":4,"place":"city","name":"91A14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.43229,-3.04951]},"properties":{"population":795296,"capital":4,"place":"city","name":"91A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.38108,-0.4209]},"properties":{"population":469134,"capital":4,"place":"city","name":"91A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.23285,-1.33831]},"properties":{"population":649828,"capital":4,"place":"city","name":"91A18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.72332,-4.39872]},"properties":{"population":792515,"capital":4,"place":"city","name":"91A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.29126,-5.9368]},"properties":{"population":768753,"capital":4,"place":"city","name":"91A22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.69942,-4.67071]},"properties":{"population":855986,"capital":4,"place":"city","name":"91A23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.54414,-9.62533]},"properties":{"population":774802,"capital":4,"place":"city","name":"91A24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.70368,-1.49645]},"properties":{"population":712849,"capital":4,"place":"city","name":"91A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.00258,-0.6311]},"properties":{"population":738523,"capital":4,"place":"city","name":"91A26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.10239,1.54335]},"properties":{"population":435670,"capital":4,"place":"city","name":"91A27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.1826,-2.91663]},"properties":{"population":692559,"capital":4,"place":"city","name":"91A28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.82729,-1.64632]},"properties":{"population":504985,"capital":4,"place":"city","name":"91A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.43365,-2.58029]},"properties":{"population":702197,"capital":4,"place":"city","name":"91A35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.99566,-2.42122]},"properties":{"population":823031,"capital":4,"place":"city","name":"91A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.355,-9.88961]},"properties":{"population":813622,"capital":4,"place":"city","name":"91A43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.52189,1.47622]},"properties":{"population":671417,"capital":4,"place":"city","name":"91A44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.42789,1.35906]},"properties":{"population":721817,"capital":4,"place":"city","name":"91A46"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.45896,-0.70619]},"properties":{"population":578074,"capital":4,"place":"city","name":"91A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.50502,-9.92496]},"properties":{"population":554517,"capital":4,"place":"city","name":"91A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.50704,-4.13134]},"properties":{"population":748211,"capital":4,"place":"city","name":"91A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.35828,0.67665]},"properties":{"population":698749,"capital":4,"place":"city","name":"91A65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.43933,2.75164]},"properties":{"population":473619,"capital":4,"place":"city","name":"91A68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.70068,2.43709]},"properties":{"population":459511,"capital":4,"place":"city","name":"91A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.82711,-3.92095]},"properties":{"population":881655,"capital":4,"place":"city","name":"91A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.45603,3.20148]},"properties":{"population":283321,"capital":4,"place":"city","name":"91A81"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.59312,2.93796]},"properties":{"population":382864,"capital":4,"place":"city","name":"91A86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.49104,1.36547]},"properties":{"population":747929,"capital":4,"place":"city","name":"91A90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.10116,-1.46613]},"properties":{"population":737650,"capital":4,"place":"city","name":"91A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.58289,1.41435]},"properties":{"population":588332,"capital":4,"place":"city","name":"91Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.35787,-3.5827]},"properties":{"population":636130,"capital":4,"place":"city","name":"91B02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.7847,-0.06784]},"properties":{"population":490527,"capital":4,"place":"city","name":"91B03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.72795,-0.21533]},"properties":{"population":534233,"capital":4,"place":"city","name":"91B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.31248,-2.855]},"properties":{"population":923278,"capital":4,"place":"city","name":"91B06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.51345,-2.87002]},"properties":{"population":807028,"capital":4,"place":"city","name":"91B08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.09563,-0.95991]},"properties":{"population":696885,"capital":4,"place":"city","name":"91B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.02122,-0.87205]},"properties":{"population":788758,"capital":4,"place":"city","name":"91B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.8065,-1.05663]},"properties":{"population":844912,"capital":4,"place":"city","name":"91B14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.76554,-0.21722]},"properties":{"population":686066,"capital":4,"place":"city","name":"91B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.50718,-2.0292]},"properties":{"population":851978,"capital":4,"place":"city","name":"91B16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.03648,-0.86668]},"properties":{"population":701750,"capital":4,"place":"city","name":"91B18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.54128,-2.19568]},"properties":{"population":875668,"capital":4,"place":"city","name":"91B24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.70206,0.14976]},"properties":{"population":708002,"capital":4,"place":"city","name":"91B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.50488,-1.61926]},"properties":{"population":896456,"capital":4,"place":"city","name":"91B26"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[19.62447,-9.28045]},"properties":{"population":893379,"capital":4,"place":"city","name":"91B28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.63273,-1.85005]},"properties":{"population":929752,"capital":4,"place":"city","name":"91B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.79131,-1.15429]},"properties":{"population":800803,"capital":4,"place":"city","name":"91B32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.43756,-3.59101]},"properties":{"population":830474,"capital":4,"place":"city","name":"91B38"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.75612,-0.71706]},"properties":{"population":534233,"capital":4,"place":"city","name":"91B39"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.6436,-1.05466]},"properties":{"population":797108,"capital":4,"place":"city","name":"91B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.59268,-0.01325]},"properties":{"population":456434,"capital":4,"place":"city","name":"91B41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.80476,-1.04039]},"properties":{"population":791571,"capital":4,"place":"city","name":"91B42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.60764,-0.26721]},"properties":{"population":433073,"capital":4,"place":"city","name":"91B43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.81417,-0.73823]},"properties":{"population":738894,"capital":4,"place":"city","name":"91B44"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.72396,-1.3351]},"properties":{"population":853934,"capital":4,"place":"city","name":"91B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.53682,0.49189]},"properties":{"population":599146,"capital":4,"place":"city","name":"91B51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.38928,0.48226]},"properties":{"population":720711,"capital":4,"place":"city","name":"91B52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.52175,0.26289]},"properties":{"population":766152,"capital":4,"place":"city","name":"91B54"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.46808,-1.0915]},"properties":{"population":690073,"capital":4,"place":"city","name":"91B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.4242,-2.24693]},"properties":{"population":837008,"capital":4,"place":"city","name":"91B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.25333,-2.51056]},"properties":{"population":911964,"capital":4,"place":"city","name":"91B62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.63205,-1.2218]},"properties":{"population":851338,"capital":4,"place":"city","name":"91B64"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.67854,-2.96959]},"properties":{"population":705789,"capital":4,"place":"city","name":"91B66"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.68905,0.73122]},"properties":{"population":730854,"capital":4,"place":"city","name":"91B68"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.56171,0.15992]},"properties":{"population":644888,"capital":4,"place":"city","name":"91B69"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.58433,-2.14377]},"properties":{"population":764252,"capital":4,"place":"city","name":"91B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.57492,-0.02122]},"properties":{"population":636647,"capital":4,"place":"city","name":"91B72"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.24702,-1.74749]},"properties":{"population":736644,"capital":4,"place":"city","name":"91B74"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.68709,-1.34899]},"properties":{"population":848404,"capital":4,"place":"city","name":"91B76"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.69361,-2.34736]},"properties":{"population":647697,"capital":4,"place":"city","name":"91B80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.9212,-0.52497]},"properties":{"population":846505,"capital":4,"place":"city","name":"91B82"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.26121,-1.47186]},"properties":{"population":838320,"capital":4,"place":"city","name":"91B84"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.68333,-0.58881]},"properties":{"population":531320,"capital":4,"place":"city","name":"91B86"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.94106,-2.67895]},"properties":{"population":742117,"capital":4,"place":"city","name":"91B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.33782,-2.50237]},"properties":{"population":573009,"capital":4,"place":"city","name":"91Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.78536,-2.74966]},"properties":{"population":570044,"capital":4,"place":"city","name":"91C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.89624,-6.172]},"properties":{"population":547646,"capital":4,"place":"city","name":"91C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.82327,-6.23545]},"properties":{"population":631173,"capital":4,"place":"city","name":"91C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.84751,-6.44821]},"properties":{"population":576205,"capital":4,"place":"city","name":"91C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.42369,-5.92045]},"properties":{"population":333220,"capital":4,"place":"city","name":"91Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.35507,0.89788]},"properties":{"population":738894,"capital":4,"place":"city","name":"91D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.82269,-3.29897]},"properties":{"population":346573,"capital":4,"place":"city","name":"91D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.99256,0.76789]},"properties":{"population":719142,"capital":4,"place":"city","name":"91D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.85472,-3.29133]},"properties":{"population":606378,"capital":4,"place":"city","name":"91D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.04787,1.21622]},"properties":{"population":819422,"capital":4,"place":"city","name":"91D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.68066,0.63867]},"properties":{"population":503695,"capital":4,"place":"city","name":"91D35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.71215,-2.76397]},"properties":{"population":708170,"capital":4,"place":"city","name":"91D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.85356,-3.42121]},"properties":{"population":373766,"capital":4,"place":"city","name":"91Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.44656,-1.44593]},"properties":{"population":671538,"capital":4,"place":"city","name":"91E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[20.45365,-9.46498]},"properties":{"population":701929,"capital":4,"place":"city","name":"91E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.34406,0.0178]},"properties":{"population":670441,"capital":4,"place":"city","name":"91E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.51657,1.30902]},"properties":{"population":627476,"capital":4,"place":"city","name":"91E45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.94305,-1.24627]},"properties":{"population":723489,"capital":4,"place":"city","name":"91E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.15121,2.95367]},"properties":{"population":361091,"capital":4,"place":"city","name":"91Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.53698,-3.35963]},"properties":{"population":709672,"capital":4,"place":"city","name":"91F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.24248,-1.29949]},"properties":{"population":689365,"capital":4,"place":"city","name":"91F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.85408,-2.78848]},"properties":{"population":451085,"capital":4,"place":"city","name":"91F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.8246,-2.41593]},"properties":{"population":179175,"capital":4,"place":"city","name":"91Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.0855,-5.36688]},"properties":{"population":663856,"capital":4,"place":"city","name":"91G05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.27773,-1.00947]},"properties":{"population":874305,"capital":4,"place":"city","name":"91G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.26231,-5.19278]},"properties":{"population":580513,"capital":4,"place":"city","name":"91G15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.14893,-0.83797]},"properties":{"population":890218,"capital":4,"place":"city","name":"91G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.97798,-1.60264]},"properties":{"population":722037,"capital":4,"place":"city","name":"91G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.10312,-1.74137]},"properties":{"population":747022,"capital":4,"place":"city","name":"91G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.36442,-5.35978]},"properties":{"population":514749,"capital":4,"place":"city","name":"91G45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.01809,-1.94262]},"properties":{"population":709174,"capital":4,"place":"city","name":"91G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.53083,-1.0561]},"properties":{"population":806902,"capital":4,"place":"city","name":"91G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.8419,-1.60865]},"properties":{"population":806337,"capital":4,"place":"city","name":"91G70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.99873,-0.94812]},"properties":{"population":807899,"capital":4,"place":"city","name":"91G80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.2636,-1.8358]},"properties":{"population":694022,"capital":4,"place":"city","name":"91G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.99936,-1.96066]},"properties":{"population":596357,"capital":4,"place":"city","name":"91Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.0075,-10.71134]},"properties":{"population":455387,"capital":4,"place":"city","name":"92-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.24627,6.63892]},"properties":{"population":669332,"capital":4,"place":"city","name":"92-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.28782,8.62181]},"properties":{"population":723633,"capital":4,"place":"city","name":"92-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.16901,11.7065]},"properties":{"population":534710,"capital":4,"place":"city","name":"92-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.81514,6.49084]},"properties":{"population":646146,"capital":4,"place":"city","name":"92-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.31028,5.55362]},"properties":{"population":289037,"capital":4,"place":"city","name":"92-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.03325,8.35491]},"properties":{"population":745066,"capital":4,"place":"city","name":"92-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.84678,-10.91685]},"properties":{"population":820056,"capital":4,"place":"city","name":"92-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.93412,11.07564]},"properties":{"population":526785,"capital":4,"place":"city","name":"92-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.81802,4.62454]},"properties":{"population":69314,"capital":4,"place":"city","name":"92-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.33568,5.34819]},"properties":{"population":692657,"capital":4,"place":"city","name":"92-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.72779,-10.44246]},"properties":{"population":850370,"capital":4,"place":"city","name":"92B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.89391,10.74561]},"properties":{"population":552942,"capital":4,"place":"city","name":"92B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.94286,6.8286]},"properties":{"population":682979,"capital":4,"place":"city","name":"92B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[16.33667,9.97636]},"properties":{"population":929495,"capital":4,"place":"city","name":"92B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.41739,8.3677]},"properties":{"population":642810,"capital":4,"place":"city","name":"92B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.16269,6.13314]},"properties":{"population":573979,"capital":4,"place":"city","name":"92B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.8254,8.85276]},"properties":{"population":429045,"capital":4,"place":"city","name":"92Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.13902,-10.58454]},"properties":{"population":782444,"capital":4,"place":"city","name":"92C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.10398,-8.09]},"properties":{"population":819146,"capital":4,"place":"city","name":"92C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.00464,-10.57764]},"properties":{"population":767415,"capital":4,"place":"city","name":"92C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.91573,9.28985]},"properties":{"population":785979,"capital":4,"place":"city","name":"92C17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.90355,-10.70442]},"properties":{"population":885437,"capital":4,"place":"city","name":"92C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.18858,-8.14219]},"properties":{"population":798752,"capital":4,"place":"city","name":"92C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.52332,6.82942]},"properties":{"population":602827,"capital":4,"place":"city","name":"92C32"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.34081,9.16147]},"properties":{"population":803333,"capital":4,"place":"city","name":"92C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.07987,9.74316]},"properties":{"population":827715,"capital":4,"place":"city","name":"92C37"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.88602,-11.26042]},"properties":{"population":900822,"capital":4,"place":"city","name":"92C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.27151,9.49303]},"properties":{"population":786403,"capital":4,"place":"city","name":"92C42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.48461,8.96767]},"properties":{"population":782524,"capital":4,"place":"city","name":"92C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[7.62262,9.20646]},"properties":{"population":219722,"capital":4,"place":"city","name":"92C47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.50338,-8.16077]},"properties":{"population":939515,"capital":4,"place":"city","name":"92C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.31139,-8.16539]},"properties":{"population":883521,"capital":4,"place":"city","name":"92C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[14.70874,9.00124]},"properties":{"population":808886,"capital":4,"place":"city","name":"92C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.04083,8.05307]},"properties":{"population":477912,"capital":4,"place":"city","name":"92C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.36769,8.43816]},"properties":{"population":395124,"capital":4,"place":"city","name":"92C75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.89966,8.57522]},"properties":{"population":677422,"capital":4,"place":"city","name":"92C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.67286,-10.64627]},"properties":{"population":663200,"capital":4,"place":"city","name":"92C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.12293,7.15734]},"properties":{"population":785321,"capital":4,"place":"city","name":"92Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.28371,-10.85117]},"properties":{"population":884303,"capital":4,"place":"city","name":"92D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.19164,8.88258]},"properties":{"population":859951,"capital":4,"place":"city","name":"92D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.35258,8.83891]},"properties":{"population":811342,"capital":4,"place":"city","name":"92D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.42956,-10.80516]},"properties":{"population":994251,"capital":4,"place":"city","name":"92D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[11.17909,-10.77689]},"properties":{"population":926813,"capital":4,"place":"city","name":"92D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[8.88949,-8.00121]},"properties":{"population":930719,"capital":4,"place":"city","name":"92D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.80707,7.72841]},"properties":{"population":407753,"capital":4,"place":"city","name":"92D45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.50112,8.5897]},"properties":{"population":726542,"capital":4,"place":"city","name":"92D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.82234,6.17701]},"properties":{"population":614632,"capital":4,"place":"city","name":"92D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[9.64683,7.50614]},"properties":{"population":502388,"capital":4,"place":"city","name":"92Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.62424,7.75925]},"properties":{"population":806840,"capital":4,"place":"city","name":"92E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.39542,-10.90455]},"properties":{"population":796693,"capital":4,"place":"city","name":"92E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.72865,7.82765]},"properties":{"population":669332,"capital":4,"place":"city","name":"92E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.02951,7.00984]},"properties":{"population":655677,"capital":4,"place":"city","name":"92Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.25529,4.95354]},"properties":{"population":663068,"capital":4,"place":"city","name":"92F05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.73738,4.50166]},"properties":{"population":248490,"capital":4,"place":"city","name":"92F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.14416,1.3809]},"properties":{"population":69314,"capital":4,"place":"city","name":"92H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.07393,1.62604]},"properties":{"population":69314,"capital":4,"place":"city","name":"92Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.95977,1.90127]},"properties":{"population":472738,"capital":4,"place":"city","name":"93-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.27477,2.54457]},"properties":{"population":708086,"capital":4,"place":"city","name":"93-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.82933,6.34271]},"properties":{"population":797659,"capital":4,"place":"city","name":"93-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.96586,1.96793]},"properties":{"population":567675,"capital":4,"place":"city","name":"93-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.11191,5.2732]},"properties":{"population":634212,"capital":4,"place":"city","name":"93-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.58271,1.78198]},"properties":{"population":194591,"capital":4,"place":"city","name":"93-05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.08196,5.40875]},"properties":{"population":776174,"capital":4,"place":"city","name":"93-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.54438,1.72327]},"properties":{"population":433073,"capital":4,"place":"city","name":"93-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.58925,1.64471]},"properties":{"population":474493,"capital":4,"place":"city","name":"93-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.64109,1.65081]},"properties":{"population":69314,"capital":4,"place":"city","name":"93-11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.6188,1.64509]},"properties":{"population":796102,"capital":4,"place":"city","name":"93-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.17683,-1.57386]},"properties":{"population":552942,"capital":4,"place":"city","name":"93A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.31906,3.78676]},"properties":{"population":769802,"capital":4,"place":"city","name":"93A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.2458,0.79058]},"properties":{"population":765159,"capital":4,"place":"city","name":"93A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.20179,4.3562]},"properties":{"population":860135,"capital":4,"place":"city","name":"93A14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.10536,5.07125]},"properties":{"population":843663,"capital":4,"place":"city","name":"93A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.03595,-1.29691]},"properties":{"population":640191,"capital":4,"place":"city","name":"93A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.58537,-1.50386]},"properties":{"population":109861,"capital":4,"place":"city","name":"93A25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.22439,-9.89345]},"properties":{"population":878155,"capital":4,"place":"city","name":"93A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[13.07778,-9.69278]},"properties":{"population":701929,"capital":4,"place":"city","name":"93A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.57974,-0.96284]},"properties":{"population":395124,"capital":4,"place":"city","name":"93Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.10281,6.4832]},"properties":{"population":790948,"capital":4,"place":"city","name":"93B03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.14223,-8.20053]},"properties":{"population":934075,"capital":4,"place":"city","name":"93B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.55912,6.75454]},"properties":{"population":881522,"capital":4,"place":"city","name":"93B07"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.37749,3.01856]},"properties":{"population":741878,"capital":4,"place":"city","name":"93B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.34365,3.30499]},"properties":{"population":753689,"capital":4,"place":"city","name":"93B11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.30418,5.84294]},"properties":{"population":836404,"capital":4,"place":"city","name":"93B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.40937,3.1036]},"properties":{"population":773280,"capital":4,"place":"city","name":"93B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[1.31132,3.43439]},"properties":{"population":779729,"capital":4,"place":"city","name":"93B17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.98797,4.93589]},"properties":{"population":726961,"capital":4,"place":"city","name":"93B18"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.29703,2.3238]},"properties":{"population":693342,"capital":4,"place":"city","name":"93B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.87211,-0.83884]},"properties":{"population":299573,"capital":4,"place":"city","name":"93B24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.42692,5.55768]},"properties":{"population":798309,"capital":4,"place":"city","name":"93B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.21671,5.12251]},"properties":{"population":719443,"capital":4,"place":"city","name":"93B27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.20442,2.64566]},"properties":{"population":686066,"capital":4,"place":"city","name":"93B28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.09435,1.25899]},"properties":{"population":626149,"capital":4,"place":"city","name":"93B29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.27564,6.19162]},"properties":{"population":889013,"capital":4,"place":"city","name":"93B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.88038,7.05423]},"properties":{"population":917946,"capital":4,"place":"city","name":"93B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.26739,5.87877]},"properties":{"population":871341,"capital":4,"place":"city","name":"93B36"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.67853,7.19391]},"properties":{"population":907703,"capital":4,"place":"city","name":"93B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.40557,0.87364]},"properties":{"population":550938,"capital":4,"place":"city","name":"93B45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.28379,0.67891]},"properties":{"population":455387,"capital":4,"place":"city","name":"93B47"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.31275,6.30802]},"properties":{"population":857489,"capital":4,"place":"city","name":"93B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.17339,5.92965]},"properties":{"population":895144,"capital":4,"place":"city","name":"93B51"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.5314,7.02859]},"properties":{"population":926160,"capital":4,"place":"city","name":"93B52"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.27795,1.70914]},"properties":{"population":630809,"capital":4,"place":"city","name":"93B53"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.63756,3.26159]},"properties":{"population":788683,"capital":4,"place":"city","name":"93B55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.17414,2.79307]},"properties":{"population":713648,"capital":4,"place":"city","name":"93B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.50104,2.04767]},"properties":{"population":698841,"capital":4,"place":"city","name":"93B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[0.03304,1.54349]},"properties":{"population":662273,"capital":4,"place":"city","name":"93B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.2783,-0.57966]},"properties":{"population":494164,"capital":4,"place":"city","name":"93Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.4597,-9.8281]},"properties":{"population":1004229,"capital":4,"place":"city","name":"93C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.62283,-9.74056]},"properties":{"population":1011143,"capital":4,"place":"city","name":"93C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.11849,-10.1868]},"properties":{"population":982390,"capital":4,"place":"city","name":"93C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.45406,-8.3943]},"properties":{"population":904640,"capital":4,"place":"city","name":"93C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.03916,2.58582]},"properties":{"population":761677,"capital":4,"place":"city","name":"93C23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.58074,5.34706]},"properties":{"population":793558,"capital":4,"place":"city","name":"93C25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.38603,1.00098]},"properties":{"population":523644,"capital":4,"place":"city","name":"93C27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.53489,1.09037]},"properties":{"population":443081,"capital":4,"place":"city","name":"93C28"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.40068,0.67167]},"properties":{"population":389182,"capital":4,"place":"city","name":"93C29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.86898,5.79594]},"properties":{"population":859858,"capital":4,"place":"city","name":"93C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.47544,5.59976]},"properties":{"population":865973,"capital":4,"place":"city","name":"93C35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.67933,6.01845]},"properties":{"population":944786,"capital":4,"place":"city","name":"93C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.41417,5.38803]},"properties":{"population":833038,"capital":4,"place":"city","name":"93C41"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.95111,5.57975]},"properties":{"population":880207,"capital":4,"place":"city","name":"93C42"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.49225,1.02347]},"properties":{"population":675227,"capital":4,"place":"city","name":"93C43"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.45907,0.36549]},"properties":{"population":69314,"capital":4,"place":"city","name":"93C45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.443,6.0888]},"properties":{"population":963893,"capital":4,"place":"city","name":"93C55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.00178,2.75564]},"properties":{"population":805356,"capital":4,"place":"city","name":"93C57"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.3594,0.35939]},"properties":{"population":138629,"capital":4,"place":"city","name":"93C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.02182,1.65759]},"properties":{"population":694215,"capital":4,"place":"city","name":"93C62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.15505,5.00677]},"properties":{"population":795647,"capital":4,"place":"city","name":"93C65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.04417,4.67054]},"properties":{"population":700669,"capital":4,"place":"city","name":"93C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.89965,5.18269]},"properties":{"population":807961,"capital":4,"place":"city","name":"93C73"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.18492,4.84492]},"properties":{"population":744014,"capital":4,"place":"city","name":"93C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.14147,4.62595]},"properties":{"population":733236,"capital":4,"place":"city","name":"93C83"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.09987,-9.85598]},"properties":{"population":892425,"capital":4,"place":"city","name":"93C85"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.85146,5.062]},"properties":{"population":924522,"capital":4,"place":"city","name":"93C95"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-0.11866,3.03765]},"properties":{"population":855583,"capital":4,"place":"city","name":"93C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.40412,0.69945]},"properties":{"population":556452,"capital":4,"place":"city","name":"93Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.34832,-9.93149]},"properties":{"population":903539,"capital":4,"place":"city","name":"93D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[12.52855,-9.82684]},"properties":{"population":849699,"capital":4,"place":"city","name":"93D09"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.41,1.13395]},"properties":{"population":693147,"capital":4,"place":"city","name":"93D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.38697,4.98219]},"properties":{"population":942100,"capital":4,"place":"city","name":"93D15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.08725,4.72061]},"properties":{"population":895156,"capital":4,"place":"city","name":"93D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.16996,3.80705]},"properties":{"population":819781,"capital":4,"place":"city","name":"93D21"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.53806,-0.3843]},"properties":{"population":69314,"capital":4,"place":"city","name":"93D22"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.20085,0.14799]},"properties":{"population":582008,"capital":4,"place":"city","name":"93D23"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.0826,3.73237]},"properties":{"population":751806,"capital":4,"place":"city","name":"93D25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[10.72863,-8.28103]},"properties":{"population":794129,"capital":4,"place":"city","name":"93D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.59958,-0.25718]},"properties":{"population":554517,"capital":4,"place":"city","name":"93D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.39772,-0.57149]},"properties":{"population":592958,"capital":4,"place":"city","name":"93D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.22002,0.87306]},"properties":{"population":795822,"capital":4,"place":"city","name":"93D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.55468,-0.54443]},"properties":{"population":520400,"capital":4,"place":"city","name":"93Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.33111,3.42753]},"properties":{"population":874273,"capital":4,"place":"city","name":"93E03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.34825,3.2923]},"properties":{"population":935496,"capital":4,"place":"city","name":"93E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.36379,3.28127]},"properties":{"population":929062,"capital":4,"place":"city","name":"93E11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.3069,3.08879]},"properties":{"population":850410,"capital":4,"place":"city","name":"93E12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.12606,0.02436]},"properties":{"population":617794,"capital":4,"place":"city","name":"93E14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.16624,3.13101]},"properties":{"population":855294,"capital":4,"place":"city","name":"93E15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.17526,3.73756]},"properties":{"population":936802,"capital":4,"place":"city","name":"93E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.50173,2.90675]},"properties":{"population":732712,"capital":4,"place":"city","name":"93E24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.44238,2.96728]},"properties":{"population":805864,"capital":4,"place":"city","name":"93E25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.25883,-0.11569]},"properties":{"population":695368,"capital":4,"place":"city","name":"93E35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-1.97912,-0.04684]},"properties":{"population":668959,"capital":4,"place":"city","name":"93E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-2.19546,0.02408]},"properties":{"population":500394,"capital":4,"place":"city","name":"93Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.23607,4.38291]},"properties":{"population":551342,"capital":4,"place":"city","name":"94-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.42011,3.90644]},"properties":{"population":729844,"capital":4,"place":"city","name":"94-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.58211,3.64486]},"properties":{"population":726892,"capital":4,"place":"city","name":"94-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.99351,4.55367]},"properties":{"population":588053,"capital":4,"place":"city","name":"94-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.16632,4.02739]},"properties":{"population":620859,"capital":4,"place":"city","name":"94-04"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.33242,3.64455]},"properties":{"population":756475,"capital":4,"place":"city","name":"94-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.98986,4.46645]},"properties":{"population":109861,"capital":4,"place":"city","name":"94-08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.9413,4.57459]},"properties":{"population":207944,"capital":4,"place":"city","name":"94-10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.85231,4.73129]},"properties":{"population":714755,"capital":4,"place":"city","name":"94-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.75566,2.19002]},"properties":{"population":868828,"capital":4,"place":"city","name":"94A05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.84279,1.77873]},"properties":{"population":949182,"capital":4,"place":"city","name":"94A08"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.50306,2.35755]},"properties":{"population":743366,"capital":4,"place":"city","name":"94A11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.73049,2.05104]},"properties":{"population":994131,"capital":4,"place":"city","name":"94A12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.7732,2.58693]},"properties":{"population":824170,"capital":4,"place":"city","name":"94A13"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.36268,2.82162]},"properties":{"population":739572,"capital":4,"place":"city","name":"94A14"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.69179,2.34138]},"properties":{"population":844376,"capital":4,"place":"city","name":"94A15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.85474,3.0962]},"properties":{"population":317805,"capital":4,"place":"city","name":"94A16"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.10231,2.11725]},"properties":{"population":910409,"capital":4,"place":"city","name":"94A17"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.69807,2.38871]},"properties":{"population":752131,"capital":4,"place":"city","name":"94A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.76979,2.74226]},"properties":{"population":764683,"capital":4,"place":"city","name":"94A24"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.60841,3.04956]},"properties":{"population":810198,"capital":4,"place":"city","name":"94A29"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.86057,2.84536]},"properties":{"population":665544,"capital":4,"place":"city","name":"94A34"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.46288,2.71329]},"properties":{"population":877322,"capital":4,"place":"city","name":"94A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.72602,2.90231]},"properties":{"population":673340,"capital":4,"place":"city","name":"94A45"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.73607,2.90598]},"properties":{"population":468213,"capital":4,"place":"city","name":"94A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.91919,2.85115]},"properties":{"population":768845,"capital":4,"place":"city","name":"94A55"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.73354,2.49385]},"properties":{"population":1018456,"capital":4,"place":"city","name":"94A60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.55048,2.72432]},"properties":{"population":892492,"capital":4,"place":"city","name":"94A62"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-9.12811,2.7993]},"properties":{"population":696034,"capital":4,"place":"city","name":"94A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.71754,2.86052]},"properties":{"population":489783,"capital":4,"place":"city","name":"94Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.46055,4.26356]},"properties":{"population":875368,"capital":4,"place":"city","name":"94B05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-3.98398,4.15026]},"properties":{"population":673815,"capital":4,"place":"city","name":"94B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.43484,4.6013]},"properties":{"population":627664,"capital":4,"place":"city","name":"94B12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.01285,4.23437]},"properties":{"population":793737,"capital":4,"place":"city","name":"94B15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.10141,4.43883]},"properties":{"population":577144,"capital":4,"place":"city","name":"94B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.52473,4.31248]},"properties":{"population":759839,"capital":4,"place":"city","name":"94B25"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.32567,4.17364]},"properties":{"population":729369,"capital":4,"place":"city","name":"94B27"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.68947,4.86759]},"properties":{"population":355534,"capital":4,"place":"city","name":"94B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.18077,4.231]},"properties":{"population":804494,"capital":4,"place":"city","name":"94B35"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.60504,4.92337]},"properties":{"population":612468,"capital":4,"place":"city","name":"94B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.31918,4.89753]},"properties":{"population":519849,"capital":4,"place":"city","name":"94B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.08901,4.28082]},"properties":{"population":792189,"capital":4,"place":"city","name":"94B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.64535,4.69099]},"properties":{"population":746336,"capital":4,"place":"city","name":"94B65"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.25555,4.87382]},"properties":{"population":629710,"capital":4,"place":"city","name":"94B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.82658,4.64946]},"properties":{"population":607993,"capital":4,"place":"city","name":"94B75"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.7734,4.81123]},"properties":{"population":753529,"capital":4,"place":"city","name":"94B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-7.87165,4.92046]},"properties":{"population":585793,"capital":4,"place":"city","name":"94Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[15.04863,-3.85974]},"properties":{"population":831849,"capital":4,"place":"city","name":"94C05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-6.01033,3.77525]},"properties":{"population":918173,"capital":4,"place":"city","name":"94C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.50249,3.99893]},"properties":{"population":555682,"capital":4,"place":"city","name":"94C11"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.58798,3.85586]},"properties":{"population":720637,"capital":4,"place":"city","name":"94C12"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.80142,3.92283]},"properties":{"population":804910,"capital":4,"place":"city","name":"94C15"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.45579,3.99114]},"properties":{"population":667582,"capital":4,"place":"city","name":"94C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.02377,4.2483]},"properties":{"population":417438,"capital":4,"place":"city","name":"94C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.7184,3.55837]},"properties":{"population":748773,"capital":4,"place":"city","name":"94C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-8.27567,4.2763]},"properties":{"population":484418,"capital":4,"place":"city","name":"94Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[23.67686,-5.72151]},"properties":{"population":774932,"capital":4,"place":"city","name":"94D05"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.4722,5.80702]},"properties":{"population":549306,"capital":4,"place":"city","name":"94D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.37832,5.74827]},"properties":{"population":179175,"capital":4,"place":"city","name":"94D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.54185,11.38792]},"properties":{"population":321887,"capital":4,"place":"city","name":"97-00"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.99009,16.56954]},"properties":{"population":629341,"capital":4,"place":"city","name":"97-01"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.82356,11.35493]},"properties":{"population":482831,"capital":4,"place":"city","name":"97-02"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.17017,16.79325]},"properties":{"population":561312,"capital":4,"place":"city","name":"97-03"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.96447,16.57411]},"properties":{"population":584643,"capital":4,"place":"city","name":"97-06"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.8583,11.22831]},"properties":{"population":380666,"capital":4,"place":"city","name":"97-XX"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.77477,11.40873]},"properties":{"population":309104,"capital":4,"place":"city","name":"97A10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.00039,16.57044]},"properties":{"population":577765,"capital":4,"place":"city","name":"97A20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.86629,16.84031]},"properties":{"population":594017,"capital":4,"place":"city","name":"97A30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.62076,11.36297]},"properties":{"population":438202,"capital":4,"place":"city","name":"97A40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.95549,11.42527]},"properties":{"population":138629,"capital":4,"place":"city","name":"97A50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.9762,11.13805]},"properties":{"population":270805,"capital":4,"place":"city","name":"97A70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.01631,16.64479]},"properties":{"population":551342,"capital":4,"place":"city","name":"97A80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.85351,11.35869]},"properties":{"population":194591,"capital":4,"place":"city","name":"97A90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-10.80203,11.06761]},"properties":{"population":219722,"capital":4,"place":"city","name":"97A99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.90708,16.69365]},"properties":{"population":138629,"capital":4,"place":"city","name":"97Axx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.89665,13.70184]},"properties":{"population":378418,"capital":4,"place":"city","name":"97B10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.36415,12.66956]},"properties":{"population":397029,"capital":4,"place":"city","name":"97B20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.57661,13.53402]},"properties":{"population":179175,"capital":4,"place":"city","name":"97B30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.41822,13.0138]},"properties":{"population":506259,"capital":4,"place":"city","name":"97B40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.35524,13.19607]},"properties":{"population":505624,"capital":4,"place":"city","name":"97B50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.33892,13.04468]},"properties":{"population":380666,"capital":4,"place":"city","name":"97B60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.64162,13.63359]},"properties":{"population":371357,"capital":4,"place":"city","name":"97B70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.66784,13.39728]},"properties":{"population":289037,"capital":4,"place":"city","name":"97B99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.50051,17.27111]},"properties":{"population":194591,"capital":4,"place":"city","name":"97Bxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.95676,13.16659]},"properties":{"population":248490,"capital":4,"place":"city","name":"97C10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.69757,12.87136]},"properties":{"population":352636,"capital":4,"place":"city","name":"97C20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.54621,13.18333]},"properties":{"population":516478,"capital":4,"place":"city","name":"97C30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.31787,13.0453]},"properties":{"population":426267,"capital":4,"place":"city","name":"97C40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.87171,12.94298]},"properties":{"population":382864,"capital":4,"place":"city","name":"97C50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.71731,12.9024]},"properties":{"population":400733,"capital":4,"place":"city","name":"97C60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.97604,13.06591]},"properties":{"population":440671,"capital":4,"place":"city","name":"97C70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.2521,13.27096]},"properties":{"population":313549,"capital":4,"place":"city","name":"97C80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.49632,12.98678]},"properties":{"population":325809,"capital":4,"place":"city","name":"97C90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.32399,12.58364]},"properties":{"population":333220,"capital":4,"place":"city","name":"97C99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.44596,17.30313]},"properties":{"population":230258,"capital":4,"place":"city","name":"97Cxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.58128,17.03218]},"properties":{"population":471849,"capital":4,"place":"city","name":"97D10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.49663,12.0185]},"properties":{"population":491998,"capital":4,"place":"city","name":"97D20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.72048,11.83232]},"properties":{"population":539362,"capital":4,"place":"city","name":"97D30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.60445,17.20938]},"properties":{"population":572358,"capital":4,"place":"city","name":"97D40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.29304,11.8514]},"properties":{"population":491998,"capital":4,"place":"city","name":"97D50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.29225,17.09433]},"properties":{"population":455387,"capital":4,"place":"city","name":"97D60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.08623,12.11166]},"properties":{"population":382864,"capital":4,"place":"city","name":"97D70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.46371,11.82634]},"properties":{"population":329583,"capital":4,"place":"city","name":"97D80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.25968,12.16973]},"properties":{"population":385014,"capital":4,"place":"city","name":"97D99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.25455,17.17709]},"properties":{"population":248490,"capital":4,"place":"city","name":"97Dxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.84454,16.91565]},"properties":{"population":299573,"capital":4,"place":"city","name":"97E10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.14535,11.97445]},"properties":{"population":441884,"capital":4,"place":"city","name":"97E20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.89251,12.10835]},"properties":{"population":420469,"capital":4,"place":"city","name":"97E30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.09803,17.06684]},"properties":{"population":398898,"capital":4,"place":"city","name":"97E40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.73268,17.01419]},"properties":{"population":477068,"capital":4,"place":"city","name":"97E50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.68369,13.18706]},"properties":{"population":349650,"capital":4,"place":"city","name":"97E60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.2239,13.13982]},"properties":{"population":138629,"capital":4,"place":"city","name":"97E99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.22861,13.21965]},"properties":{"population":160943,"capital":4,"place":"city","name":"97Exx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.99782,13.60703]},"properties":{"population":358351,"capital":4,"place":"city","name":"97F10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.83693,14.14494]},"properties":{"population":219722,"capital":4,"place":"city","name":"97F20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.18524,13.55176]},"properties":{"population":333220,"capital":4,"place":"city","name":"97F30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.04173,13.80258]},"properties":{"population":336729,"capital":4,"place":"city","name":"97F40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.72047,13.11078]},"properties":{"population":411087,"capital":4,"place":"city","name":"97F50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.1186,13.86348]},"properties":{"population":577455,"capital":4,"place":"city","name":"97F60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.51815,14.00322]},"properties":{"population":194591,"capital":4,"place":"city","name":"97F70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.74055,14.09145]},"properties":{"population":194591,"capital":4,"place":"city","name":"97F80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.66366,13.97678]},"properties":{"population":230258,"capital":4,"place":"city","name":"97F90"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.85011,13.84869]},"properties":{"population":160943,"capital":4,"place":"city","name":"97F99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.31452,13.18828]},"properties":{"population":256494,"capital":4,"place":"city","name":"97Fxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.01949,17.51197]},"properties":{"population":465396,"capital":4,"place":"city","name":"97G10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.6137,13.55298]},"properties":{"population":256494,"capital":4,"place":"city","name":"97G20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.55128,17.61715]},"properties":{"population":329583,"capital":4,"place":"city","name":"97G30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.9992,17.10769]},"properties":{"population":554126,"capital":4,"place":"city","name":"97G40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.85287,13.55736]},"properties":{"population":325809,"capital":4,"place":"city","name":"97G50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.7176,13.56859]},"properties":{"population":455387,"capital":4,"place":"city","name":"97G60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.85377,13.63138]},"properties":{"population":453259,"capital":4,"place":"city","name":"97G70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.83198,13.51622]},"properties":{"population":385014,"capital":4,"place":"city","name":"97G80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.09521,13.91276]},"properties":{"population":457471,"capital":4,"place":"city","name":"97G99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.56495,13.55568]},"properties":{"population":289037,"capital":4,"place":"city","name":"97Gxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.70065,14.05001]},"properties":{"population":366356,"capital":4,"place":"city","name":"97H10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.99827,14.31094]},"properties":{"population":289037,"capital":4,"place":"city","name":"97H20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.72245,14.43716]},"properties":{"population":438202,"capital":4,"place":"city","name":"97H30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.03339,14.4289]},"properties":{"population":451085,"capital":4,"place":"city","name":"97H40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.5254,14.52761]},"properties":{"population":230258,"capital":4,"place":"city","name":"97H50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.13623,14.16172]},"properties":{"population":486753,"capital":4,"place":"city","name":"97H60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.83224,14.51769]},"properties":{"population":248490,"capital":4,"place":"city","name":"97H99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.82158,14.45105]},"properties":{"population":248490,"capital":4,"place":"city","name":"97Hxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.13328,17.53709]},"properties":{"population":480402,"capital":4,"place":"city","name":"97I10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.85707,14.7915]},"properties":{"population":489034,"capital":4,"place":"city","name":"97I20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.61272,17.87103]},"properties":{"population":503695,"capital":4,"place":"city","name":"97I30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.78069,14.61178]},"properties":{"population":478749,"capital":4,"place":"city","name":"97I40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.90366,14.75488]},"properties":{"population":494875,"capital":4,"place":"city","name":"97I50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.77073,14.70858]},"properties":{"population":378418,"capital":4,"place":"city","name":"97I60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.83003,14.70662]},"properties":{"population":449980,"capital":4,"place":"city","name":"97I70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.55237,14.77047]},"properties":{"population":346573,"capital":4,"place":"city","name":"97I80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.70621,14.59796]},"properties":{"population":435670,"capital":4,"place":"city","name":"97I99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.64354,14.77792]},"properties":{"population":256494,"capital":4,"place":"city","name":"97Ixx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.95356,17.64502]},"properties":{"population":358351,"capital":4,"place":"city","name":"97K10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.10758,17.22106]},"properties":{"population":529831,"capital":4,"place":"city","name":"97K20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.95383,14.09033]},"properties":{"population":397029,"capital":4,"place":"city","name":"97K30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.70705,17.49682]},"properties":{"population":404305,"capital":4,"place":"city","name":"97K40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.9249,17.43704]},"properties":{"population":515329,"capital":4,"place":"city","name":"97K50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.37776,14.28548]},"properties":{"population":424849,"capital":4,"place":"city","name":"97K60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.28028,14.15075]},"properties":{"population":451085,"capital":4,"place":"city","name":"97K70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[3.78699,17.70621]},"properties":{"population":420469,"capital":4,"place":"city","name":"97K80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.1095,14.09692]},"properties":{"population":325809,"capital":4,"place":"city","name":"97K99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[6.27367,13.97585]},"properties":{"population":207944,"capital":4,"place":"city","name":"97Kxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.66221,11.25126]},"properties":{"population":446590,"capital":4,"place":"city","name":"97M10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.12758,11.10497]},"properties":{"population":69314,"capital":4,"place":"city","name":"97M20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.91901,11.21741]},"properties":{"population":418965,"capital":4,"place":"city","name":"97M30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.50167,17.2461]},"properties":{"population":451085,"capital":4,"place":"city","name":"97M40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.68635,16.92271]},"properties":{"population":611146,"capital":4,"place":"city","name":"97M50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.83584,10.96923]},"properties":{"population":460517,"capital":4,"place":"city","name":"97M60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.11826,10.85345]},"properties":{"population":343398,"capital":4,"place":"city","name":"97M70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-11.17483,11.25688]},"properties":{"population":448863,"capital":4,"place":"city","name":"97M80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.898,11.06166]},"properties":{"population":376120,"capital":4,"place":"city","name":"97M99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13.06486,10.99718]},"properties":{"population":230258,"capital":4,"place":"city","name":"97Mxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.20951,28.01812]},"properties":{"population":230258,"capital":4,"place":"city","name":"97N10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.15644,28.074]},"properties":{"population":289037,"capital":4,"place":"city","name":"97N20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.25462,28.02179]},"properties":{"population":230258,"capital":4,"place":"city","name":"97N30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.26549,27.98928]},"properties":{"population":380666,"capital":4,"place":"city","name":"97N40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.17201,28.0168]},"properties":{"population":393182,"capital":4,"place":"city","name":"97N50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.01827,27.84852]},"properties":{"population":391202,"capital":4,"place":"city","name":"97N60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.14206,28.08545]},"properties":{"population":270805,"capital":4,"place":"city","name":"97N70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-4.99719,28.20789]},"properties":{"population":355534,"capital":4,"place":"city","name":"97N80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.4446,28.2298]},"properties":{"population":138629,"capital":4,"place":"city","name":"97N99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-5.47664,27.75039]},"properties":{"population":160943,"capital":4,"place":"city","name":"97Nxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.55227,-5.60518]},"properties":{"population":207944,"capital":4,"place":"city","name":"97P10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.83202,-5.97573]},"properties":{"population":373766,"capital":4,"place":"city","name":"97P20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.60124,-5.67502]},"properties":{"population":109861,"capital":4,"place":"city","name":"97P30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.68976,-5.7911]},"properties":{"population":219722,"capital":4,"place":"city","name":"97P40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.6548,-5.80503]},"properties":{"population":277258,"capital":4,"place":"city","name":"97P50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.89317,-5.5219]},"properties":{"population":69314,"capital":4,"place":"city","name":"97P70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.68005,-5.72037]},"properties":{"population":69314,"capital":4,"place":"city","name":"97P80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.55249,-5.60395]},"properties":{"population":179175,"capital":4,"place":"city","name":"97P99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.88523,-5.9482]},"properties":{"population":138629,"capital":4,"place":"city","name":"97Pxx"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.08239,14.87231]},"properties":{"population":69314,"capital":4,"place":"city","name":"97Q20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.10899,14.65384]},"properties":{"population":179175,"capital":4,"place":"city","name":"97Q99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.03577,14.78132]},"properties":{"population":160943,"capital":4,"place":"city","name":"97R40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.23082,14.94601]},"properties":{"population":69314,"capital":4,"place":"city","name":"97R50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[2.26053,14.8236]},"properties":{"population":69314,"capital":4,"place":"city","name":"97R60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.50941,12.10299]},"properties":{"population":194591,"capital":4,"place":"city","name":"97U10"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.69466,11.62259]},"properties":{"population":329583,"capital":4,"place":"city","name":"97U20"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.73434,12.10725]},"properties":{"population":283321,"capital":4,"place":"city","name":"97U30"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[5.0063,16.73562]},"properties":{"population":638181,"capital":4,"place":"city","name":"97U40"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.22052,11.90375]},"properties":{"population":523644,"capital":4,"place":"city","name":"97U50"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.45645,11.73219]},"properties":{"population":395124,"capital":4,"place":"city","name":"97U60"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.56926,11.78543]},"properties":{"population":584932,"capital":4,"place":"city","name":"97U70"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[4.67702,16.95234]},"properties":{"population":385014,"capital":4,"place":"city","name":"97U80"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.49142,11.85332]},"properties":{"population":321887,"capital":4,"place":"city","name":"97U99"}},
    {"type":"Feature","geometry":{"type":"Point","coordinates":[-12.66586,12.01288]},"properties":{"population":138629,"capital":4,"place":"city","name":"97Uxx"}}
]
};

    export default citiesData;
