import React from 'react';
import { Box, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; 

const RatioDisplay = ({ searchQuery, ratioData }) => {
  const matchedRatio = ratioData[searchQuery];

  if (matchedRatio !== undefined) {
    const increasePercentage = matchedRatio * 100;
    const formattedPercentage = increasePercentage.toFixed(2);

    return (
      <Box sx={{ margin: 2, padding: 2, border: '1px solid #ccc', borderRadius: 4, backgroundColor: '#fff' }}>
        <Typography variant="h6">
          {increasePercentage > 39 ? <TrendingUpIcon color="success" /> : null}
                {`In last 5 years ${formattedPercentage}% increase comparing to past`}
            </Typography>
            <br/>
            <Typography variant="h8">
            If increase is higher than average, you can see <TrendingUpIcon color="success" /> icon
            </Typography>

      </Box>
    );
  } else {
    return null; 
  }
};

export default RatioDisplay;
