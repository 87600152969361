import React from "react";
import L from "leaflet";
import landPolygonsData from './land-polygons';
import './map.css';
import "leaflet-side-by-side";
import "leaflet-simple-map-screenshoter";
import citiesData from './cities';  
import connectionsData from './intersection_results_level_5_merged_top_keyword.json';  
import 'leaflet-curve';
import citiesGraph from './cities_graph_filtered.json'; 
import ReactDOM from 'react-dom';
import GetClassNames from './GetClassNames';

const getColor = (d) => {
  return d > 5000
    ? "#4B000F"
    : d > 4000
    ? "#67000D"
    : d > 3000
    ? "#800026"
    : d > 2000
    ? "#A30026"
    : d > 1000
    ? "#BD0026"
    : d > 500
    ? "#E31A1C"
    : d > 200
    ? "#FC4E2A"
    : d > 100
    ? "#FD8D3C"
    : d > 50
    ? "#FEB24C"
    : d > 20
    ? "#FED976"
    : d > 10
    ? "#FFEDA0"
    : "#FFFFCC"; 
};


const dijkstra = {
  find_path: (graph, startNode, endNode) => {
      const distances = {};
      const previous = {};
      const pq = new PriorityQueue();
      const adjacencyList = {};

      graph.links.forEach(link => {
          if (!adjacencyList[link.source]) adjacencyList[link.source] = [];
          if (!adjacencyList[link.target]) adjacencyList[link.target] = [];
          adjacencyList[link.source].push({ node: link.target, weight: 1 });
          adjacencyList[link.target].push({ node: link.source, weight: 1 });
      });

      graph.nodes.forEach(node => {
          distances[node.id] = Infinity;
          previous[node.id] = null;
      });
      distances[startNode] = 0;
      pq.enqueue(startNode, 0);

      while (!pq.isEmpty()) {
          const { element: currentNode } = pq.dequeue();

          if (currentNode === endNode) {
              const path = [];
              let temp = endNode;
              while (temp) {
                  path.unshift(temp);
                  temp = previous[temp];
              }
              return path;
          }

          if (adjacencyList[currentNode]) {
              adjacencyList[currentNode].forEach(neighbor => {
                  const alt = distances[currentNode] + neighbor.weight;
                  if (alt < distances[neighbor.node]) {
                      distances[neighbor.node] = alt;
                      previous[neighbor.node] = currentNode;
                      pq.enqueue(neighbor.node, alt);
                  }
              });
          }
      }

      return []; 
  }
};

class PriorityQueue {
  constructor() {
      this.collection = [];
  }

  enqueue(element, priority) {
      const node = { element, priority };
      let added = false;
      for (let i = 0; i < this.collection.length; i++) {
          if (node.priority < this.collection[i].priority) {
              this.collection.splice(i, 0, node);
              added = true;
              break;
          }
      }
      if (!added) {
          this.collection.push(node);
      }
  }

  dequeue() {
      return this.collection.shift();
  }

  isEmpty() {
      return this.collection.length === 0;
  }
}

const getAuthorPublications = async (author) => {
  const apiUrl = `https://backend.mathmap.kwarc.info/api/author-publications?author=${encodeURIComponent(author)}`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch author publications, ERROR description - ', error);
    return null; 
  }
};

const getClassesPublications = async (classname) => {
  const apiUrl = `https://backend.mathmap.kwarc.info/api/search?q=${encodeURIComponent(classname)}`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch author publications:', error);
    return null; 
  }
};

const interpolateColor = (value, min, max) => {

  const normalizedValue = (value - min) / (max - min);

  const red = Math.floor(255 * normalizedValue);
  const blue = Math.floor(255 * (1 - normalizedValue));

  const redHex = red.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  return `#${redHex}00${blueHex}`;
};




class Map extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authorPublications: {},
      classesPublications: {}
    };
}


  componentDidMount() {
    this.map = L.map("map", {
      center: [10, 3],
      zoom: 5
    });

    const localTileLayer1 = L.tileLayer('http://localhost:8080/tile/{z}/{x}/{y}.png', {
      attribution: '&copy; Local Tile Server 1',
      minZoom: 1,
      maxZoom: 18,
      maxNativeZoom: 18
    }).addTo(this.map);

    const localTileLayer2 = L.tileLayer('http://localhost:8082/tile/{z}/{x}/{y}.png', {
      attribution: '&copy; Local Tile Server 2',
      minZoom: 1,
      maxZoom: 18,
      maxNativeZoom: 18
    }).addTo(this.map);

    const sideBySide = L.control.sideBySide(localTileLayer1, localTileLayer2);
    sideBySide.addTo(this.map);

    const screenshoterOptions = {
      position: 'topleft',
      iconUrl: "https://www.svgrepo.com/show/509189/photo.svg",
      caption: 'Save',
      captionFontSize: 14,
      captionFont: 'Arial',
      captionColor: 'black',
      captionBgColor: 'white'
    };

    L.simpleMapScreenshoter(screenshoterOptions).addTo(this.map);

    this.legend = L.control({ position: "bottomright" });

    this.legend.onAdd = function(map) {
      this._div = L.DomUtil.create("div", "info legend");
      this.update();
      return this._div;
    };

    this.legend.update = function() {
      const grades = [0, 10, 20, 50, 100, 200, 500, 1000, 2000, 3000, 4000, 5000];
      let labels = [];
      const wetlandSrc = 'https://wiki.openstreetmap.org/w/images/a/af/Rendering-natural-mud-mapnik.png'; 
      const wetlandText = 'Wetlands < 89.99'; 

      const grasslandSrc = 'https://wiki.openstreetmap.org/w/images/1/1f/Natural-Grassland.png'; 
      const grasslandText = '>= 89.99 Grass < 340.99'; 

      const scrubSrc = 'https://wiki.openstreetmap.org/w/images/e/e5/Rendering-area-natural-scrub.png'; 
      const scrubText = '340.99 >= Scrub < 849'; 

      const woodSrc = 'https://wiki.openstreetmap.org/w/images/c/cc/Rendering-area-natural-wood.png'; 
      const woodText = '849 >= Wood < 2033'; 

      const bareRockSrc = 'https://wiki.openstreetmap.org/w/images/6/6a/Bare_rock.png'; 
      const bareRockText = 'Bare rock >= 2033'; 

      for (let i = 0; i < grades.length; i++) {
        labels.push(
          '<i style="background:' +
          getColor(grades[i] + 1) +
          '"></i> ' +
          grades[i] +
          (grades[i + 1] ? "&ndash;" + grades[i + 1] + "<br>" : "+")
        );
      }
      labels.push('<br>');
      labels.push('<br>');

      labels.push(
        '<div>' +
        '<i style="background: linear-gradient(90deg, #0000ff, #7e00eb, #ac00d4, #cc00bd, #e300a4, #f4008c, #ff0075, #ff005f, #ff004a, #ff0036, #ff0021, #ff0000);width:100%;"></i>' +
        '</div>' +
        '<div>' +
        '<span>Min and max of </span>'+
        '</div>' +
        '<div>' +
        '<span> publications per author</span>' +
        '</div>'
      );


      labels.push('<br>');


      labels.push(
        '<div>' +
        '<img src="' + wetlandSrc + '" alt="' + wetlandText + '" style="width:45px;height:45px;">' +
        '</div>' +
        '<div>' +
        '<span>' + wetlandText + '</span>' +
        '</div>'
      );
      labels.push(
        '<div>' +
        '<img src="' + grasslandSrc + '" alt="' + grasslandText + '" style="width:45px;height:45px;">' +
        '</div>' +
        '<div>' +
        '<span>' + grasslandText + '</span>' +
        '</div>'
      );
      labels.push(
        '<div>' +
        '<img src="' + scrubSrc + '" alt="' + scrubText + '" style="width:45px;height:45px;">' +
        '</div>' +
        '<div>' +
        '<span>' + scrubText + '</span>' +
        '</div>'
      );
      labels.push(
        '<div>' +
        '<img src="' + woodSrc + '" alt="' + woodText + '" style="width:45px;height:45px;">' +
        '</div>' +
        '<div>' +
        '<span>' + woodText + '</span>' +
        '</div>'
      );
      labels.push(
        '<div>' +
        '<img src="' + bareRockSrc + '" alt="' + bareRockText + '" style="width:45px;height:45px;">' +
        '</div>' +
        '<div>' +
        '<span>' + bareRockText + '</span>' +
        '</div>'
      );

      this._div.innerHTML = labels.join("");
    };

    if (this.props.showLegend) {
      this.legend.addTo(this.map);
    }

    this.map.invalidateSize();

    this.updateMapLayers(this.props.showAll);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.showAll !== this.props.showAll ||
      prevProps.searchQuery !== this.props.searchQuery ||
      prevProps.authorSearchQuery !== this.props.authorSearchQuery ||
      prevProps.filterEnabled !== this.props.filterEnabled
    ) {
      this.updateMapLayers(this.props.showAll);
    }
    
    if (
      prevProps.generateConnections !== this.props.generateConnections ||
      prevProps.searchQuery !== this.props.searchQuery
    ) {
      if (this.props.generateConnections) {
        this.generateConnections(this.props.searchQuery);
      } else {

        if (this.connectionsLayer) {
          this.map.removeLayer(this.connectionsLayer);
        }
      }
    }    

    if (prevProps.showLegend !== this.props.showLegend) {
      if (this.props.showLegend) {
        this.legend.addTo(this.map);
      } else {
        this.map.removeControl(this.legend);
      }
    }
  }

  
  updateMapLayers = async (showAll) => {
    const { searchQuery, filterEnabled, authorSearchQuery } = this.props;

    let filteredData = landPolygonsData;
    let authorPublications = {};
    let classesPublications = this.state.classesPublications;

    if (!filterEnabled && (!searchQuery || searchQuery.trim() === '')) {
        if (this.geojson) {
            this.map.removeLayer(this.geojson);
        }

        this.geojson = L.geoJson(filteredData, {
            style: this.mapStyle,
            onEachFeature: this.onEachFeature,
        });

        if (showAll) {
            this.layer = L.layerGroup([this.geojson]).addTo(this.map);
        } else {
            this.map.removeLayer(this.layer);
        }

        return;
    }

    const promises = [];

    if (authorSearchQuery) {
        promises.push(getAuthorPublications(authorSearchQuery));
    }

    if (searchQuery && !/\d/.test(searchQuery)) {
        promises.push(getClassesPublications(searchQuery));
    }

    const results = await Promise.all(promises);

    if (authorSearchQuery) {
        authorPublications = results.shift();
        this.setState({ authorPublications });
    }

    if (searchQuery && !/\d/.test(searchQuery)) {
        classesPublications = results.shift();
        this.setState({ classesPublications });
    }

    if (filterEnabled) {
        filteredData = {
            ...filteredData,
            features: filteredData.features.filter((feature) => {
                const featureName = feature.properties.name ? feature.properties.name.toLowerCase() : '';

                let matchesSearchQuery = true;
                if (searchQuery) {
                    matchesSearchQuery = /\d/.test(searchQuery) ? 
                        featureName.startsWith(searchQuery.toLowerCase()) : 
                        classesPublications?.result && Object.values(classesPublications.result).some((cls) =>
                            cls.code && cls.code.toLowerCase() === featureName
                        );
                }

                let matchesAuthorPublications = true;
                if (authorSearchQuery && Object.keys(authorPublications).length > 0) {
                    matchesAuthorPublications = authorPublications.hasOwnProperty(feature.properties.name);
                }

                return matchesSearchQuery && matchesAuthorPublications;
            }),
        };
    }

    if (this.geojson) {
        this.map.removeLayer(this.geojson);
    }

    this.geojson = L.geoJson(filteredData, {
        style: this.mapStyle,
        onEachFeature: this.onEachFeature,
    });

    if (showAll) {
        this.layer = L.layerGroup([this.geojson]).addTo(this.map);
    } else {
        this.map.removeLayer(this.layer);
    }
};



  generateConnections = (searchQuery) => {
    if (this.connectionsLayer) {
        this.map.removeLayer(this.connectionsLayer);
    }

    if (searchQuery) {
        const filteredConnections = Object.entries(connectionsData).filter(
            ([key, value]) => {
                const [city1, ] = key.split(" - ");
                return city1.toLowerCase() === searchQuery.toLowerCase();
            }
        );

        const connections = L.layerGroup();

        filteredConnections.forEach(([key, description]) => {
            const [city1, city2] = key.split(" - ");
            const city1Data = citiesData.features.find(
                (city) => city.properties.name.toLowerCase() === city1.toLowerCase()
            );
            const city2Data = citiesData.features.find(
                (city) => city.properties.name.toLowerCase() === city2.toLowerCase()
            );

            if (city1Data && city2Data) {
                

                try {
                    console.log(`Checking cities in graph ${city1}, ${city2}`);
                    console.log('citiesGraph nodes found', citiesGraph.nodes.map(node => node.id));
                    console.log('citiesGraph edges', citiesGraph.links);

                    const city1InGraph = citiesGraph.nodes.find(node => node.id === city1);
                    const city2InGraph = citiesGraph.nodes.find(node => node.id === city2);

                    if (city1InGraph && city2InGraph) {

                      const path = dijkstra.find_path(citiesGraph, city1, city2);

                        if (path.length > 0) {

                          const pathCoords = path.map(cityName => {
                                const coords = citiesData.features.find(
                                    (city) => city.properties.name.toLowerCase() === cityName.toLowerCase()
                                )?.geometry.coordinates;
                                return coords ? [coords[1], coords[0]] : null;
                            }).filter(Boolean);


                            const polyline = L.polyline(pathCoords, {
                                color: '#2196f3', 
                                weight: 2,
                                opacity: 0.5,
                            });

                            const modifiedDescription = `<strong>[${city2}]</strong> ~ ${description}`;

                            const lastCoords = pathCoords[pathCoords.length - 1];
                            polyline.bindTooltip(modifiedDescription, { permanent: true, direction: "top" }).addTo(connections);
                            polyline.on('add', () => {
                                polyline.openTooltip(lastCoords);
                            });

                        } else {
                            console.warn(`No path found from ${city1} to ${city2}`);
                        }
                    } else {
                        console.warn(`City not found in graph: ${city1} or ${city2}`);
                    }
                } catch (error) {
                    console.error(`Could not find a path between ${city1} and ${city2}:`, error);
                }
            } else {
                console.warn(`No data was found for ${city1} or ${city2}`);
            }
        });

        this.connectionsLayer = connections.addTo(this.map);
    }
};
  
  
  

mapStyle = (feature) => {
  const { authorSearchQuery } = this.props;
  const { authorPublications } = this.state;

  let fillColor = getColor(feature.properties.density);
  let borderColor = "white";
  let weight_style = 2;

  if (authorSearchQuery && authorPublications && authorPublications.hasOwnProperty(feature.properties.name)) {
    const publicationCount = authorPublications[feature.properties.name];
    
    const counts = Object.values(authorPublications);
    const minCount = Math.min(...counts);
    const maxCount = Math.max(...counts);

    borderColor = interpolateColor(publicationCount, minCount, maxCount);
    weight_style = 5
}

  return {
      weight: weight_style,
      opacity: 1,
      color: borderColor,
      dashArray: "3",
      fillOpacity: 0.7,
      fillColor: fillColor
  };

};
onEachFeature = (feature, layer) => {
  layer.on({
    mouseover: this.highlightFeature,
    mouseout: this.resetHighlight,
    click: (e) => this.zoomToFeature(e, feature)
  });
};



highlightFeature = (e) => {
  const layer = e.target;
  const { name, density } = layer.feature.properties;

  layer.setStyle({
      weight: 5,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7
  });

  layer.bringToFront();


  const popupContent = document.createElement('div');
  popupContent.innerHTML = `
    <div id="results-panel-container1"></div>
    <h6>${name}</h6>
    <p>Number of publications: ${density}</p>
    <div id="results-panel-container1"></div>
  `;

  ReactDOM.render(
    <GetClassNames searchQuery={name} className="results-panel" />,
    popupContent.querySelector('#results-panel-container1'),
  );

  if (this.popup) {
      this.popup.setContent(popupContent);
      this.popup.setLatLng(e.latlng);  
  } else {

    this.popup = L.popup({
          closeButton: false,
          offset: L.point(0, -10) 
      })
      .setLatLng(e.latlng)  
      .setContent(popupContent)
      .openOn(this.map);
  }
};

resetHighlight = (e) => {
  const layer = e.target;
  this.geojson.resetStyle(layer);

  if (this.popup) {
      this.map.closePopup(this.popup);
      this.popup = null;
}

};

  zoomToFeature = (e, feature) => {
    if (!feature.properties.name) {
      alert("Feature name could not be identified, please use the search bar.");
      return;
    }

    this.map.fitBounds(e.target.getBounds());
    this.props.onFeatureClick(feature.properties.name);
  };

  render() {
    return (
      <div id="map" style={{ width: '100%', height: '100%' }} />
    );
  }
}

export default Map;
